<script setup>
import { ref, computed, watch } from "vue";
import { useStore } from "@/store";
import PoinHeaderStartMenu from "./PoinHeaderStartMenu.vue";

const store = useStore();

const active = ref(false);

const btnClickActive = computed(
  () => store.getters["common/openPoinStartMenu"]
);

watch(
  [btnClickActive, active],
  (
    [btnClickActiveValue, openValue],
    [prevBtnClickActiveValue, prevOpenValue]
  ) => {
    if (btnClickActiveValue !== prevBtnClickActiveValue) {
      if (btnClickActiveValue) {
        active.value = true;
      }
    }

    if (openValue !== prevOpenValue) {
      if (!openValue) {
        store.commit("common/activeStartMenu", false);
      }
    }
  }
);
</script>

<template>
  <v-menu v-model="active" offset-y left content-class="start-menu">
    <template v-slot:activator="{ on }">
      <button class="p-btn" v-on="on">
        <span class="p-btn__content">
          시작하기
          <p-icon
            icon="DownSm/White"
            size="20"
            class="rotate-icon"
            :style="{ transform: active ? 'rotate(180deg)' : 'rotate(0deg)' }"
          />
        </span>
      </button>
    </template>
    <PoinHeaderStartMenu />
  </v-menu>
</template>

<style lang="scss" scoped>
.start-menu {
  position: fixed !important;
  top: 56px !important;
  border-radius: 4px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
}
.p-btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  padding: 0 8px 0 12px;
  cursor: pointer;
  border-radius: 4px;
  background-color: $white;
  @include hover-before;
  background-color: $primary;
  color: $white;
  height: 32px;
  &:before {
    background-color: $primary800;
  }
  &:focus::before,
  &:hover::before {
    opacity: 1;
  }
  .p-btn__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 0 auto;
    position: relative;
    gap: 4px;
    font-size: 14px;
    font-weight: 500;
  }
}
</style>
