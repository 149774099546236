import { createPinia } from "pinia";
import { markRaw } from "vue";
import router from "@/router";
import { api, apiV2 } from "@/store";

const pinia = createPinia();

// 플러그인을 통해 전역 속성 주입
pinia.use(({ store }) => {
  store.router = markRaw(router);
  store.apiV1 = markRaw(api);
  store.api = markRaw(apiV2);
});

export default pinia;
