<script setup>
import { onBeforeUnmount, onMounted } from "vue";
import MobileNavItem from "./MobileNavItem.vue";
import { campusMakers, supporters, communities } from "./navigation";
onMounted(() => {
  document.body.classList.add("scroll-lock");
});
onBeforeUnmount(() => {
  document.body.classList.remove("scroll-lock");
});
</script>

<template>
  <nav class="navigation">
    <ul class="pt-5 pb-15 w-full d-flex-column">
      <MobileNavItem text="캠퍼스 메이커" :items="campusMakers" />
      <MobileNavItem text="성장 지원" :items="supporters" />
      <MobileNavItem text="커뮤니티" :items="communities" />
    </ul>
  </nav>
</template>

<style lang="scss" scoped>
.navigation {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  height: 100dvh;
  width: 292px;
  background-color: $white;
  z-index: 1000;
  box-shadow: 0 -1px 0 0 $gray3;
  overflow: auto;
  padding: 0 var(--gutter);
  @include hide-scrollbar;
}
</style>
