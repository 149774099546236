<template>
  <div class="webview">
    <router-view class="router-view" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "",
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/getAuthenticated",
    }),
  },
  methods: {
    ...mapActions({}),
  },
  created() {},
  mounted() {},
};
</script>
<style scoped lang="scss">
.webview {
  .router-view {
    min-height: var(--main-content-height);
    padding-bottom: 40px;
  }
}
</style>
