<template>
  <v-dialog
    :value="alert"
    max-width="374"
    persistent
    style="z-index: 10001"
    no-click-animation
    content-class="white hide-scrollbar p-alert"
    :fullscreen="isSm && !!lottie"
  >
    <div
      class="alert__wrap text-center py-6 px-5 d-flex-column align-center"
      :class="{ 'm-fullscreen': isSm && !!lottie }"
    >
      <div id="lottie" class="lottie" v-if="lottie"></div>
      <v-icon v-else :color="iconColor" size="48" class="mb-5">{{
        getIcon
      }}</v-icon>
      <div class="w-full d-flex flex-column justify-center mb-6">
        <b
          v-if="title"
          class="black--text"
          :class="!!lottie ? 'text-h4-medium mb-3' : 'text-h5-medium mb-4'"
          >{{ title }}</b
        >
        <div
          class="text-body-2-regular keep-all"
          v-html="msg"
          :class="{ 'gray--text text--darken-3': Boolean(title) }"
        ></div>
      </div>

      <p-btn
        color="primary"
        class="w-full confirm-btn"
        :loading="loading"
        @click="onSubmit"
        v-if="!csLink"
        :large="isSm && !!lottie"
      >
        {{ btnText }}
      </p-btn>
      <div class="confirm--actions" v-else>
        <p-btn
          :large="isSm && !!lottie"
          :loading="loading"
          color="primary"
          class="w-full"
          @click="onSubmit"
        >
          {{ btnText }}
        </p-btn>
        <p-btn
          :large="isSm && !!lottie"
          color="primarySub"
          class="w-full"
          @click="ask"
          >문의하기</p-btn
        >
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { webBaseUrl, isLocal } from "@/config";
import ChannelTalk from "@/utils/ChannelService";
import lottie from "lottie-web";

export default {
  name: "PoinUiAlert",
  props: {
    alert: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    msg: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    csLink: {
      type: Boolean,
      default: false,
    },
    btnText: {
      type: String,
      default: "확인",
    },
    icon: {
      type: String,
      default: "alert",
    },
    lottie: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters({
      domain: "campuses/getCampusDomain",
      roleCd: "members/getMemberRoleCd",
      isStaff: "members/isStaff",
      isSm: "common/isSm",
      campusOnly: "common/campusOnly",
    }),
    csLinkRoute() {
      if (!this.domain || this.roleCd === "A") {
        return `${webBaseUrl}/help/ask`;
      }
      if (this.isLocal) {
        return this.$router.resolve({
          name: "campus-help-faq",
          params: this.$route.params,
        }).href;
      }
      const baseUrl = window.location.origin;
      return `${baseUrl}/help/faq`;
    },
    iconColor() {
      switch (this.icon) {
        case "alert":
          return "error";
        case "confirm":
          return "success";
        case "info":
          return "info";
      }
      return "error";
    },
    getIcon() {
      switch (this.icon) {
        case "alert":
          return "$alert-circle";
        case "confirm":
          return "$confirm-circle";
        case "info":
          return "$info-circle";
      }
      return "$alert-circle";
    },
  },
  watch: {
    alert(open) {
      if (open && this.lottie) {
        this.$nextTick(() => {
          lottie.loadAnimation({
            container: document.getElementById("lottie"), // the dom element that will contain the animation
            renderer: "svg",
            loop: true,
            autoplay: true,
            path: `https://file.poincampus.com/lottie/${this.lottie}.json`, // the path to the animation json
            // path: "https://assets5.lottiefiles.com/datafiles/zc3XRzudyWE36ZBJr7PIkkqq0PFIrIBgp4ojqShI/newAnimation.json", // the path to the animation json
          });
        });
      } else {
        lottie.destroy();
      }
    },
  },
  methods: {
    ask() {
      if (!this.campusOnly || this.isStaff) {
        ChannelTalk.showMessenger();
      } else {
        this.$router.push({
          name: "campus-help-faq",
          params: this.$route.params,
        });
      }
      this.onSubmit();
    },
    onSubmit() {
      if (this.loading) {
        return;
      }
      this.$emit("submit");
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm--actions {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  width: 100%;
}
.lottie {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  margin-bottom: 24px;
}
.m-fullscreen {
  height: 100%;
  .confirm-btn,
  .confirm--actions {
    margin-top: auto;
  }
}
</style>
