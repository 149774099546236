import { endpointV2 as endpoint } from "@/config";
// 커뮤니티 -> 커뮤니티 목록 조회

export async function getCommunities({
  campusId,
  memberId,
  limit,
  sort,
  adminYn,
  keyword,
  shutdownYn,
  lastKey,
  openYn,
  excludeProductId,
  includeProductId,
  allYn,
  myYn,
  joinType,
  delegatorId,
}) {
  const params = {
    ...(memberId && { memberId }),
    ...(limit && { limit }),
    ...(sort && { sort }),
    ...(campusId && { campusId }),
    ...(adminYn && { adminYn }),
    ...(keyword && { keyword }),
    ...(shutdownYn && { shutdownYn }),
    ...(lastKey && { lastKey }),
    ...(openYn && { openYn }),
    ...(excludeProductId && { excludeProductId }),
    ...(includeProductId && { includeProductId }),
    ...(allYn && { allYn }),
    ...(myYn && { myYn }),
    ...(joinType && { joinType }),
    ...(delegatorId && { delegatorId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.communities}`,
    params,
  });
}
// 커뮤니티 -> 비로그인 커뮤니티 목록 조회
export async function getPublicCommunities({
  limit,
  campusId,
  lastKey,
  keyword,
  sort,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(limit && { limit }),
    ...(lastKey && { lastKey }),
    ...(keyword && { keyword }),
    ...(sort && { sort }),
  };
  return this.getMethod("get", false, {
    url: `${endpoint.communities}/public`,
    params,
  });
}
// 커뮤니티 -> 커뮤니티 생성
export async function postCommunity({
  campusId,
  memberId,
  name,
  images,
  products,
  joinType,
  openYn,
  addMembers,
  thumbnailImage,
}) {
  const data = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(name && { name }),
    ...(images && { images }),
    ...(products && { products }),
    ...(joinType && { joinType }),
    ...(openYn !== undefined && { openYn }),
    ...(addMembers && { addMembers }),
    ...(thumbnailImage && { thumbnailImage }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.communities}/`,
    data,
  });
}
// 커뮤니티 -> 커뮤니티 단건 조회
export async function getCommunity({ campusId, memberId, id }) {
  if (!id) {
    return;
  }
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(id && { id }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.communities}/${id}`,
    params,
  });
}
// 커뮤니티 -> 커뮤니티 단건 조회 (비회원)
export async function getCommunityPublic({ campusId, memberId, id }) {
  if (!id) {
    return;
  }
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(id && { id }),
  };
  return this.getMethod("get", false, {
    url: `${endpoint.communities}/public/${id}`,
    params,
  });
}
//커뮤니티 수정
export async function putCommunity({
  images,
  productIds,
  joinType,
  changeType,
  campusId,
  name,
  thumbnailImage,
  openYn,
  memberId,
  shutdownYn,
  id,
}) {
  if (!id) {
    return;
  }
  const data = {
    ...(images && { images }),
    ...(productIds && { productIds }),
    ...(joinType && { joinType }),
    ...(changeType && { changeType }),
    ...(campusId && { campusId }),
    ...(name && { name }),
    ...(thumbnailImage && { thumbnailImage }),
    ...(openYn !== undefined && { openYn }),
    ...(memberId && { memberId }),
    ...(shutdownYn !== undefined && { shutdownYn }),
  };
  return this.getMethod("put", true, {
    url: `${endpoint.communities}/${id}`,
    data,
  });
}
//커뮤니티 삭제
export async function deleteCommunity({ id, campusId, memberId }) {
  if (!id) {
    return;
  }
  const data = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("delete", true, {
    url: `${endpoint.communities}/${id}`,
    data,
  });
}

//커뮤니티 -> 커뮤니티 게시글 목록 조회
export async function getPosts({
  keyword,
  memberId,
  limit,
  privateYn,
  campusId,
  hotYn,
  adminYn,
  comId,
  endDate,
  startDate,
  lastKey,
  notiYn,
  sort,
}) {
  const params = {
    ...(keyword && { keyword }),
    ...(memberId && { memberId }),
    ...(limit && { limit }),
    ...(privateYn !== undefined && { privateYn }),
    ...(campusId && { campusId }),
    ...(hotYn && { hotYn }),
    ...(adminYn && { adminYn }),
    ...(comId && { comId }),
    ...(endDate && { endDate }),
    ...(startDate && { startDate }),
    ...(lastKey && { lastKey }),
    ...(notiYn !== undefined && { notiYn }),
    ...(sort && { sort }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.communities}/posts`,
    params,
  });
}
//커뮤니티 -> 커뮤니티 게시글 목록 조회 (비회원)
export async function getPostsPublic({
  keyword,
  limit,
  privateYn,
  campusId,
  hotYn,
  adminYn,
  comId,
  endDate,
  startDate,
  lastKey,
  notiYn,
  sort,
}) {
  const params = {
    ...(keyword && { keyword }),
    ...(limit && { limit }),
    ...(privateYn !== undefined && { privateYn }),
    ...(campusId && { campusId }),
    ...(hotYn && { hotYn }),
    ...(adminYn && { adminYn }),
    ...(comId && { comId }),
    ...(endDate && { endDate }),
    ...(startDate && { startDate }),
    ...(lastKey && { lastKey }),
    ...(notiYn !== undefined && { notiYn }),
    ...(sort && { sort }),
  };
  return this.getMethod("get", false, {
    url: `${endpoint.communities}/posts/public`,
    params,
  });
}
export async function postPost({
  privateYn,
  pinYn,
  subject,
  conts,
  campusId,
  comIds,
  notiYn,
  attachImages,
  memberId,
  attaches,
}) {
  const data = {
    ...(privateYn !== undefined && { privateYn }),
    ...(pinYn !== undefined && { pinYn }),
    ...(subject && { subject }),
    ...(conts && { conts }),
    ...(campusId && { campusId }),
    ...(comIds && { comIds }),
    ...(notiYn && { notiYn }),
    ...(attachImages && { attachImages }),
    ...(memberId && { memberId }),
    ...(attaches && { attaches }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.communities}/posts`,
    data,
  });
}
export async function getMyPosts({
  memberId,
  limit,
  campusId,
  comId,
  endDate,
  targetId,
  startDate,
  lastKey,
  keyword,
}) {
  if (!targetId) {
    return;
  }
  const params = {
    ...(memberId && { memberId }),
    ...(limit && { limit }),
    ...(campusId && { campusId }),
    ...(comId && { comId }),
    ...(endDate && { endDate }),
    ...(targetId && { targetId }),
    ...(startDate && { startDate }),
    ...(lastKey && { lastKey }),
    ...(keyword && { keyword }),
  };

  return this.getMethod("get", true, {
    url: `${endpoint.communities}/posts/my/${targetId}`,
    params,
  });
}
export async function getPost({ memberId, campusId, id }) {
  if (!id) {
    return;
  }
  const params = {
    ...(memberId && { memberId }),
    ...(campusId && { campusId }),
    ...(id && { id }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.communities}/posts/${id}`,
    params,
  });
}
export async function getPostPublic({ campusId, id }) {
  if (!id) {
    return;
  }
  const params = {
    ...(campusId && { campusId }),
    ...(id && { id }),
  };
  return this.getMethod("get", false, {
    url: `${endpoint.communities}/posts/public/${id}`,
    params,
  });
}
export async function putPost({
  id,
  memberId,
  campusId,
  modAll,
  privateYn,
  pinYn,
  subject,
  conts,
  comId,
  notiYn,
  attachImages,
  attaches,
}) {
  if (!id) {
    return;
  }
  const data = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(modAll && { modAll }),
    ...(privateYn !== undefined && { privateYn }),
    ...(pinYn !== undefined && { pinYn }),
    ...(subject && { subject }),
    ...(conts && { conts }),
    ...(comId && { comId }),
    ...(notiYn && { notiYn }),
    ...(attachImages && { attachImages }),
    ...(attaches && { attaches }),
  };
  return this.getMethod("put", true, {
    url: `${endpoint.communities}/posts/${id}`,
    data,
  });
}
export async function deletePost({ id, memberId, campusId, delAll }) {
  if (!id) {
    return;
  }
  const data = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(delAll && { delAll }),
  };
  return this.getMethod("delete", true, {
    url: `${endpoint.communities}/posts/${id}`,
    data,
  });
}

//커뮤니티 담당자 관리  조회
export async function getCommunityDelegators({ memberId, campusId, id }) {
  const params = {
    ...(memberId && { memberId }),
    ...(campusId && { campusId }),
    ...(id && { id }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.communities}/delegators`,
    params,
  });
}
//커뮤니티 담당자 관리
export async function postCommunityDelegators({
  changeType,
  campusId,
  id,
  targets,
  memberId,
}) {
  const data = {
    ...(changeType && { changeType }),
    ...(campusId && { campusId }),
    ...(id && { id }),
    ...(targets && { targets }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("put", true, {
    url: `${endpoint.communities}/delegators`,
    data,
  });
}
