export default (apiInstance) => ({
  // 요금제 정보 전체 조회
  reqGetPlanList: async (store, payload = {}) => {
    const { planType, useYn = true } = payload;
    const result = await apiInstance.plans.getPlanList({
      planType,
      useYn,
    });
    if (result.success) {
      store.commit("setPlanList", result.data);
    }
    return result;
  },
  // 요금제 정보 단건 조회
  reqGetPlan: async (store, { planType, id }) => {
    const result = await apiInstance.plans.getPlan({ planType, id });
    if (result.success) {
      store.commit("setPlan", result.data);
    }
    return result;
  },

  // v2 요금 플랜 전체 조회
  reqGetPlanListV2: async (store, payload = {}) => {
    const { planType = "BASIC", useYn = true } = payload;
    if (!planType) {
      return { success: false };
    }
    const result = await apiInstance.plans.getPlanListV2({
      planType,
      useYn,
    });
    if (result.success) {
      store.commit("setPlanListV2", result.data.results);
    }
    return result;
  },
  // v2 요금 플랜 단건 조회
  reqGetPlanV2: async (store, { planType, id }) => {
    const result = await apiInstance.plans.getPlanV2({ planType, id });
    if (result.success) {
      store.commit("setPlanV2", result.data);
    }
    return result;
  },
  // v2 요금 플랜 생성
  postPlan: async (store, payload) => {
    const {
      image,
      planType,
      startDttm,
      endDttm,
      planCode,
      spec,
      name,
      desc,
      monthly,
      annually,
      promotion,
      seq,
      useYn,
      defaultYn,
    } = payload;

    const data = {
      image,
      planType,
      startDttm,
      endDttm,
      planCode,
      spec,
      name,
      desc,
      monthly,
      annually,
      promotion,
      seq,
      useYn,
      defaultYn,
    };
    const result = await apiInstance.plans.postPlan(data);

    return result;
  },
});
