import globalStore from "@/store";

export const setPwaInfo = ({
  iconPath = "https://poincampus.com/icon/common/logo_app.png",
  campusName = "PoinCampus",
} = {}) => {
  const campusUuid = globalStore.getters["campuses/getCampusUuid"];
  let href = "/manifest.json";
  const baseUrl = window.location.origin;
  if (campusUuid && campusName !== "PoinCampus") {
    href = `${baseUrl}/manifest.json?ownerUuid=${campusUuid}`;
  }
  document.getElementById("manifest")?.setAttribute("href", href);
  document.getElementById("appleAppIcon")?.setAttribute("href", iconPath);
  document.getElementById("appleAppTitle")?.setAttribute("content", campusName);
};
