import { ProgressStatus } from "@/enums/membersEnum";
import { ContentType as ContentTypeEnum } from "@/enums/productsEnum";
import { getDateFormat } from "@/utils/date-format";

export default {
  getPlaylist(state) {
    return state.playlist?.filter((p) => p.member?.memberStatus !== "CANCEL");
  },
  getPlaylistAutoPlayYn(state) {
    return state.playlistAutoPlayYn;
  },
  getActivitiesLastKey(state) {
    return state.activities.lastKey;
  },
  getActivities(state) {
    return state.activities.items;
  },
  getRoomProduct(state) {
    return state.roomProduct;
  },
  getRoomContents(state) {
    return state.roomContents;
  },
  getRoomContent(state) {
    return state.roomContent;
  },
  getFilteredContents(state) {
    // Case 1. 순차 재생일 때
    if (state.roomProduct?.SETTING?.options?.sequencialYn) {
      /**
       * 이미 수강한 콘텐츠를 제외한 첫번째 콘텐츠는 wait: false,
       * 해당 콘텐츠 다음 순서의 콘텐츠들은 wait: true,
       * 로 설정한다.
       *
       * wait: true인 콘텐츠는 접근할 수 없다.
       */
      let wait = false;

      const isComplete = (content) => {
        const group = [ProgressStatus.COMPLETE, ProgressStatus.COMPLETE_FORCE];
        if (group.includes(content?.activity?.completeStatus)) {
          return true;
        }
        return false;
      };

      const isRealContent = (content) => {
        const group = [ContentTypeEnum.CURRICULUM, ContentTypeEnum.SECTION];
        if (group.includes(content.contentType)) {
          return false;
        }
        return true;
      };

      const setWait = (content) => {
        if (!isRealContent(content)) {
          return;
        }
        if (isComplete(content)) {
          return;
        }
        wait = true;
      };

      const arr = [];
      const newContents =
        state.roomContents?.length > 0
          ? structuredClone(state.roomContents)
          : [];

      newContents.forEach((content) => {
        const success = isComplete(content) || content.previewYn;
        if (content.items?.length > 0) {
          content.items.forEach((child) => {
            const success = isComplete(child) || child.previewYn;
            child.wait = success ? false : wait;
            if (!wait) {
              setWait(child);
            }
            if (child.items?.length > 0) {
              child.items.forEach((descendant) => {
                const success = isComplete(descendant) || descendant.previewYn;
                descendant.wait = success ? false : wait;
                if (!wait) {
                  setWait(descendant);
                }
              });
            }
          });
        }
        arr.push({ ...content, wait: success ? false : wait });
        if (!wait) {
          setWait(content);
        }
      });
      return arr;
    }
    // Case 2.순차재생이 아닐 때
    return state.roomContents || [];
  },
  getQuestions(state) {
    return state.questions;
  },
  getCurrentIndex(state) {
    return state.currentIndex;
  },
  getCurrentTab(state) {
    return state.currentTab;
  },
  getQuestion(state) {
    return state.questions?.[state.currentIndex];
  },
  getQuiz(state) {
    return state.quiz || {};
  },
  getQuizProgress(state) {
    return state.quizProgress;
  },
  getQuizStandby(state) {
    return state.quizProgress === "STANDBY";
  },
  getQuizInProgress(state) {
    return state.quizProgress === "PROGRESS";
  },
  getQuizDone(state) {
    return state.quizProgress === "DONE";
  },
  getLocalVolumes(state) {
    return state.localVolumes;
  },
  getQuizStats(state) {
    return state.quizStats;
  },
  getAside(state) {
    return state.aside;
  },
  getApplication(state) {
    return state.application || {};
  },
  getApplicationProgress(state) {
    return state.applicationProgress;
  },
  getApplicationStandby(state) {
    return state.applicationProgress === "STANDBY";
  },
  getApplicationInProgress(state) {
    return state.applicationProgress === "PROGRESS";
  },
  getApplicationDone(state) {
    return state.applicationProgress === "DONE";
  },
  getApplicationStats(state) {
    return state.applicationStats.stat;
  },
  getApplicationStatItems(state) {
    return state.applicationStats.results;
  },
  getApplicationStatUsers(state) {
    return state.applicationStatUsers.items;
  },
  getApplicationStatUsersLastKey(state) {
    return state.applicationStatUsers.lastKey;
  },
  isSupportsVideoTimeline(state) {
    return state.isSupportsVideoTimeline;
  },
};
