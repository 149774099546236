import { ref, reactive, computed, watch } from "vue";
import { defineStore } from "pinia";

export const useEditorStore = defineStore("editor", () => {
  // 이메일 / 사이트 디자인 공통
  const editor = ref(null);
  const activeElement = ref(null);
  const globalStyles = ref({});
  function setStyles(styles) {
    activeElement.value.styles = { ...styles };
  }
  function setButtonStyles(styles) {
    activeElement.value.elStyles = { ...styles };
  }
  function setGlobalStyles(styles, key) {
    globalStyles.value[key] = { ...styles };
  }
  function destroy() {
    if (editor.value) {
      editor.value.destroy();
    }
  }

  // 이메일
  const mailSk = ref("");
  const items = ref([]);
  const footer = ref({
    uuid: "FOOTER",
    blockType: "FOOTER",
    text: "",
    link: "",
    styles: {
      backgroundColor: "transparent",
      paddingTop: 12,
      paddingBottom: 12,
      paddingLeft: 24,
      paddingRight: 24,
    },
  });
  const links = ref([]);
  const leftNav = ref("BLOCK");

  function setPrevItems(blocks = []) {
    items.value = [];
    blocks.forEach((item, idx, arr) => {
      if (idx === arr.length - 1) {
        footer.value = { ...item };
      } else {
        items.value.push({ ...item });
      }
    });
  }
  function clear() {
    destroy();
    editor.value = null;
    activeElement.value = null;
    leftNav.value = "BLOCK";
    links.value = [];
    items.value = [];
    footer.value = {
      uuid: "FOOTER",
      blockType: "FOOTER",
      text: "",
      link: "",
      styles: {
        backgroundColor: "transparent",
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 24,
        paddingRight: 24,
      },
    };
  }

  return {
    mailSk,
    items,
    footer,
    links,
    editor,
    leftNav,
    globalStyles,
    activeElement,
    setStyles,
    setButtonStyles,
    setGlobalStyles,
    destroy,
    setPrevItems,
    clear,
  };
});
