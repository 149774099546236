export default {
  setNotificationBar: (state, info) => {
    state.notificationBar = { ...info };
  },
  // 전체
  setInterviewList: (state, list) => {
    state.interviewList = [...list];
  },
  // 단건
  setInterviewItem: (state, item) => {
    state.interviewItem = { ...item };
  },
};
