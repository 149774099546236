import { useStore } from "@/store";

// piniaSessionStorage.js
export function piniaSessionStoragePlugin({ store }) {
  // 초기 상태를 세션 스토리지에서 불러옵니다.
  const list = ["checkout", "orders", "payment"];
  const vuex = useStore();
  const campusId = vuex.getters["campuses/getCampusUuid"];

  if (list.includes(store.$id) && campusId) {
    const id = "poin-pinia-" + store.$id + ":" + campusId;
    const savedState = sessionStorage.getItem(id);
    if (savedState) {
      store.$patch(JSON.parse(savedState));
    }

    // 상태 변경을 감시하고 세션 스토리지에 저장합니다.
    store.$subscribe(
      (mutation, state) => {
        sessionStorage.setItem(id, JSON.stringify(state));
      },
      { detached: true }
    );

    store.$resetSession = () => {
      sessionStorage.removeItem(id);
      store.$reset(); // 초기화
    };
  }
}
