class IndexedDBUtil {
  constructor(dbName, storeName) {
    this.dbName = dbName;
    this.storeName = storeName;
    this.dbVersion = 2;
    this.db = null;
  }

  // 데이터베이스 열기
  openDB() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, this.dbVersion);

      request.onupgradeneeded = (event) => {
        this.db = event.target.result;
        if (!this.db.objectStoreNames.contains(this.storeName)) {
          this.db.createObjectStore(this.storeName, {
            keyPath: "id",
            autoIncrement: true,
          });
        }
      };

      request.onsuccess = (event) => {
        this.db = event.target.result;
        resolve(this.db);
      };

      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  }

  // 트랜잭션 생성
  createTransaction(storeName, mode = "readonly") {
    const transaction = this.db.transaction(storeName, mode);
    return transaction.objectStore(storeName);
  }

  // 데이터 추가
  addData(data) {
    return new Promise((resolve, reject) => {
      const store = this.createTransaction(this.storeName, "readwrite");
      const request = store.add(data);

      request.onsuccess = () => {
        resolve(request.result);
      };

      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  }

  // 데이터 가져오기
  getData(key) {
    return new Promise((resolve, reject) => {
      const store = this.createTransaction(this.storeName);
      const request = store.get(key);

      request.onsuccess = () => {
        resolve(request.result);
      };

      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  }

  // 모든 데이터 가져오기
  getAllData() {
    return new Promise((resolve, reject) => {
      const store = this.createTransaction(this.storeName);
      const request = store.getAll();

      request.onsuccess = () => {
        resolve(request.result);
      };

      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  }

  // 데이터 업데이트
  updateData(data) {
    return new Promise((resolve, reject) => {
      const store = this.createTransaction(this.storeName, "readwrite");
      const request = store.put(data);

      request.onsuccess = () => {
        resolve(request.result);
      };

      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  }

  // 데이터 삭제
  deleteData(key) {
    return new Promise((resolve, reject) => {
      const store = this.createTransaction(this.storeName, "readwrite");
      const request = store.delete(key);

      request.onsuccess = () => {
        resolve(request.result);
      };

      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  }

  clearAllData() {
    return new Promise((resolve, reject) => {
      const store = this.createTransaction(this.storeName, "readwrite");
      const request = store.clear();

      request.onsuccess = () => {
        resolve();
      };

      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  }
}

export default IndexedDBUtil;
