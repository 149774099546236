export default (apiInstance) => ({
  checkToken: async (store, payload) => {
    const {
      code,
      userUuid = store.rootGetters["users/getUserUuid"],
      grantType,
      redirectUri,
    } = payload;
    let data = {
      userUuid,
      grantType,
      redirectUri,
    };
    if (code) {
      data.code = code;
    }
    const result = await apiInstance.zoom.checkToken(data);
    return result;
  },
  deleteToken: async (store) => {
    const result = await apiInstance.zoom.deleteToken({
      userUuid: store.rootGetters["users/getUserUuid"],
    });
    return result;
  },
});
