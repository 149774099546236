export default {
  setPlan(state, plan) {
    state.plan = Object.assign({}, plan);
  },
  setPlanList(state, planList) {
    if (planList) {
      state.planList = [...planList];
    } else {
      state.planList = [];
    }
  },
  selectPlan(state, plan = {}) {
    state.selectedPlan = plan;
  },
};
