export default {
  setCongitoId: (state, cognitoId) => {
    state.cognitoId = cognitoId;
  },
  setUser: (state, user) => {
    state.user = {
      ...user,
    };
    state.userUuid = user?.uuid || "";
  },
  updateUser: (state, user) => {
    state.user = {
      ...state.user,
      ...user,
    };
  },
  setInstructor: (state, instructor) => {
    state.instructor = {
      ...instructor,
    };
  },
  setSubscriptions: (state, { userType, subscriptions }) => {
    const data = {};
    data[userType] = subscriptions;
    state.subscriptions = Object.assign({}, state.subscriptions, data);
  },
  setSubscription: (state, { tarUuid, subscrYn }) => {
    if (!subscrYn) {
      const user = Object.assign({}, state.user);
      user.subscrCnt -= 1;
      if (user.subscrCnt < 0) {
        user.subscrCnt = 0;
      }
      state.user = Object.assign({}, user);

      const data = state.subscriptions.user.filter(
        (item) => item.uuid !== tarUuid
      );
      state.subscriptions = Object.assign({}, state.subscriptions, {
        user: data,
      });
    } else {
      const user = Object.assign({}, state.user);
      user.subscrCnt += 1;
      state.user = Object.assign({}, user);
    }
  },
  setMembership: (state, membership) => {
    const user = Object.assign({}, state.user);
    if (membership) {
      user.memberShip = membership;
    } else {
      delete user.memberShip;
    }
    state.user = Object.assign({}, user);
  },
  setInstructorAccounts: (state, accounts) => {
    const instructor = Object.assign({}, state.instructor);
    if (accounts) {
      instructor.accounts = accounts;
    } else {
      instructor.accounts = [];
    }
    state.instructor = instructor;
  },
};
