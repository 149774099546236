<script>
import { isDev, oAuthRedirectUrl } from "@/config";
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      campusUuid: "campuses/getCampusUuid",
      campusInfo: "campuses/getCampusInfo",
      campusOnly: "common/campusOnly",
      locationPath: "common/getLocationPath",
      isCampusJoinMember: "members/isCampusJoinMember",
      isCampusRequestMember: "members/isCampusRequestMember",
      ua: "common/getUa",
    }),
    isInAppBrowser() {
      const ua = window.navigator.userAgent;
      // 모바일 인앱브라우저 판별 (Mobile Safari UI/WKWebView) - 인스타그램 인앱브라우저 포함
      if (ua?.match(/(iPhone|iPad|iPod).*AppleWebKit(?!.*Safari)/i)) {
        return true;
      }
      // 카카오 인앱브라우저
      if (ua?.match(/kakaotalk/i)) {
        return true;
      }
      // 인스타그램 인앱브라우저
      if (ua?.match(/instagram/i)) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      setLocationPath: "common/setLocationPath",
    }),
    joinBySns(snsType) {
      if (!this.campusOnly) {
        // 요금 플랜 선택 후 캠퍼스 개설 페이지로 가는 경우, 선택된 요금제 및 요금 플랜 팝업을 띄우기 위해 쿼리를 넘겨준다.
        const registerQuery = {
          ...(this.$route.query?.openYn && {
            openYn: this.$route.query.openYn,
          }),
          ...(this.$route.query?.planCycle && {
            planCycle: this.$route.query.planCycle,
          }),
          ...(this.$route.query?.campusType && {
            campusType: this.$route.query.campusType,
          }),
        };
        this.setLocationPath(
          this.$router.resolve({
            name: "campus-register",
            query: registerQuery,
          }).href
        );
      } else if (
        this.locationPath &&
        this.$router.resolve({
          path: this.locationPath,
        }).resolved?.name === "campus-staff-join"
      ) {
        //
      } else if (!this.isCampusJoinMember && !this.isCampusRequestMember) {
        this.setLocationPath(
          this.$router.resolve({ name: "campus-member-join" }).href
        );
      } else {
        this.setLocationPath(
          this.$router.resolve({ name: "campus-home" }).href
        );
      }

      switch (snsType) {
        case "FACEBOOK":
          this.facebookButtonClick();
          break;
        case "GOOGLE":
          this.googleButtonClick();
          break;
        case "KAKAO":
          this.kakaoButtonClick();
          break;
        case "NAVER":
          this.naverButtonClick();
          break;
        case "APPLE":
          this.appleButtonClick();
      }
    },
    async facebookButtonClick() {
      window.location.href = `${oAuthRedirectUrl}?snsType=FACEBOOK&state=${this.getOAuthState(
        "facebook"
      )}`;
    },
    async googleButtonClick() {
      // TODO: 안드로이드 카카오 웹뷰인 경우, 크롬 띄워주기
      const inApp = ["kakaotalk", "instagram"];
      if (inApp.includes(this.ua) || this.isInAppBrowser) {
        this.$eventBus.$emit("alert", {
          open: true,
          icon: "alert",
          title: "Google로 로그인",
          msg: "카카오톡/인스타그램 인앱 브라우저에서는 구글 로그인을 이용할 수 없습니다. 다른 웹 브라우저를 이용해주세요. (크롬 권장)",
        });
        return;
      }
      window.location.href = `${oAuthRedirectUrl}?snsType=GOOGLE&state=${this.getOAuthState(
        "google"
      )}`;
    },
    async kakaoButtonClick() {
      window.location.href = `${oAuthRedirectUrl}?snsType=KAKAO&state=${this.getOAuthState(
        "kakao"
      )}`;
    },
    naverButtonClick() {
      // TODO: 운영환경별 url 변경. 외부도메인일때만 아래 콜백 페이지로 가도록 하고, 이외에는 원래대로 하도록 변경 필요.
      if (window.location.hostname.includes("poincampus.com")) {
        window.location.href = `${oAuthRedirectUrl}?snsType=NAVER&state=${this.getOAuthState(
          "naver"
        )}`;
      } else {
        // TODO: 개발서버 도메인 변경 필요
        window.location.href = `https://${
          isDev ? "dev." : ""
        }poincampus.com/oauth-callback/naver?naverRedirect=true&state=${this.getOAuthState(
          "naver"
        )}`;
      }
    },
    appleButtonClick() {
      window.location.href = `${oAuthRedirectUrl}?snsType=APPLE&state=${this.getOAuthState(
        "apple"
      )}`;
    },
    getOAuthState(snsType) {
      return encodeURIComponent(
        btoa(
          JSON.stringify({
            url: `${window.location.origin}/oauth-callback/${snsType}`,
            nextUrl: this.locationPath,
            campusUuid: this.campusUuid,
          })
        )
      );
    },
  },
};
</script>
