export function signOut() {
  if (window.JsBridge) {
    window.JsBridge?.initEntry("");
    window.JsBridge.signOut();
    return;
  }
  if (window.webkit?.messageHandlers) {
    window.webkit?.messageHandlers?.initEntry?.postMessage("");
    window.webkit.messageHandlers.signOut.postMessage("");
  }
}
