import { computed, ref } from "vue";
import { defineStore } from "pinia";
import { useStore } from "@/store";
import {
  poinReferrer,
  poinPartnerCode,
  poinLinkLog,
} from "@/utils/cookie-utils";

const INITIAL_STATES = {
  payInfo: {
    customerName: "",
    contact: "",
    email: "",
    custAdds: [],
    paymentWay: "",
    cardId: "",
    quota: 0,
  },
  recipient: {
    note: "",
    zipCode: "",
    contact: "",
    intro: "",
    name: "",
    place: {
      pre: "",
      post: "",
      zone: "",
      lon: 0,
      lat: 0,
      desc: "",
    },
  },
};

const utils = {
  getCustValue(item) {
    if (item.inputType === "CHECK") {
      return item.value.join(", ");
    }
    return item.value2 ? `${item.value} ${item.value2}` : item.value;
  },

  mergeAndRemoveDuplicates(a, b) {
    const mergedMap = new Map();

    const addOrMergeItem = (item) => {
      const key = JSON.stringify(
        Object.entries(item)
          .sort()
          .filter(([k]) => !["quantity", "subscriptionYn"].includes(k))
      );

      if (mergedMap.has(key)) {
        const existingItem = mergedMap.get(key);
        if (existingItem.quantity && item.quantity) {
          existingItem.quantity += item.quantity;
        }
      } else {
        mergedMap.set(key, { ...item });
      }
    };

    [...a, ...b].forEach(addOrMergeItem);
    return Array.from(mergedMap.values());
  },
};

export const useCheckoutStore = defineStore("checkout", () => {
  const store = useStore();

  const payInfo = ref({ ...INITIAL_STATES.payInfo });
  const recipient = ref({ ...INITIAL_STATES.recipient });
  const custAdds = ref([]);
  const orderCheck = ref(null);
  const products = ref([]);
  const cartResult = ref(null);

  const cart = computed(() => cartResult.value?.cartProducts || []); // 장바구니
  const extraProducts = computed(() => {
    return store.getters["products/getProduct"]?.extProducts || [];
  });

  // 장바구니
  const cartActions = {
    // 장바구니 조회
    async getCart() {
      const campusId = store.getters["campuses/getCampusUuid"];
      const memberId = store.getters["members/getMemberId"];
      const res = await this.api.payments.getProductsCart({
        campusId,
        memberId,
      });
      if (res?.success) {
        cartResult.value = res.data;
      }
      return res;
    },
    // 프로덕트 상세페이지 > 장바구니 버튼 클릭 시, 기존 장바구니 목록과 프로덕트 구매 목록을 병합하여 저장함.
    async addCart() {
      const campusId = store.getters["campuses/getCampusUuid"];
      const memberId = store.getters["members/getMemberId"];
      const res = await this.api.payments.postProductsCart({
        campusId,
        memberId,
        products: utils.mergeAndRemoveDuplicates(cart.value, products.value),
      });
      if (res?.success) {
        cartResult.value = res.data;
      }
      return res;
    },
    // 장바구니 페이지 > 장바구니 목록 수정
    async postCart(newValue) {
      const campusId = store.getters["campuses/getCampusUuid"];
      const memberId = store.getters["members/getMemberId"];
      const res = await this.api.payments.postProductsCart({
        campusId,
        memberId,
        products: newValue,
      });
      if (res?.success) {
        cartResult.value = res.data;
      }
      return res;
    },
    // 장바구니 초기화
    async resetCart() {
      const campusId = store.getters["campuses/getCampusUuid"];
      const memberId = store.getters["members/getMemberId"];
      const res = await this.api.payments.postProductsCart({
        campusId,
        memberId,
        products: [],
      });
      if (res?.success) {
        cartResult.value = res.data;
      }
      return res;
    },
  };

  // 주문 및 가격 확인
  const orderActions = {
    async order({
      tran_cd,
      enc_data,
      good_mny,
      enc_info,
      ordr_idxx,
      good_name,
      paymentWay,
      cardId,
      escw_used,
      kcpRegisterYn,
      Ret_URL,
      pay_method,
      user_agent,
    }) {
      const campusId = store.getters["campuses/getCampusUuid"];
      const memberId = store.getters["members/getMemberId"];
      const userId = store.getters["users/getUserUuid"];
      const partnerCode = poinPartnerCode.get();
      const referrer = poinReferrer.get();
      const linkLog = poinLinkLog.get();

      const result = await this.api.payments.postProductsOrders({
        campusId,
        userId,
        memberId,
        customerName: payInfo.value.customerName,
        contact: payInfo.value.contact,
        email: payInfo.value.email,
        ...(payInfo.value.custAdds.length > 0 && {
          custAdds: payInfo.value.custAdds,
        }),
        cardId: payInfo.value.cardId,
        ...(payInfo.value.quota && { quota: payInfo.value.quota }),
        ...(recipient.value?.zipCode && {
          recipient: recipient.value,
        }),
        products: products.value,
        good_name,
        good_mny: good_mny || orderCheck.value?.amount || "0",
        paymentWay,
        ...(custAdds.value?.length > 0 && {
          custAdds: custAdds.value?.map((item) => {
            return {
              custAdditionals: item.custAdditionals.map((cur) => {
                return {
                  inputType: cur.inputType,
                  name: cur.name,
                  requiredYn: cur.requiredYn,
                  value: utils.getCustValue(cur),
                };
              }),
              ...(item.productId && { productId: item.productId }),
              ...(item.productName && { productName: item.productName }),
            };
          }),
        }),
        cardId,
        ...(tran_cd && { tran_cd }),
        ...(enc_data && { enc_data }),
        ...(enc_info && { enc_info }),
        ...(ordr_idxx && { ordr_idxx }),
        ...(escw_used && { escw_used }),
        ...(kcpRegisterYn && { kcpRegisterYn }),
        ...(Ret_URL && { Ret_URL }),
        ...(pay_method && { pay_method }),
        ...(user_agent && { user_agent }),
        ...(partnerCode && {
          partnerCode,
        }),
        ...(referrer && {
          referrer,
        }),
        ...(linkLog && {
          linkPk: linkLog.pk,
          linkId: linkLog.id,
        }),
      });

      return result;
    },

    async check(couponAutoYn) {
      const campusId = store.getters["campuses/getCampusUuid"];
      const memberId = store.getters["members/getMemberId"];
      const userId = store.getters["users/getUserUuid"];

      const result = await this.api.payments.postProductsOrdersCheck({
        campusId,
        userId,
        memberId,
        recipient: recipient.value,
        products: products.value,
        ...(couponAutoYn && { couponAutoYn }),
      });

      if (result?.success) {
        orderCheck.value = result.data;
        if (couponAutoYn) {
          const pproducts = result.data?.results?.reduce((acc, cur) => {
            cur.products?.forEach((p) => {
              acc.push(p);
            });
            return acc;
          }, []);
          products.value = pproducts.map((p) => {
            if (p.couponId) {
              return {
                ...p.params,
                couponId: p.couponId,
              };
            }
            return p.params;
          });
        }
      }

      return result;
    },
  };

  // 상태 초기화
  function reset() {
    payInfo.value = { ...INITIAL_STATES.payInfo };
    recipient.value = { ...INITIAL_STATES.recipient };
    custAdds.value = [];
  }

  return {
    // data
    payInfo,
    recipient,
    products,
    orderCheck,
    custAdds,
    cartResult,
    // computed
    cart,
    extraProducts,
    // method
    reset,
    ...cartActions,
    ...orderActions,
  };
});
