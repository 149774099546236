export default {
  setUserGroups: (state, groups) => {
    state.userGroups = groups;
  },
  clearUserGroups: (state) => {
    state.userGroups = [];
  },
  deleteUserGroup: (state, sk) => {
    const itemIdx = state.userGroups?.findIndex((group) => group.sk === sk);
    state.userGroups.splice(itemIdx, 1);
  },
  setGroup: (state, group) => {
    state.group = group;
  },
  clearGroup: (state) => {
    state.group = {};
  },

  //주소록
  setAddrBooks: (state, addrBooks) => {
    state.addrBooks = addrBooks;
  },
  clearAddrBooks: (state) => {
    state.addrBooks = [];
  },
  deleteAddrBook: (state, sk) => {
    const itemIdx = state.addrBooks?.findIndex((book) => book.sk === sk);
    state.addrBooks.splice(itemIdx, 1);
  },
  setAddrBook: (state, addrBook) => {
    state.addrBook = addrBook;
  },
  clearAddrBook: (state) => {
    state.addrBook = {};
  },
  setAddrBookAddrs: (state, addrs) => {
    state.addrs = [...state.addrs, ...addrs];
  },
  clearAddrBookAddrs: (state) => {
    state.addrs = [];
  },
  setAddrBookAddrsLastKey: (state, lastKey) => {
    state.addrsLastKey = lastKey;
  },
  clearAddrBookAddrsLastKey: (state) => {
    state.addrsLastKey = "";
  },
  setAddrBookAddrsCnt: (state, cntObj) => {
    state.addrsCnt = cntObj ? { ...cntObj } : null;
  },
};
