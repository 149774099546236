<script setup>
import { ref, toRefs } from "vue";
import colors from "@/utils/colors";
import LinkItem from "./LinkItem.vue";

const props = defineProps({
  text: {
    type: String,
    default: "",
  },
  items: {
    type: Array,
    default: () => [],
  },
});

const { text } = toRefs(props);
const expand = ref(false);
</script>

<template>
  <li class="nav-item">
    <div
      class="menu"
      @click="expand = !expand"
      :class="{ 'is-expand': expand }"
    >
      <span class="text">
        {{ text }}
      </span>
      <v-icon
        size="24"
        class="sm-icon"
        :color="expand ? colors.primary.base : colors.gray.darken3"
        >$down-sm</v-icon
      >
    </div>
    <v-expand-transition>
      <ul class="list" v-show="expand">
        <LinkItem v-for="item in items" :key="item.link.name" :item="item" />
      </ul>
    </v-expand-transition>
  </li>
</template>

<style lang="scss" scoped>
.nav-item {
  position: relative;
  display: flex;
  flex-direction: column;
}
.menu {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  color: $black;
  cursor: pointer;
  color: $black;
  font-size: 18px;
  padding: 16px 0;
  &.is-expand {
    color: $primary;
    .sm-icon {
      transform: rotate(-180deg);
    }
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
</style>
