import _ from "lodash";

export default {
  setContents(state, result) {
    state.contents.lastKey = result.lastKey;
    if (result.results) {
      state.contents.items = [...result.results];
    }
  },
  updateContents(state, result) {
    state.contents.lastKey = result.lastKey;
    if (result.results) {
      state.contents.items = [...state.contents.items, ...result.results];
    }
  },
  clearContents(state) {
    state.contents.lastKey = "";
    state.contents.items = [];
  },
  updateSaveContents(state, result) {
    state.contents.lastKey = result?.lastKey;
    state.contents.items = result.items;
  },
  removeContent(state, id) {
    state.contents.items = state.contents.items.map((content) => {
      if (content.id === id) {
        return {
          ...content,
          name: "삭제된 콘텐츠입니다.",
          delYn: true,
        };
      }
      return content;
    });
  },
  setContent(state, content) {
    state.content = { ...content };
  },
  clearContent(state) {
    state.content = {};
  },
  setDeletableVimeoIds(state, id) {
    if (!id) {
      return;
    }
    const ids = [...state.deletableVimeoIds, id];
    state.deletableVimeoIds = [...new Set(ids)];
  },
  removeDeletableVimeoIds(state, removeId) {
    state.deletableVimeoIds = state.deletableVimeoIds.filter(
      (id) => id !== removeId
    );
  },
  clearDeletableVimeoIds(state) {
    state.deletableVimeoIds = [];
  },
  setContentDelegatorIds(state, data) {
    if (data.length > 0) {
      state.delegatorIds = data?.reduce((acc, delegator) => {
        acc.push(delegator.id);
        return acc;
      }, []);
    }
  },
  setContentDelegators(state, result) {
    state.delegators = result;
  },
  clearDelegators(state) {
    state.delegators = [];
    state.delegatorIds = [];
  },
  setFileReady(state, yn) {
    state.fileReady = yn;
  },
};
