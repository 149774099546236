export default {
  quitReason: [],
  provision: [],
  reservationConfig: [],
  priviacy: [],
  provisionEdu: [],
  consentEdu: [],
  category: [],
  courseQuestion: [],
  instructorGrade: [],
  bankOrg: [],
  events: [],
  cupRegisterOpen: [],
  campusPlanConfigure: [],
  terms: [],
  sysConfig: null,
};
