export default {
  campusInfo: null,
  userCampusList: {},
  certificate: null,
  tempVolumes: "",
  homeSection: {},
  productSection: {},
  subscription: {},
  designs: [],
};
