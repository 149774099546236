function comma(x) {
  if (!x) return "0";
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
function replaceComma(x) {
  if (typeof x === "number") return x;
  if (!x) return 0;
  return Number(String(x).replaceAll(",", ""));
}

function secToMinFormat(sec) {
  const min = Math.floor(sec / 60);
  const secRest = Math.floor(sec % 60);
  const minString = min < 10 ? "0" + min : min;
  const secString = secRest < 10 ? "0" + secRest : secRest;
  return minString + ":" + secString;
}

function secToMinFormatKo(sec) {
  const min = Math.floor(sec / 60);
  const secRest = Math.floor(sec % 60);
  const minString = min < 10 ? `0${min}` : min;
  const secString = secRest < 10 ? `0${secRest}` : secRest;
  return `${minString}분  ${secString}초`;
}

function durationFormat(sec) {
  const min = Math.floor(sec / 60);
  const secRest = Math.floor(sec % 60);
  const hours = Math.floor(min / 60);
  const minRest = Math.floor(min % 60);
  const mm = minRest < 10 ? `0${minRest}` : minRest;
  const ss = secRest < 10 ? `0${secRest}` : secRest;
  return hours > 0 ? `${hours}:${mm}:${ss}` : `${mm}:${ss}`;
}

function viewCountFormat(view) {
  let result = view.toString();
  if (view < 1000) {
    result = comma(result);
  } else if (view < 10000) {
    result = result.charAt(0) + "천회+";
  } else if (view < 100000000) {
    result = parseInt(view / 10000) + "만회+";
  } else {
    result = parseInt(view / 100000000) + "만회+";
  }

  return result;
}

function fileSizeFormat(fileSize, fixed = 2) {
  if (!fileSize) {
    return "0B";
  }

  if (fileSize < 1024) {
    return `${fileSize}B`;
  } else if (fileSize < 1024 * 1024) {
    return `${(fileSize / 1024).toFixed(fixed)}KB`;
  } else if (fileSize < 1024 * 1024 * 1024) {
    return `${(fileSize / (1024 * 1024)).toFixed(fixed)}MB`;
  } else if (fileSize < 1024 * 1024 * 1024 * 1024) {
    return `${(fileSize / (1024 * 1024 * 1024)).toFixed(fixed)}GB`;
  } else {
    return `${(fileSize / (1024 * 1024 * 1024 * 1024)).toFixed(fixed)}TB`;
  }
}

const convertSeconds = (timeStr) => {
  if (!timeStr) {
    return "";
  }
  const timeParts = timeStr.split(":").map(Number); // string -> number

  if (timeParts.length > 2) {
    // HH:MM:SS 형식
    const [hours, minutes, seconds] = timeParts;
    return hours * 3600 + minutes * 60 + seconds;
  }

  // MM:SS 형식
  const [minutes, seconds] = timeParts;
  return minutes * 60 + seconds;
};

/**
 * Rounds a number to a fixed number of decimal places.
 *
 * @param {number} x - The number to be rounded.
 * @param {number} precision - The number of decimal places to round to.
 * @return {number} The rounded number.
 */
function roundToFixed(x, precision) {
  if (typeof x !== "number" || Number.isNaN(x)) {
    return 0;
  }

  const rounded = Number(x.toFixed(precision));
  return Number.isFinite(rounded) ? rounded : x;
}

export {
  comma,
  replaceComma,
  secToMinFormat,
  viewCountFormat,
  fileSizeFormat,
  secToMinFormatKo,
  durationFormat,
  convertSeconds,
  roundToFixed,
};
