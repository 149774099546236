import Vue from "vue";
import VueI18n from "vue-i18n";
import { createI18n } from "vue-i18n-composable";

import { messages } from "@/i18n/messages";

Vue.use(VueI18n);

export const i18n = createI18n({
  locale: "ko",
  messages,
});
