import { useFavicon } from "@vueuse/core";

function getHeaderHeight() {
  const elementApp = window.getComputedStyle(document.getElementById("app"));
  return parseInt(elementApp.getPropertyValue("--header-height"));
}

function getOffsetTop(element) {
  if (isMobile()) {
    if (window.scrollY < element.offsetTop) {
      return element.offsetTop + getHeaderHeight();
    }
    return element.offsetTop - getHeaderHeight() * 2;
  }
  return element.offsetTop - getHeaderHeight();
}

function scrollToElementTop(element, behavior = "smooth") {
  const options = {
    top: getOffsetTop(element),
  };
  if (behavior) {
    options.behavior = behavior;
  }
  window.scrollTo(options);
}

function getDeviceWidth() {
  return window.innerWidth;
}

function isMobile(size = 960) {
  // const isMobileAgent = navigator.userAgent.match(/iPhone|iPad|iPod|Android/i) != null || window.location.pathname.indexOf('mobile') > -1
  return getDeviceWidth() < size;
}

function isMobileDevice() {
  const userAgent = navigator.userAgent.toLowerCase();
  return (
    typeof window.orientation !== "undefined" ||
    /mobi/.test(userAgent) ||
    /android/.test(userAgent) ||
    /iphone|ipad|ipod/.test(userAgent) ||
    /windows phone/.test(userAgent)
  );
}

function setFavicon(url = `/favicon.ico`) {
  const favicon = document.getElementById("favicon");
  const faviconShortcut = document.getElementById("favicon-shortcut");
  if (favicon) {
    favicon.href = url;
  }
  if (faviconShortcut) {
    faviconShortcut.href = url;
  }
  const icon = useFavicon();
  icon.value = url;
}

function setDocumentTitle(campusInfo) {
  let title = "포인캠퍼스 PoinCampus";
  if (campusInfo) {
    title =
      campusInfo.SEO?.tags
        ?.match(/<title>(.*?)<\/title>/gm)
        ?.shift()
        ?.replace(/(<([^>]+)>)/gi, "") || campusInfo.name;
  }
  document.title = title;
}

function isMobileAgent() {
  if (navigator.userAgentData) return navigator.userAgentData.mobile;
  else
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
}

/**
 * form에 value 주입.
 */
const injectFormData = (form, formParams) => {
  for (const key in formParams) {
    if (form[key]) {
      form[key].value = formParams[key];
    } else {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = key;
      input.value = formParams[key];
      form.appendChild(input);
    }
  }
};

// form 생성 후 value 주입. form 반환
const createForm = (method = "POST", action = "", formParams) => {
  const form = document.createElement("form");
  form.method = method;
  form.action = action;
  form.style.display = "none";
  document.body.appendChild(form);
  injectFormData(form, formParams);
  return form;
}


export {
  getHeaderHeight,
  scrollToElementTop,
  getDeviceWidth,
  isMobile,
  setFavicon,
  setDocumentTitle,
  isMobileAgent,
  isMobileDevice,
  injectFormData,
  createForm,
};
