export default {
  setQuitReason: (state, quitReason) => {
    state.quitReason = quitReason;
  },
  setProvision: (state, provision) => {
    state.provision = provision;
  },
  setReservationConfig: (state, reservationConfig) => {
    state.reservationConfig = reservationConfig;
  },
  setPrivacy: (state, priviacy) => {
    state.priviacy = priviacy;
  },
  setProvisionEdu: (state, provisionEdu) => {
    state.provisionEdu = provisionEdu;
  },
  setTerms: (state, terms) => {
    state.terms = terms;
  },
  setConsentEdu: (state, consentEdu) => {
    state.consentEdu = consentEdu;
  },
  setCategory: (state, category) => {
    state.category = category;
  },
  setCourseQuestion: (state, courseQuestion) => {
    state.courseQuestion = courseQuestion;
  },
  setInstructorGrade: (state, instructorGrade) => {
    state.instructorGrade = instructorGrade;
  },
  setBankOrg: (state, bankOrg) => {
    state.bankOrg = bankOrg;
  },
  setEvents: (state, events) => {
    state.events = events;
  },
  setCupRegisterOpen: (state, cupRegisterOpen) => {
    state.cupRegisterOpen = cupRegisterOpen;
  },
  setCampusPlanConfigure: (state, campusPlanConfigure) => {
    state.campusPlanConfigure = campusPlanConfigure;
  },
  setSysConfig: (state, config) => {
    state.sysConfig = config;
  },
};
