import { endpointV2 as endpoint } from "@/config";

// http://swagger.poincampus.com/?urls.primaryName=01.%EC%9A%94%EA%B8%88%EC%A0%9C%EA%B4%80%EB%A6%AC

// 요금제 정보 전체 조회
export async function getPlanList({ planType, useYn }) {
  const params = {
    ...(planType && { planType }),
    ...(useYn && { useYn }),
    useYn,
  };
  return this.getMethod("get", false, {
    url: `${endpoint.plans}/v2`,
    params,
  });
}

// 요금제 정보 단건 조회
export async function getPlan({ planType, id }) {
  return this.getMethod("get", false, {
    url: `${endpoint.plans}/${planType}/${id}`,
  });
}

// v2 요금 플랜 http://swagger.poincampus.com/#/v2%EC%9A%94%EA%B8%88%EC%A0%9C

// v2 요금 플랜 생성
export async function postPlan({
  image,
  planType,
  startDttm,
  endDttm,
  planCode,
  spec,
  prices,
  name,
  desc,
  monthly,
  annually,
  promotion,
  seq,
  useYn,
  defaultYn,
}) {
  const data = {
    ...(image && { image }),
    ...(planType && { planType }),
    ...(startDttm && { startDttm }),
    ...(endDttm && { endDttm }),
    ...(planCode && { planCode }),
    ...(spec && { spec }),
    ...(prices && { prices }),
    ...(name && { name }),
    ...(desc && { desc }),
    ...(monthly && { monthly }),
    ...(annually && { annually }),
    ...(promotion && { promotion }),
    seq,
    useYn,
    defaultYn,
  };
  return this.getMethod("post", true, {
    url: `${endpoint.plans}/v2`,
    data,
  });
}

// v2 요금 플랜 수정
export async function putPlan({ planType, id, PlanV2 }) {
  const data = {
    ...PlanV2,
  };

  return this.getMethod("put", true, {
    url: `${endpoint.plans}/v2/${planType}/${id}`,
    data,
  });
}

// v2 요금 플랜 전체 조회
export async function getPlans({ planType, useYn }) {
  const params = {
    ...(planType && { planType }),
    useYn,
  };
  return this.getMethod("get", false, {
    url: `${endpoint.plans}/v2`,
    params,
  });
}

// v2 요금 플랜 단건 조회
export async function getPlanV2({ planType, id }) {
  return this.getMethod("get", true, {
    url: `${endpoint.plans}/v2/${planType}/${id}`,
  });
}
