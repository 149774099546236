export default {
  // 프로덕트 만들기/수정
  productsStaff: {
    lastKey: "",
    items: [],
  },
  delegators: [],
  newProduct: null,
  product: {},
  reservation: {}, // 예약 관리 > 편집 데이터
  custAdditional: {
    items: [],
  },
  promotions: [],
  promotion: {},
  promotionLastkey: "",
  checkPrices: {},
  reserveUsers: {
    lastKey: "",
    items: [],
  },
};
