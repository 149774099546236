export default {
  setNotice: (state, notice) => {
    state.notice = notice;
  },
  setFaq: (state, faq) => {
    state.faq = faq;
  },
  setAskItems: (state, ask) => {
    state.ask = ask;
  },
};
