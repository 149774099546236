export default {
  // 프로덕트 만들기/수정
  contents: {
    lastKey: "",
    items: [],
  },
  content: {},
  deletableVimeoIds: [],
  delegators: [],
  delegatorIds: [],
  fileReady: false,
};
