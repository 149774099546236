export default {
  setCredit(state, result) {
    state.credit = result;
  },
  setCreditHistories(state, result) {
    state.creditHistories.lastKey = result.lastKey;
    if (result.results) {
      state.creditHistories.items = [
        ...state.creditHistories.items,
        ...result.results,
      ];
    }
  },
  clearCreditHistories(state) {
    state.creditHistories.lastKey = "";
    state.creditHistories.items = [];
  },
  setExpireCampusCredits(state, result) {
    state.expireHistories.lastKey = result.lastKey;
    if (result.results) {
      state.expireHistories.items = [
        ...state.expireHistories.items,
        ...result.results,
      ];
    }
  },
  clearExpireCampusCredits(state) {
    state.expireHistories.lastKey = "";
    state.expireHistories.items = [];
  },
};
