import globalStore from "@/store";
import { useAlert } from "@/stores/alert";

export default (apiInstance) => ({
  /*
  ==============================
              문의하기
  ==============================
  */
  // 사용자 View
  // (사용자) 문의하기 목록 조회
  async reqGetUserAskItems(store, payload) {
    const {
      userId = store.rootGetters["users/getUserUuid"],
      campusId = store.rootGetters["campuses/getCampusUuid"],
      keyword,
      askStatus,
      askType,
      first,
      order,
      limit,
    } = payload;
    if (first) {
      store.commit("clearUserAskItems");
      store.commit("clearUserAskLastKey");
    }
    const lastKey = store.getters["getUserAskLastKey"];
    const result = await apiInstance.supports.getUserAskItems({
      userId,
      campusId,
      ...(lastKey && { lastKey }),
      ...(keyword && { keyword }),
      ...(askStatus && { askStatus }),
      ...(askType && { askType }),
      ...(order && { order }),
      ...(limit && { limit }),
    });
    if (result.success) {
      const { results, lastKey } = result.data;
      store.commit("setUserAskItems", results);
      store.commit("setUserAskLastKey", lastKey);
    } else {
      if (result.name === "ACCESS_DENIED") {
        const alert = useAlert();
        alert.open({
          title: "요청 실패",
          open: true,
          msg: "접근이 제한된 페이지입니다.",
        });
      }
    }
    return result;
  },
  // (사용자) 문의자 캠퍼스 문의하기 등록
  async reqPostAsk(store, payload) {
    const {
      askType,
      subject,
      conts,
      contact,
      name,
      email,
      attaches,
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      userId = store.rootGetters["users/getUserUuid"],
    } = payload;
    const result = await apiInstance.supports.postAsk({
      askType,
      subject,
      conts,
      contact,
      campusId,
      name,
      email,
      ...{ userId },
      ...{ memberId },
      ...{ attaches },
    });
    return result;
  },
  // (사용자) 문의자 문의내용 수정하기
  async reqPutAsk(store, payload) {
    const {
      askType,
      subject,
      conts,
      contact,
      name,
      email,
      id,
      attaches,
      memberId = store.rootGetters["members/getMemberId"],
      userId = store.rootGetters["users/getUserUuid"],
      campusId = store.rootGetters["campuses/getCampusUuid"],
    } = payload;
    const result = await apiInstance.supports.putAsk({
      askType,
      subject,
      conts,
      contact,
      campusId,
      name,
      email,
      id,
      ...(userId && { userId }),
      ...(memberId && { memberId }),
      ...(attaches?.length > 0 && { attaches }),
    });
    if (!result.success) {
      let msg;
      switch (result.name) {
        case "ACCESS_DENIED":
          msg = "접근이 제한된 페이지입니다.";
          break;
        case "REQUEST_NOT_FOUND":
          msg = "존재하지 않는 게시글입니다.";
          break;
        case "UNAUTHORIZE":
          msg = "게시글에 대한 권한이 없습니다.";
          break;
        case "UNAVAILABLE":
          msg = "게시글을 수정할 수 없습니다.";
          break;
        default:
          msg = "알 수 없는 오류입니다.";
          break;
      }
      const alert = useAlert();
      alert.open({
        title: "요청 실패",
        open: true,
        msg,
      });
    }

    return result;
  },
  // (사용자) 문의자 문의취소
  async reqCancelAsk(store, payload) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      userId = store.rootGetters["users/getUserUuid"],
      id,
    } = payload;
    const result = await apiInstance.supports.cancelAsk({
      campusId,
      userId,
      id,
    });
    if (!result.success) {
      let msg;
      switch (result.name) {
        case "ACCESS_DENIED":
          msg = "접근이 제한된 페이지입니다.";
          break;
        case "REQUEST_NOT_FOUND":
          msg = "존재하지 않는 게시글입니다.";
          break;
        case "UNAUTHORIZE":
          msg = "게시글에 대한 권한이 없습니다.";
          break;
        default:
          msg = "알 수 없는 오류입니다.";
          break;
      }
      globalStore.dispatch("common/setAlert", {
        open: true,
        msg,
      });
    }
    return result;
  },
  // (공통) 문의하기 단건 조회
  async reqGetUserAsk(store, payload) {
    const {
      memberId = store.rootGetters["members/getMemberId"],
      userId = store.rootGetters["users/getUserUuid"],
      campusId = store.rootGetters["campuses/getCampusUuid"],
      id,
    } = payload;
    const result = await apiInstance.supports.getUserAsk({
      campusId,
      id,
      memberId,
      userId,
    });
    if (result.success) {
      store.commit("setAsk", result.data);
      return result;
    } else {
      let msg;
      switch (result.name) {
        case "ACCESS_DENIED":
          msg = "접근이 제한된 페이지입니다.";
          break;
        case "REQUEST_NOT_FOUND":
          msg = "존재하지 않는 게시글입니다.";
          break;
        case "UNAUTHORIZE":
          msg = "게시글에 대한 권한이 없습니다.";
          break;
        default:
          msg = "알 수 없는 오류입니다.";
          break;
      }
      const alert = useAlert();
      alert.open({
        title: "요청 실패",
        open: true,
        msg,
      });
    }
    return result;
  },
  // 캠퍼스 관리 View
  // (캠퍼스 관리) 문의하기 목록 조회
  async reqGetCampusAskItems(store, payload) {
    const {
      memberId = store.rootGetters["members/getMemberId"],
      campusId = store.rootGetters["campuses/getCampusUuid"],
      keyword,
      askStatus,
      askType,
      fromDate,
      toDate,
      limit,
      userYn,
      replyYn,
      first,
      order,
    } = payload;
    if (first) {
      store.commit("clearCampusAskItems");
      store.commit("clearCampusAskLastKey");
    }
    const lastKey = store.getters["getCampusAskLastKey"];
    const result = await apiInstance.supports.getCampusAskItems({
      memberId,
      campusId,
      ...(order && { order }),
      ...(fromDate && { fromDate }),
      ...(toDate && { toDate }),
      ...(limit && { limit }),
      ...(userYn && { userYn }),
      ...(lastKey && { lastKey }),
      ...(keyword && { keyword }),
      ...(askStatus && { askStatus }),
      ...(askType && { askType }),
      ...(replyYn && { replyYn }),
    });
    if (result.success) {
      const { results, lastKey } = result.data;
      store.commit("setCampusAskItems", results);
      store.commit("setCampusAskLastKey", lastKey);
    } else {
      let msg;
      switch (result.name) {
        case "ACCESS_DENIED":
          msg = "접근이 제한된 페이지입니다.";
          break;
        case "UNAUTHORIZE":
          msg = "조회 권한이 없습니다.";
          break;
        default:
          msg = "알 수 없는 오류입니다.";
          break;
      }
      const alert = useAlert();
      alert.open({
        title: "요청 실패",
        open: true,
        msg,
      });
    }
    return result;
  },
  async reqGetCampusAskCount(store, payload) {
    const { askStatus = "STANDBY", fromDate, toDate } = payload;
    const result = await apiInstance.supports.getCampusAskItems({
      ...(askStatus && { askStatus }),
      countYn: true,
      ...store.getters["getAskSearchOptions"],
      ...(fromDate && { fromDate }),
      ...(toDate && { toDate }),
    });
    if (result.success) {
      store.commit("setAskCnt", result.data);
    }
    return result;
  },
  // (캠퍼스 관리) 문의 답변 작성
  async reqPutAnswer(store, payload) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      answer,
      id,
      changeType = "CREATE",
      remark,
    } = payload;
    const result = await apiInstance.supports.putAnswer({
      campusId,
      id,
      memberId,
      changeType,
      ...(answer && { answer }),
      ...(remark && { remark }),
    });
    if (result.success) {
      store.commit("setAsk", result.data);
    } else {
      let msg;
      switch (result.name) {
        case "ACCESS_DENIED":
          msg = "접근이 제한된 페이지입니다.";
          break;
        case "REQUEST_NOT_FOUND":
          msg = "존재하지 않는 게시글입니다.";
          break;
        case "UNAUTHORIZE":
          msg = "답변 작성에 대한 권한이 없습니다.";
          break;
        case "UNAVAILABLE":
          msg = "답변을 작성할 수 없습니다.";
          break;
        case "INVALID_PARAMETER":
          msg = "답변 내용을 입력해주세요.";
          break;
        default:
          msg = "알 수 없는 오류입니다.";
          break;
      }
      const alert = useAlert();
      alert.open({
        title: "요청 실패",
        open: true,
        msg,
      });
    }
    return result;
  },
  // (캠퍼스 관리) 문의하기 단건 조회
  async reqGetCampusAsk(store, payload) {
    const {
      memberId = store.rootGetters["members/getMemberId"],
      userId = store.rootGetters["users/getUserUuid"],
      campusId = store.rootGetters["campuses/getCampusUuid"],
      id,
    } = payload;
    const result = await apiInstance.supports.getCampusAsk({
      campusId,
      id,
      memberId,
      userId,
    });
    if (result.success) {
      store.commit("setAsk", result.data);
      return result.data;
    } else {
      let msg;
      switch (result.name) {
        case "ACCESS_DENIED":
          msg = "접근이 제한된 페이지입니다.";
          break;
        case "REQUEST_NOT_FOUND":
          msg = "존재하지 않는 게시글입니다.";
          break;
        case "UNAUTHORIZE":
          msg = "게시글에 대한 권한이 없습니다.";
          break;
        default:
          msg = "알 수 없는 오류입니다.";
          break;
      }
      const alert = useAlert();
      alert.open({
        title: "요청 실패",
        open: true,
        msg,
      });
    }
    return result;
  },

  /*
  ==============================
          문의하기 (댓글)
  ==============================
  */
  // 사용자 View
  async reqGetUserReplies(store, payload) {
    const {
      userId = store.rootGetters["users/getUserUuid"],
      campusId = store.rootGetters["campuses/getCampusUuid"],
      id,
      limit,
      order,
      first,
    } = payload;
    if (first) {
      store.commit("clearUserReplies");
      store.commit("clearUserReplyLastKey");
    }
    const lastKey = store.getters["getUserReplyLastKey"];
    if (!first && !lastKey) {
      globalStore.dispatch("common/setAlert", {
        open: true,
        msg: "마지막 페이지입니다",
      });
      return;
    }

    const result = await apiInstance.supports.getUserReplies({
      userId,
      campusId,
      id,
      ...(limit && { limit }),
      ...(order && { order }),
      ...(lastKey && { lastKey }),
    });
    if (result.success) {
      const { results, lastKey } = result.data;
      store.commit("setUserReplies", results);
      store.commit("setUserReplyLastKey", lastKey);
    } else {
      let msg;
      switch (result.name) {
        case "ACCESS_DENIED":
          msg = "접근이 제한된 페이지입니다.";
          break;
        case "REQUEST_NOT_FOUND":
          msg = "존재하지 않는 게시글입니다.";
          break;
        case "UNAUTHORIZE":
          msg = "권한이 없습니다.";
          break;
        default:
          msg = "알 수 없는 오류입니다.";
          break;
      }
      const alert = useAlert();
      alert.open({
        title: "요청 실패",
        open: true,
        msg,
      });
    }
    return result;
  },
  async reqPostUserReply(store, payload) {
    const {
      memberId = store.rootGetters["members/getMemberId"],
      campusId = store.rootGetters["campuses/getCampusUuid"],
      userId = store.rootGetters["users/getUserUuid"],
      name,
      conts,
      id,
      attaches,
    } = payload;
    const result = await apiInstance.supports.postUserReply({
      conts,
      campusId,
      id,
      ...(userId && { userId }),
      ...(memberId && { memberId }),
      ...(attaches?.length > 0 && { attaches }),
    });
    if (result.success) {
      store.commit("addNewUserComment", result.data);
    } else {
      let msg;
      switch (result.name) {
        case "ACCESS_DENIED":
        case "BAD_REQUEST":
          msg = "잘못된 요청입니다.";
          break;
        case "REQUEST_NOT_FOUND":
          msg = "존재하지 않는 게시글입니다.";
          break;
        case "UNAUTHORIZE":
        case "UNAVAILABLE":
          msg = "해당 게시글에 댓글을 작성할 수 없습니다.";
          break;
        default:
          msg = "알 수 없는 오류입니다.";
          break;
      }
      const alert = useAlert();
      alert.open({
        title: "요청 실패",
        open: true,
        msg,
      });
    }
    return result;
  },
  async reqDeleteUserReply(store, payload) {
    const {
      memberId = store.rootGetters["members/getMemberId"],
      campusId = store.rootGetters["campuses/getCampusUuid"],
      userId = store.rootGetters["users/getUserUuid"],
      id,
    } = payload;
    const result = await apiInstance.supports.deleteUserReply({
      campusId,
      id,
      ...(userId && { userId }),
      ...(memberId && { memberId }),
    });
    if (result.success) {
      store.commit("removeUserComment", id);
    } else {
      let msg;
      switch (result.name) {
        case "ACCESS_DENIED":
        case "BAD_REQUEST":
          msg("잘못된 요청입니다.");
          break;
        case "REQUEST_NOT_FOUND":
          msg("존재하지 않는 댓글입니다.");
          break;
        case "UNAUTHORIZE":
          msg("댓글을 삭제할 수 없습니다.");
          break;
        default:
          msg("알 수 없는 오류입니다.");
          break;
      }
      const alert = useAlert();
      alert.open({
        title: "요청 실패",
        open: true,
        msg,
      });
    }
    return result;
  },
  // 캠퍼스 관리 View
  async reqGetCampusReplies(store, payload) {
    const {
      memberId = store.rootGetters["members/getMemberId"],
      campusId = store.rootGetters["campuses/getCampusUuid"],
      id,
      limit,
      order,
      first,
    } = payload;
    if (first) {
      store.commit("clearCampusReplies");
      store.commit("clearCampusReplyLastKey");
    }
    const lastKey = store.getters["getCampusReplyLastKey"];
    if (!first && !lastKey) {
      globalStore.dispatch("common/setAlert", {
        open: true,
        msg: "마지막 페이지입니다",
      });
      return;
    }
    const result = await apiInstance.supports.getCampusReplies({
      memberId,
      campusId,
      id,
      ...(limit && { limit }),
      ...(order && { order }),
      ...(lastKey && { lastKey }),
    });
    if (result.success) {
      const { results, lastKey } = result.data;
      store.commit("setCampusReplies", results);
      store.commit("setCampusReplyLastKey", lastKey);
    } else {
      let msg;
      switch (result.name) {
        case "ACCESS_DENIED":
          msg = "접근이 제한된 페이지입니다.";
          break;
        case "REQUEST_NOT_FOUND":
          msg = "존재하지 않는 게시글입니다.";
          break;
        case "UNAUTHORIZE":
          msg = "권한이 없습니다.";
          break;
        default:
          msg = "알 수 없는 오류입니다.";
          break;
      }
      const alert = useAlert();
      alert.open({
        title: "요청 실패",
        open: true,
        msg,
      });
    }
    return result;
  },
  async reqPostCampusReply(store, payload) {
    const {
      memberId = store.rootGetters["members/getMemberId"],
      campusId = store.rootGetters["campuses/getCampusUuid"],
      userId = store.rootGetters["users/getUserUuid"],
      name,
      conts,
      id,
      attaches,
    } = payload;
    const result = await apiInstance.supports.postCampusReply({
      conts,
      campusId,
      id,
      ...(name && { name }),
      ...(userId && { userId }),
      ...(memberId && { memberId }),
      ...(attaches?.length > 0 && { attaches }),
    });
    if (result.success) {
      store.commit("addNewCampusComment", result.data);
    } else {
      let msg;
      switch (result.name) {
        case "ACCESS_DENIED":
        case "BAD_REQUEST":
          msg = "잘못된 요청입니다.";
          break;
        case "REQUEST_NOT_FOUND":
          msg = "존재하지 않는 게시글입니다.";
          break;
        case "UNAUTHORIZE":
        case "UNAVAILABLE":
          msg = "해당 게시글에 댓글을 작성할 수 없습니다.";
          break;
        default:
          msg = "알 수 없는 오류입니다.";
          break;
      }
      const alert = useAlert();
      alert.open({
        title: "요청 실패",
        open: true,
        msg,
      });
    }
    return result;
  },
  async reqDeleteCampusReply(store, payload) {
    const {
      memberId = store.rootGetters["members/getMemberId"],
      campusId = store.rootGetters["campuses/getCampusUuid"],
      userId = store.rootGetters["users/getUserUuid"],
      id,
    } = payload;
    const result = await apiInstance.supports.deleteCampusReply({
      campusId,
      id,
      ...(userId && { userId }),
      ...(memberId && { memberId }),
    });
    if (result.success) {
      store.commit("removeCampusComment", id);
    } else {
      let msg;
      switch (result.name) {
        case "ACCESS_DENIED":
        case "BAD_REQUEST":
          msg = "잘못된 요청입니다.";
          break;
        case "REQUEST_NOT_FOUND":
          msg = "존재하지 않는 댓글입니다.";
          break;
        case "UNAUTHORIZE":
          msg = "댓글을 삭제할 수 없습니다.";
          break;
        default:
          msg = "알 수 없는 오류입니다.";
          break;
      }
      const alert = useAlert();
      alert.open({
        title: "요청 실패",
        open: true,
        msg,
      });
    }
    return result;
  },
  setAskSearchOptions: (store, payload) => {
    store.commit("setAskSearchOptions", payload);
  },

  /*
    ==============================
                FAQ
    ==============================
    */
  // (캠퍼스 관리) FAQ 목록 조회
  async reqGetFaqItems(store, payload) {
    const { first, limit, order = "DESC", sort = "REGDTTM" } = payload;
    if (first) {
      store.commit("clearFaqItems");
      store.commit("clearFaqLastKey");
    }
    const lastKey = store.getters["getFaqLastKey"];
    if (!first && !lastKey) {
      return;
    }
    const result = await apiInstance.supports.getFaqItems({
      memberId: store.rootGetters["members/getMemberId"],
      campusId: store.rootGetters["campuses/getCampusUuid"],
      keyword: payload.keyword,
      frequentlyYn: payload.frequentlyYn,
      upperId: payload.upperId,
      sort,
      limit,
      order,
      lastKey,
    });
    if (result.success) {
      const { results, lastKey } = result.data;
      store.commit("setFaqItems", results);
      store.commit("setFaqLastKey", lastKey);
    } else {
      const alert = useAlert();
      switch (result.name) {
        case "ACCESS_DENIED":
          alert.open({
            title: "요청 실패",
            open: true,
            msg: "접근이 제한된 페이지입니다.",
          });
          break;
      }
    }
    return result;
  },
  // (캠퍼스 관리) FAQ 목록 조회
  async reqGetFaqTemplates(store, payload) {
    const result = await apiInstance.supports.getFaqTemplates({
      memberId: store.rootGetters["members/getMemberId"],
      campusId: store.rootGetters["campuses/getCampusUuid"],
      keyword: payload?.keyword,
      frequentlyYn: payload?.frequentlyYn,
      upperId: payload?.upperId,
    });
    if (result.success) {
      const { results } = result.data;
      store.commit("setFaqTemplates", results?.reverse());
    } else {
      const alert = useAlert();
      switch (result.name) {
        case "ACCESS_DENIED":
          alert.open({
            title: "요청 실패",
            open: true,
            msg: "접근이 제한된 페이지입니다.",
          });
          break;
      }
    }
    return result;
  },
  // (캠퍼스 관리) FAQ 글 등록
  async reqPostFaq(store, payload) {
    const { subject, conts, frequentlyYn, upperId } = payload;
    const result = await apiInstance.supports.postFaq({
      memberId: store.rootGetters["members/getMemberId"],
      campusId: store.rootGetters["campuses/getCampusUuid"],
      subject,
      conts,
      frequentlyYn,
      upperId,
    });
    if (result.success) {
      store.commit("setFaq", result.data);
    } else {
      let msg;
      switch (result.name) {
        case "ACCESS_DENIED":
          msg = "잘못된 요청입니다.";
          break;
        case "BAD_REQUEST":
          msg = "데이터가 올바르지 않습니다.";
          break;
        case "REQUEST_NOT_FOUND":
          msg = "FAQ 유형 정보가 존재하지 않습니다.";
          break;
        default:
          msg = "알 수 없는 오류입니다.";
          break;
      }
      const alert = useAlert();
      alert.open({
        title: "요청 실패",
        open: true,
        msg,
      });
    }
    return result;
  },
  // (캠퍼스 관리) FAQ 글 수정
  async reqPutFaq(store, payload) {
    const { subject, conts, frequentlyYn, id, upperId } = payload;
    const result = await apiInstance.supports.putFaq({
      memberId: store.rootGetters["members/getMemberId"],
      campusId: store.rootGetters["campuses/getCampusUuid"],
      subject,
      conts,
      frequentlyYn,
      id,
      upperId,
    });
    if (result.success) {
      store.commit("setFaq", result.data);
    } else {
      let msg;
      switch (result.name) {
        case "ACCESS_DENIED":
          msg = "잘못된 요청입니다.";
          break;
        case "BAD_REQUEST":
          msg = "데이터가 올바르지 않습니다.";
          break;
        case "REQUEST_NOT_FOUND":
          msg = "FAQ 유형 정보 또는 해당 FAQ가 존재하지 않습니다.";
          break;
        default:
          msg = "알 수 없는 오류입니다.";
          break;
      }
      const alert = useAlert();
      alert.open({
        title: "요청 실패",
        open: true,
        msg,
      });
    }
    return result;
  },
  // (캠퍼스 관리) FAQ 글 삭제
  async reqDeleteFaq(store, payload) {
    const { id } = payload;
    const result = await apiInstance.supports.deleteFaq({
      memberId: store.rootGetters["members/getMemberId"],
      campusId: store.rootGetters["campuses/getCampusUuid"],
      id,
    });
    if (result.success) {
      store.commit("setFaq", result.data);
    } else {
      let msg;
      switch (result.name) {
        case "ACCESS_DENIED":
          msg = "잘못된 요청입니다.";
          break;
        case "REQUEST_NOT_FOUND":
          msg = "FAQ가 존재하지 않습니다.";
          break;
        default:
          msg = "알 수 없는 오류입니다.";
          break;
      }
      const alert = useAlert();
      alert.open({
        title: "요청 실패",
        open: true,
        msg,
      });
    }
    return result;
  },
  // (캠퍼스 관리) FAQ 분류 목록 조회
  async reqGetFaqCategories(store) {
    const result = await apiInstance.supports.getFaqCategories({
      memberId: store.rootGetters["members/getMemberId"],
      campusId: store.rootGetters["campuses/getCampusUuid"],
    });
    if (result.success) {
      store.commit("setFaqCategories", result.data.results);
    } else {
      let msg;
      switch (result.name) {
        case "ACCESS_DENIED":
          msg = "잘못된 요청입니다.";
          break;
        default:
          msg = "알 수 없는 오류입니다.";
          break;
      }
      const alert = useAlert();
      alert.open({
        title: "요청 실패",
        open: true,
        msg,
      });
    }
    return result;
  },
  // (캠퍼스 관리) FAQ 분류 관리(등록/수정/삭제)
  async reqPostFaqCategories(store, targets) {
    const result = await apiInstance.supports.postFaqCategories({
      memberId: store.rootGetters["members/getMemberId"],
      campusId: store.rootGetters["campuses/getCampusUuid"],
      targets,
    });
    if (result.success) {
      store.commit("setFaqCategories", result.data.results);
    } else {
      let msg;
      switch (result.name) {
        case "ACCESS_DENIED":
          msg = "잘못된 요청입니다.";
          break;
        case "UNAUTHORIZE":
          msg = "수정 권한이 없습니다.";
          break;
        case "INVALID_PARAMETER":
          msg =
            "대체 카테고리 정보가 없거나 카테고리가 속하는 게시글이 존재하는 경우, 카테고리를 삭제할 수 없습니다.";
          break;
        default:
          msg = "알 수 없는 오류입니다.";
          break;
      }
      const alert = useAlert();
      alert.open({
        title: "요청 실패",
        open: true,
        msg,
      });
    }
    return result;
  },
  // (캠퍼스 관리) FAQ 단건 조회
  async reqGetFaq(store, { id }) {
    const result = await apiInstance.supports.getFaq({
      memberId: store.rootGetters["members/getMemberId"],
      campusId: store.rootGetters["campuses/getCampusUuid"],
      id,
    });
    if (result.success) {
      store.commit("setFaq", result.data);
    } else {
      let msg;
      switch (result.name) {
        case "ACCESS_DENIED":
          msg = "잘못된 요청입니다.";
          break;
        default:
          msg = "알 수 없는 오류입니다.";
          break;
      }
      globalStore.dispatch("common/setAlert", {
        open: true,
        msg,
      });
    }
    return result;
  },
  setFaqSearchOptions: (store, payload) => {
    store.commit("setFaqSearchOptions", payload);
  },
  // (고객센터) FAQ 전체 조회
  async reqGetUserAllFaq(store, payload) {
    const result = await apiInstance.supports.getUserAllFaq({
      campusId: store.rootGetters["campuses/getCampusUuid"],
      ...(payload?.keyword && { keyword: payload.keyword }),
      ...(payload?.frequentlyYn && { frequentlyYn: payload.frequentlyYn }),
      ...(payload?.upperId && { upperId: payload.upperId }),
    });
    if (result.success) {
      store.commit("setUserFaqItems", result.data.results);
    } else {
      let msg;
      switch (result.name) {
        case "ACCESS_DENIED":
          msg = "잘못된 요청입니다.";
          break;
        default:
          msg = "알 수 없는 오류입니다.";
          break;
      }
      const alert = useAlert();
      alert.open({
        title: "요청 실패",
        open: true,
        msg,
      });
    }
    return result;
  },
  // (고객센터) FAQ 분류 전체 조회
  async reqGetUserFaqCategories(store) {
    const result = await apiInstance.supports.getUserFaqCategories({
      campusId: store.rootGetters["campuses/getCampusUuid"],
    });
    if (result.success) {
      store.commit("setUserFaqCategories", result.data.results);
    } else {
      let msg;
      switch (result.name) {
        case "ACCESS_DENIED":
          msg = "잘못된 요청입니다.";
          break;
        default:
          msg = "알 수 없는 오류입니다.";
          break;
      }
      const alert = useAlert();
      alert.open({
        title: "요청 실패",
        open: true,
        msg,
      });
    }
    return result;
  },
  // (고객센터) FAQ 단건 조회
  async reqGetUserFaq(store, { id }) {
    const result = await apiInstance.supports.getUserFaq({
      campusId: store.rootGetters["campuses/getCampusUuid"],
      id,
    });
    if (result.success) {
      store.commit("setUserFaq", result.data);
    } else {
      let msg;
      switch (result.name) {
        case "ACCESS_DENIED":
          msg = "잘못된 요청입니다.";
          break;
        default:
          msg = "알 수 없는 오류입니다.";
          break;
      }
      const alert = useAlert();
      alert.open({
        title: "요청 실패",
        open: true,
        msg,
      });
    }
    return result;
  },
});
