import Vue from "vue";
import Vuex from "vuex";
import createLogger from "vuex/dist/logger";

import ApiClient from "@/api";
import { baseUrl, baseUrlV2 } from "@/config";
import localStorage from "@/utils/local-storage";
import sessionStorage from "@/utils/session-storage";

import common from "./modules/common";
import users from "./modules/users";
import auth from "./modules/auth";
import configure from "./modules/configure";
import supports from "./modules/supports";
import zoom from "./modules/zoom";

import promotions from "./modules/v2/promotions";
import campuses from "./modules/v2/campuses";
import plans from "./modules/v2/plans";
import members from "./modules/v2/members";
import payments from "./modules/v2/payments";
import admins from "./modules/v2/admins";
import vods from "./modules/v2/vods";
import supportsV2 from "./modules/v2/supports";
import products from "./modules/v2/products";
import contents from "./modules/v2/contents";
import communities from "./modules/v2/communities";
import activities from "./modules/v2/activities";
import articles from "./modules/v2/articles";
import notifications from "./modules/v2/notifications";
import transcodes from "./modules/v2/transcodes";
import compiles from "./modules/v2/compiles";
import applications from "./modules/v2/applications";
import credits from "./modules/v2/credits";
import groups from "./modules/v2/groups";

Vue.use(Vuex);

export const api = new ApiClient(null, { baseURL: baseUrl }, [
  "auth",
  "users",
  "configure",
  "supports",
  "zoom",
]);

export const apiV2 = new ApiClient(null, { baseURL: baseUrlV2 }, [
  "campuses",
  "plans",
  "members",
  "payments",
  "admins",
  "vods",
  "supports",
  "promotions",
  "products",
  "contents",
  "communities",
  "activities",
  "articles",
  "notifications",
  "transcodes",
  "compiles",
  "credits",
  "groups",
  "coupons",
  "campaigns",
  "histories",
]);

const debug = import.meta.env.NODE_ENV !== "production";

const store = new Vuex.Store({
  modules: {
    common: {
      ...common,
      actions: common.actions(api, localStorage, sessionStorage),
      state: common.state(localStorage, sessionStorage),
    },
    users: {
      ...users,
      actions: users.actions(api),
    },
    auth: {
      ...auth,
      actions: auth.actions(api, localStorage, sessionStorage),
      state: auth.state(localStorage, sessionStorage),
    },
    configure: {
      ...configure,
      actions: configure.actions(api),
    },
    supports: {
      namespaced: true,
      // v2와 v1 충돌시, v2가 override 하도록 한다.
      getters: {
        ...supports.getters,
        ...supportsV2.getters,
      },
      mutations: {
        ...supports.mutations,
        ...supportsV2.mutations,
      },
      state: {
        ...supports.state,
        ...supportsV2.state,
      },
      actions: { ...supports.actions(api), ...supportsV2.actions(apiV2) },
    },
    notifications: {
      ...notifications,
      actions: notifications.actions(apiV2),
    },
    payments: {
      ...payments,
      actions: payments.actions(apiV2),
    },
    campuses: {
      ...campuses,
      actions: campuses.actions(apiV2),
    },
    zoom: {
      ...zoom,
      actions: zoom.actions(api),
    },
    promotions: {
      ...promotions,
      actions: promotions.actions(apiV2),
    },
    plans: {
      ...plans,
      actions: plans.actions(apiV2),
    },
    members: {
      ...members,
      actions: members.actions(apiV2),
    },
    admins: {
      ...admins,
      actions: admins.actions(apiV2),
    },
    vods: {
      ...vods,
      actions: vods.actions(apiV2),
    },
    products: {
      ...products,
      actions: products.actions(apiV2),
    },
    contents: {
      ...contents,
      actions: contents.actions(apiV2),
    },
    communities: {
      ...communities,
      actions: communities.actions(apiV2),
    },
    activities: {
      ...activities,
      actions: activities.actions(apiV2),
    },
    articles: {
      ...articles,
      actions: articles.actions(apiV2),
    },
    transcodes: {
      ...transcodes,
      actions: transcodes.actions(apiV2),
    },
    compiles: {
      ...compiles,
      actions: compiles.actions(apiV2),
    },
    applications: {
      ...applications,
      actions: applications.actions(apiV2),
    },
    credits: {
      ...credits,
      actions: credits.actions(apiV2),
    },
    groups: {
      ...groups,
      actions: groups.actions(apiV2),
    },
  },
  strict: debug,
  // plugins: debug ? [createLogger()] : [],
});

export default store;

export const useStore = () => store;
