export default {
  getUserGroups: (state) => {
    return state.userGroups || [];
  },
  getGroup: (state) => {
    return state.group || {};
  },

  //주소록
  getAddrBooks: (state) => {
    return state.addrBooks || [];
  },
  getAddrBook: (state) => {
    return state.addrBook || {};
  },
  getAddrBookAddrs: (state) => {
    return state.addrs || [];
  },
  getAddrBookAddrsLastKey: (state) => {
    return state.addrsLastKey;
  },
  getAddrBookAddrsCnt: (state) => {
    return state.addrsCnt;
  },
};
