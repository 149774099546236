import { endpointV2 as endpoint } from "@/config";

// policy template 조회
export async function getPolicyTemplate({ adminsObjType }) {
  const params = {
    adminsObjType,
  };
  return this.getMethod("get", true, {
    url: `${endpoint.admins}/`,
    params,
  });
}

// policy template 조회
export async function getPublicAdmins({ adminsObjType }) {
  const params = {
    adminsObjType,
  };
  return this.getMethod("get", false, {
    url: `${endpoint.admins}/publics`,
    params,
  });
}

// 인터뷰 전체 조회
export async function getInterviewList() {
  return this.getMethod("get", false, {
    url: `${endpoint.admins}/interview`,
  });
}

// 인터뷰 단건 조회
export async function getInterview({ id }) {
  const params = {
    id,
  };
  return this.getMethod("get", false, {
    url: `${endpoint.admins}/interview/${id}`,
    params,
  });
}

export async function getUsersExcelDownloadAdmin() {
  return this.getMethod("get", true, {
    url: `${endpoint.admins}/users/excel`,
  });
}
