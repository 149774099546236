<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router/composables";
import { useStore } from "@/store";
import { useCheckoutStore } from "@/stores/checkout";

const route = useRoute();
const store = useStore();
const checkoutStore = useCheckoutStore();

const isStaff = computed(() => store.getters["members/isStaff"]);
const count = computed(() => checkoutStore.cart?.length || 0);
const countNormalized = computed(() => {
  if (count.value > 99) {
    return "99+";
  }
  return count.value;
});

function fetch() {
  checkoutStore.getCart();
}

fetch();
</script>

<template>
  <router-link
    v-if="!isStaff"
    :to="{
      name: 'campus-cart',
      params: route.params,
      query: route.query,
    }"
    :class="$style.icon"
  >
    <p-icon icon="ShoppingCart/Black" size="20" />
    <span v-if="count > 0" :class="$style.cnt">{{ countNormalized }}</span>
  </router-link>
</template>

<style lang="scss" module>
.icon {
  padding: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid $gray4;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  background-color: $white;
  @include hover-transition(background);
  &:hover {
    background-color: $gray1;
  }
}
.cnt {
  box-sizing: border-box;
  position: absolute;
  min-width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  line-height: 16px;
  padding: 0 4px;
  font-size: 10px;
  font-weight: bold;
  background: $coral;
  color: $white;
  text-align: center;
  border-radius: 24px;
  top: -6px;
  left: 22px;
}
</style>
