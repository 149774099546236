import { ref, reactive, computed } from "vue";
import { defineStore } from "pinia";

export const useConfirm = defineStore("confirm", () => {
  const state = reactive({
    open: false,
    icon: "",
    title: "",
    msg: "",
    callback: () => {},
    btnTxtConfirm: "",
    btnTxtCancel: "",
    checkboxText: "",
    reverse: false,
    cancelEnable: true,
    noClose: false,
  });

  const isOpen = computed(() => state.open);

  function open(params = {}) {
    state.open = true;
    state.icon = params.icon || "";
    state.title = params.title || "";
    state.msg = params.msg || "";
    state.callback = params.callback || (() => {});
    state.btnTxtConfirm = params.btnTxtConfirm || "";
    state.btnTxtCancel = params.btnTxtCancel || "";
    state.checkboxText = params.checkboxText || "";
    state.reverse = params.reverse || false;
    state.cancelEnable = params.cancelEnable || true;
    state.noClose = params.noClose || false;
  }
  function close() {
    state.open = false;
    state.icon = "";
    state.title = "";
    state.msg = "";
    state.callback = () => {};
    state.btnTxtConfirm = "";
    state.btnTxtCancel = "";
    state.checkboxText = "";
    state.reverse = false;
    state.cancelEnable = true;
    state.noClose = false;
  }
  return { state, isOpen, open, close };
});
