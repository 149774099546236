import globalStore from "@/store";
import { toJsonDateFormat } from "@/utils/date-format";
export default (apiInstance) => ({
  async reqGetCampusCredit(store, payload = {}) {
    const {
      campusId = globalStore.getters["campuses/getCampusUuid"],
      memberId = globalStore.getters["members/getMemberId"],
    } = payload;

    const result = await apiInstance.credits.getCampusCredit({
      campusId,
      memberId,
    });
    if (result.success) {
      store.commit("setCredit", result.data);
    }
    return result;
  },

  async reqGetCampusCreditHistories(store, payload = {}) {
    const {
      campusId = globalStore.getters["campuses/getCampusUuid"],
      memberId = globalStore.getters["members/getMemberId"],
      extinctYn,
      historyType,
      limit,
      eDate,
      order,
      sDate,
      first,
    } = payload;

    if (first) {
      store.commit("clearCreditHistories");
    }

    const lastKey = store.getters["getCreditHistoriesLastKey"];

    const result = await apiInstance.credits.getCampusCreditHistories({
      campusId,
      memberId,
      extinctYn,
      ...(historyType && { historyType }),
      ...(limit && { limit }),
      ...(sDate && { sDate: toJsonDateFormat(sDate, true) }),
      ...(eDate && { eDate: toJsonDateFormat(eDate) }),
      ...(order && { order }),
      ...(lastKey && { lastKey }),
    });

    if (result?.success) {
      store.commit("setCreditHistories", result.data);
    }

    return result;
  },
  async reqGetExpireCampusCredits(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      extinctYn = true,
      limit,
      first,
    } = payload;

    if (first) {
      store.commit("clearExpireCampusCredits");
    }

    const lastKey = store.getters["getExpireCreditsLastKey"];

    const result = await apiInstance.credits.getCampusCreditHistories({
      campusId,
      memberId,
      extinctYn,
      ...(limit && { limit }),
      ...(lastKey && { lastKey }),
    });

    if (result?.success) {
      store.commit("setExpireCampusCredits", result.data);
    }

    return result;
  },
});
