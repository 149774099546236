export default (apiInstance) => ({
  // 프로모션 목록 조회
  reqGetPromotions: async (store) => {
    const result = await apiInstance.promotions.getPromotions();
    if (result.success) {
      const { results } = result.data;
      store.commit("setPromotions", results);
    }
    return result;
  },
  // 프로모션 이하의 상품 목록 조회
  reqGetProducts: async (store, payload) => {
    const { promotionId, productType } = payload;
    const data = {
      promotionId,
      productType,
    };
    const result = await apiInstance.promotions.getProducts(data);
    if (result.success) {
      const { results } = result.data;
      store.commit("setProducts", results);
    }
    return result;
  },
  // 프로모션 결제 후 이력 입력
  reqPostHistories: async (store, payload) => {
    const {
      zipCode,
      amount,
      address,
      addressDetail,
      contact,
      userUuid,
      name,
      promotionId,
      productType,
      email,
      products,
    } = payload;
    const data = {
      amount: parseInt(amount) || 0,
      contact,
      userUuid,
      name,
      email,
      promotionId,
      products,
      ...{ zipCode },
      ...{ address },
      ...{ addressDetail },
      ...{ productType },
    };
    const result = await apiInstance.promotions.postHistories(data);
    return result;
  },
});
