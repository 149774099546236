import { endpointV2 as endpoint } from "@/config";

export async function getCampusCredit({ campusId, memberId }) {
  return this.getMethod("get", true, {
    url: `${endpoint.credits}/`,
    params: {
      campusId,
      memberId,
    },
  });
}
export async function getCampusCreditHistories({
  campusId,
  memberId,
  extinctYn,
  limit = 10,
  sDate,
  eDate,
  order,
  lastKey,
  historyType,
}) {
  const params = {
    ...(extinctYn !== undefined && { extinctYn }),
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(limit && { limit }),
    ...(sDate && { sDate }),
    ...(eDate && { eDate }),
    ...(order && { order }),
    ...(lastKey && { lastKey }),
    ...(historyType && { historyType }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.credits}/histories`,
    params,
  });
}
