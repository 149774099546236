import { endpointV2 as endpoint } from "@/config";

// http://swagger.poincampus.com/?urls.primaryName=06.%EC%BA%A0%ED%8D%BC%EC%8A%A4%EB%B3%84%20%EA%B3%A0%EA%B0%9D%EC%84%BC%ED%84%B0

/*
==============================
            문의하기
==============================
*/

// 사용자 View
// (사용자) 문의하기 목록 조회
export async function getUserAskItems({
  userId,
  campusId,
  keyword,
  askStatus,
  askType,
  limit = 10,
  lastKey,
  order,
}) {
  const params = {
    campusId,
    userId,
    limit,
    ...(lastKey && { lastKey }),
    ...(keyword && { keyword }),
    ...(askStatus && { askStatus }),
    ...(askType && { askType }),
    ...(order && { order }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.supports}/asks`,
    params,
  });
}

// (사용자) 문의자 캠퍼스 문의하기 등록
export async function postAsk({
  askType,
  subject,
  conts,
  contact,
  campusId,
  name,
  userId,
  email,
  memberId,
  attaches,
}) {
  const data = {
    askType,
    subject,
    conts,
    contact,
    campusId,
    name,
    email,
    ...(userId && { userId }),
    ...(memberId && { memberId }),
    ...(attaches && { attaches }),
  };
  return this.getMethod("post", false, {
    url: `${endpoint.supports}/asks`,
    data,
  });
}

// (사용자) 문의자 문의내용 수정하기
export async function putAsk({
  askType,
  subject,
  conts,
  contact,
  campusId,
  name,
  id,
  email,
  userId,
  memberId,
  attaches,
}) {
  const data = {
    askType,
    subject,
    conts,
    id,
    contact,
    campusId,
    name,
    email,
    ...(userId && { userId }),
    ...(memberId && { memberId }),
    ...({ attaches: attaches || [] }),
  };
  return this.getMethod("put", true, {
    url: `${endpoint.supports}/asks`,
    data,
  });
}

// (사용자) 문의자 문의취소
export async function cancelAsk({ campusId, id, userId }) {
  const data = {
    campusId,
    id,
    userId,
  };
  return this.getMethod("delete", true, {
    url: `${endpoint.supports}/asks`,
    data,
  });
}

// (사용자) 문의하기 단건 조회
export async function getUserAsk({ campusId, id, memberId, userId }) {
  if (!id || !campusId) {
    return false;
  }
  const params = {
    ...(userId && { userId }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.supports}/asks/${campusId}/${id}`,
    params,
  });
}

// 캠퍼스 관리 View
// (캠퍼스 관리) 문의하기 목록 조회
export async function getCampusAskItems({
  memberId,
  campusId,
  keyword,
  askStatus,
  askType,
  limit = 20,
  lastKey,
  fromDate,
  toDate,
  userYn,
  replyYn,
  countYn,
  order,
}) {
  const params = {
    memberId,
    campusId,
    limit,
    ...(order && { order }),
    ...(countYn && { countYn }),
    ...(fromDate && { fromDate }),
    ...(toDate && { toDate }),
    ...(userYn && { userYn }),
    ...(!countYn && lastKey && { lastKey }),
    ...(keyword && { keyword }),
    ...(askStatus && { askStatus }),
    ...(askType && { askType }),
    ...(replyYn && { replyYn }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.supports}/asks/campus`,
    params,
  });
}

// (캠퍼스 관리) 문의 답변 작성
export async function putAnswer({
  answer,
  campusId,
  id,
  memberId,
  changeType,
  remark,
}) {
  const data = {
    campusId,
    id,
    memberId,
    changeType,
    ...(answer && { answer }),
    ...(remark && { remark }),
  };
  return this.getMethod("put", true, {
    url: `${endpoint.supports}/asks/campus`,
    data,
  });
}

// (캠퍼스 관리) 문의하기 단건 조회
export async function getCampusAsk({ campusId, id, memberId, userId }) {
  if (!id || !campusId) {
    return false;
  }
  const params = {
    ...(userId && { userId }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.supports}/asks/campus/${campusId}/${id}`,
    params,
  });
}

/*
==============================
        문의하기 (댓글)
==============================
*/
// 사용자 View
export async function getUserReplies({
  campusId,
  userId,
  id,
  limit = 20,
  order = "DESC",
  lastKey,
}) {
  const params = {
    userId,
    campusId,
    id,
    limit,
    order,
    ...(lastKey && { lastKey }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.supports}/replies`,
    params,
  });
}

export async function postUserReply({ conts, campusId, id, userId, memberId }) {
  const data = {
    conts,
    campusId,
    id,
    ...(userId && { userId }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.supports}/replies`,
    data,
  });
}

export async function deleteUserReply({ campusId, id, userId, memberId }) {
  const data = {
    campusId,
    id,
    ...(userId && { userId }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("delete", true, {
    url: `${endpoint.supports}/replies`,
    data,
  });
}

// 캠퍼스 관리 View
export async function getCampusReplies({
  campusId,
  memberId,
  id,
  limit = 20,
  order = "DESC",
  lastKey,
}) {
  const params = {
    memberId,
    campusId,
    id,
    limit,
    order,
    ...(lastKey && { lastKey }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.supports}/replies/campus`,
    params,
  });
}

export async function postCampusReply({
  conts,
  campusId,
  id,
  userId,
  memberId,
  name,
}) {
  const data = {
    conts,
    campusId,
    id,
    ...(name && { name }),
    ...(userId && { userId }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.supports}/replies/campus`,
    data,
  });
}

export async function deleteCampusReply({ campusId, id, userId, memberId }) {
  const data = {
    campusId,
    id,
    ...(userId && { userId }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("delete", true, {
    url: `${endpoint.supports}/replies/campus`,
    data,
  });
}

/*
==============================
            FAQ
==============================
*/
// (캠퍼스 관리) FAQ 목록 조회
export async function getFaqItems({
  memberId,
  campusId,
  keyword,
  frequentlyYn,
  upperId,
  limit = 20,
  order = "DESC",
  lastKey,
  sort = "REGDTTM",
}) {
  if (!memberId || !campusId) {
    return;
  }
  const params = {
    memberId,
    campusId,
    limit,
    order,
    sort,
    ...(upperId && { upperId }),
    ...(frequentlyYn && { frequentlyYn }),
    ...(lastKey && { lastKey }),
    ...(keyword && { keyword }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.supports}/faq`,
    params,
  });
}

// (캠퍼스 관리) FAQ Template 목록 조회
export async function getFaqTemplates({
  memberId,
  campusId,
  keyword,
  frequentlyYn,
  upperId,
  limit = 100000,
  order = "DESC",
  lastKey,
  sort = "REGDTTM",
}) {
  if (!memberId || !campusId) {
    return;
  }
  const params = {
    memberId,
    campusId,
    limit,
    order,
    sort,
    ...(upperId && { upperId }),
    ...(frequentlyYn && { frequentlyYn }),
    ...(keyword && { keyword }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.supports}/admin/faq`,
    params,
  });
}

// (캠퍼스 관리) FAQ 글 등록
export async function postFaq({
  memberId,
  campusId,
  subject,
  conts,
  frequentlyYn,
  upperId,
}) {
  if (!memberId || !campusId) {
    return;
  }
  const data = {
    memberId,
    campusId,
    subject,
    conts,
    frequentlyYn,
    upperId,
  };
  return this.getMethod("post", true, {
    url: `${endpoint.supports}/faq`,
    data,
  });
}

// (캠퍼스 관리) FAQ 글 수정
export async function putFaq({
  memberId,
  campusId,
  subject,
  conts,
  frequentlyYn,
  id,
  upperId,
}) {
  if (!memberId || !campusId) {
    return;
  }
  const data = {
    memberId,
    campusId,
    subject,
    conts,
    frequentlyYn,
    id,
    upperId,
  };
  return this.getMethod("put", true, {
    url: `${endpoint.supports}/faq`,
    data,
  });
}

// (캠퍼스 관리) FAQ 글 삭제
export async function deleteFaq({ memberId, campusId, id }) {
  if (!memberId || !campusId || !id) {
    return;
  }
  const data = {
    memberId,
    campusId,
    id,
  };
  return this.getMethod("delete", true, {
    url: `${endpoint.supports}/faq`,
    data,
  });
}

// (캠퍼스 관리) FAQ 분류 목록 조회
export async function getFaqCategories({ memberId, campusId }) {
  if (!memberId || !campusId) {
    return;
  }
  const params = { memberId, campusId };
  return this.getMethod("get", true, {
    url: `${endpoint.supports}/faq/categories`,
  });
}

// (캠퍼스 관리) FAQ 분류 관리(등록/수정/삭제)
export async function postFaqCategories({ memberId, campusId, targets }) {
  const data = {
    memberId,
    campusId,
    targets,
  };
  return this.getMethod("post", true, {
    url: `${endpoint.supports}/faq/categories`,
    data,
  });
}

// (캠퍼스 관리) FAQ 단건 조회
export async function getFaq({ memberId, campusId, id }) {
  if (!memberId || !campusId || !id) {
    return;
  }
  const params = {
    memberId,
    campusId,
  };
  return this.getMethod("get", true, {
    url: `${endpoint.supports}/faq/${id}`,
    params,
  });
}

// (고객센터) FAQ 전체 조회
export async function getUserAllFaq({
  campusId,
  keyword,
  frequentlyYn,
  upperId,
}) {
  if (!campusId) {
    return;
  }
  const params = {
    campusId,
    ...(keyword && { keyword }),
    ...(frequentlyYn && { frequentlyYn }),
    ...(upperId && { upperId }),
  };
  return this.getMethod("get", false, {
    url: `${endpoint.supports}/faq/user`,
    params,
  });
}

// (고객센터) FAQ 분류 전체 조회
export async function getUserFaqCategories({ campusId }) {
  if (!campusId) {
    return;
  }
  return this.getMethod("get", false, {
    url: `${endpoint.supports}/faq/user/categories`,
    params: { campusId },
  });
}

// (고객센터) FAQ 단건 조회
export async function getUserFaq({ campusId, id }) {
  if (!campusId || !id) {
    return;
  }
  return this.getMethod("get", false, {
    url: `${endpoint.supports}/faq/user/${id}`,
    params: { campusId },
  });
}
