export default {
  // userPaymentsHistory: [],
  userPaymentsHistories: [],
  userPaymentSummary: null,
  customerNameKeyword: "",
  paymentLastKey: null,
  cards: [],
  planHistories: [],
  planHistoriesLastKey: "",
  checkoutHistory: null,
  productPayment: null,
  userDetailPayments: {
    lastKey: "",
    items: [],
  },
  userDetailPaymentSummary: [],
  productPaymentsHistories: {
    lastKey: "",
    items: [],
  },
  productPaymentsSummary: {},
  userCheckoutHistory: null,
  userPaymentDropDownItems: {
    products: [],
    promotions: [],
  },
  userPurchases: {
    lastKey: "",
    items: [],
  },
  userCancelPurchases: null,
  table: {
    page: 1,
    limit: 10,
    keyword: "",
    paymentStatus: "",
    promotionId: "",
    basis: "",
    inPaymentStatus: [],
    paymentWay: "",
    filterYn: false,
    filterOption: [],
  },
  dashboardPaymentsSummary: {},
  reservationOptions: [],
  paymentsHistorySettlement: {},
};
