import { ContentType as ContentTypeEnum } from "@/enums/productsEnum";

/** GET */

// 기본 속성을 처리하는 함수
const processBaseProperties = (item) => {
  const { id, upperId, syncYn, origin } = item;
  return {
    ...(syncYn && origin && { ...origin }),
    ...(upperId && { upperId }),
    ...(id && { id }),
  };
};

// 코드 관련 속성을 처리하는 함수
const processCodeProperties = (item) => {
  if (!item.codeYn) return {};

  return {
    ...(!item.codeLang && { codeLang: [] }),
    ...(!item.codeFiles && { codeFiles: [] }),
    ...(!item.testSet && {
      testSet: [
        {
          stdin: "",
          stdout: "",
        },
      ],
    }),
  };
};

// 주관식 문제 속성을 처리하는 함수
const processSubjectiveQuestion = (item) => {
  if (
    item.contentType === ContentTypeEnum.QUESTION &&
    item.questionType === "SBJT" &&
    !item.sbjtAns
  ) {
    return { sbjtAns: [] };
  }
  return {};
};

// 단일 아이템을 처리하는 함수
const processItem = (item) => {
  if (!item) return null;

  const processedItems = item.items?.map(processItem);

  return structuredClone({
    ...item,
    ...processBaseProperties(item),
    ...processCodeProperties(item),
    ...processSubjectiveQuestion(item),
    ...(processedItems && { items: processedItems }),
  });
};

// 메인 포맷팅 함수
export const formatContents = (contents = []) => {
  return contents.map(processItem);
};

/** PUT */

const transformDate = (date) => (date ? new Date(date).toJSON() : "");

const processQuizOptions = (options) => ({
  quiz: {
    ...options.quiz,
    startDttm: transformDate(options.quiz.startDttm),
    endDttm: transformDate(options.quiz.endDttm),
  },
});

const getContentTypeSpecificFields = (item) => {
  if (item.contentType === ContentTypeEnum.LIVE) {
    return {
      startDttm: transformDate(item.startDttm),
      endDttm: transformDate(item.endDttm),
    };
  }

  if (item.contentType === ContentTypeEnum.QUIZ) {
    return {
      options: processQuizOptions(item.options),
    };
  }

  return {};
};

const transformContentItem = (item) => {
  if (!item) return null;

  // Remove temporary fields
  const cleanedItem = { ...item };
  delete cleanedItem.tempId;
  delete cleanedItem.hide;
  if (cleanedItem.quizFormType === "") {
    delete cleanedItem.quizFormType;
  }

  // Base transformed object
  const transformed = {
    ...(item.syncYn
      ? {
          contentType: item.contentType,
          syncYn: item.syncYn,
          originId: item.originId,
        }
      : cleanedItem),
    previewYn: item.previewYn,
    accessDttm: transformDate(item.accessDttm),
    ...getContentTypeSpecificFields(item),
    ...(item.id && { id: item.id }),
  };

  // Process nested items if they exist
  if (item.items?.length) {
    transformed.items = item.items.map(transformContentItem);
  }

  return transformed;
};

// 메인 포맷팅 함수
export const transformContents = (contents = []) => {
  return contents.map(transformContentItem);
};
