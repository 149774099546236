export default (apiInstance) => ({
  // Store 사용하지 않는 경우
  async getCommunities(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      limit,
      sort,
      adminYn,
      keyword,
      shutdownYn,
      openYn,
      excludeProductId,
      includeProductId,
      allYn = false,
      myYn = false,
      lastKey,
      delegatorId,
    } = payload;
    const type = memberId ? "getCommunities" : "getPublicCommunities";
    return apiInstance.communities[type]({
      campusId,
      memberId,
      ...(limit && { limit }),
      ...(sort && { sort }),
      ...(adminYn && { adminYn }),
      ...(keyword && { keyword }),
      shutdownYn,
      ...(excludeProductId && { excludeProductId }),
      ...(includeProductId && { includeProductId }),
      ...(lastKey && { lastKey }),
      openYn,
      ...(allYn && { allYn }),
      ...(myYn && { myYn }),
      ...(delegatorId && { delegatorId }),
    });
  },
  // 회원 커뮤니티 목록 조회
  async reqGetCommunities(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      limit,
      sort,
      adminYn,
      keyword,
      shutdownYn,
      first = true,
      openYn,
      joinType,
      excludeProductId,
      includeProductId,
      local = false, // store 사용하지 않는 경우
      allYn = false,
    } = payload;
    if (!local && first) {
      store.commit("clearCommunities");
    }
    const lastKey = store.getters["getCommunitiesLastKey"];
    const result = await apiInstance.communities.getCommunities({
      campusId,
      memberId,
      ...(limit && { limit }),
      ...(sort && { sort }),
      ...(adminYn && { adminYn }),
      ...(keyword && { keyword }),
      shutdownYn,
      ...(excludeProductId && { excludeProductId }),
      ...(includeProductId && { includeProductId }),
      ...(lastKey && { lastKey }),
      openYn,
      ...(joinType && { joinType }),
      ...(allYn && { allYn }),
    });
    if (!local && result.success) {
      store.commit("setCommunities", result.data);
    }
    return result;
  },
  //비회원 커뮤니티 목록 조회
  async reqGetPublicCommunities(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      limit,
      keyword,
      first,
    } = payload;
    if (first) {
      store.commit("clearCommunities");
    }
    const lastKey = store.getters["getCommunitiesLastKey"];
    const result = await apiInstance.communities.getPublicCommunities({
      campusId,
      ...(keyword && { keyword }),
      ...(lastKey && { lastKey }),
      ...(limit && { limit }),
    });
    if (result.success) {
      store.commit("setCommunities", result.data);
    }
    return result;
  },
  //커뮤니티 생성
  async reqPostCommunity(store, payload = {}) {
    const campusId = store.rootGetters["campuses/getCampusUuid"];
    const memberId = store.rootGetters["members/getMemberId"];
    const { name } = payload;
    if (!name) {
      return {
        success: false,
        message: "커뮤니티 이름을 입력해주세요.",
      };
    }
    const params = {
      campusId,
      memberId,
      name,
      images: {
        conts: "",
        textColor: "black",
        horizontal: "CENTER",
        nameYn: true, // 배너 > 커뮤니티 이름 적용 기본값 설정
        items: [
          {
            path: "https://file.poincampus.com/assets/sample/community.png",
            key: "DEFAULT",
          },
        ],
      },
      products: [],
      joinType: "AUTO",
      openYn: true,
      addMembers: [],
      thumbnailImage: {
        path: "https://file.poincampus.com/assets/sample/community-thumbnail.png",
        key: "DEFAULT",
      },
    };
    const result = await apiInstance.communities.postCommunity(params);
    return result;
  },
  //커뮤니티 단건 조회
  async reqGetCommunity(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      id,
    } = payload;
    const result = await apiInstance.communities.getCommunity({
      campusId,
      memberId,
      ...(id && { id }),
    });
    if (result.success) {
      store.commit("setCommunity", result.data);
      store.commit("setCommunityDashboard", result.data);
    }
    return result;
  },
  // 비회원커뮤니티단건조회
  async reqGetCommunityPublic(store, payload = {}) {
    const { campusId = store.rootGetters["campuses/getCampusUuid"], id } =
      payload;
    const result = await apiInstance.communities.getCommunityPublic({
      campusId,
      ...(id && { id }),
    });
    if (result.success) {
      store.commit("setCommunity", result.data);
      store.commit("setCommunityDashboard", result.data);
    }
    return result;
  },
  async reqPutCommunity(store, payload = {}) {
    const {
      images,
      productIds,
      joinType,
      changeType,
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      name,
      thumbnailImage,
      openYn,
      shutdownYn,
      id,
    } = payload;
    const result = await apiInstance.communities.putCommunity({
      ...(images && { images }),
      ...(productIds && { productIds }),
      ...(joinType && { joinType }),
      ...(changeType && { changeType }),
      ...(name && { name }),
      ...(thumbnailImage && { thumbnailImage }),
      ...(openYn !== undefined && { openYn }),
      shutdownYn,
      campusId,
      memberId,
      ...(id && { id }),
    });
    if (result.success) {
      store.commit("setCommunity", result.data);
      store.commit("setCommunityDashboard", result.data);
    }
    return result;
  },
  async reqDeleteCommunity(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      id,
    } = payload;
    const result = await apiInstance.communities.deleteCommunity({
      campusId,
      memberId,
      ...(id && { id }),
    });
    return result;
  },
  //커뮤티니 포스트 조회 Store X
  async getPosts(store, payload = {}) {
    const {
      keyword,
      memberId = store.rootGetters["members/getMemberId"],
      campusId = store.rootGetters["campuses/getCampusUuid"],
      limit,
      privateYn,
      hotYn,
      adminYn,
      comId,
      endDate,
      startDate,
      notiYn,
      lastKey,
      sort,
    } = payload;
    if (!memberId) {
      return;
    }
    return apiInstance.communities.getPosts({
      memberId,
      campusId,
      ...(keyword && { keyword }),
      ...(limit && { limit }),
      ...(comId && { comId }),
      ...(endDate && { endDate }),
      ...(startDate && { startDate }),
      ...(lastKey && { lastKey }),
      ...(sort && { sort }),
      ...(privateYn != null && { privateYn }),
      ...(hotYn != null && { hotYn }),
      ...(notiYn != null && { notiYn }),
      ...(adminYn != null && { adminYn }),
    });
  },
  //커뮤티니 포스트 조회
  async reqGetPosts(store, payload = {}) {
    const {
      keyword,
      memberId = store.rootGetters["members/getMemberId"],
      campusId = store.rootGetters["campuses/getCampusUuid"],
      limit,
      privateYn,
      hotYn,
      adminYn,
      comId,
      endDate,
      startDate,
      notiYn,
      first,
      sort,
    } = payload;
    if (first) {
      store.commit("clearPosts");
    }
    const lastKey = store.getters["getPostsLastKey"];
    const result = await apiInstance.communities.getPosts({
      memberId,
      campusId,
      ...(keyword && { keyword }),
      ...(limit && { limit }),
      privateYn,
      ...(hotYn && { hotYn }),
      ...(adminYn && { adminYn }),
      ...(comId && { comId }),
      ...(endDate && { endDate }),
      ...(startDate && { startDate }),
      ...(lastKey && { lastKey }),
      notiYn,
      ...(sort && { sort }),
    });
    if (result.success) {
      store.commit("setPosts", result.data);
    }
    return result;
  },
  //커뮤티니 포스트 조회 (비회원)
  async reqGetPostsPublic(store, payload = {}) {
    const {
      keyword,
      campusId = store.rootGetters["campuses/getCampusUuid"],
      limit,
      privateYn,
      hotYn,
      adminYn,
      comId,
      endDate,
      startDate,
      notiYn,
      first,
      sort,
    } = payload;
    if (first) {
      store.commit("clearPosts");
    }
    const lastKey = store.getters["getPostsLastKey"];
    const result = await apiInstance.communities.getPostsPublic({
      campusId,
      ...(keyword && { keyword }),
      ...(limit && { limit }),
      privateYn,
      ...(hotYn && { hotYn }),
      ...(adminYn && { adminYn }),
      ...(comId && { comId }),
      ...(endDate && { endDate }),
      ...(startDate && { startDate }),
      ...(lastKey && { lastKey }),
      notiYn,
      ...(sort && { sort }),
    });
    if (result.success) {
      store.commit("setPosts", result.data);
    }
    return result;
  },
  //내가 작성한 포스트 조회
  async reqGetMyPosts(store, payload = {}) {
    const {
      memberId = store.rootGetters["members/getMemberId"],
      limit,
      campusId = store.rootGetters["campuses/getCampusUuid"],
      comId,
      endDate,
      targetId,
      startDate,
      first,
      keyword,
    } = payload;
    if (first) {
      store.commit("clearMyPosts");
    }
    if (!memberId || !targetId) {
      return;
    }
    const lastKey = store.getters["getMyPostsLastKey"];
    const result = await apiInstance.communities.getMyPosts({
      ...(memberId && { memberId }),
      ...(limit && { limit }),
      ...(campusId && { campusId }),
      ...(comId && { comId }),
      ...(endDate && { endDate }),
      ...(targetId && { targetId }),
      ...(startDate && { startDate }),
      ...(lastKey && { lastKey }),
      ...(keyword && { keyword }),
    });
    if (result.success) {
      store.commit("setMyPosts", result.data);
    }
    return result;
  },
  //포스트 단건조회 (비회원)
  async reqGetPostPublic(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      id,
      first = true,
    } = payload;
    if (first) {
      store.commit("clearPost");
    }
    const result = await apiInstance.communities.getPostPublic({
      ...(campusId && { campusId }),
      ...(id && { id }),
    });
    if (result.success) {
      store.commit("updatePost", result.data);
    }
    return result;
  },
  //포스트 단건조회
  async reqGetPost(store, payload = {}) {
    const {
      memberId = store.rootGetters["members/getMemberId"],
      campusId = store.rootGetters["campuses/getCampusUuid"],
      id,
      first = true,
    } = payload;
    if (first) {
      store.commit("clearPost");
    }
    const result = await apiInstance.communities.getPost({
      ...(memberId && { memberId }),
      ...(campusId && { campusId }),
      ...(id && { id }),
    });
    if (result.success) {
      store.commit("updatePost", result.data);
    }
    return result;
  },
  //포스트 생성
  async reqPostPost(store) {
    const memberId = store.rootGetters["members/getMemberId"];
    const campusId = store.rootGetters["campuses/getCampusUuid"];
    const {
      privateYn,
      pinYn,
      subject,
      conts,
      comIds,
      notiYn,
      attachImages,
      attaches,
    } = store.getters["getPost"];
    const result = await apiInstance.communities.postPost({
      privateYn,
      pinYn,
      ...(subject && { subject }),
      ...(conts && { conts }),
      ...(campusId && { campusId }),
      ...(comIds && { comIds }),
      ...(notiYn && { notiYn }),
      ...(attachImages && { attachImages }),
      ...(memberId && { memberId }),
      ...(attaches && { attaches }),
    });
    return result;
  },

  //포스트 수정
  async reqPutPost(store, payload = {}) {
    const memberId = store.rootGetters["members/getMemberId"];
    const campusId = store.rootGetters["campuses/getCampusUuid"];
    const {
      id,
      modAll,
      privateYn,
      pinYn,
      subject,
      conts,
      notiYn,
      attachImages,
      attaches,
    } = store.getters["getPost"];
    const { comId } = payload;
    const result = await apiInstance.communities.putPost({
      ...(id && { id }),
      ...(campusId && { campusId }),
      ...(memberId && { memberId }),
      ...(modAll && { modAll }),
      privateYn,
      pinYn,
      ...(subject && { subject }),
      ...(conts && { conts }),
      ...(comId && { comId }),
      ...(notiYn && { notiYn }),
      ...(attachImages && { attachImages }),
      ...(attaches && { attaches }),
    });
    return result;
  },

  //포스트 삭제
  async reqDeletePost(store, payload = {}) {
    const { id, memberId, campusId, delAll } = payload;
    const result = await apiInstance.communities.deletePost({
      ...(id && { id }),
      ...(campusId && { campusId }),
      ...(memberId && { memberId }),
      ...(delAll && { delAll }),
    });
    if (result.success) {
      return result;
    }
    return result;
  },
  //담당자 관리
  async reqGetCommunityDelegators(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      id,
    } = payload;
    // if (first) {
    //   store.commit("clearCommunityMembers");
    // }
    const result = await apiInstance.communities.getCommunityDelegators({
      ...(memberId && { memberId }),
      ...(campusId && { campusId }),
      ...(id && { id }),
    });
    if (result.success) {
      store.commit("setCommunityDelegators", result.data);
    }
    return result;
  },
  async reqPostCommunityDelegators(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      id,
      changeType,
      targets,
    } = payload;
    const result = await apiInstance.communities.postCommunityDelegators({
      ...(changeType && { changeType }),
      ...(campusId && { campusId }),
      ...(id && { id }),
      ...(targets && { targets }),
      ...(memberId && { memberId }),
    });
    if (result.success) {
      return result;
    }
    return result;
  },
});
