export default (apiInstance) => ({
  reqGetNotificationsV2: async (store, payload = {}) => {
    const { userId = store.rootGetters["users/getUserUuid"], limit } = payload;
    const lastKey = store.rootGetters["notifications/getLastKey"];
    const params = {
      userId,
      limit,
      ...(lastKey && { lastKey }),
    };
    const result = await apiInstance.notifications.getNotifications(params);
    if (result.success) {
      store.commit("setNotifications", result.data);
    }
  },
  reqGetNotificationCnt: async (store) => {
    const params = {
      userId: store.rootGetters["users/getUserUuid"],
      countOnly: true,
    };
    const result = await apiInstance.notifications.getNotifications(params);
    if (result?.success) {
      store.commit("setNewCount", result.data);
    }
  },

  reqGetNotification: async (store, payload = {}) => {
    const { userId = store.rootGetters["users/getUserUuid"], pk, rk } = payload;
    const result = await apiInstance.notifications.getNotification({
      userId,
      pk,
      rk,
    });
    if (result.success) {
      store.commit("setNotification", result.data);
      store.dispatch("reqGetNotificationCnt");
    }
    return result.success;
  },
  reqDeleteNotifications: async (store, data = {}) => {
    const result = await apiInstance.notifications.deleteNotifications({
      userId: store.rootGetters["users/getUserUuid"],
      data,
    });
    if (result.success) {
      store.dispatch("reqGetNotificationCnt");
    }
    return result;
  },
  reqDeleteAllNotifications: async (store) => {
    const userId = store.rootGetters["users/getUserUuid"];
    const result = await apiInstance.notifications.deleteNotifications({
      userId: userId,
    });

    if (result.success) {
      store.commit("deleteAllNotifications", {});
      store.dispatch("reqGetNotificationCnt");
    }
  },
  toggleContainerActive: (store) => {
    store.commit("toggleContainerActive", {});
  },
  resetNotifications: (store) => {
    store.commit("resetNotifications", {});
  },
  setClearNotifications: (store) => {
    store.commit("setClearNotifications", {});
  },
});
