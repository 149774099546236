import { computed, ref } from "vue";
import { defineStore } from "pinia";
import { useAlert } from "@/stores/alert";
import { useStore } from "@/store";
import { useI18n } from "vue-i18n-composable";
import { ca } from "date-fns/locale";

export const useProductStore = defineStore("product", () => {
  const store = useStore();
  const alert = useAlert();
  const { t } = useI18n();
  const newProduct = ref(null);

  const product = computed(() => {
    return store.getters["products/getProduct"];
  });
  const productId = computed(() => product.value.id);
  const productName = computed(() => product.value?.name || "");

  const imageSrc = computed(() => {
    return product.value?.image?.items?.[0]?.path;
  });

  // 수익 분배 설정
  async function putProductProfitShare(payload = {}) {
    const campusId = store.getters["campuses/getCampusUuid"];
    const memberId = store.getters["members/getMemberId"];
    const { partnerYn, partnerLinkYn, partnerLinkRate, targets } = payload;

    if (targets?.length < 1) {
      return { success: false };
    }

    const result = await this.api.products.putPartners({
      campusId,
      memberId,
      partnerYn,
      partnerLinkYn,
      partnerLinkRate,
      targets,
    });

    if (!result.success) {
      let title = "설정 실패";
      let msg = "";
      switch (result.code) {
        case "ACCESS_DENIED":
          msg = t("error.access");
          break;
        case "UNAUTHORIZE":
          msg = t("error.authorize");
          break;
        case "INVALID_PARAMETER":
          msg = t("error.invalid");
          break;
        case "NOT_SUPPORTED":
          msg = t("error.notSupportedAddon", { type: "제휴 마케팅" });
          break;
        default:
          msg = t("error.default");
      }

      alert.open({
        title,
        msg,
      });
    }

    return result;
  }

  async function getSearchPartners(payload = {}) {
    const params = {
      campusId: store.getters["campuses/getCampusUuid"],
      memberId: store.getters["members/getMemberId"],
      ...payload,
    };
    const result = await this.api.products.getSearchPartners(params);
    return result;
  }

  const categories = ref([]);
  async function getCategories(payload = {}) {
    categories.value = [];
    const res = await this.api.products.getCategories({
      campusId: store.getters["campuses/getCampusUuid"],
      ...(payload?.manageYn && { manageYn: payload.manageYn }),
    });
    if (res?.success) {
      categories.value = res.data?.categories || [];
    }
    return res;
  }
  async function postCategories(payload = {}) {
    const memberId = store.getters["members/getMemberId"];
    const campusId = store.getters["campuses/getCampusUuid"];
    const { categories = [] } = payload;
    const result = await this.api.products.postCategories({
      campusId,
      memberId,
      categories,
    });
    if (result.success) {
      categories.value = result.data.categories || [];
    }
    return result;
  }

  async function putExtra({ productId, changeType, targets }) {
    const campusId = store.getters["campuses/getCampusUuid"];
    const memberId = store.getters["members/getMemberId"];

    const result = await this.api.products.putExtra({
      campusId,
      ...(productId && { productId }),
      ...(changeType && { changeType }),
      ...(targets && { targets }),
      memberId,
    });

    return result;
  }

  async function getPromotions(payload) {
    const campusId = store.getters["campuses/getCampusUuid"];
    const memberId = store.getters["members/getMemberId"];

    const res = await this.api.products.getPromotions({
      memberId,
      campusId,
      ...payload,
    });

    return res;
  }

  async function getDetail({
    id,
    userId = store.getters["users/getUserUuid"],
    campusId = store.getters["campuses/getCampusUuid"],
    memberId = store.getters["members/getMemberId"],
    calcUserCoupons = false,
    publicYn = false,
  }) {
    if (!id) {
      return { success: false };
    }

    const isMember = memberId && store.getters["members/isCampusJoinMember"];

    const getDetail =
      isMember && !publicYn
        ? this.api.products.getDetail
        : this.api.products.getDetailPublic;

    const result = await getDetail({
      id,
      ...(userId && { userId }),
      ...(campusId && { campusId }),
      ...(memberId && { memberId }),
      ...(calcUserCoupons && { calcUserCoupons }),
    });
    if (result.success) {
      store.commit("products/setProduct", result.data);
      store.commit("members/setProductMember", result.data.productMember);
    }
    return result;
  }

  return {
    product,
    newProduct,
    productId,
    productName,
    imageSrc,
    putProductProfitShare,
    getSearchPartners,
    categories,
    getCategories,
    postCategories,
    putExtra,
    getDetail,
    getPromotions,
  };
});
