export default {
  playlist: [],
  playlistAutoPlayYn: true, // 회원 > 프로덕트 수강화면 > 자동 재생 지원 가능 여부
  activities: {
    lastKey: "",
    items: [],
  },
  roomProduct: null,
  roomContents: [],
  roomContent: null,
  // Local
  quiz: {}, // 프로덕트 운영 > 콘텐츠 > 채점
  quizStats: {}, // 프로덕트 운영 > 콘텐츠 > 결과통계
  questions: [],
  quizProgress: "STANDBY",
  currentIndex: 0,
  currentTab: "Info",
  aside: false,
  // 신청서
  application: {},
  applicationStats: {
    results: [],
    stat: null,
  },
  applicationStatUsers: {
    lastKey: "",
    items: [],
  },
  applicationProgress: "STANDBY",
  isSupportsVideoTimeline: false, // 비디오 콘텐츠 > 동영상 플레이어 타임라인 제공 여부
};
