<template>
  <div class="notification__wrapper hide-scrollbar">
    <div
      v-if="notificationItems.length > 0"
      @click.stop
      class="notification-item"
    >
      <div
        v-for="item in notificationItems"
        :key="item.rk"
        class="notification rounded py-3 px-2"
        @click="clickNotification(item)"
      >
        <div class="d-flex align-center justify-space-between">
          <div class="notification-content keep-all">
            <div class="notification-content--header mb-2">
              <v-avatar size="20">
                <img :src="item.thumbnail" alt="profile" class="lazy" cover />
              </v-avatar>
              <div class="w-full d-flex align-center single-line">
                <p class="name text-body-1-medium mr-2">
                  {{ item.name }}
                </p>
                <v-icon color="primary" v-if="!item.readYn" size="16"
                  >mdi-alpha-n-circle</v-icon
                >
              </div>
            </div>

            <p class="text-detail-1-regular mb-1">
              {{ item.subject }}
            </p>
            <pre class="text-detail-1-regular gray--text text--darken-3 mb-1">
              {{ item.conts }}
            </pre>

            <p class="text-detail-1-regular gray--text text--darken-3">
              {{ item.regDttm }}
            </p>
          </div>
        </div>
        <p-icon
          size="24"
          icon="Trash/Gray8"
          @click.native.stop="clickDeleteNotification(item)"
        />
      </div>
    </div>
    <empty-box v-else height="550" msg="알림이 없습니다." />
    <v-overlay :value="loading" z-index="9998" opacity="0" absolute>
      <v-progress-circular indeterminate color="primary" />
    </v-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getDateFormat } from "@/utils/date-format";

const DEFAULT_IMAGE_PATH =
  "https://file.poincampus.com/assets/ProfileImg@2x.png";
const POIN_IMAGE_PATH = "https://file.poincampus.com/assets/favicon.png";

export default {
  name: "NotificationList",
  computed: {
    ...mapGetters({
      containerActive: "notifications/getContainerActive",
      notifications: "notifications/getNotifications",
      notification: "notifications/getNotification",
      lastKey: "notifications/getLastKey",
      isMobile: "common/isMobile",
      user: "users/getUser",
      campusInfo: "campuses/getCampusInfo",
    }),
    notificationItems() {
      const result = [];
      this.notifications.map((el) => {
        const isPoinNoti = el.sender?.senderType === "SYSTEM";
        result.push({
          ...el,
          thumbnail: isPoinNoti
            ? POIN_IMAGE_PATH
            : el.sender?.imgPath || DEFAULT_IMAGE_PATH,
          name: isPoinNoti ? "포인캠퍼스" : el.sender?.name,
          regDttm: getDateFormat(el.regDttm),
        });
      });
      return result;
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      reqGetNotification: "notifications/reqGetNotification",
      reqGetNotifications: "notifications/reqGetNotificationsV2",
      reqPutNotifications: "notifications/reqPutNotifications",
      reqDeleteNotifications: "notifications/reqDeleteNotifications",
      resetNotifications: "notifications/resetNotifications",
      toggleContainerActive: "notifications/toggleContainerActive",
    }),
    hasNext() {
      return this.lastKey;
    },
    async loadNext(first) {
      if (first) {
        this.resetNotifications();
      }
      await this.reqGetNotifications({
        limit: 20,
      });
      setTimeout(() => {
        this.loading = false;
      }, 300);
    },
    async clickNotification(item) {
      const isSuccess = await this.reqGetNotification({
        pk: item.pk,
        rk: item.rk,
      });
      if (isSuccess) {
        this.$router.push({
          name: "notification-bridge",
          params: item,
        });
      }
      this.toggleContainerActive();
    },
    clickDeleteNotification(item) {
      if (!this.loading) {
        this.loading = true;
        this.deleteNotification({ pk: item.pk, rk: item.rk });
      }
    },
    async deleteNotification(item) {
      const result = await this.reqDeleteNotifications(item);
      if (result.success) {
        this.loadNext(true);
      }
      this.loading = false;
    },
    async handleScroll() {
      const client = document.querySelector(".notification__wrapper");
      client.addEventListener("scroll", () => {
        const listContainer = client.querySelector(".notification-item");
        const listHeight = listContainer.lastChild.offsetHeight;
        const clientHeight = client.clientHeight;

        let scrollTop = client.scrollTop;
        let scrollHeight = client.scrollHeight;

        if (this.loading) {
          return;
        }
        if (
          scrollTop + clientHeight + listHeight >= scrollHeight &&
          this.lastKey
        ) {
          this.loading = true;
          this.loadNext(false);
          client.scrollTo({ top: scrollHeight, behavior: "smooth" });
        }
      });
    },
  },
  created() {
    this.loading = true;
    this.loadNext(true);
  },
  mounted() {
    this.handleScroll();
  },
};
</script>

<style lang="scss" scoped>
.notification__wrapper {
  height: 100%;
  overflow: scroll;
  z-index: 99;
}
.notification {
  display: grid;
  grid-template-columns: 1fr 24px;
  gap: 20px;
  align-items: flex-start;
  cursor: pointer;
  &-content {
    &--header {
      display: grid;
      grid-template-columns: 20px 1fr;
      align-items: center;
      gap: 8px;
      .name {
        @include single-line;
      }
    }
  }
  &:hover {
    background-color: $gray1;
    transition: 0.3s;
  }
}
</style>
