import isEmpty from "lodash.isempty";

const getters = {
  getAuthenticated: (state) => state.authenticated,
  getAuth: (state) => {
    return state.auth;
  },
  getTokenRefreshing: (state) => {
    return state.tokenRefreshing;
  },
  getSnsType: (state) => {
    if (!isEmpty(state.auth)) {
      return state.auth.snsType;
    }
    return "";
  },
  getCognitoId: (state) => {
    if (!isEmpty(state.auth)) {
      return state.auth.cognitoId;
    }
    return null;
  },
  getJoinUser: (state) => {
    return state.joinUser;
  },
};

export default getters;
