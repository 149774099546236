import { endpointV2 as endpoint } from "@/config";

// 요금제 정보 단건 조회
export async function getVod({ id }) {
  return this.getMethod("get", true, {
    url: `${endpoint.vods}/`,
    params: {
      id,
    },
  });
}
