const sessionStorage = window.sessionStorage;

function parseJson(value) {
  let parseValue = null;
  try {
    parseValue = JSON.parse(value);
  } catch (e) {
    console.log(e);
  }
  return parseValue;
}

function jsonStringify(value) {
  let stringifyValue = null;
  try {
    stringifyValue = JSON.stringify(value);
  } catch (e) {
    console.log(e);
  }
  return stringifyValue;
}

function CreateStroage() {
  this.storage = {
    auth: parseJson(sessionStorage.getItem("auth")),
  };
  return {
    getAuth: () => {
      return this.storage.auth;
    },
    setAuth: (auth) => {
      sessionStorage.setItem("auth", jsonStringify(auth));
    },
    clear: () => {
      sessionStorage.clear();
    },
  };
}

export default new CreateStroage();
