const localStorage = window.localStorage;

function parseJson(value) {
  let parseValue = null;
  try {
    parseValue = JSON.parse(value);
  } catch (e) {
    console.log(e);
  }
  return parseValue;
}

function jsonStringify(value) {
  let stringifyValue = null;
  try {
    stringifyValue = JSON.stringify(value);
  } catch (e) {
    console.log(e);
  }
  return stringifyValue;
}

function CreateStroage() {
  this.storage = {
    auth: parseJson(localStorage.getItem("auth")),
    keepLogin: true,
    locationPath: localStorage.getItem("locationPath"),
  };
  return {
    // auth
    getAuth: () => {
      return this.storage.auth;
    },
    setAuth: (auth) => {
      localStorage.setItem("auth", jsonStringify(auth));
    },
    // common
    getKeepLogin: () => {
      return this.storage.keepLogin;
    },
    setKeepLogin: (keepLogin) => {
      localStorage.setItem("keepLogin", jsonStringify(keepLogin));
    },
    clear: () => {
      localStorage.clear();
    },
    setLocationPath: (path) => {
      localStorage.setItem("locationPath", path);
    },
    getLocationPath: () => {
      return this.storage.locationPath;
    },
  };
}

export default new CreateStroage();
