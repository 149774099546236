<template>
  <div v-show="show" class="add-pwa lazy">
    <div class="d-flex-column gap-1">
      <div class="d-flex align-center gap-1">
        <v-img :src="appIcon" height="24px" max-width="24px" />
        <p class="text-body-1-medium black--text">
          {{ appTitle }}
        </p>
      </div>
      <p class="text-body-2-regular gray--text text--darken-3">
        {{ installDesc }}
      </p>
    </div>
    <div class="add-pwa__btns">
      <p-btn @click="onCloseBtnClick" color="gray">나중에</p-btn>
      <p-btn @click="installApp" color="primary">앱 설치하기</p-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { webBaseUrl } from "@/config";

export default {
  name: "poin-pwa",
  data() {
    return {
      installPrompt: null,
      installButton: false,
      installDesc:
        "편리하게 사이트에 바로 접속할 수 있는 앱을 다운로드 받아보세요.",
    };
  },
  computed: {
    ...mapGetters({
      isMobile: "common/isMobile",
      campusInfo: "campuses/getCampusInfo",
      display: "campuses/getDisplayCampusInfo",
      campusOnly: "common/campusOnly",
      isEzwelEntry: "common/isEzwelEntry",
    }),
    appTitle() {
      return this.campusInfo ? this.campusInfo.name : "포인캠퍼스";
    },
    appIcon() {
      return (
        this.display?.favicon?.path ||
        "https://file.poincampus.com/assets/favicon.png"
      );
    },
    show() {
      return (
        window.location.origin !== webBaseUrl &&
        this.campusOnly &&
        this.installButton &&
        this.$route?.name !== "campus-app-download" &&
        !this.isEzwelEntry
      );
    },
  },
  watch: {
    campusInfo: {
      immediate: true,
      handler(campus) {
        if (campus) {
          const cookie = this.getCookie(`PWA_${campus.campusId}`);
          if (cookie && cookie === "hidden") {
            this.installButton = false;
          }
        }
      },
    },
  },
  methods: {
    async installApp() {
      if (!this.installPrompt) return false;
      this.installPrompt.prompt();
      // Remove the event reference
      this.installPrompt = null;
      // Hide the button
      this.installButton = false;
    },
    onCloseBtnClick() {
      this.setCookie(
        `PWA${this.campusInfo ? "_" + this.campusInfo.campusId : ""}`,
        "hidden",
        { "max-age": 259200 }
      );
      this.installButton = false;
    },
    getCookie(name) {
      let matches = document.cookie.match(
        new RegExp(
          "(?:^|; )" +
            name.replace(/([.$?*|{}()[\]\\/+^])/g, "\\$1") +
            "=([^;]*)"
        )
      );
      return matches ? decodeURIComponent(matches[1]) : undefined;
    },
    setCookie(name, value, options = {}) {
      options = {
        path: "/",
        ...options,
      };

      if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
      }

      let updatedCookie =
        encodeURIComponent(name) + "=" + encodeURIComponent(value);

      for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
          updatedCookie += "=" + optionValue;
        }
      }

      document.cookie = updatedCookie;
    },
  },
  mounted() {
    // pwa 설치 확인 후, 미설치일 경우, 설치 안내
    // console.log('Listening for Install prompt')
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      // console.log('Install Prompt fired')
      this.installPrompt = e;
      // See if the app is already installed, in that case, do nothing
      if (
        (window.matchMedia &&
          window.matchMedia("(display-mode: standalone)").matches) ||
        window.navigator.standalone === true
      ) {
        return false;
      }
      // Set the state variable to make button visible
      this.installButton = true;
      if (this.campusInfo) {
        const cookie = this.getCookie(`PWA_${this.campusInfo.campusId}`);
        if (cookie && cookie === "hidden") {
          this.installButton = false;
        }
      }
    });
  },
};
</script>

<style scoped lang="scss">
.add-pwa {
  position: fixed;
  left: 40px;
  width: calc(100vw - 80px);
  background: $white;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 20px;
  align-items: center;
  border-radius: 8px;
  z-index: 111;
  bottom: 20px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.28) 0px 8px 28px 0;

  &__btns {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: flex-end;
  }
  @media (min-width: 1280px) {
    width: 1200px;
    left: calc(50vw - 640px + 40px);
  }
  @media (max-width: $mdBreakPoint) {
    left: 24px;
    width: calc(100vw - 48px);
  }
  @media (max-width: $mdBreakPoint) {
    left: 20px;
    width: calc(100vw - 40px);
    grid-template-columns: 1fr;
  }
}
</style>
