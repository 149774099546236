import * as auth from "./auth";
import * as users from "./users";
import * as configure from "./configure";
import * as vimeo from "./vimeo";
import * as zoom from "./zoom";
import * as supports from "./supports";

// V2
import * as promotions from "./v2/promotions";
import * as supportsV2 from "./v2/supports";
import * as campuses from "./v2/campuses";
import * as members from "./v2/members";
import * as plans from "./v2/plans";
import * as payments from "./v2/payments";
import * as admins from "./v2/admins";
import * as vods from "./v2/vods";
import * as products from "./v2/products";
import * as contents from "./v2/contents";
import * as communities from "./v2/communities";
import * as activities from "./v2/activities";
import * as articles from "./v2/articles";
import * as notifications from "./v2/notifications";
import * as transcodes from "./v2/transcodes";
import * as compiles from "./v2/compiles";
import * as credits from "./v2/credits";
import * as coupons from "./v2/coupons";
import * as campaigns from "./v2/campaigns";
import * as groups from "./v2/groups";
import * as histories from "./v2/histories";

export default {
  auth: {
    ...auth,
  },
  users: {
    ...users,
  },
  configure: {
    ...configure,
  },
  vimeo: {
    ...vimeo,
  },
  supports: {
    ...supports,
    ...supportsV2,
  },
  payments: {
    ...payments,
  },
  zoom: {
    ...zoom,
  },
  promotions: {
    ...promotions,
  },
  // V2
  campuses: {
    ...campuses,
  },
  plans: {
    ...plans,
  },
  members: {
    ...members,
  },
  admins: {
    ...admins,
  },
  vods: {
    ...vods,
  },
  products: {
    ...products,
  },
  contents: {
    ...contents,
  },
  communities: {
    ...communities,
  },
  activities: {
    ...activities,
  },
  articles: {
    ...articles,
  },
  notifications: {
    ...notifications,
  },
  transcodes,
  compiles: {
    ...compiles,
  },
  credits: {
    ...credits,
  },
  coupons: {
    ...coupons,
  },
  campaigns: {
    ...campaigns,
  },
  groups: {
    ...groups,
  },
  histories: {
    ...histories,
  },
};
