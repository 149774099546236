export default {
  getUserAskItems: (state) => {
    return state.userAskItems;
  },
  getUserAskLastKey: (state) => {
    return state.userAskLastKey;
  },
  getUserReplies: (state) => {
    return state.userReplies;
  },
  getUserReplyLastKey: (state) => {
    return state.userReplyLastKey;
  },
  getCampusAskItems: (state) => {
    return state.campusAskItems;
  },
  getCampusAskLastKey: (state) => {
    return state.campusAskLastKey;
  },
  getCampusReplies: (state) => {
    return state.campusReplies;
  },
  getCampusReplyLastKey: (state) => {
    return state.campusReplyLastKey;
  },
  getAsk: (state) => {
    return state.ask;
  },
  getAskSearchOptions: (state) => {
    return state.askSearchOptions;
  },
  getAskCnt: (state) => {
    return state.askCnt;
  },
  getFaqItems(state) {
    return state.faqItems;
  },
  getFaqLastKey(state) {
    return state.faqLastKey;
  },
  getFaqCategories(state) {
    return state.faqCategories;
  },
  getFaq(state) {
    return state.faq;
  },
  getFaqSearchOptions(state) {
    return state.faqSearchOptions;
  },
  getFaqTemplates(state) {
    return state.faqTemplates;
  },
  getUserFaqItems(state) {
    return state.userFaqItems;
  },
  getUserFaqCategories(state) {
    return state.userFaqCategories;
  },
  getUserFaq(state) {
    return state.userFaq;
  },
};
