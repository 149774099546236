import { isValidJSON } from "@/utils/validation";

export default function createResponse(res) {
  let result = {
    success: true,
  };
  if (res) {
    try {
      const { data, status, headers } = res;
      return {
        ...result,
        data: isValidJSON(data) ? JSON.parse(data) : data,
        status,
        headers,
      };
    } catch (e) {
      return {
        ...result,
        data: null,
      };
    }
  }
  return result;
}
