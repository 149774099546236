<script setup>
import { useAuth } from "@/hooks/useAuth.js";
import PoincampusLogo from "./PoincampusLogo.vue";
import PoinHeaderStartButton from "./PoinHeaderStartButton.vue";
import PoinHeaderNavigation from "./PoinHeaderNavigation.vue";
import UserProfile from "@/components/poin-ui/UserProfile.vue";
import NotificationIcon from "@/components/poin-ui/NotificationIcon.vue";

const { signIn, isAuthenticated, campusYn } = useAuth();
</script>

<template>
  <header class="header__container">
    <div class="header__wrap">
      <PoincampusLogo />
      <PoinHeaderNavigation />

      <div class="ml-auto d-flex align-center gap-3">
        <template v-if="isAuthenticated">
          <PoinHeaderStartButton v-if="!campusYn" />
          <NotificationIcon />
          <UserProfile />
        </template>
        <template v-else>
          <p-btn color="gray" outlined small @click="signIn">로그인</p-btn>
          <PoinHeaderStartButton />
        </template>
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.header__container {
  width: 100%;
  height: var(--header-height);
  background-color: $white;
  box-shadow: 0 1px 0 0 $gray3;
  z-index: var(--header-zIndex);
  top: 0;
  left: 0;
  position: sticky;
  .header__wrap {
    width: 100%;
    height: 100%;
    padding: 0 var(--gutter);
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 40px;
  }
}
</style>
