export default {
  setUserPaymentsHistories: (state, history) => {
    if (!history) {
      return false;
    } else {
      state.userPaymentsHistories = state.userPaymentsHistories.concat(
        history.results
      );
    }
  },
  setuserPaymentsHistoriesReset: (state) => {
    state.userPaymentsHistories = [];
    state.paymentLastKey = null;
  },
  setPaymentSummary: (state, data) => {
    if (!data) {
      return false;
    } else {
      state.userPaymentSummary = data;
    }
  },
  setLastKey: (state, lastKey) => {
    state.paymentLastKey = lastKey;
  },
  setPaymentSummaryReset: (state) => {
    state.userPaymentSummary = [];
  },
  //검색 초기화
  setCustomerNameKeyword: (state, keyword) => {
    state.customerNameKeyword = keyword;
  },
  setCards: (state, results) => {
    state.cards = results;
  },
  updateCard: (state, card) => {
    if (!card) {
      return;
    }

    const idx = state.cards?.findIndex((old) => old.id === card.id);
    if (idx > -1) {
      state.cards[idx] = card;
    }
  },
  deleteCard: (state, cardId) => {
    state.cards = state.cards.filter((card) => card.id !== cardId);
  },
  setPlanHistories: (state, histories) => {
    state.planHistories = [...state.planHistories, ...histories];
  },
  setPlanHistoriesLastKey: (state, lastKey) => {
    state.planHistoriesLastKey = lastKey;
  },
  clearPlanHistories: (state) => {
    state.planHistories = [];
  },
  clearPlanHistoriesLastKey: (state) => {
    state.planHistoriesLastKey = "";
  },
  clearAllPlanHistories: (state) => {
    state.planHistoriesLastKey = "";
    state.planHistories = [];
  },
  setCheckoutHistory: (state, checkoutHistory) => {
    state.checkoutHistory = checkoutHistory ? { ...checkoutHistory } : null;
  },
  setProductPayment: (state, payment) => {
    state.productPayment = payment;
  },
  setUserDetailPayments: (state, result) => {
    state.userDetailPayments.lastKey = result.lastKey;
    if (result.results) {
      state.userDetailPayments.items = [
        ...state.userDetailPayments.items,
        ...result.results,
      ];
    }
  },
  setUserDetailPaymentSummary: (state, payments) => {
    state.userDetailPayments = payments;
  },
  clearUserDetailPayments: (state) => {
    state.userDetailPayments.lastKey = "";
    state.userDetailPayments.items = [];
  },
  setProductPaymentsHistories: (state, result) => {
    state.productPaymentsHistories.lastKey = result?.lastKey;
    if (result.results) {
      state.productPaymentsHistories.items = [
        ...state.productPaymentsHistories.items,
        ...result.results,
      ];
    }
  },
  clearPaymentsHistories: (state) => {
    state.productPaymentsHistories.lastKey = "";
    state.productPaymentsHistories.items = [];
  },
  setProductPaymentsSummary: (state, summary) => {
    state.productPaymentsSummary = summary;
  },
  setDashboardPaymentsSummary: (state, summary) => {
    state.dashboardPaymentsSummary = summary;
  },
  setUserCheckoutHistory: (state, history) => {
    state.userCheckoutHistory = history;
  },
  setPaymentDropDownItems: (state, items) => {
    state.userPaymentDropDownItems = items;
  },
  setUserPurchases: (state, result) => {
    state.userPurchases.lastKey = result.lastKey;
    if (result.results) {
      state.userPurchases.items = [
        ...state.userPurchases.items,
        ...result.results,
      ];
    }
  },
  clearUserPurchases: (state) => {
    state.userPurchases.lastKey = "";
    state.userPurchases.items = [];
  },
  setUserCancelPurchases: (state, purchases) => {
    state.userCancelPurchases = purchases;
  },
  setPaymentTable: (state, item) => {
    state.table = item;
  },
  setPage: (state, num) => {
    state.table.page = num;
  },
  setClearTable: (state) => {
    state.table = {
      page: 1,
      limit: 10,
      keyword: "",
      paymentStatus: "",
      promotionId: "",
      basis: "",
    };
  },
  setReservationOptions: (state, options) => {
    state.reservationOptions = options.results || [];
  },
  clearReservationOptions: (state) => {
    state.reservationOptions = [];
  },
  setPaymentsHistorySettlement: (state, settlement) => {
    state.paymentsHistorySettlement = settlement;
  },
};
