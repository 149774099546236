import { endpointV2 as endpoint } from "@/config";

export async function getEmails({
  campusId,
  memberId,
  sk,
  keyword,
  limit = 10,
  order,
  sort,
  basis,
  toType,
  toId,
  campaignStatus,
  lastKey,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(sk && { sk }),
    ...(keyword && { keyword }),
    ...(limit && { limit }),
    ...(order && { order }),
    ...(sort && { sort }),
    ...(basis && { basis }),
    ...(toType && { toType }),
    ...(toId && { toId }),
    ...(campaignStatus && { campaignStatus }),
    ...(lastKey && { lastKey }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.campaigns}/email`,
    params,
  });
}

export async function getEmailPublic({ campusId, sk }) {
  const params = {
    ...(campusId && { campusId }),
  };
  return this.getMethod("get", false, {
    url: `${endpoint.campaigns}/email/public/${sk}`,
    params,
  });
}

export async function postEmail({
  campusId,
  memberId,
  sk,
  isCopy,
  name,
  toId,
  toType,
  toName,
}) {
  const data = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(name && { name }),
    ...(sk && { sk }),
    ...(toId && { toId }),
    ...(toType && { toType }),
    ...(toName && { toName }),
    ...(isCopy !== null && { isCopy }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.campaigns}/email`,
    data,
  });
}

export async function sendEmail({
  campusId,
  memberId,
  sendEmailType,
  reserveDttm,
  addrs,
  sk,
}) {
  const data = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(sendEmailType && { sendEmailType }),
    ...(addrs?.length > 0 && { addrs }),
    ...(reserveDttm && { reserveDttm }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.campaigns}/email/${sk}`,
    data,
  });
}

export async function putEmail({
  campusId,
  memberId,
  sk,
  toId,
  prvTxt,
  contentKey,
  toType,
  toName,
  title,
  psYn,
  statOpenCnt,
  statBounceCnt,
  statClickCnt,
  statToCnt,
  reserveDttm,
  adYn,
  regDttm,
  campaignStatus,
  modDttm,
  name,
  fromName,
  campaignObjType,
  pk,
  links,
  blocks,
  globalStyles,
  shrYn,
  ogTitle,
  ogDesc,
  ogImgKey,
}) {
  const data = {
    toId,
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(prvTxt && { prvTxt }),
    ...(contentKey && { contentKey }),
    ...(toType && { toType }),
    ...(toName && { toName }),
    ...(title && { title }),
    ...(psYn != null && { psYn }),
    ...(statOpenCnt != null && { statOpenCnt }),
    ...(statBounceCnt != null && { statBounceCnt }),
    ...(statClickCnt != null && { statClickCnt }),
    ...(statToCnt != null && { statToCnt }),
    ...(adYn != null && { adYn }),
    ...(links != null && { links }),
    ...(reserveDttm && { reserveDttm }),
    ...(regDttm && { regDttm }),
    ...(campaignStatus && { campaignStatus }),
    ...(modDttm && { modDttm }),
    ...(name && { name }),
    ...(fromName && { fromName }),
    ...(campaignObjType && { campaignObjType }),
    ...(pk && { pk }),
    ...(blocks && { blocks: JSON.stringify(blocks) }),
    ...(globalStyles && { globalStyles: JSON.stringify(globalStyles) }),
    ...(shrYn != null && { shrYn }),
    ...(ogTitle != null && { ogTitle }),
    ...(ogDesc != null && { ogDesc }),
    ...(ogImgKey != null && { ogImgKey }),
  };
  return this.getMethod("put", true, {
    url: `${endpoint.campaigns}/email/${sk}`,
    data,
  });
}

export async function deleteEmail({ campusId, memberId, sk }) {
  const data = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("delete", true, {
    url: `${endpoint.campaigns}/email/${sk}`,
    data,
  });
}

export async function getReport({ campusId, memberId, sk }) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.campaigns}/report/${sk}`,
    params,
  });
}

export async function getReceivers({
  campusId,
  memberId,
  receiverStatus,
  sk,
  order,
  keyword,
  limit,
  lastKey,
  first,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(receiverStatus && { receiverStatus }),
    ...(order && { order }),
    ...(keyword && { keyword }),
    ...(limit && { limit }),
    ...(lastKey && { lastKey }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.campaigns}/receivers/${sk}`,
    params,
  });
}
export async function getReceiversCnt({
  campusId,
  memberId,
  receiverStatus,
  sk,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(receiverStatus && { receiverStatus }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.campaigns}/receivers/${sk}/counts`,
    params,
  });
}
export async function getReceiverActivities({
  campusId,
  memberId,
  email,
  sk,
  order,
  keyword,
  limit,
  lastKey,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(email && { email }),
    ...(order && { order }),
    ...(keyword && { keyword }),
    ...(limit && { limit }),
    ...(lastKey && { lastKey }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.campaigns}/receivers/${sk}/activities`,
    params,
  });
}

export async function getLinks({ campusId, memberId, sk }) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.campaigns}/links/${sk}`,
    params,
  });
}

export async function getLinkReceivers({
  campusId,
  memberId,
  sk,
  linkId,
  order,
  keyword,
  limit,
  lastKey,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(linkId && { linkId }),
    ...(order && { order }),
    ...(keyword && { keyword }),
    ...(limit && { limit }),
    ...(lastKey && { lastKey }),
  };

  return this.getMethod("get", true, {
    url: `${endpoint.campaigns}/links/${sk}/receivers`,
    params,
  });
}

export async function getDetails({
  campusId,
  memberId,
  addrSk,
  addrBookSk,
  targetMemberId,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(addrSk && { addrSk }),
    ...(addrBookSk && { addrBookSk }),
    ...(targetMemberId && { targetMemberId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.campaigns}/details`,
    params,
  });
}
export async function getDetailActivities({
  keyword,
  memberId,
  limit = 10,
  targetMemberId,
  campusId,
  order,
  addrSk,
  addrBookSk,
  activityType,
  lastKey,
}) {
  const params = {
    ...(keyword && { keyword }),
    ...(memberId && { memberId }),
    ...(limit && { limit }),
    ...(targetMemberId && { targetMemberId }),
    ...(campusId && { campusId }),
    ...(order && { order }),
    ...(addrSk && { addrSk }),
    ...(addrBookSk && { addrBookSk }),
    ...(activityType && { activityType }),
    ...(lastKey && { lastKey }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.campaigns}/details/activities`,
    params,
  });
}

export async function getReportExcelKey({
  campusId,
  memberId,
  receiverExcelType,
  sk,
  linkId,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(receiverExcelType && { receiverExcelType }),
    ...(linkId && { linkId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.campaigns}/receivers/${sk}/excel`,
    params,
  });
}
