import * as firebase from "firebase/app";
import { firebaseConfig } from "@/config";
import { getMessaging, getToken, isSupported } from "firebase/messaging";
import { firebasePublicVapidKey } from "@/config";

const app = firebase.initializeApp(firebaseConfig);

const getFirebaseToken = async () => {
  try {
    const supported = await isSupported();
    if (!supported) {
      return;
    }
    const messaging = getMessaging(app);
    const token = await getToken(messaging, {
      vapidKey: firebasePublicVapidKey,
    });
    return token;
  } catch (error) {
    console.log(error);
    return;
  }
};

export { app, isSupported, getFirebaseToken };
