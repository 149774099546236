<script setup>
import { useAuth } from "@/hooks/useAuth";
import { computed } from "vue";
import { useRoute } from "vue-router/composables";
import { useCampusStore } from "@/stores/campus";
import CampusLogo from "@/components/layout/header/CampusLogo.vue";
import NotificationIcon from "@/components/poin-ui/NotificationIcon.vue";
import CartIcon from "./CartIcon.vue";

const { isAuthenticated } = useAuth();
const emit = defineEmits(["showAside"]);

const route = useRoute();

const campusStore = useCampusStore();
const styles = computed(() => {
  return campusStore.styles;
});

const bgColor = computed(() => styles.value?.headerStyles?.backgroundColor);
</script>

<template>
  <header class="header__container">
    <div class="header__wrap">
      <router-link
        :to="{ name: 'campus-home', params: route.params }"
        class="d-flex mr-auto"
      >
        <CampusLogo />
      </router-link>
      <div v-if="isAuthenticated" class="d-flex align-center gap-3">
        <CartIcon />
        <NotificationIcon />
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.header__container {
  width: 100%;
  height: var(--header-height);
  background-color: v-bind(bgColor);
  box-shadow: 0 1px 0 0 $gray3;
  z-index: var(--header-zIndex);
  top: 0;
  left: 0;
  position: fixed;
  .header__wrap {
    padding: 0 var(--gutter);
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 24px;
  }
}
</style>
