import { endpointV2 as endpoint } from "@/config";

export async function postProductReview({
  productId,
  conts,
  campusId,
  rating,
  attachImages,
  memberId,
  paymentId,
  directYn,
  excelKey,
  imgPaths,
  regMember,
  directSourceName,
  regDttm,
}) {
  const data = {
    ...(productId && { productId }),
    ...(conts && { conts }),
    ...(campusId && { campusId }),
    ...(rating && { rating }),
    ...(attachImages && { attachImages }),
    ...(memberId && { memberId }),
    ...(paymentId && { paymentId }),
    ...(directYn && { directYn }),
    ...(excelKey && { excelKey }),
    ...(imgPaths && { imgPaths }),
    ...(regMember && { regMember }),
    ...(directSourceName && { directSourceName }),
    ...(regDttm && { regDttm }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.articles}/product/review`,
    data,
  });
}

export async function getProductReviewsAdmin({
  keyword,
  memberId,
  bestYn,
  hideYn,
  campusId,
  lastKey,
  replyYn,
  rating,
  productId,
  startDate,
  endDate,
  limit,
  order,
  directYn,
}) {
  const params = {
    ...(keyword && { keyword }),
    ...(memberId && { memberId }),
    ...(bestYn !== undefined && { bestYn }),
    ...(hideYn && { hideYn }),
    ...(campusId && { campusId }),
    ...(lastKey && { lastKey }),
    ...(replyYn !== undefined && { replyYn }),
    ...(rating && { rating }),
    ...(productId && { productId }),
    ...(startDate && { startDate }),
    ...(endDate && { endDate }),
    ...(limit && { limit }),
    ...(order && { order }),
    ...(directYn !== undefined && { directYn }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.articles}/product/review/admin`,
    params,
  });
}
export async function putProductReviewAdmin({
  reviewId,
  productId,
  replyAttaches,
  campusId,
  bestYn,
  hideYn,
  reply,
  memberId,
  paymentId,
}) {
  if (!reviewId) {
    return {
      success: false,
    };
  }
  const data = {
    ...(productId && { productId }),
    ...(paymentId && { paymentId }),
    ...(replyAttaches && { replyAttaches }),
    ...(campusId && { campusId }),
    ...(bestYn !== undefined && { bestYn }),
    ...(hideYn !== undefined && { hideYn }),
    ...(reply && { reply }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("put", true, {
    url: `${endpoint.articles}/product/review/admin/${reviewId}`,
    data,
  });
}
export async function getProductReviews({
  productId,
  limit,
  sort,
  campusId,
  order,
  lastKey,
  bestYn,
  bestFirst,
  directYn,
}) {
  if (!productId) {
    return {
      success: false,
    };
  }
  const params = {
    ...(productId && { productId }),
    ...(limit && { limit }),
    ...(sort && { sort }),
    ...(campusId && { campusId }),
    ...(order && { order }),
    ...(lastKey && { lastKey }),
    ...(bestYn && { bestYn }),
    ...(bestFirst && { bestFirst }),
    ...(directYn !== undefined && {directYn}),
  };
  return this.getMethod("get", false, {
    url: `${endpoint.articles}/product/review/detail/${productId}`,
    params,
  });
}
export async function getMemberProductReviews({
  memberId,
  campusId,
  targetMemberId,
  keyword,
  limit,
  lastKey,
}) {
  if (!targetMemberId) {
    return {
      success: false,
    };
  }
  const params = {
    ...(memberId && { memberId }),
    ...(campusId && { campusId }),
    ...(keyword && { keyword }),
    ...(limit && { limit }),
    ...(lastKey && { lastKey }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.articles}/product/review/my/${targetMemberId}`,
    params,
  });
}

export async function putProductReview({
  reviewId,
  productId,
  conts,
  campusId,
  rating,
  attachImages,
  memberId,
  paymentId,
  directYn,
  excelKey,
  imgPaths,
  regMember,
  directSourceName,
  regDttm,
}) {
  if (!reviewId) {
    return {
      success: false,
    };
  }
  const data = {
    ...(productId && { productId }),
    ...(paymentId && { paymentId }),
    ...(campusId && { campusId }),
    ...(rating && { rating }),
    ...(attachImages && { attachImages }),
    ...(memberId && { memberId }),
    conts: conts || "",
    ...(directYn && { directYn }),
    ...(excelKey && { excelKey }),
    ...(imgPaths && { imgPaths }),
    ...(regMember && { regMember }),
    ...(directSourceName && { directSourceName }),
    ...(regDttm && { regDttm }),
  };
  return this.getMethod("put", true, {
    url: `${endpoint.articles}/product/review/${reviewId}`,
    data,
  });
}

export async function deleteProductReview({
  reviewId,
  productId,
  campusId,
  memberId,
}) {
  if (!reviewId) {
    return {
      success: false,
    };
  }
  const data = {
    ...(productId && { productId }),
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("delete", true, {
    url: `${endpoint.articles}/product/review/${reviewId}`,
    data,
  });
}

export async function getPublicProductNotices({
  keyword,
  limit,
  campusId,
  lastKey,
  productId,
}) {
  const params = {
    ...(keyword && { keyword }),
    ...(limit && { limit }),
    ...(campusId && { campusId }),
    ...(lastKey && { lastKey }),
    ...(productId && { productId }),
  };
  return this.getMethod("get", false, {
    url: `${endpoint.articles}/product/notice`,
    params,
  });
}
export async function postProductNotice({
  productId,
  subject,
  conts,
  campusId,
  attachImages,
  pubTarget,
  memberId,
  attaches,
}) {
  const data = {
    ...(productId && { productId }),
    ...(subject && { subject }),
    ...(conts && { conts }),
    ...(campusId && { campusId }),
    ...(attachImages && { attachImages }),
    ...(pubTarget && { pubTarget }),
    ...(memberId && { memberId }),
    ...(attaches && { attaches }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.articles}/product/notice`,
    data,
  });
}
export async function getProductNotices({
  memberId,
  campusId,
  keyword,
  limit,
  adminYn,
  endDate,
  pubTarget,
  startDate,
  productId,
  lastKey,
}) {
  const params = {
    ...(memberId && { memberId }),
    ...(campusId && { campusId }),
    ...(keyword && { keyword }),
    ...(limit && { limit }),
    ...(adminYn && { adminYn }),
    ...(endDate && { endDate }),
    ...(pubTarget && { pubTarget }),
    ...(startDate && { startDate }),
    ...(productId && { productId }),
    ...(lastKey && { lastKey }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.articles}/product/notice/member`,
    params,
  });
}
export async function getProductNotice({
  memberId,
  campusId,
  productId,
  adminYn,
  id,
}) {
  if (!id) {
    return {
      success: false,
    };
  }
  const params = {
    ...(memberId && { memberId }),
    ...(campusId && { campusId }),
    ...(productId && { productId }),
    ...(adminYn && { adminYn }),
    ...(id && { id }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.articles}/product/notice/member/${id}`,
    params,
  });
}
export async function getPublicProductNotice({ campusId, productId, id }) {
  if (!id) {
    return {
      success: false,
    };
  }
  const params = {
    ...(campusId && { campusId }),
    ...(productId && { productId }),
    ...(id && { id }),
  };
  return this.getMethod("get", false, {
    url: `${endpoint.articles}/product/notice/${id}`,
    params,
  });
}
export async function putProductNotice({
  productId,
  subject,
  conts,
  campusId,
  attachImages,
  pubTarget,
  memberId,
  attaches,
  id,
}) {
  if (!id) {
    return {
      success: false,
    };
  }
  const data = {
    ...(productId && { productId }),
    ...(subject && { subject }),
    ...(conts && { conts }),
    ...(campusId && { campusId }),
    ...(attachImages && { attachImages }),
    ...(pubTarget && { pubTarget }),
    ...(memberId && { memberId }),
    ...(attaches && { attaches }),
  };
  return this.getMethod("put", true, {
    url: `${endpoint.articles}/product/notice/${id}`,
    data,
  });
}
export async function deleteProductNotice({
  id,
  campusId,
  memberId,
  productId,
}) {
  if (!id) {
    return {
      success: false,
    };
  }
  const data = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(id && { id }),
    ...(productId && { productId }),
  };
  return this.getMethod("delete", true, {
    url: `${endpoint.articles}/product/notice/${id}`,
    data,
  });
}

// 댓글 조회 (회원)
export async function getReplies({
  memberId,
  articleObjType,
  limit,
  targetRootId,
  campusId,
  targetId,
  upperId,
  lastKey,
}) {
  if (!articleObjType || !targetRootId || !targetId) {
    return {
      success: false,
    };
  }
  const params = {
    articleObjType,
    targetRootId,
    targetId,
    ...(memberId && { memberId }),
    ...(limit && { limit }),
    ...(campusId && { campusId }),
    ...(upperId && { upperId }),
    ...(lastKey && { lastKey }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.articles}/reply`,
    params,
  });
}

// 댓글 조회 (비회원)
export async function getRepliesPublic({
  articleObjType,
  limit,
  targetRootId,
  campusId,
  targetId,
  upperId,
  lastKey,
}) {
  if (!articleObjType || !targetRootId || !targetId) {
    return {
      success: false,
    };
  }

  const params = {
    articleObjType,
    targetRootId,
    targetId,
    ...(limit && { limit }),
    ...(campusId && { campusId }),
    ...(upperId && { upperId }),
    ...(lastKey && { lastKey }),
  };
  return this.getMethod("get", false, {
    url: `${endpoint.articles}/reply/public`,
    params,
  });
}

// 댓글 작성
export async function postReply({
  mentionMember,
  articleObjType,
  targetId,
  privateYn,
  targetRootId,
  conts,
  campusId,
  upperId,
  attaches,
  memberId,
}) {
  if (!articleObjType || !targetId || !targetRootId || !conts) {
    return {
      success: false,
    };
  }
  const data = {
    articleObjType,
    targetRootId,
    targetId,
    conts,
    ...(mentionMember && { mentionMember }),
    ...(privateYn && { privateYn }),
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(upperId && { upperId }),
    ...(attaches?.length > 0 && { attaches }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.articles}/reply`,
    data,
  });
}

// 댓글 수정
export async function putReply({
  id,
  articleObjType,
  targetId,
  privateYn,
  targetRootId,
  conts,
  campusId,
  memberId,
  attaches,
}) {
  if (!id || !articleObjType || !targetId || !targetRootId || !conts) {
    return {
      success: false,
    };
  }
  const data = {
    articleObjType,
    targetRootId,
    targetId,
    conts,
    ...(privateYn && { privateYn }),
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(attaches && { attaches }),
  };
  return this.getMethod("put", true, {
    url: `${endpoint.articles}/reply/put/${id}`,
    data,
  });
}

// 댓글 삭제
export async function deleteReply({
  id,
  articleObjType,
  targetId,
  targetRootId,
  campusId,
  memberId,
}) {
  if (!id || !targetId || !targetRootId || !articleObjType) {
    return {
      success: false,
    };
  }
  const data = {
    articleObjType,
    targetRootId,
    targetId,
    ...(memberId && { memberId }),
    ...(campusId && { campusId }),
  };
  return this.getMethod("delete", true, {
    url: `${endpoint.articles}/reply/del/${id}`,
    data,
  });
}

// 캠퍼스 관리 > 댓글 관리 > 목록 조회
export async function getRepliesAdmin({
  memberId,
  limit,
  privateYn,
  targetRootId,
  campusId,
  lastKey,
  keyword,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(targetRootId && { targetRootId }),
    ...(keyword && { keyword }),
    ...(privateYn !== undefined && { privateYn }),
    ...(lastKey && { lastKey }),
    ...(limit && { limit }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.articles}/reply/admin`,
    params,
  });
}

// 회원 > 회원 상세 > 활동 내역 > 회원 작성 댓글 목록 조회
export async function getRepliesMember({
  keyword,
  memberId,
  articleObjType,
  limit,
  privateYn,
  targetRootId,
  campusId,
  targetMemberId,
  lastKey,
}) {
  if (!targetMemberId || !articleObjType) {
    return {
      success: false,
    };
  }
  const params = {
    articleObjType,
    ...(keyword && { keyword }),
    ...(memberId && { memberId }),
    ...(limit && { limit }),
    ...(privateYn !== undefined && { privateYn }),
    ...(targetRootId && { targetRootId }),
    ...(campusId && { campusId }),
    ...(lastKey && { lastKey }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.articles}/reply/member/${targetMemberId}`,
    params,
  });
}
// 회원 캠퍼스 공지 조회 (캠퍼스 참여 회원 or 관리자만 조회 가능)
export async function getCampusNotices({
  keyword,
  memberId,
  limit,
  campusId,
  endDate,
  pubTarget,
  startDate,
  lastKey,
  categoryId,
  sort,
  order,
}) {
  const params = {
    ...(keyword && { keyword }),
    ...(memberId && { memberId }),
    ...(limit && { limit }),
    ...(campusId && { campusId }),
    ...(endDate && { endDate }),
    ...(pubTarget && { pubTarget }),
    ...(startDate && { startDate }),
    ...(lastKey && { lastKey }),
    ...(categoryId && { categoryId }),
    ...(sort && { sort }),
    ...(order && { order }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.articles}/campus/notice`,
    params,
  });
}
// 회원 캠퍼스 공지 단건 조회
export async function getCampusNotice({ memberId, campusId, id, adminYn }) {
  if (!id) {
    return console.log("require id.");
  }
  const params = {
    ...(memberId && { memberId }),
    ...(campusId && { campusId }),
    ...(adminYn && { adminYn }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.articles}/campus/notice/${id}`,
    params,
  });
}
// 비회원 캠퍼스 공지 조회
export async function getCampusPublicNotices({
  keyword,
  limit,
  startDate,
  campusId,
  lastKey,
  endDate,
  categoryId,
}) {
  const params = {
    ...(keyword && { keyword }),
    ...(limit && { limit }),
    ...(startDate && { startDate }),
    ...(campusId && { campusId }),
    ...(lastKey && { lastKey }),
    ...(endDate && { endDate }),
    ...(categoryId && { categoryId }),
  };
  return this.getMethod("get", false, {
    url: `${endpoint.articles}/campus/notice/public`,
    params,
  });
}
// 비회원 캠퍼스 공지 단건 조회
export async function getCampusPublicUserNotice({ campusId, id }) {
  const params = {
    ...(campusId && { campusId }),
  };
  return this.getMethod("get", false, {
    url: `${endpoint.articles}/campus/notice/public/${id}`,
    params,
  });
}
// 캠퍼스 공지 생성
export async function postCampusNotice({
  campusId,
  memberId,
  pubTarget,
  subject,
  conts,
  attachImages,
  attaches,
  categoryId,
  notiYn,
  thumbnail,
}) {
  if (!pubTarget || !subject || !conts) {
    //
  }
  const data = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(pubTarget && { pubTarget }),
    ...(subject && { subject }),
    ...(conts && { conts }),
    ...(attachImages && { attachImages }),
    ...(attaches && { attaches }),
    ...(categoryId && { categoryId }),
    ...(thumbnail && { thumbnail }),
    notiYn,
  };
  return this.getMethod("post", true, {
    url: `${endpoint.articles}/campus/notice`,
    data,
  });
}
// 캠퍼스 공지 수정
export async function putCampusNotice({
  id,
  campusId,
  memberId,
  pubTarget,
  subject,
  conts,
  attachImages,
  attaches,
  categoryId,
  thumbnail,
  notiYn,
}) {
  if (!id) {
    return console.log("require id.");
  }
  const data = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(pubTarget && { pubTarget }),
    ...(subject && { subject }),
    ...(conts && { conts }),
    ...(attachImages && { attachImages }),
    ...(attaches && { attaches }),
    ...(thumbnail && { thumbnail }),
    categoryId,
    notiYn,
  };
  return this.getMethod("put", true, {
    url: `${endpoint.articles}/campus/notice/${id}`,
    data,
  });
}
// 캠퍼스 공지 삭제
export async function deleteCampusNotice({ id, campusId, memberId }) {
  if (!id) {
    return console.log("require id.");
  }
  const data = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("delete", true, {
    url: `${endpoint.articles}/campus/notice/${id}`,
    data,
  });
}
// 1:1 피드백 생성
export async function postProductFeedBack({
  productId,
  contentId,
  campusId,
  memberId,
  questionId,
  codeConts,
  articleObjType = "PRODUCT_FEEDBACK",
  subject,
  conts,
  askTarget,
  askAIType,
  oldProductName,
  oldContentName,
  oldQuestionName,
}) {
  if (!productId || !contentId) {
    console.log("require contentId, productId");
    return { success: false };
  }
  const data = {
    ...(codeConts && { codeConts }),
    ...(questionId && { questionId }),
    ...(articleObjType && { articleObjType }),
    ...(subject && { subject }),
    ...(conts && { conts }),
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(askTarget && { askTarget }),
    ...(askAIType && { askAIType }),
    ...(oldProductName && { oldProductName }),
    ...(oldContentName && { oldContentName }),
    ...(oldQuestionName && { oldQuestionName }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.articles}/feedback/${productId}/${contentId}`,
    data,
  });
}
// 1:1 피드백 조회
export async function getProductFeedBack({
  campusId,
  memberId,
  targetMemberId,
  questionId,
  feedbackId,
  productId,
  contentId,
  askTarget,
  limit,
  status,
  contentOnly,
  startDate,
  endDate,
  keyword,
  lastKey,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(productId && { productId }),
    ...(targetMemberId && { targetMemberId }),
    ...(questionId && { questionId }),
    ...(feedbackId && { feedbackId }),
    ...(contentId && { contentId }),
    ...(askTarget && { askTarget }),
    ...(limit && { limit }),
    ...(status && { status }),
    ...(contentOnly && { contentOnly }),
    ...(startDate && { startDate }),
    ...(endDate && { endDate }),
    ...(keyword && { keyword }),
    ...(lastKey && { lastKey }),
  };

  return this.getMethod("get", true, {
    url: `${endpoint.articles}/feedback`,
    params,
  });
}
// 마이 페이지 > 캠퍼스 회원 작성 가능 리뷰 조회
export async function getAvailableReviews({
  campusId,
  memberId,
  targetMemberId,
  targetUserId,
}) {
  const params = {
    campusId,
    memberId,
    targetMemberId,
    targetUserId,
  };

  return this.getMethod("get", true, {
    url: `${endpoint.articles}/product/review/my/available`,
    params,
  });
}

export async function getCampusNoticeCategories({ campusId, memberId, sigYn }) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("get", sigYn, {
    url: `${endpoint.articles}/campus/notice/category`,
    params,
  });
}

export async function postCampusNoticeCategory({
  campusId,
  memberId,
  targets,
}) {
  const data = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(targets && { targets }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.articles}/campus/notice/category`,
    data,
  });
}
