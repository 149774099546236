export default {
  getPlanList: (state) => {
    return (
      state.planList
        ?.filter((plan) => plan.planType === "BASIC")
        ?.sort((a, b) => a.seq - b.seq) || []
    );
  },
  getAddonList: (state) => {
    return (
      state.planList
        ?.filter((plan) => plan.planType === "ADDON")
        ?.sort((a, b) => a.seq - b.seq) || []
    );
  },
  getPlan: (state) => {
    return state.plan || null;
  },
  getPlanByValue: (state) => (params) => {
    const { planCode, shortName, planCycle, planType } = params;
    return state.planList?.find((plan) => {
      return (
        plan.planCode === planCode ||
        plan.shortName === shortName ||
        plan.planCycle === planCycle ||
        plan.planType === planType
      );
    });
  },
  getSelectedPlan(state) {
    return state.selectedPlan || {};
  },
};
