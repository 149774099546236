export default {
  containerActive: false,
  newCount: 0,
  notification: null,
  notifications: {
    results: [],
    lastKey: null,
  },
  notiTarget: null,
};
