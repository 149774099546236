import globalStore from "@/store";
import isEmpty from "lodash.isempty";

export default {
  setMemberItem: (state, member) => {
    state.memberItem = isEmpty(member) ? null : Object.assign({}, member);
  },
  updateMemberItem: (state, member) => {
    if (!member?.id) {
      state.memberItem = null;
      return;
    }
    if (state.memberItem) {
      state.memberItem = { ...state.memberItem, ...member };
      return;
    }
    state.memberItem = { ...member };
  },
  clearMemberItem: (state, member) => {
    state.memberItem = null;
  },
  setAuthorites: (state, authorities) => {
    state.authorities = authorities;
  },
  setRoles: (state, roles) => {
    state.roles = [...roles];
  },
  setStaffList: (state, campuses) => {
    state.staff.value = state.staff.value.concat(campuses);
  },
  setInviteCnt: (state, inviteCnt) => {
    state.staff.inviteCnt = inviteCnt;
  },
  resetStaffList: (state) => {
    state.staff.value = [];
  },
  setStaffLastKey: (state, lastKey) => {
    state.staff.lastKey = lastKey;
  },
  clearStaffList: (state) => {
    state.staff.lastKey = "";
    state.staff.value = [];
  },
  setCertificate: (state, certKey) => {
    state.certificateKey = certKey;
  },
  clearCertificate: (state) => {
    state.certificateKey = "";
  },
  setCampusMembers: (state, members) => {
    state.campusMembers = [...state.campusMembers, ...members];
  },
  clearCampusMembers: (state) => {
    state.campusMembers = [];
  },
  setCampusMembersLastKey: (state, lastKey) => {
    state.campusMembersLastKey = lastKey;
  },
  clearCampusMembersLastKey: (state) => {
    state.campusMembersLastKey = "";
  },
  setCampusMembersCnt: (state, cntObj) => {
    state.campusMembersCnt = cntObj ? { ...cntObj } : null;
  },
  setCampusMember: (state, member) => {
    state.campusMember = { ...state.member, ...member };
  },
  clearCampusMember: (state) => {
    state.campusMember = null;
  },
  updateCampusMember: (state, member) => {
    if (!state.campusMember || member?.uuid === state.campusMember?.uuid) {
      state.campusMember = { ...member };
    } else {
      globalStore.dispatch("common/setAlert", {
        open: true,
        msg: "ID가 일치하지 않습니다.",
      });
    }
  },
  setProductsMembers(state, result) {
    state.productsMembers.lastKey = result.lastKey;
    if (result.results) {
      state.productsMembers.items = [...result.results];
    }
  },
  updateProductsMembers(state, result) {
    state.productsMembers.lastKey = result.lastKey;
    if (result.results) {
      state.productsMembers.items = [
        ...state.productsMembers.items,
        ...result.results,
      ];
    }
  },
  clearProductsMembers(state) {
    state.productsMembers.lastKey = "";
    state.productsMembers.items = [];
  },
  setProductsMember(state, member) {
    state.productsMember = member ? { ...member } : null;
  },
  updateProductsMember(state, member) {
    state.productsMember = member
      ? { ...state.productsMember, ...member }
      : null;
  },
  clearProductsMember(state) {
    state.productsMember = null;
  },
  setProductMemberProgress(state, progress) {
    state.productMemberProgress = progress ? { ...progress } : null;
  },
  setProductMember(state, member) {
    state.productMember = member ? { ...member } : null;
  },
  clearProductMember(state) {
    state.productMember = null;
  },
  setCommunityMembers(state, result) {
    state.communityMembers.lastKey = result.lastKey;
    state.communityMembers.members = [
      ...state.communityMembers.members,
      ...result.results,
    ];
  },
  setCommunityMember(state, result) {
    state.communityMember = { ...result };
  },
  clearCommunityMembers(state) {
    state.communityMembers.lastKey = "";
    state.communityMembers.members = [];
  },
  setJoinProducts(state, products) {
    state.joinProducts = products;
  },
  setJoinCommunities(state, result) {
    state.joinCommunities = result.results;
  },
  setAuthorities: (state, result) => {
    state.authorities = result;
  },
  setProductMembersEndDttm: (state, payload) => {
    const { unprocessedMemberIds, endDttm, targets } = payload;
    state.productsMembers.items.forEach((member, idx) => {
      const isTarget = targets?.find((t) => t.memberId === member.memberId);

      if (isTarget) {
        const isUnprocessed = unprocessedMemberIds?.find(
          (id) => id === member.memberId
        );
        if (!isUnprocessed) {
          state.productsMembers.items[idx].endDttm = endDttm;
        }
      }
    });
  },
  setGroupMembers: (state, members) => {
    state.groupMembers = [...state.groupMembers, ...members];
  },
  clearGroupMembers: (state) => {
    state.groupMembers = [];
  },
  setGroupMembersLastKey: (state, lastKey) => {
    state.groupMembersLastKey = lastKey;
  },
  clearGroupMembersLastKey: (state) => {
    state.groupMembersLastKey = "";
  },
  setGroupMembersCnt: (state, cntObj) => {
    state.groupMembersCnt = cntObj ? { ...cntObj } : null;
  },
  setMktForbiddenRoute: (state, { type, route }) => {
    if (type === "ADD") {
      state.mktForbiddenRoute = [
        ...new Set([...state.mktForbiddenRoute, route]),
      ];
    } else {
      state.mktForbiddenRoute = state.mktForbiddenRoute?.filter(
        (forbidden) => forbidden !== route
      );
    }
  },
};
