export default (apiInstance) => ({
  /*
  ==============================
              비디오 관리
  ==============================
  */
  // 원본 비디오 url 조회.
  // vimeoYn인 경우, 비메오 원본 영상 url이 반환되므로 바로 다운로드 가능.
  // timeout 발생시 주기적으로 카피 작업 완료 여부 확인 필요.
  async reqGetOriginalVideoUrl(store, payload) {
    // { videoId }
    const {
      memberId = store.rootGetters["members/getMemberId"],
      campusId = store.rootGetters["campuses/getCampusUuid"],
      videoId,
    } = payload;

    const result = await apiInstance.transcodes.getOriginalVideoUrl({
      memberId,
      campusId,
      videoId,
    });

    return result;
  },
});
