export const AskStatus = {
  COMPLETE: "COMPLETE",
  STANDBY: "STANDBY",
  CANCEL: "CANCEL",
};

export const AskType = {
  CAMPUS: "CAMPUS",
  SYSTEM: "SYSTEM",
};
