<template>
  <v-dialog
    :value="isOpen"
    :max-width="maxWidth"
    persistent
    no-click-animation
    content-class="white hide-scrollbar p-confirm"
  >
    <div class="border-box text-center py-6 px-5">
      <v-icon :color="iconColor" size="48" class="mb-5">{{ getIcon }}</v-icon>
      <div class="w-full d-flex flex-column align-center mb-6">
        <b v-if="title" class="black--text text-h5-medium mb-4">{{ title }}</b>
        <div
          class="text-body-2-regular keep-all"
          v-html="message"
          :class="{ 'gray--text text--darken-3': Boolean(title) }"
        ></div>
      </div>
      <div class="w-full d-flex justify-center mb-6" v-if="checkboxText">
        <p-checkbox v-model="checkboxValue" :label="checkboxText" />
      </div>

      <div class="confirm--actions" :class="{ reverse }">
        <p-btn
          :color="btnThemeCancel"
          class="w-full"
          @click="$emit('confirm', false, checkboxValue)"
          :disabled="loading"
        >
          {{ btnTxtCancel }}
        </p-btn>
        <p-btn
          :color="btnThemeConfirm"
          class="w-full"
          @click="$emit('confirm', true, checkboxValue)"
          :loading="loading"
          :disabled="disabled"
        >
          {{ btnTxtConfirm }}
        </p-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "PoinUiConfirm",
  data() {
    return {
      checkboxValue: false,
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    icon: {
      type: String,
      default: "alert",
    },
    title: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    btnTxtConfirm: {
      type: String,
      default: "확인",
    },
    btnTxtCancel: {
      type: String,
      default: "취소",
    },
    btnThemeConfirm: {
      type: String,
      default: "primary",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: Number,
      default: 374,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    checkboxText: {
      type: String,
      default: "",
    },
  },
  watch: {
    isOpen: {
      immediate: true,
      handler(isOpen) {
        if (isOpen) {
          this.checkboxValue = false;
        }
      },
    },
  },
  computed: {
    iconColor() {
      switch (this.icon) {
        case "alert":
          return "error";
        case "confirm":
          return "success";
        case "info":
          return "info";
      }
      return "error";
    },
    getIcon() {
      switch (this.icon) {
        case "alert":
          return "$alert-circle";
        case "confirm":
          return "$confirm-circle";
        case "info":
          return "$info-circle";
      }
      return "$alert-circle";
    },
    btnThemeCancel() {
      return this.reverse ? "primarySub" : "gray";
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm--actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  .p-btn {
    grid-row: 1;
  }
  &.reverse {
    > .p-btn {
      &:last-child {
        grid-column: 1;
      }
    }
  }
}
</style>
