export default {
  setNewCount: (state, count) => {
    if (count) {
      state.newCount = count;
    } else {
      state.newCount = null;
    }
  },
  setNotifications: (state, data) => {
    state.notifications.lastKey = data.lastKey;
    state.notifications.results = [
      ...state.notifications.results,
      ...data.results,
    ];
  },
  resetNotifications: (state, data) => {
    state.notifications = {
      results: [],
      lastKey: null,
    };
  },
  setNotification: (state, data) => {
    state.notification = data;
  },
  deleteAllNotifications: (state, data) => {
    state.notifications.results = [];
    state.newCount = 0;
  },
  toggleContainerActive: (state, data) => {
    state.containerActive = !state.containerActive;
  },
  setClearNotifications: (state, data) => {
    state.newCount = 0;
    state.notifications = {
      results: [],
      lastKey: null,
    };
  },
  setNotiTarget: (state, item) => {
    if (!item) {
      state.notiTarget = null;
      return;
    }

    state.notiTarget = {
      id: item?.notiTargetId,
      upperId: item?.articleUpperId,
    };
  },
};
