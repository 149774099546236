<script setup>
const logoSrc = "https://file.poincampus.com/assets/images/PoinCampusLogo.png";
</script>

<template>
  <router-link class="d-flex" :to="{ name: 'poin-home' }">
    <img :src="logoSrc" class="logo" />
  </router-link>
</template>

<style lang="scss" scoped>
.logo {
  box-sizing: border-box;
  height: 28px;
  width: auto;
  max-width: 100%;
  padding-bottom: 4px;
  object-fit: contain;
  // @media (max-width: $mdBreakPoint) {
  //   height: 24px;
  // }
}
</style>
