export default {
  getQuitReason: (state) => state.quitReason,
  getProvision: (state) => state.provision,
  getReservationConfig: (state) => state.reservationConfig,
  getPrivacy: (state) => state.priviacy,
  getProvisionEdu: (state) => state.provisionEdu,
  getTerms: (state) => {
    const terms = state.terms;
    if (terms && terms.length > 0) {
      return terms[0].value;
    }
    return "";
  },
  getConsentEdu: (state) => state.consentEdu,
  getCategory: (state) => state.category,
  getInstructorGrade: (state) => state.instructorGrade,
  getBankOrg: (state) => state.bankOrg,
  getCourseCategoryOption: (state) => {
    if (state.category && state.category.length) {
      return state.category.map((item) => ({
        label: item.value,
        id: item.code,
        value: item.code,
        seq: item.seq,
      }));
    }
    return state.category;
  },
  getCourseQuestion: (state) => state.courseQuestion,
  getEvents: (state) => state.events,
  getEventsStatus: (state) => state.eventsStatus,
  getCupRegisterOpen: (state) => state.cupRegisterOpen,
  getCampusPlanConfigure: (state) => state.campusPlanConfigure,
  getSysConfig: (state) => state.sysConfig,
};
