const category = "promotions";

// 프로모션 목록 조회
export async function getPromotions() {
  return this.getMethod("get", false, {
    url: `${category}`,
  });
}

// 프로모션 이하의 상품 목록 조회
export async function getProducts({ promotionId, productType }) {
  return this.getMethod("get", false, {
    url: `${category}/products/${promotionId}`,
    params: {
      productType,
    },
  });
}

// 프로모션 결제 후 이력 입력
export async function postHistories({
  zipCode,
  amount,
  address,
  addressDetail,
  contact,
  userUuid,
  name,
  promotionId,
  productType,
  email,
  products,
}) {
  const data = {
    amount,
    contact,
    userUuid,
    name,
    email,
    promotionId,
    products,
    ...{ zipCode },
    ...{ address },
    ...{ addressDetail },
    ...{ productType },
  };
  return this.getMethod("post", true, {
    url: `${category}/histories`,
    data,
  });
}
