<template>
  <v-avatar :size="size">
    <v-img
      :src="`https://file.poincampus.com/assets/sns/${snsType}-circle.svg`"
      :alt="snsType"
    />
  </v-avatar>
</template>

<script>
export default {
  props: {
    snsType: {
      type: String,
      default: "none",
    },
    size: {
      type: Number,
      default: 20,
    },
  },
};
</script>

<style></style>
