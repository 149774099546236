/**
 * 포인캠퍼스 url인지 확인.
 * poincampus.com, dev.poincampus.com, localhost 인 경우
 * @param hostname
 * @return boolean
 */
export function isPoinDomain(hostname = window.location.hostname) {
  const poinHostNameList = [
    "poincampus.com",
    "dev.poincampus.com",
    "localhost",
  ];
  return !!poinHostNameList.includes(hostname);
}

/**
 * 포인캠퍼스 서브도메인 여부 확인.
 * @param hostname
 * @return boolean
 */
export function isPoinSubDomain(hostname = window.location.hostname) {
  return (
    hostname.includes("poincampus.com") &&
    hostname !== "dev.poincampus.com" &&
    hostname.split(".").length > 2
  );
}

/**
 * 외부도메인 여부 확인
 * @param hostname
 * @return boolean
 */
export function isExtDomain(hostname = window.location.hostname) {
  return (
    !hostname.includes("poincampus.com") || !hostname.includes("localhost")
  );
}

export function getSearchParam(name) {
  const urlParams = new URLSearchParams(window.location.search);
  let result;
  if (urlParams.has(name)) {
    result = urlParams.get(name);
  }
  return result;
}

export function removeSearchParam(name, url = window.location.href) {
  let newUrl;
  try {
    const U = new URL(url);
    U.searchParams.delete(name);
    newUrl = U.href;
    if (history.replaceState) {
      history.replaceState(null, "", newUrl);
    }
  } catch (e) {
    console.log(e);
  }
  return newUrl;
}
