<script setup>
import { computed } from "vue";
import { useCampusStore } from "@/stores/campus";
import CampusHeaderNavItem from "@/components/layout/header/CampusHeaderNavItem.vue";

const campusStore = useCampusStore();
const menus = computed(() => {
  return campusStore.menus;
});
const items = computed(() => menus.value?.filter((m) => !m.mainYn && m.useYn));
const styles = computed(() => {
  return campusStore.styles;
});
const color = computed(() => styles.value?.headerStyles?.color);
const accentColor = computed(() => styles.value?.headerStyles?.accentColor);
const bgColor = computed(() => styles.value?.headerStyles?.backgroundColor);
const fontFamily = computed(() => styles.value?.bodyStyles?.fontFamily);
</script>

<template>
  <nav class="navigation">
    <ul class="d-flex align-center gap-7">
      <CampusHeaderNavItem
        v-for="item in items"
        :key="item.id"
        :item="item"
        :color="color"
        :accent-color="accentColor"
        :bg-color="bgColor"
        :font-family="fontFamily"
        readonly
      />
    </ul>
  </nav>
</template>

<style lang="scss" scoped></style>
