const baseUrl = import.meta.env.VITE_BASE_URL;
const baseUrlV2 = import.meta.env.VITE_BASE_URL_V2;
const isCampus = import.meta.env.VITE_IS_CAMPUS === "true";
const endpoint = {
  purchase: import.meta.env.VITE_PURCHASE_ENDPOINT,
  search: import.meta.env.VITE_SEARCH_ENDPOINT,
  reviews: import.meta.env.VITE_REVIEWS_ENDPOINT,
  auth: import.meta.env.VITE_AUTH_ENDPOINT,
  users: import.meta.env.VITE_USERS_ENDPOINT,
  configure: import.meta.env.VITE_CONFIGURE_ENDPOINT,
  courses: import.meta.env.VITE_COURSES_ENDPOINT,
  supports: import.meta.env.VITE_SUPPORTS_ENDPOINT,
  notifications: import.meta.env.VITE_NOTIFICATIONS_ENDPOINT,
  payments: import.meta.env.VITE_PAYMENTS_ENDPOINT,
  reserves: import.meta.env.VITE_RESERVES_ENDPOINT,
  reservations: import.meta.env.VITE_RESERVATIONS_ENDPOINT,
  reservationCustomer: import.meta.env.VITE_RESERVATION_CUSTOMER_ENDPOINT,
  settlement: import.meta.env.VITE_RESERVATION_SETTLEMENT_ENDPOINT,
  campuses: import.meta.env.VITE_CAMPUSES_ENDPOINT,
  campusesPosts: import.meta.env.VITE_CAMPUSES_POSTS_ENDPOINT,
  campusesMember: import.meta.env.VITE_CAMPUSES_MEMBER_ENDPOINT,
  zoom: import.meta.env.VITE_ZOOM_ENDPOINT,
};

const endpointV2 = {
  campuses: import.meta.env.VITE_CAMPUSES_ENDPOINT_V2,
  members: import.meta.env.VITE_MEMBERS_ENDPOINT_V2,
  plans: import.meta.env.VITE_PLANS_ENDPOINT_V2,
  payments: import.meta.env.VITE_PAYMENTS_ENDPOINT_V2,
  admins: import.meta.env.VITE_ADMINS_ENDPOINT_V2,
  vods: import.meta.env.VITE_VODS_ENDPOINT,
  supports: import.meta.env.VITE_SUPPORTS_ENDPOINT_V2,
  notifications: import.meta.env.VITE_NOTIFICATIONS_ENDPOINT_V2,
  credits: import.meta.env.VITE_CREDITS_ENDPOINT_V2,
  products: import.meta.env.VITE_PRODUCTS_ENDPOINT,
  contents: import.meta.env.VITE_CONTENTS_ENDPOINT,
  communities: import.meta.env.VITE_COMMUNITIES_ENDPOINT,
  activities: import.meta.env.VITE_ACTIVITIES_ENDPOINT,
  articles: import.meta.env.VITE_ARTICLES_ENDPOINT,
  transcodes: import.meta.env.VITE_TRANSCODES_ENDPOINT,
  compiles: import.meta.env.VITE_COMPILES_ENDPOINT,
  groups: import.meta.env.VITE_GROUPS_ENDPOINT,
  coupons: import.meta.env.VITE_COUPONS_ENDPOINT,
  campaigns: import.meta.env.VITE_CAMPAIGNS_ENDPOINT,
  histories: import.meta.env.VITE_HISTORIES_ENDPOINT,
};

const facebook = {
  appId: import.meta.env.VITE_FACEBOOK_APPID,
  loginProtocolScheme: import.meta.env.VITE_FACEBOOK_LOGIN_PROTOCOL_SCHEME,
  appSecret: import.meta.env.VITE_FACEBOOK_APP_SECRET,
  androidKeyHash: import.meta.env.VITE_FACEBOOK_ANDROID_KEY_HASH,
};

const google = {
  clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID,
  clientSecret: import.meta.env.VITE_GOOGLE_CLIENT_SECRET,
};

const kakao = {
  appId: import.meta.env.VITE_KAKAO_APP_ID,
  appKey: import.meta.env.VITE_KAKAO_APP_KEY,
  clientId: import.meta.env.VUE_KAKAO_CLIENT_ID,
};

const naver = {
  oauthUrl: import.meta.env.VITE_NAVER_OAUTH_URL,
  redirect_uri: import.meta.env.VITE_NAVER_OAUTH_REDIRECT_URI,
  clientId: import.meta.env.VITE_NAVER_CLIENT_ID,
  clientSecret: import.meta.env.VITE_NAVER_CLIENT_SECRET,
  oauthState: import.meta.env.VITE_NAVER_OAUTH_STATE,
};

const vimeo = {
  baseUrl: import.meta.env.VITE_VIMEO_URL,
  accessToken: import.meta.env.VITE_VIMEO_TOKEN,
  deleteAccessToken: import.meta.env.VITE_VIMEO_DELETE_TOKEN,
  // clientId: import.meta.env.VITE_VIMEO_CLIENT_ID,
  // clientSecret: import.meta.env.VITE_VIMEO_CLIENT_SECRET
};

const s3 = {
  attatchmentBucketName: import.meta.env.VITE_S3_ATTACHMENT_BUCKET_NAME,
  s3AccessKeyId: import.meta.env.VITE_AWS_S3_ACCESS_KEY_ID,
  s3SecretAccessKey: import.meta.env.VITE_AWS_S3_SECRET_ACCESS_KEY,
  vodBucketName: import.meta.env.VITE_S3_VOD_BUCKET_NAME,
};

const aws = {
  identityPoolId: import.meta.env.VITE_AWS_COGNITO_IDENTITY_POOL_ID,
  region: import.meta.env.VITE_AWS_REGION,
};

const apple = {
  clientId: import.meta.env.VITE_APPLE_CLIENT_ID,
  redirectURI: import.meta.env.VITE_APPLE_OAUTH_REDIRECT_URI,
};

const zoom = {
  clientId: import.meta.env.VITE_ZOOM_CLIENT_ID,
};

const webBaseUrl = import.meta.env.VITE_WEB_BASE_URL;

const isDev = import.meta.env.VITE_IS_DEV === "true";

const isLocal = import.meta.env.VITE_IS_LOCAL === "true";

// Token Refresh 만료 시간 검사(초)
const authExpriedTimeCheck = 60;

const sentry = {
  dsn: import.meta.env.VITE_SENTRY_DSN,
};

const passwordSecretKey = import.meta.env.VITE_PASSWORD_SECRET_KEY;

const impCode = import.meta.env.VITE_IMP_CODE;

const oAuthRedirectUrl = import.meta.env.VITE_OAUTH_REDIRECT_URL;

const videoIframeUrl = import.meta.env.VITE_VIDEO_IFRAME_URL;

const vodJwtSecret = import.meta.env.VITE_VOD_JWT_SECRET;
const vodDistHostname = import.meta.env.VITE_VOD_DEST_HOST;

const kcp = {
  siteCode: import.meta.env.VITE_KCP_SITE_CODE,
  batchSiteCode: import.meta.env.VITE_KCP_SITE_CODE_BATCH,
  retUrl: import.meta.env.VITE_KCP_RET_URL,
  batchRetUrl: import.meta.env.VITE_KCP_BATCH_RET_URL,
  batchGroupId: import.meta.env.VITE_KCP_BATCH_GROUP_ID,
};
const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_APP_ID,
};

const firebasePublicVapidKey = import.meta.env.VITE_PUBLIC_VAPID_KEY;

const datadog = {
  applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
  clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
};

const channel = {
  baseUrl: import.meta.env.VITE_CHANNEL_URL,
  pluginKey: import.meta.env.VITE_CHANNEL_BTN_PLUGIN_KEY, //btn
  accessSecret: import.meta.env.VITE_CHANNEL_BTN_ACCESS_SECRET, //btn
  apiAccessKey: import.meta.env.VITE_CHANNEL_ACCESS_KEY, //api
  apiAccessSecret: import.meta.env.VITE_CHANNEL_ACCESS_SECRET, //api
  memberSecretKey: import.meta.env.VITE_CHANNEL_MEMBER_SECRET_KEY, //memberScret
};

const poinChannelConfig = {
  pluginKey: import.meta.env.VITE_CHANNEL_BTN_PLUGIN_KEY,
  zIndex: 99,
  customLauncherSelector: ".channel-btn",
  mobileMessengerMode: "newTab",
  language: "ko",
};

const ncp = {
  appName: import.meta.env.VITE_NCP_APP_NAME,
  clientId: import.meta.env.VITE_NCP_CLIENT_ID,
  clientSecret: import.meta.env.VITE_NCP_CLIENT_SECRET,
};

const partnerConsentKey = import.meta.env.VITE_PARTNER_CONSENT_KEY;

const NONE_TIME = 99999999999999;

export {
  firebasePublicVapidKey,
  firebaseConfig,
  isCampus,
  baseUrl,
  baseUrlV2,
  endpoint,
  endpointV2,
  facebook,
  google,
  kakao,
  naver,
  vimeo,
  webBaseUrl,
  authExpriedTimeCheck,
  s3,
  aws,
  apple,
  zoom,
  isDev,
  sentry,
  passwordSecretKey,
  impCode,
  oAuthRedirectUrl,
  videoIframeUrl,
  isLocal,
  vodJwtSecret,
  vodDistHostname,
  kcp,
  datadog,
  channel,
  poinChannelConfig,
  ncp,
  partnerConsentKey,
  NONE_TIME,
};
