export default {
  getUserPaymentsHistories: (state) => state.userPaymentsHistories,
  getLastKey: (state) => state.paymentLastKey,
  getPaymentSummary: (state) => state.userPaymentSummary,
  getCustomerNameKeyword: (state) => state.customerNameKeyword,
  getCards: (state) => state.cards,
  getPlanHistories: (state) => state.planHistories,
  getPlanHistoriesLastKey: (state) => state.planHistoriesLastKey,
  getCheckoutHistory: (state) => state.checkoutHistory,
  getProductPayment: (state) => state.productPayment,
  getUserDetailPayments: (state) => state.userDetailPayments.items,
  getUserDetailPaymentsLastKey: (state) => state.userDetailPayments.lastKey,
  getUserDetailPaymentSummary: (state) => state.userDetailPaymentSummary,
  getProductPaymentsHistories: (state) => state.productPaymentsHistories.items,
  getProductPaymentsHistoriesLastKey: (state) =>
    state.productPaymentsHistories.lastKey,
  getProductPaymentsSummary: (state) => state.productPaymentsSummary,
  getDashboardPaymentsSummary: (state) => state.dashboardPaymentsSummary,
  getUserCheckoutHistory: (state) => state.userCheckoutHistory,
  userDropDownProducts: (state) => state.userPaymentDropDownItems.products,
  userDropDownPromotions: (state) => state.userPaymentDropDownItems.promotions,
  getUserPurchases: (state) => state.userPurchases.items,
  getUserPurchasesLastKey: (state) => state.userPurchases.lastKey,
  getUserCancelPurchases: (state) => state.getUserCancelPurchases,
  getPaymentTable: (state) => state.table,
  getReservationOptions: (state) => state.reservationOptions,
  getPaymentsHistorySettlement: (state) => state.paymentsHistorySettlement,
};
