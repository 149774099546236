import { computed } from "vue";
import { defineStore } from "pinia";
import { useStore } from "@/store";
import colors from "@/utils/colors";
import { useRoute } from "vue-router/composables";

export const useCampusStore = defineStore("campus", () => {
  const store = useStore();
  const route = useRoute();
  const campus = computed(() => {
    return store.getters["campuses/getCampusInfo"];
  });
  const designs = computed(() => {
    return store.getters["campuses/getDesigns"];
  });
  const campusId = computed(() => campus.value?.id || "");
  const domain = computed(() => campus.value?.domain || "");
  const extDomain = computed(() => campus.value?.extDomain || "");
  const campusUrl = computed(() => {
    if (extDomain.value) {
      return `https://${extDomain.value}`;
    }
    if (domain.value) {
      return `https://${domain.value}.poincampus.com`;
    }
    return "";
  });
  const menus = computed(() => {
    return designs.value?.filter((d) => d.menuType !== "GLOBAL_STYLES") || [];
  });
  const styles = computed(() => {
    const obj = designs.value?.find((d) => d.menuType === "GLOBAL_STYLES");
    const strStyles = obj?.globalStyles || "";
    if (strStyles) {
      return JSON.parse(strStyles);
    }
    return {
      bodyStyles: {
        color: "#000000FF",
        backgroundColor: "#FFFFFFFF",
        fontFamily: "Pretendard",
      },
      headerStyles: {
        backgroundColor: "#FFFFFFFF",
        color: "#000000FF",
        accentColor: String(colors.primary.base),
        fontFamily: "Pretendard",
        logo: { key: "", path: "" },
      },
      textStyles: {
        fontFamily: "Pretendard",
        color: "#000000FF",
      },
      buttonStyles: {
        borderRadius: 4,
        backgroundColor: "#000000FF",
        color: "#FFFFFFFF",
        borderColor: "transparent",
        borderWidth: 0,
        fontFamily: "Pretendard",
        paddingX: 16,
        paddingY: 12,
      },
      productStyles: {
        fontFamily: "Pretendard",
        color: "#000000FF",
        accentColor: String(colors.primary.base),
      },
      footerStyles: {
        backgroundColor: "#FFFFFFFF",
        titleColor: "#000000FF",
        bodyColor: String(colors.gray.darken3),
        fontFamily: "Pretendard",
        html: "",
      },
    };
  });
  const currentMenu = computed(() => {
    let cur = null;
    const subMenus = menus.value.reduce((acc, cur) => {
      if (cur.menuType === "DROPDOWN") {
        return [...acc, ...cur.items];
      }
      return acc;
    }, []);
    switch (route.name) {
      case "campus-home":
        cur = menus.value.find((m) => m.mainYn);
        break;
      case "campus-product-home":
        cur = menus.value.find((m) => m.menuType === "PRODUCT");
        break;
      case "campus-community-home":
        cur = menus.value.find((m) => m.menuType === "COMMUNITY");
        break;
      case "campus-notice-home":
        cur = menus.value.find((m) => m.menuType === "NOTICE");
        break;
      case "campus-page":
        cur =
          menus.value.find((m) => m.slug === route.params.slug) ||
          subMenus.find((m) => m.slug === route.params.slug);
        break;
      default:
        break;
    }
    return cur;
  });
  const popups = computed(() => {
    const popups = campus.value?.POPUPS || [];
    const validDttm = (popup = {}) => {
      const NONE_DTTM = 99999999999999;
      const startDttm = popup.startDttm
        ? new Date(popup.startDttm).getTime()
        : NONE_DTTM;
      const endDttm = popup.endDttm
        ? new Date(popup.endDttm).getTime()
        : NONE_DTTM;
      const today = Date.now();
      if (startDttm === NONE_DTTM && endDttm === NONE_DTTM) {
        return true;
      }
      if (startDttm < today && today < endDttm) {
        return true;
      }
      return startDttm === NONE_DTTM && today < endDttm;
    };
    return popups
      ?.filter((popup) => popup.usageYn && validDttm(popup))
      ?.sort((a, b) => a.seq - b.seq);
  });
  const isFreePlan = computed(() => {
    return campus.value?.planCode === "FREE";
  });
  const SALES = computed(() => {
    return campus.value?.SALES || null;
  });
  const SETTING = computed(() => {
    return campus.value?.SETTING || null;
  });
  return {
    campus,
    campusId,
    domain,
    extDomain,
    menus,
    styles,
    currentMenu,
    popups,
    isFreePlan,
    campusUrl,
    SALES,
    SETTING,
  };
});
