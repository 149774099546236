export default {
  userGroups: [],
  group: {},
  //주소록
  addrBooks: [],
  addrBook: {},
  //주소
  addrs: [],
  addr: {},
  addrsLastKey: "",
  addrsCnt: null,
};
