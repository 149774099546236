import isEmpty from "lodash.isempty";

import globalStore from "@/store";

import { datadogRum } from "@datadog/browser-rum";

export default (apiInstance) => ({
  // set cognito Id & check user pool
  setInitialze: async (store, payload) => {
    if (store.rootGetters["auth/getCognitoId"]) {
      store.dispatch("setCongitoId", {
        cognitoId: store.rootGetters["auth/getCognitoId"],
      });
      const checkUserPoolResult = await globalStore.dispatch(
        "auth/checkUserPool",
        payload
      );
      return checkUserPoolResult;
    }
  },
  setClearUser: async (store) => {
    store.dispatch("setCongitoId", { cognitoId: "" });
    store.dispatch("setUser", { user: {} });
    store.dispatch("setInstructor", { instructor: {} });
    // globalStore.dispatch("edutainer/setEdutainerClear");
    globalStore.dispatch("notifications/setClearNotifications");
  },
  setCongitoId: (store, payload) => {
    const { cognitoId = "" } = payload;
    store.commit("setCongitoId", cognitoId);
  },
  setUser: async (store, payload) => {
    const { user } = payload;
    if (user) {
      store.commit("setUser", user);
      datadogRum.setUser({
        id: user.uuid,
        name: user.name,
        email: user.email,
      });
    } else {
      datadogRum.clearUser();
    }
  },
  setInstructor: async (store, payload) => {
    const { instructor } = payload;
    if (instructor) {
      store.commit("setInstructor", instructor);
      // globalStore.dispatch("edutainer/setRegistered", { registered: true });
      // globalStore.dispatch("edutainer/setRegisterStep", { step: 2 });
    }
  },
  setMembership: async (store, payload) => {
    store.commit("setMembership", payload);
  },
  requestCognitoId: async (store, payload) => {
    const { cognitoId } = store.state;
    if (cognitoId) {
      const result = await apiInstance.users.getCognitoId({
        cognitoId,
        ...payload,
      });
      if (!isEmpty(result.data)) {
        const { user, instructor } = result.data;
        if (user) {
          store.dispatch("setUser", { user });
        }
        if (instructor) {
          store.dispatch("setInstructor", { instructor });
        }
        return true;
      }
      return false;
    }
    return false;
  },
  reqCheckUserEmail: async (store, payload) => {
    const { email } = payload;
    if (email) {
      const result = await apiInstance.users.getCheckUserEmail(email);
      return result;
    }
  },
  reqPutUpdateUsers: async (store, payload) => {
    const {
      birthdate,
      gender,
      cp,
      name,
      image,
      nickname,
      intro,
      email,
      deviceId,
      // TODO: MKT
      mktYn,
    } = payload;
    const uuid = store.rootGetters["users/getUserUuid"];
    const result = await apiInstance.users.putUpdateUsers({
      userId: uuid,
      ...((intro || intro === "") && { intro }),
      ...((cp || cp === "") && { cp }),
      ...((birthdate || birthdate === "") && { birthdate }),
      ...(name && { name }),
      ...(nickname && { nickname }),
      ...(gender && { gender }),
      ...(email && { email }),
      ...(image && { image }),
      deviceId, // 디바이스 아이디 삭제를 위해 빈 문자열을 보내는 경우 있음.
      // TODO: MKT
      mktYn,
    });
    if (result.success) {
      const { data } = result;
      store.dispatch("setUser", { user: data });
    } else {
      // cognito error
      globalStore.dispatch("common/setAlert", {
        open: true,
        msg: "<p>시스템 오류입니다.</p>",
        csLink: true,
      });
    }
    return result;
  },
  reqPutUpdateUserPush: async (store, payload) => {
    const { push } = payload;
    const uuid = store.rootGetters["users/getUserUuid"];
    const result = await apiInstance.users.putUpdateUserPush({
      userId: uuid,
      push,
    });
    if (result.success) {
      const { data } = result;
      store.dispatch("setUser", { user: data });
    }
    return result;
  },
  reqUserQuit: async (store, payload) => {
    // TODO 강사인 경우.....
    const { quitYn, reason } = payload;
    const uuid = store.rootGetters["users/getUserUuid"];
    const result = await apiInstance.users.putUserQuit({
      userId: uuid,
      quitYn,
      reason,
    });
    if (result.success) {
      const { data } = result;
      store.dispatch("setUser", { user: data });
    }

    return result;
  },
  // post instructor
  reqPostInstructor: async (store, payload) => {
    const {
      realName,
      intro,
      name,
      sns,
      cp,
      image,
      bizNumber,
      bizName,
      bizContact,
    } = payload;
    const uuid = store.rootGetters["users/getUserUuid"];
    const result = await apiInstance.users.postInstructors({
      userId: uuid,
      realName,
      intro,
      name: name || store.rootGetters["users/getUser"].name,
      sns,
      cp,
      image,
      biz: {
        bizNumber,
        bizName,
        bizContact,
      },
    });
    if (result.success) {
      const { data } = result;
      store.dispatch("setInstructor", { instructor: data });
    }
  },
  // put instructor
  reqUpdateInstructor: async (store, payload) => {
    const { realName, intro, name, sns, cp, bizNumber, bizName, bizContact } =
      payload;
    const uuid = store.rootGetters["users/getUserUuid"];
    const result = await apiInstance.users.putInstructors({
      userId: uuid,
      realName,
      intro,
      name,
      sns,
      cp,
      biz: {
        number: bizNumber,
        name: bizName,
        contact: bizContact,
      },
    });
    if (result.success) {
      const { data } = result;
      store.dispatch("setInstructor", { instructor: data });
    }
    return result.success;
  },
  // result true or false
  reqUsersImageFileUpload: async (store, payload) => {
    const uuid = store.getters["getUserUuid"];
    const { userType, file } = payload;
    const result = await apiInstance.users.postImage({
      userType,
      uuid,
      file,
    });
    if (result.success) {
      const user = result.data;
      store.commit("setUser", result.data);
      if (user && user.image) {
        return user.image;
      }
    }
    return null;
  },
  reqInstructorsImageFileUpload: async (store, payload) => {
    const uuid = store.getters["getUserUuid"];
    const { userType, file } = payload;
    const result = await apiInstance.users.postImage({
      userType,
      uuid,
      file,
    });
    return result;
  },
  /* subscriptions */
  reqGetSubscriptions: async (store, { userType }) => {
    const uuid = store.rootGetters["users/getUserUuid"];
    let result;
    if (userType === "user") {
      result = await apiInstance.users.getUserSubscriptions({ uuid: uuid });
    } else {
      result = await apiInstance.users.getInstructorSubscriptions({
        uuid: uuid,
      });
    }
    if (result.success) {
      const { results } = result.data;
      if (results && results.length) {
        store.commit("setSubscriptions", {
          userType: userType,
          subscriptions: results,
        });
      } else {
        store.commit("setSubscriptions", {
          userType: userType,
          subscriptions: [],
        });
      }
    }
  },
  /* post subscriptions yn */
  reqPostSubscription: async (store, { tarUuid, subscrYn }) => {
    const uuid = store.rootGetters["users/getUserUuid"];
    const result = await apiInstance.users.postSubscriptions({
      uuid,
      tarUuid,
      subscrYn,
    });
    if (result.success) {
      store.commit("setSubscription", { tarUuid, subscrYn });
    }
    return result.success;
  },

  /* instructor/accounts/{uuid} 강사 계좌정보 조회 */
  reqGetInstructorAccounts: async (store, payload) => {
    const uuid = store.rootGetters["users/getUserUuid"];
    const data = { uuid };

    const result = await apiInstance.users.getInstructorAccounts(data);
    if (result.success) {
      store.commit("setInstructorAccounts", result.data.results);
    }
  },
  /* instructor/accounts/{uuid} 강사 계좌정보 저장 */
  reqPostInstructorAccounts: async (store, { accounts }) => {
    const uuid = store.rootGetters["users/getUserUuid"];
    const data = {
      uuid,
      accounts,
    };

    const result = await apiInstance.users.postInstructorAccounts(data);
    if (result.success) {
      store.commit("setInstructorAccounts", result.data);
    }
    return result.success;
  },
});
