export default {
  getProductReviews(state) {
    return state.productReviews.items;
  },
  getProductReviewsLastKey(state) {
    return state.productReviews.lastKey;
  },
  getProductReviewsStat(state) {
    return state.productReviews.stats;
  },
  getProductNoticesLastKey(state) {
    return state.productNotices.lastKey;
  },
  getProductNotices(state) {
    return state.productNotices.items;
  },
  getProductNotice(state) {
    return state.productNotice;
  },
  getRepliesLastKey(state) {
    return state.replies.lastKey;
  },
  getReplies(state) {
    return state.replies.items;
  },
  getCampusNoticesLastKey(state) {
    return state.campusNotices.lastKey;
  },
  getCampusNotices(state) {
    return state.campusNotices.items;
  },
  getCampusNotice(state) {
    return state.campusNotice;
  },
  getFeedBackList(state) {
    return state.feedBackList.items;
  },
  getFeedBackListLastKey(state) {
    return state.feedBackList.lastKey;
  },
  getFeedBack(state) {
    return state.feedBack;
  },
  getFeedbackViewMode(state) {
    return state.feedbackViewMode || "post";
  },
  getFeedbackCode(state) {
    return state.feedbackCode;
  },
  getAvailableReviews(state) {
    return state.availableReviews;
  },
  getCampusNoticeCategories(state) {
    return state.campusNoticeCategories;
  },
  getCampusNoticeCategoriesUsed(state) {
    return state.campusNoticeCategories?.filter((category) => category.pubYn);
  },
  getLoadingNotice(state) {
    return state.productNotices.loading;
  },
  getLoadingReview(state) {
    return state.productReviews.loading;
  },
};
