export default {
  setUserAskItems: (state, userAskItems) => {
    state.userAskItems = [...state.userAskItems, ...userAskItems];
  },
  setUserAskLastKey: (state, lastKey) => {
    state.userAskLastKey = lastKey;
  },
  clearUserAskItems: (state) => {
    state.userAskItems = [];
  },
  clearUserAskLastKey: (state) => {
    state.userAskLastKey = "";
  },
  clearAskItems(state) {
    state.campusAskLastKey = "";
    state.campusAskItems = [];
  },
  setCampusAskItems: (state, campusAskItems) => {
    state.campusAskItems = [...state.campusAskItems, ...campusAskItems];
  },
  setCampusAskLastKey: (state, lastKey) => {
    state.campusAskLastKey = lastKey;
  },
  clearCampusAskItems: (state) => {
    state.campusAskItems = [];
  },
  clearCampusAskLastKey: (state) => {
    state.campusAskLastKey = "";
  },
  setUserReplies: (state, userReplies) => {
    state.userReplies = [...state.userReplies, ...userReplies];
  },
  setUserReplyLastKey: (state, lastKey) => {
    state.userReplyLastKey = lastKey;
  },
  clearUserReplies: (state) => {
    state.userReplies = [];
  },
  clearUserReplyLastKey: (state) => {
    state.userReplyLastKey = "";
  },
  setCampusReplies: (state, campusReplies) => {
    state.campusReplies = [...state.campusReplies, ...campusReplies];
  },
  setCampusReplyLastKey: (state, lastKey) => {
    state.campusReplyLastKey = lastKey;
  },
  clearCampusReplies: (state) => {
    state.campusReplies = [];
  },
  clearCampusReplyLastKey: (state) => {
    state.campusReplyLastKey = "";
  },
  addNewUserComment: (state, newComment) => {
    state.userReplies = [newComment, ...state.userReplies];
  },
  removeUserComment: (state, commentId) => {
    state.userReplies = state.userReplies.filter(
      (reply) => reply.id !== commentId
    );
  },
  addNewCampusComment: (state, newComment) => {
    state.campusReplies = [newComment, ...state.campusReplies];
  },
  removeCampusComment: (state, commentId) => {
    const idx = state.campusReplies.findIndex(
      (reply) => reply.id === commentId
    );
    if (idx > -1) {
      state.campusReplies.splice(idx, 1);
    }
  },
  setAsk: (state, ask) => {
    state.ask = Object.assign({}, ask);
  },
  setAnswerHistories: (state, histories) => {
    state.answerHistories = [...histories];
  },
  setAskSearchOptions: (state, options) => {
    state.askSearchOptions = { ...options };
  },
  setAskCnt: (state, result) => {
    state.askCnt = { ...result };
  },
  setFaqItems(state, faqItems) {
    state.faqItems = [...state.faqItems, ...faqItems];
  },
  setFaqLastKey(state, lastKey) {
    state.faqLastKey = lastKey;
  },
  clearFaqItems(state) {
    state.faqItems = [];
  },
  clearFaqLastKey(state) {
    state.faqLastKey = "";
  },
  setFaqCategories(state, categories) {
    state.faqCategories = categories;
  },
  setFaq(state, faq) {
    state.faq = faq;
  },
  setFaqSearchOptions(state, options) {
    state.faqSearchOptions = { ...options };
  },
  setFaqTemplates(state, templates) {
    state.faqTemplates = [...templates];
  },
  setUserFaqItems(state, userFaqItems) {
    state.userFaqItems = userFaqItems;
  },
  setUserFaqCategories(state, faqCategories) {
    state.userFaqCategories = faqCategories;
  },
  setUserFaq(state, faq) {
    state.userFaq = faq;
  },
};
