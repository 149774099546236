import { endpoint } from "@/config";

// token
export async function checkToken({ code, grantType, userUuid, redirectUri }) {
  const data = {
    code,
    grant_type: grantType,
    userUuid,
    redirectUri,
  };

  return this.getMethod("post", true, {
    url: `${endpoint.zoom}/token`,
    data,
  });
}

// delete token
export async function deleteToken({ userUuid }) {
  if (!userUuid) {
    return;
  }

  const data = {
    userUuid,
  };

  return this.getMethod("delete", true, {
    url: `${endpoint.zoom}/token`,
    data,
  });
}
