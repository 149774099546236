// import { isMobile } from "@/utils/dom-utils";
import {
  Role,
  Authority,
  MemberStatus,
  PartnerStatus,
} from "@/enums/membersEnum";
import {
  loginPathBeforeEnter,
  getCampusHomeRoute,
} from "@/router/utils/auth-utils";
import { toCampusJoin } from "@/utils/store-utils";
import globalStore from "@/store";
import { useCheckoutStore } from "@/stores/checkout.js";
import { useAlert } from "@/stores/alert.js";
import Vue from "vue";
const vm = new Vue();
import SnsSelect from "@/components/common/SnsSelect.vue";

const USER_CAMPUS_SCOPE = [
  "PLAN",
  "SETTING",
  "SITE",
  "USAGE",
  "POLICY",
  "POPUPS",
];

export const campusRoutes = {
  meta: { campusOnly: true },
  component: () => import("@/views/campus"),
  children: [
    {
      path: "",
      name: "campus-home",
      meta: {
        loadCampusScope: USER_CAMPUS_SCOPE,
      },
      component: () => import("@/views/campus/Home.vue"),
    },
    {
      path: "download",
      name: "campus-app-download",
      component: () => import("@/components/campus/Download.vue"),
    },
    {
      path: "cart",
      name: "campus-cart",
      meta: { authRequired: true, loadCampusScope: USER_CAMPUS_SCOPE },
      component: () => import("@/views/campus/cart/Page.vue"),
    },
    {
      // 캠퍼스 관리자 [메인 메뉴 > 소개글 구성] 커스텀 페이지
      path: "pages/:slug",
      name: "campus-page",
      meta: {
        loadCampusScope: USER_CAMPUS_SCOPE,
      },
      component: () => import("@/views/campus/Page.vue"),
      beforeEnter(to, from, next) {
        const isPrivateCampus = globalStore.getters["campuses/isPrivateCampus"];
        const isRequestMember =
          globalStore.getters["members/isCampusRequestMember"];
        const isCampusJoinMember =
          globalStore.getters["members/isCampusJoinMember"];
        // 회원만 접근 가능 캠퍼스
        if (isPrivateCampus && !isCampusJoinMember) {
          if (isRequestMember) {
            vm.$eventBus.$emit("alert", {
              open: true,
              icon: "alert",
              title: "회원 접근 가능",
              msg: "가입 승인 후에 접근할 수 있습니다.",
            });
            back(to, from, next);
            return;
          }
          next(toCampusJoin(undefined, true, to));
          return;
        }
        // 누구나 접근 가능 캠퍼스
        next();
      },
    },
    {
      // 캠퍼스 관리자 [메인 메뉴 > 소개글 구성] 커스텀 페이지
      path: "emails/:slug",
      name: "campus-emails",
      component: () => import("@/views/campus/Emails.vue"),
    },
    {
      path: "unsubscribe",
      name: "campus-public-unsubscribe",
      component: () => import("@/views/campus/subscribe/Unsubscribe.vue"),
    },
    {
      path: "join",
      component() {
        return import("@/views/join");
      },
      children: [
        {
          path: "",
          name: "campus-join-select",
          component: {
            data() {
              return {
                useType: "join",
              };
            },
            extends: SnsSelect,
          },
        },
        {
          path: "agreement",
          name: "campus-join-terms-agree",
          component: () => import("@/views/join/TermsAgree.vue"),
          props: true,
        },
        {
          path: "form",
          name: "campus-join-form",
          component: () => import("@/views/join/Form.vue"),
          props: true,
        },
        {
          path: "code",
          name: "campus-join-code",
          component: () => import("@/views/join/Code.vue"),
          props: true,
        },
      ],
      beforeEnter(to, from, next) {
        loginPathBeforeEnter(to, from, next);
      },
    },
    {
      path: "login",
      component: () => import("@/views/login"),
      children: [
        {
          path: "",
          name: "campus-login-select",
          component: {
            data() {
              return {
                useType: "login",
              };
            },
            extends: SnsSelect,
          },
        },
        {
          path: "reset-password",
          name: "campus-reset-password",
          component: () => import("@/views/login/Reset-Password.vue"),
        },
      ],
      beforeEnter(to, from, next) {
        loginPathBeforeEnter(to, from, next);
      },
    },
    {
      path: "staff",
      name: "campus-staff-join",
      meta: { authRequired: true },
      component: () => import("@/components/campus/Staff-Join.vue"),
    },
    {
      path: "my",
      meta: { authRequired: true, loadCampusScope: USER_CAMPUS_SCOPE },
      component: () => import("@/views/my"),
      children: [
        {
          path: "",
          name: "campus-my",
          redirect: { name: "campus-playlist" },
        },
        {
          path: "playlist",
          name: "campus-playlist",
          meta: { authRequired: true },
          component: () => import("@/views/my/Playlist.vue"),
        },
        {
          path: "applications",
          name: "campus-applications",
          meta: { authRequired: true },
          component: () => import("@/views/my/Applications.vue"),
        },
        {
          path: "subscription",
          name: "campus-subscription",
          meta: { authRequired: true },
          component: () => import("@/views/my/Subscription.vue"),
        },
        {
          path: "subscription/:id",
          name: "campus-subscription-detail",
          meta: { authRequired: true },
          component: () => import("@/views/my/SubscriptionDetail.vue"),
        },
        {
          path: "reservation",
          name: "campus-reservation",
          meta: { authRequired: true },
          component: () => import("@/views/my/Reservation.vue"),
        },
        {
          path: "reservation/:paymentId",
          name: "campus-reservation-detail",
          meta: { authRequired: true },
          component: () => import("@/views/my/ReservationDetail.vue"),
        },
        {
          path: "orders",
          meta: { authRequired: true },
          component: () => import("@/views/my/orders/Main.vue"),
          children: [
            {
              path: "",
              name: "campus-orders",
              component: () => import("@/views/my/orders/Page.vue"),
            },
            {
              path: ":id",
              name: "campus-orders-detail",
              component: () => import("@/views/my/orders/detail/Page.vue"),
            },
            {
              path: "claim/:type/:id",
              meta: { authRequired: true },
              component: () => import("@/views/my/orders/claim/Page.vue"),
              children: [
                {
                  path: "step1",
                  name: "campus-orders-claim-step1",
                  component: () =>
                    import("@/views/my/orders/claim/steps/Step1.vue"),
                },
                {
                  path: "step2",
                  name: "campus-orders-claim-step2",
                  component: () =>
                    import("@/views/my/orders/claim/steps/Step2.vue"),
                },
                {
                  path: "step3",
                  name: "campus-orders-claim-step3",
                  component: () =>
                    import("@/views/my/orders/claim/steps/Step3.vue"),
                },
                {
                  path: "complete",
                  name: "campus-orders-claim-complete",
                  component: () =>
                    import("@/views/my/orders/claim/steps/Complete.vue"),
                },
              ],
            },
            {
              path: "claim-detail/:type/:id",
              name: "campus-orders-claim-detail",
              component: () =>
                import("@/views/my/orders/claim-detail/Page.vue"),
            },
          ],
        },
        {
          path: "purchases/:purchaseId",
          name: "campus-purchase-detail",
          meta: { authRequired: true },
          component: () => import("@/views/my/PurchaseDetail.vue"),
        },
        {
          path: "coupons",
          name: "campus-coupons",
          meta: { authRequired: true },
          component: () => import("@/views/my/Coupons.vue"),
        },
        {
          path: "reviews",
          name: "campus-reviews",
          meta: { authRequired: true },
          component: () => import("@/views/my/Reviews.vue"),
        },
        {
          path: "feedback",
          name: "campus-feedback",
          meta: { authRequired: true },
          component: () => import("@/views/my/FeedBack.vue"),
        },
        // {
        //   path: "asks",
        //   name: "campus-asks",
        //   meta: { authRequired: true },
        //   component: () => import("@/views/my/Ask.vue"),
        // },
        {
          path: "notification",
          name: "campus-notification",
          meta: { authRequired: true },
          component: () => import("@/views/my/Notification.vue"),
        },
        {
          path: "account",
          name: "campus-account",
          meta: { authRequired: true },
          component: () => import("@/views/my/account/Page.vue"),
        },
        {
          path: "cards",
          name: "campus-cards",
          meta: { authRequired: true },
          component: () => import("@/views/my/Cards.vue"),
        },
      ],
    },
    {
      path: "cancel/:purchaseId",
      meta: { authRequired: true },
      component: () => import("@/views/cancel"),
      children: [
        {
          path: "",
          meta: {
            authRequired: true,
          },
          name: "campus-purchase-cancel",
          component: () => import("@/views/cancel/Cancel.vue"),
        },
        {
          path: "complete",
          meta: { authRequired: true },
          name: "campus-purchase-cancel-complete",
          component: () => import("@/views/cancel/Complete.vue"),
        },
      ],
    },
    {
      path: "certificates/:productId/:memberId",
      name: "campus-certificates",
      meta: { authRequired: true, customLayout: true },
      component: () => import("@/views/certificates"),
    },
    {
      path: "partners-landing",
      name: "campus-partners-landing",
      meta: {
        loadCampusScope: [
          "PLAN",
          "SETTING",
          "SITE",
          "USAGE",
          "POLICY",
          "POPUPS",
          "SALES",
        ],
      },
      component: () => import("@/views/campus/partners-landing/Page.vue"),
      beforeEnter(to, from, next) {
        const isUsed = globalStore.getters["campuses/checkServeYn"]("PARTNERS");
        const member = globalStore.getters["members/getMemberItem"];
        if (!isUsed) {
          vm.$eventBus.$emit("alert", {
            open: true,
            icon: "alert",
            title: "접근 불가",
            msg: "파트너 서비스가 종료되었습니다.",
          });
          back(to, from, next);
          return;
        }

        if ([PartnerStatus.REJECT].includes(member?.partnerStatus)) {
          return next({
            name: "campus-partners-await",
            params: to.params,
            query: to.query,
          });
        }
        return next();
      },
    },
    {
      path: "partners-join",
      meta: {
        authRequired: true,
        customLayout: true,
      },
      component: () => import("@/views/campus/partners-join/Page.vue"),
      children: [
        {
          path: "",
          name: "campus-partners-join",
          redirect: { name: "campus-partners-join-step1" },
        },
        {
          path: "step1",
          name: "campus-partners-join-step1",
          component: () => import("@/views/campus/partners-join/Step1.vue"),
        },
        {
          path: "step2",
          name: "campus-partners-join-step2",
          component: () => import("@/views/campus/partners-join/Step2.vue"),
        },
        {
          path: "step3",
          name: "campus-partners-join-step3",
          component: () => import("@/views/campus/partners-join/Step3.vue"),
        },
        {
          path: "step4",
          name: "campus-partners-join-step4",
          component: () => import("@/views/campus/partners-join/Step4.vue"),
        },
      ],
      beforeEnter(to, from, next) {
        const isStaff = globalStore.getters["members/isStaff"];
        const member = globalStore.getters["members/getMemberItem"];
        const isCampusJoinMember =
          globalStore.getters["members/isCampusJoinMember"];
        const isUsed = globalStore.getters["campuses/checkServeYn"]("PARTNERS");
        if (!isUsed) {
          vm.$eventBus.$emit("alert", {
            open: true,
            icon: "alert",
            title: "접근 불가",
            msg: "파트너 서비스가 종료되었습니다.",
          });
          back(to, from, next);
          return;
        }
        if (isStaff) {
          vm.$eventBus.$emit("alert", {
            open: true,
            icon: "alert",
            title: "관리자 계정 신청 불가",
            msg: "현재 관리자 계정 로그인 상태입니다.<br/>파트너는 회원 계정으로 신청 가능합니다.",
          });
          back(to, from, next);
          return;
        }
        if (!isCampusJoinMember) {
          vm.$eventBus.$emit("alert", {
            open: true,
            icon: "alert",
            title: "회원 접근 가능",
            msg: "가입 승인 후에 접근할 수 있습니다.",
          });
          back(to, from, next);
          return;
        }
        if (
          [PartnerStatus.JOIN, PartnerStatus.DISABLED].includes(
            member?.partnerStatus
          )
        ) {
          return next({
            name: "campus-partners-dashboard",
            params: to.params,
            query: to.query,
          });
        }
        if (member?.partnerStatus === PartnerStatus.REQUEST) {
          return next({
            name: "campus-partners-await",
            params: to.params,
            query: to.query,
          });
        }
        next();
      },
    },
    {
      path: "product",
      component: () => import("@/views/campus/product"),
      beforeEnter(to, from, next) {
        const isPrivateCampus = globalStore.getters["campuses/isPrivateCampus"];
        const isRequestMember =
          globalStore.getters["members/isCampusRequestMember"];
        const isCampusJoinMember =
          globalStore.getters["members/isCampusJoinMember"];
        // 회원만 접근 가능 캠퍼스
        if (isPrivateCampus && !isCampusJoinMember) {
          if (isRequestMember) {
            vm.$eventBus.$emit("alert", {
              open: true,
              icon: "alert",
              title: "회원 접근 가능",
              msg: "가입 승인 후에 접근할 수 있습니다.",
            });
            back(to, from, next);
            return;
          }
          next(toCampusJoin(undefined, true, to));
          return;
        }
        // 누구나 접근 가능 캠퍼스
        next();
      },
      children: [
        {
          path: "",
          name: "campus-product-home",
          meta: {
            loadCampusScope: USER_CAMPUS_SCOPE,
            slug: "product",
          },
          component: () => import("@/views/campus/product/Home.vue"),
        },
        {
          path: "reservation/:productId",
          name: "campus-product-reservation",
          meta: {
            productDetail: true,
          },
          component: () => import("@/views/campus/product/Reservation.vue"),
        },
        {
          path: "reservation/:productId/:optionId",
          name: "campus-product-reservation-detail",
          meta: {
            productDetail: true,
          },
          component: () =>
            import("@/views/campus/product/ReservationDetail.vue"),
        },
        {
          path: ":productId",
          name: "campus-product-detail",
          component: () => import("@/views/campus/product/detail/Page.vue"),
          meta: {
            productDetail: true,
          },
          beforeEnter(to, from, next) {
            globalStore.commit("products/clearProduct", null, { root: true });
            next();
          },
        },
        {
          path: ":productId/notice",
          name: "campus-product-notice",
          component: () => import("@/views/campus/product/NoticeList.vue"),
        },
        {
          path: ":productId/notice/:noticeId",
          name: "campus-product-notice-detail",
          component: () => import("@/views/campus/product/NoticeDetail.vue"),
        },
        {
          path: ":productId/review",
          name: "campus-product-review",
          component: () => import("@/views/campus/product/ReviewList.vue"),
        },
      ],
    },
    {
      path: "notice",
      component: () => import("@/views/campus/notice"),
      meta: {
        loadCampusScope: USER_CAMPUS_SCOPE,
      },
      children: [
        {
          path: "",
          name: "campus-notice-home",
          meta: {
            slug: "notice",
          },
          component: () => import("@/views/campus/notice/Home.vue"),
        },
        {
          path: ":id",
          name: "campus-notice-detail",
          component: () => import("@/views/campus/notice/Detail.vue"),
        },
      ],
    },
    {
      path: "checkout",
      meta: {
        authRequired: true,
        loadCampusScope: [
          "PLAN",
          "SETTING",
          "SITE",
          "USAGE",
          "POLICY",
          "PAYMETHOD",
        ],
      },
      name: "campus-product-checkout",
      component: () => import("@/views/campus/checkout/Page.vue"),
      beforeEnter(to, from, next) {
        const isCampusJoinMember =
          globalStore.getters["members/isCampusJoinMember"];
        const isRequestMember =
          globalStore.getters["members/isCampusRequestMember"];
        if (globalStore.getters["members/isStaff"]) {
          vm.$eventBus.$emit("alert", {
            open: true,
            icon: "alert",
            title: "회원 접근 가능",
            msg: "직원 계정은 상품을 구입하실 수 없습니다.",
          });
          back(to, from, next);
          return;
        }
        if (!isCampusJoinMember) {
          if (isRequestMember) {
            vm.$eventBus.$emit("alert", {
              open: true,
              icon: "alert",
              title: "회원 접근 가능",
              msg: "가입 승인 후에 접근할 수 있습니다.",
            });
            back(to, from, next);
            return;
          }
          next(toCampusJoin(undefined, true, to));
          return;
        }

        // 이지웰
        const isEzwelEntry = globalStore.getters["common/isEzwelEntry"];
        const isSubsctiption = to.query.subscriptionYn;
        const checkoutStore = useCheckoutStore();
        const alert = useAlert();
        if (isEzwelEntry && isSubsctiption) {
          alert.open({
            icon: "alert",
            title: "정기구매 미지원",
            msg: "현재 복지몰에서는 정기구매로 결제하실 수 없습니다.",
          });
          back(to, from, next);
          return;
        }
        if (isEzwelEntry && checkoutStore.products.length > 1) {
          alert.open({
            icon: "alert",
            title: "단건 구매만 가능",
            msg: "현재 복지몰에서는 한 번에 하나의 상품만 결제 가능합니다. 여러 상품을 구매하시려면 각각 따로 결제해 주세요.",
          });
          back(to, from, next);
          return;
        }
        next();
      },
    },
    {
      path: "order/:orderId",
      meta: {
        authRequired: true,
        loadCampusScope: [
          "PLAN",
          "SETTING",
          "SITE",
          "USAGE",
          "POLICY",
          "PAYMETHOD",
        ],
      },
      name: "campus-product-order",
      component: () => import("@/views/campus/order/Page.vue"),
    },
    {
      path: "enroll/:productId",
      meta: {
        authRequired: true,
      },
      name: "campus-product-enroll",
      component: () => import("@/views/campus/Enroll.vue"),
      beforeEnter(to, from, next) {
        const isCampusJoinMember =
          globalStore.getters["members/isCampusJoinMember"];
        const isRequestMember =
          globalStore.getters["members/isCampusRequestMember"];
        if (globalStore.getters["members/isStaff"]) {
          back(to, from, next);
          return;
        }
        if (!isCampusJoinMember) {
          if (isRequestMember) {
            vm.$eventBus.$emit("alert", {
              open: true,
              icon: "alert",
              title: "회원 접근 가능",
              msg: "가입 승인 후에 접근할 수 있습니다.",
            });
            back(to, from, next);
            return;
          }
          next(toCampusJoin(undefined, true, to));
          return;
        }
        next();
      },
    },
    {
      path: "enroll-complete/:productMemberId",
      meta: {
        authRequired: true,
      },
      name: "campus-product-enroll-complete",
      component: () => import("@/views/campus/EnrollComplete.vue"),
    },
    {
      path: "room/:productId",
      meta: { authRequired: true, memberAuth: true },
      component: () => import("@/views/campus/room"),
      children: [
        {
          path: "",
          name: "campus-room-home",
          component: () => import("@/views/campus/room/Content.vue"),
        },
        {
          path: "content",
          name: "campus-room-content",
          redirect: { name: "campus-room-home" },
        },
        {
          path: "info",
          name: "campus-room-info",
          component: () => import("@/views/campus/room/Info.vue"),
        },
        {
          path: "notice",
          name: "campus-room-notice",
          component: () => import("@/views/campus/room/Notice.vue"),
        },
        {
          path: "notice/:noticeId",
          name: "campus-room-notice-detail",
          component: () => import("@/views/campus/room/NoticeDetail.vue"),
        },
        {
          path: "qna",
          name: "campus-room-qna",
          component: () => import("@/views/campus/room/Qna.vue"),
        },
        {
          path: "feedback",
          name: "campus-room-feedback",
          component: () => import("@/views/campus/room/FeedBack.vue"),
        },
        {
          path: "feedback/:contentId/:feedbackId",
          name: "campus-room-feedback-detail",
          component: () => import("@/views/campus/room/FeedBackDetail.vue"),
        },
      ],
      beforeEnter(to, _, next) {
        const productMembers =
          globalStore.getters["members/getMemberItem"]?.productMembers || [];
        const target =
          productMembers.find(
            (member) => member.targetId === to.params.productId
          ) || null;
        globalStore.commit("members/setProductMember", target, {
          root: true,
        });
        next();
      },
    },
    {
      path: "playground/:productId/:contentId",
      name: "campus-playground",
      component: () => import("@/views/campus/Playground.vue"),
      async beforeEnter(to, _, next) {
        await globalStore.dispatch("members/reqGetMember");
        const productMembers =
          globalStore.getters["members/getMemberItem"]?.productMembers || [];

        const target =
          productMembers.find(
            (member) => member.targetId === to.params.productId
          ) || null;
        if (
          target?.pauseEndDttm &&
          new Date(target?.pauseEndDttm) > new Date()
        ) {
          return next({
            name: "campus-room-home",
            params: to.params,
          });
        }
        globalStore.commit("members/setProductMember", target, {
          root: true,
        });
        next();
      },
    },
    {
      path: "application/:productId",
      name: "campus-application",
      meta: { authRequired: true, memberAuth: true },
      component: () => import("@/views/campus/Application.vue"),
      beforeEnter(to, from, next) {
        const isRequestMember =
          globalStore.getters["members/isCampusRequestMember"];

        if (isRequestMember) {
          vm.$eventBus.$emit("alert", {
            open: true,
            icon: "alert",
            title: "회원 접근 가능",
            msg: "가입 승인 후에 접근할 수 있습니다.",
          });
          back(to, from, next);
          return;
        }

        const productMembers =
          globalStore.getters["members/getMemberItem"]?.productMembers || [];
        const target =
          productMembers.find(
            (member) => member.targetId === to.params.productId
          ) || null;
        globalStore.commit("members/setProductMember", target, {
          root: true,
        });
        next();
      },
    },
    {
      path: "community",
      component: () => import("@/views/campus/community"),
      async beforeEnter(to, from, next) {
        const isPrivateCampus = globalStore.getters["campuses/isPrivateCampus"];
        const isRequestMember =
          globalStore.getters["members/isCampusRequestMember"];
        const isCampusJoinMember =
          globalStore.getters["members/isCampusJoinMember"];
        // 회원만 접근 가능 캠퍼스
        if (isPrivateCampus && !isCampusJoinMember) {
          if (isRequestMember) {
            vm.$eventBus.$emit("alert", {
              open: true,
              icon: "alert",
              title: "회원 접근 가능",
              msg: "가입 승인 후에 접근할 수 있습니다.",
            });
            back(to, from, next);
            return;
          }
          next(toCampusJoin(undefined, true, to));
          return;
        }
        // 누구나 접근 가능 캠퍼스
        next();
      },
      children: [
        {
          path: "",
          name: "campus-community-home",
          meta: {
            loadCampusScope: [
              "PLAN",
              "SETTING",
              "SITE",
              "USAGE",
              "POLICY",
              "POPUPS",
            ],
            slug: "community",
          },
          component: () => import("@/views/campus/community/Home.vue"),
        },
        {
          path: ":communityId",
          // meta: { authRequired: true, MemberAuth: true },
          name: "campus-community-detail",
          component: () => import("@/views/campus/community/Detail.vue"),
          async beforeEnter(to, from, next) {
            // 비회원 커뮤니티 상세 조회
            const isGuest = !globalStore.getters["users/getUserUuid"];
            const result = await globalStore.dispatch(
              isGuest
                ? "communities/reqGetCommunityPublic"
                : "communities/reqGetCommunity",
              {
                id: to.params.communityId,
              }
            );
            if (result.success) {
              return next();
            }
            let title = "접근 불가";
            let msg = "오류가 발생했습니다.";
            switch (result.name) {
              case "ACCESS_DENIED":
                msg = "접근이 제한된 페이지입니다.";
                break;
              case "UNAUTHORIZE":
                msg = "권한이 없습니다.";
                break;
              // memberId 없어서 발생한 에러일 경우, alert를 띄우지 않는다.
              case "BAD_REQUEST_PARAMETERS":
                msg = "";
                break;
            }
            if (msg) {
              vm.$eventBus.$emit("alert", {
                open: true,
                title,
                msg,
              });
            }
            const isPrivateCampus =
              globalStore.getters["campuses/isPrivateCampus"];
            const name = isPrivateCampus
              ? "campus-home"
              : "campus-community-home";
            next({
              name,
              params: to.params,
            });
          },
        },
        {
          path: ":communityId/write",
          meta: { authRequired: true, MemberAuth: true },
          name: "campus-community-write",
          component: () => import("@/views/campus/community/Write.vue"),
          beforeEnter(to, from, next) {
            const isManager = globalStore.getters["members/hasRoleOrAuth"]({
              authority: [
                Authority.CAMPUS_SUPER_MANAGE,
                Authority.COMMUNITY_SUPER_MANAGE,
              ],
            });
            if (
              !isManager &&
              globalStore.getters["members/getMyCommunityMembers"]?.filter(
                (member) => member.memberStatus === MemberStatus.JOIN
              )?.length < 1
            ) {
              vm.$eventBus.$emit("alert", {
                open: true,
                title: `참여중인 커뮤니티가 없습니다.`,
                msg: `<p>커뮤니티 참여 이후, 게시글을 작성해보세요.</p>`,
              });
              const isPrivateCampus =
                globalStore.getters["campuses/isPrivateCampus"];
              const name = isPrivateCampus
                ? "campus-home"
                : "campus-community-home";
              return next({
                name,
                params: to.params,
              });
            }
            next();
          },
        },
        {
          path: ":communityId/my",
          meta: { authRequired: true, MemberAuth: true },
          name: "campus-community-my",
          component: () => import("@/views/campus/community/Activity"),
        },
        {
          path: ":communityId/edit/:postId",
          meta: { authRequired: true, MemberAuth: true },
          name: "campus-community-post-edit",
          component: () => import("@/views/campus/community/Edit.vue"),
          beforeEnter(to, from, next) {
            const isManager = globalStore.getters["members/hasRoleOrAuth"]({
              authority: [
                Authority.CAMPUS_SUPER_MANAGE,
                Authority.COMMUNITY_SUPER_MANAGE,
              ],
            });
            const isCommunityJoinMember =
              globalStore.getters["members/getMyCommunityMembers"]?.find(
                (community) => {
                  return community.targetId === to.params.communityId;
                }
              )?.memberStatus === MemberStatus.JOIN;
            if (!isCommunityJoinMember && !isManager) {
              vm.$eventBus.$emit("alert", {
                open: true,
                msg: `커뮤니티 멤버가 아닙니다.`,
              });
              return;
            }
            return next();
          },
        },
        {
          path: ":communityId/:postId",
          // meta: { authRequired: true, MemberAuth: true },
          name: "campus-community-post",
          component: () => import("@/views/campus/community/Post.vue"),
          beforeEnter(to, from, next) {
            const isClassListAccessAll =
              globalStore.getters["campuses/getCampusInfo"]?.SETTING
                ?.classListAccess === "ALL" || false;
            const isManager = globalStore.getters["members/hasRoleOrAuth"]({
              authority: [
                Authority.CAMPUS_SUPER_MANAGE,
                Authority.COMMUNITY_SUPER_MANAGE,
              ],
            });
            const isCommunityJoinMember =
              globalStore.getters["members/getMyCommunityMembers"]?.find(
                (community) => {
                  return community.targetId === to.params.communityId;
                }
              )?.memberStatus === MemberStatus.JOIN;
            if (!isCommunityJoinMember && !isManager && !isClassListAccessAll) {
              vm.$eventBus.$emit("alert", {
                open: true,
                msg: `커뮤니티 멤버가 아닙니다.`,
              });
              const isPrivateCampus =
                globalStore.getters["campuses/isPrivateCampus"];
              const name = isPrivateCampus
                ? "campus-home"
                : "campus-community-home";
              return next({
                name,
                params: to.params,
              });
            }
            return next();
          },
        },
      ],
    },
    {
      path: "member/join",
      name: "campus-member-join",
      meta: {
        authRequired: true,
        memberAuth: true,
        loadCampusScope: ["SETTING"],
      },
      component: () => import("@/views/campus/member/Join.vue"),
      beforeEnter(_, from, next) {
        // 가입신청, 참여 상태 이거나 캠퍼스 개설자인 경우
        // 캠퍼스 가입 페이지 진입하지 못하도록 처리
        const status = globalStore.getters["members/getMemberStatus"];
        const checklist = [
          MemberStatus.JOIN,
          MemberStatus.REQUEST,
          MemberStatus.RETRY_REQUEST,
        ];
        const isOwner = globalStore.getters["campuses/isCampusOwner"];
        const isRequested = checklist.includes(status);
        if (isRequested || isOwner) {
          if (from?.name) {
            return next(from);
          }
          return next(getCampusHomeRoute());
        }
        next();
      },
    },
    {
      path: "help",
      component: () => import("@/views/campus/help"),
      children: [
        {
          path: "",
          name: "campus-help",
          redirect: { name: "campus-help-faq" },
        },
        {
          path: "faq",
          name: "campus-help-faq",
          component: () => import("@/views/campus/help/Faq.vue"),
        },
        {
          path: "ask",
          component: () => import("@/views/campus/help/ask"),
          children: [
            {
              path: "",
              name: "campus-help-ask",
              component: () => import("@/views/campus/help/ask/Home.vue"),
            },
            {
              path: "view/:slug",
              name: "campus-help-ask-view",
              meta: { authRequired: true, showCampusHelpNav: false },
              component: () => import("@/views/campus/help/ask/View.vue"),
            },
            {
              path: "write",
              name: "campus-help-ask-write",
              meta: { showCampusHelpNav: false },
              component: () => import("@/views/campus/help/ask/Write.vue"),
            },
            {
              path: "edit/:slug",
              name: "campus-help-ask-edit",
              meta: { authRequired: true, showCampusHelpNav: false },
              component: () => import("@/views/campus/help/ask/Edit.vue"),
            },
          ],
        },
      ],
    },
    {
      path: "policy",
      component: () => import("@/views/campus/policy"),
      meta: {
        loadCampusScope: ["PLAN", "SETTING", "SITE", "USAGE", "POLICY"],
      },
      children: [
        {
          path: "",
          name: "campus-policy",
          redirect: { name: "campus-policy-terms" },
        },
        {
          path: "terms",
          name: "campus-policy-terms",
          component: () => import("@/views/campus/policy/Provision.vue"),
        },
        {
          path: "privacy",
          name: "campus-policy-privacy",
          component: () => import("@/views/campus/policy/Privacy.vue"),
        },
        {
          path: "refund",
          name: "campus-policy-refund",
          component: () => import("@/views/campus/policy/Refund.vue"),
        },
      ],
    },
    {
      path: "admin",
      meta: {
        showGlobalMenu: false,
        memberAuth: {
          role: Role.A,
          staffOnly: true,
        },
        authRequired: true,
        isAdmin: true,
        loadCampusScope: [
          "PLAN",
          "USAGE",
          "SITE",
          "SETTING",
          "SEO",
          "SALES",
          "EXT_DOMAIN",
          "POLICY",
          "PAYMETHOD",
        ],
      },
      component: () => import("@/views/campus/admin"),
      children: [
        {
          path: "",
          name: "campus-admin-dashboard",
          component: () => import("@/views/campus/admin/Dashboard.vue"),
        },
        {
          path: "site",
          name: "campus-admin-site-info",
          component: () => import("@/views/campus/admin/SiteInfo.vue"),
        },
        {
          path: "domains",
          name: "campus-admin-domains",
          component: () => import("@/views/campus/admin/Domains.vue"),
        },
        {
          path: "domains/settings",
          name: "campus-admin-domains-settings",
          component: () => import("@/views/campus/admin/DomainsSettings.vue"),
        },
        {
          path: "cs",
          name: "campus-admin-cs",
          component: () => import("@/views/campus/admin/CustomerService.vue"),
        },
        {
          path: "terms",
          name: "campus-admin-terms",
          component: () => import("@/views/campus/admin/Terms.vue"),
        },
        {
          path: "recommend",
          name: "campus-admin-recommend",
          component: () => import("@/views/campus/admin/Recommend.vue"),
        },
        {
          path: "footer",
          name: "campus-admin-footer",
          component: () => import("@/views/campus/admin/FooterSettings.vue"),
        },
        {
          path: "design",
          name: "campus-admin-design",
          meta: {
            customLayout: true,
          },
          component: () => import("@/views/campus/admin/Design.vue"),
        },
        {
          path: "popup",
          component: () => import("@/views/campus/admin/popup/Main.vue"),
          children: [
            {
              path: "",
              name: "campus-admin-popup",
              component: () => import("@/views/campus/admin/popup/Home.vue"),
            },
            {
              path: "create",
              name: "campus-admin-popup-create",
              component: () => import("@/views/campus/admin/popup/Create.vue"),
              beforeEnter(to, from, next) {
                if (globalStore.getters["campuses/getPopups"]?.length > 9) {
                  vm.$eventBus.$emit("alert", {
                    open: true,
                    msg: "팝업은 최대 10개까지 만들 수 있습니다.",
                  });
                  return next({
                    name: "campus-admin-popup",
                    params: to.params,
                    query: to.query,
                  });
                }
                next();
              },
            },
            {
              path: ":id",
              name: "campus-admin-popup-edit",
              component: () => import("@/views/campus/admin/popup/Edit.vue"),
            },
          ],
        },
        {
          path: "coupon",
          component: () => import("@/views/campus/admin/coupon/Main.vue"),
          children: [
            {
              path: "",
              name: "campus-admin-coupon",
              component: () => import("@/views/campus/admin/coupon/Home.vue"),
            },
            {
              path: "create",
              name: "campus-admin-coupon-create",
              component: () => import("@/views/campus/admin/coupon/Edit.vue"),
            },
            {
              path: ":id",
              name: "campus-admin-coupon-edit",
              component: () => import("@/views/campus/admin/coupon/Edit.vue"),
            },
          ],
        },
        {
          path: "partners",
          component: () => import("@/views/campus/admin/partners/Main.vue"),
          meta: {
            loadCampusScope: ["PLAN", "SETTING", "SALES", "POLICY"],
          },
          children: [
            {
              path: "",
              name: "campus-admin-partners",
              component: () => import("@/views/campus/admin/partners/Home.vue"),
            },
            // [TODO] 여기에 ROUTE 추가
            // {
            //   path: "example",
            //   name: "campus-admin-partners-example",
            //   component: () => import("@/views/campus/admin/partners/Example.vue"),
            // },
            {
              path: "request",
              name: "campus-admin-partners-request",
              component: () =>
                import("@/views/campus/admin/partners/Request.vue"),
              beforeEnter(to, from, next) {
                const serveYn =
                  globalStore.getters["campuses/checkServeYn"]("PARTNERS");

                // '제휴 마케팅' 부가서비스 구매 완료 후 이용 신청 메뉴 접근 시 파트너 관리 화면으로 이동
                if (serveYn) {
                  return next({
                    name: "campus-admin-partners",
                    params: to.params,
                    query: to.query,
                  });
                }

                next();
              },
            },
            {
              path: "settlement",
              name: "campus-admin-partners-settlement",
              component: () =>
                import("@/views/campus/admin/partners/settlement/Page.vue"),
            },
            {
              path: "share",
              name: "campus-admin-partners-share",
              component: () =>
                import("@/views/campus/admin/partners/Share.vue"),
            },
            {
              path: "share/:id",
              name: "campus-admin-partners-share-detail",
              component: () =>
                import("@/views/campus/admin/partners/ShareDetail.vue"),
            },
            {
              path: "payments",
              name: "campus-admin-partners-payments",
              component: () =>
                import("@/views/campus/admin/partners/Payments.vue"),
            },
            {
              path: "settings",
              name: "campus-admin-partners-settings",
              component: () =>
                import("@/views/campus/admin/partners/Settings.vue"),
            },
            {
              path: "default",
              name: "campus-admin-partners-default",
              component: () =>
                import("@/views/campus/admin/partners/Default.vue"),
            },
            {
              path: "ask",
              name: "campus-admin-partners-ask",
              component: () => import("@/views/campus/admin/partners/Ask.vue"),
            },
            {
              path: "ask/:slug",
              name: "campus-admin-partners-ask-detail",
              component: () =>
                import("@/views/campus/admin/partners/AskDetail.vue"),
            },
            {
              path: "notice",
              component: () =>
                import("@/views/campus/admin/partners/notice/Main.vue"),
              children: [
                {
                  path: "",
                  name: "campus-admin-partners-notice",
                  component: () =>
                    import("@/views/campus/admin/partners/notice/Home"),
                },
                {
                  path: "detail/:id",
                  name: "campus-admin-partners-notice-detail",
                  meta: {
                    memberAuth: {
                      authority: [
                        Authority.CAMPUS_SUPER_MANAGE,
                        Authority.CAMPUS_NOTICE_SUPER_MANAGE,
                      ],
                    },
                  },
                  component: () =>
                    import("@/views/campus/admin/partners/notice/Detail"),
                },
                {
                  path: "write",
                  name: "campus-admin-partners-notice-write",
                  meta: {
                    memberAuth: {
                      authority: [
                        Authority.CAMPUS_SUPER_MANAGE,
                        Authority.CAMPUS_NOTICE_SUPER_MANAGE,
                      ],
                    },
                  },
                  component: () =>
                    import("@/views/campus/admin/partners/notice/Edit"),
                },
                {
                  path: ":id",
                  name: "campus-admin-partners-notice-edit",
                  meta: {
                    memberAuth: {
                      authority: [
                        Authority.CAMPUS_SUPER_MANAGE,
                        Authority.CAMPUS_NOTICE_SUPER_MANAGE,
                      ],
                    },
                  },
                  component: () =>
                    import("@/views/campus/admin/partners/notice/Edit"),
                },
              ],
            },
            {
              path: ":id",
              name: "campus-admin-partners-detail",
              component: () =>
                import("@/views/campus/admin/partners/Detail.vue"),
            },
          ],
        },
        {
          path: "orders",
          component: () => import("@/views/campus/admin/orders/Main.vue"),
          children: [
            {
              path: "",
              name: "campus-admin-orders",
              component: () => import("@/views/campus/admin/orders/Page.vue"),
            },
            {
              path: "manage",
              name: "campus-admin-orders-manage",
              component: () =>
                import("@/views/campus/admin/orders/manage/Page.vue"),
            },
            {
              path: "shipping",
              name: "campus-admin-orders-shipping",
              component: () =>
                import("@/views/campus/admin/orders/shipping/Page.vue"),
            },
            {
              path: "cancel",
              name: "campus-admin-orders-cancel",
              component: () =>
                import("@/views/campus/admin/orders/cancel/Page.vue"),
            },
            {
              path: "exchange",
              name: "campus-admin-orders-exchange",
              component: () =>
                import("@/views/campus/admin/orders/exchange/Page.vue"),
            },
            {
              path: "return",
              name: "campus-admin-orders-return",
              component: () =>
                import("@/views/campus/admin/orders/return/Page.vue"),
            },
          ],
        },
        {
          path: "settlement",
          name: "campus-admin-settlement",
          component: () => import("@/views/campus/admin/Settlement.vue"),
        },
        {
          path: "sales",
          name: "campus-admin-sales",
          meta: {
            loadCampusScope: [
              "PLAN",
              "USAGE",
              "SITE",
              "SETTING",
              "SEO",
              "SALES",
            ],
          },
          component: () => import("@/views/campus/admin/sales/Page.vue"),
        },
        {
          path: "service",
          component: () => import("@/views/campus/admin/service/Main.vue"),
          children: [
            {
              path: "",
              name: "campus-admin-service",
              component: () => import("@/views/campus/admin/service/Page.vue"),
            },
            {
              path: ":type",
              name: "campus-admin-service-detail",
              component: () =>
                import("@/views/campus/admin/service/Detail.vue"),
            },
          ],
        },
        {
          path: "product",
          component: () => import("@/views/campus/admin/product/Main.vue"),
          children: [
            {
              path: "",
              name: "campus-admin-product",
              redirect: { name: "campus-admin-product-sales" },
            },
            {
              path: "sales",
              name: "campus-admin-product-sales",
              component: () =>
                import("@/views/campus/admin/product/HomeSales.vue"),
            },
            {
              path: "basic",
              name: "campus-admin-product-basic",
              component: () =>
                import("@/views/campus/admin/product/HomeBasic.vue"),
            },
            {
              path: ":productId/contents/edit",
              name: "campus-admin-product-detail-contents-edit",
              meta: { customLayout: true },
              component: () =>
                import("@/views/campus/admin/product/detail/ContentsEdit.vue"),
            },
            {
              path: ":productId",
              component: () => import("@/views/campus/admin/product/detail"),
              meta: { customLayout: true },
              children: [
                {
                  path: "",
                  redirect: {
                    name: "campus-admin-product-detail-home",
                  },
                },
                {
                  path: "dashboard",
                  name: "campus-admin-product-detail-home",
                  component: () =>
                    import("@/views/campus/admin/product/detail/Home"),
                },
                {
                  path: "users",
                  name: "campus-admin-product-detail-users",
                  component: () =>
                    import("@/views/campus/admin/product/detail/Users"),
                },
                {
                  path: "users/:id",
                  name: "campus-admin-product-detail-user-detail",
                  component: () =>
                    import("@/views/campus/admin/product/detail/UserDetail"),
                },
                {
                  path: "delegator",
                  name: "campus-admin-product-detail-delegator",
                  component: () =>
                    import("@/views/campus/admin/product/detail/Delegator"),
                },
                {
                  path: "feedback",
                  name: "campus-admin-product-detail-feedback",
                  component: () =>
                    import("@/views/campus/admin/product/detail/FeedBack"),
                },
                {
                  path: "feedback/:contentId/:feedbackId",
                  name: "campus-admin-product-detail-feedback-detail",
                  component: () =>
                    import(
                      "@/views/campus/admin/product/detail/FeedBackDetail"
                    ),
                },
                {
                  path: "promotion",
                  name: "campus-admin-product-detail-promotion",
                  component: () =>
                    import("@/views/campus/admin/product/detail/Promotion"),
                },
                {
                  path: "promotion/write",
                  name: "campus-admin-product-detail-promotion-write",
                  component: () =>
                    import(
                      "@/views/campus/admin/product/detail/PromotionWrite"
                    ),
                },
                {
                  path: "promotion/:promotionId",
                  name: "campus-admin-product-detail-promotion-detail",
                  component: () =>
                    import(
                      "@/views/campus/admin/product/detail/PromotionDetail"
                    ),
                },
                {
                  path: "notices",
                  name: "campus-admin-product-detail-notices",
                  component: () =>
                    import("@/views/campus/admin/product/detail/Notices"),
                },
                {
                  path: "notices/:noticeId",
                  name: "campus-admin-product-detail-notice-detail",
                  component: () =>
                    import("@/views/campus/admin/product/detail/NoticeDetail"),
                },
                {
                  path: "notices/:noticeId/edit",
                  name: "campus-admin-product-detail-notice-edit",
                  component: () =>
                    import("@/views/campus/admin/product/detail/NoticeEdit"),
                },
                {
                  path: "notices/write",
                  name: "campus-admin-product-detail-notice-write",
                  component: () =>
                    import("@/views/campus/admin/product/detail/NoticeWrite"),
                },
                {
                  path: "reviews",
                  name: "campus-admin-product-detail-reviews",
                  component: () =>
                    import("@/views/campus/admin/product/detail/Reviews"),
                  // beforeEnter(to, from, next) {
                  //   const serveYn =
                  //     globalStore.getters["campuses/checkServeYn"](
                  //       "PRODUCT_REVIEW"
                  //     );
                  //   if (!serveYn) {
                  //     vm.$eventBus.$emit("alert", {
                  //       open: true,
                  //       msg: "리뷰 관리를 지원하지 않는 요금제입니다.",
                  //     });
                  //     next(
                  //       from.path === "/" && {
                  //         ...to,
                  //         name: "campus-admin-product-detail-home",
                  //       }
                  //     );
                  //   } else {
                  //     next();
                  //   }
                  // },
                },
                {
                  path: "payments",
                  name: "campus-admin-product-detail-payments",
                  component: () =>
                    import("@/views/campus/admin/product/detail/Payments"),
                },
                {
                  path: "payments/:id",
                  name: "campus-admin-product-detail-payment-detail",
                  component: () =>
                    import("@/views/campus/admin/product/detail/PaymentDetail"),
                },
                {
                  path: "qna",
                  name: "campus-admin-product-detail-qna",
                  component: () =>
                    import("@/views/campus/admin/product/detail/Qna"),
                },
                {
                  path: "settings",
                  name: "campus-admin-product-detail-settings",
                  meta: {
                    customLayout: true,
                  },
                  component: () =>
                    import("@/views/campus/admin/product/detail/Settings"),
                },
                {
                  path: "contents",
                  name: "campus-admin-product-detail-contents",
                  component: () =>
                    import("@/views/campus/admin/product/detail/Contents"),
                },
                {
                  path: "contents/:contentId",
                  name: "campus-admin-product-detail-contents-view",
                  component: () =>
                    import(
                      "@/views/campus/admin/product/detail/ContentsDetail"
                    ),
                },
                {
                  path: "communities",
                  name: "campus-admin-product-detail-communities",
                  component: () =>
                    import("@/views/campus/admin/product/detail/Communities"),
                },
                {
                  path: "discount/group",
                  name: "campus-admin-product-detail-discount-group",
                  component: () =>
                    import("@/views/campus/admin/product/detail/DiscountGroup"),
                },
                {
                  path: "extra",
                  name: "campus-admin-product-detail-extra",
                  component: () =>
                    import(
                      "@/views/campus/admin/product/detail/extra/Page.vue"
                    ),
                },
              ],
            },
            {
              path: ":productId/quiz/:contentId/:memberId",
              name: "campus-admin-product-detail-quiz",
              meta: { customLayout: true },
              component: () =>
                import("@/views/campus/admin/product/detail/Quiz.vue"),
            },
          ],
        },
        {
          path: "goods",
          component: () => import("@/views/campus/admin/goods/Main.vue"),
          children: [
            {
              path: "",
              name: "campus-admin-goods",
              component: () => import("@/views/campus/admin/goods/Page.vue"),
            },
            {
              path: ":productId",
              component: () =>
                import("@/views/campus/admin/goods/detail/Main.vue"),
              meta: { customLayout: true },
              children: [
                {
                  path: "",
                  redirect: {
                    name: "campus-admin-goods-detail-dashboard",
                  },
                },
                {
                  path: "dashboard",
                  name: "campus-admin-goods-detail-dashboard",
                  component: () =>
                    import(
                      "@/views/campus/admin/goods/detail/dashboard/Page.vue"
                    ),
                },
                {
                  path: "settings",
                  name: "campus-admin-goods-detail-settings",
                  component: () =>
                    import(
                      "@/views/campus/admin/goods/detail/settings/Page.vue"
                    ),
                },
                {
                  path: "discount/group",
                  name: "campus-admin-goods-detail-discount-group",
                  component: () =>
                    import(
                      "@/views/campus/admin/goods/detail/discount-group/Page.vue"
                    ),
                },
                {
                  path: "promotion",
                  name: "campus-admin-goods-detail-promotion",
                  component: () =>
                    import(
                      "@/views/campus/admin/goods/detail/promotion/Page.vue"
                    ),
                },
                {
                  path: "promotion/write",
                  name: "campus-admin-goods-detail-promotion-write",
                  component: () =>
                    import(
                      "@/views/campus/admin/goods/detail/promotion/write/Page.vue"
                    ),
                },
                {
                  path: "promotion/:promotionId",
                  name: "campus-admin-goods-detail-promotion-detail",
                  component: () =>
                    import(
                      "@/views/campus/admin/goods/detail/promotion/detail/Page.vue"
                    ),
                },
                {
                  path: "extra",
                  name: "campus-admin-goods-detail-extra",
                  component: () =>
                    import(
                      "@/views/campus/admin/product/detail/extra/Page.vue"
                    ),
                },
                {
                  path: "reviews",
                  name: "campus-admin-goods-detail-reviews",
                  component: () =>
                    import("@/views/campus/admin/product/detail/Reviews"),
                },
                {
                  path: "delegator",
                  name: "campus-admin-goods-detail-delegator",
                  component: () =>
                    import("@/views/campus/admin/product/detail/Delegator"),
                },
              ],
            },
          ],
        },
        {
          path: "reservation",
          component: () => import("@/views/campus/admin/reservation/Main.vue"),
          children: [
            {
              path: "",
              name: "campus-admin-reservation",
              component: () =>
                import("@/views/campus/admin/reservation/Home.vue"),
            },
            {
              path: ":productId",
              component: () =>
                import("@/views/campus/admin/reservation/detail"),
              meta: { customLayout: true },
              children: [
                {
                  path: "",
                  redirect: {
                    name: "campus-admin-reservation-detail-home",
                  },
                },
                {
                  path: "dashboard",
                  name: "campus-admin-reservation-detail-home",
                  component: () =>
                    import("@/views/campus/admin/reservation/detail/Home"),
                },
                {
                  path: "settings",
                  name: "campus-admin-reservation-detail-settings",
                  meta: {
                    customLayout: true,
                  },
                  component: () =>
                    import("@/views/campus/admin/reservation/detail/Settings"),
                },
                {
                  path: "options",
                  name: "campus-admin-reservation-detail-options",
                  component: () =>
                    import("@/views/campus/admin/reservation/detail/Options"),
                },
                {
                  path: "options/:optionId",
                  name: "campus-admin-reservation-detail-options-edit",
                  component: () =>
                    import(
                      "@/views/campus/admin/reservation/detail/OptionsEdit"
                    ),
                },
                {
                  path: "users",
                  name: "campus-admin-reservation-detail-users",
                  component: () =>
                    import("@/views/campus/admin/reservation/detail/Users"),
                },
                {
                  path: "users/:id",
                  name: "campus-admin-reservation-detail-user-detail",
                  component: () =>
                    import(
                      "@/views/campus/admin/reservation/detail/UserDetail"
                    ),
                },
                {
                  path: "payments",
                  name: "campus-admin-reservation-detail-payments",
                  component: () =>
                    import("@/views/campus/admin/reservation/detail/Payments"),
                },
                {
                  path: "payments/:id",
                  name: "campus-admin-reservation-detail-payment-detail",
                  component: () =>
                    import(
                      "@/views/campus/admin/reservation/detail/PaymentDetail"
                    ),
                },
                {
                  path: "reviews",
                  name: "campus-admin-reservation-detail-reviews",
                  component: () =>
                    import("@/views/campus/admin/product/detail/Reviews"),
                },
                {
                  path: "delegator",
                  name: "campus-admin-reservation-detail-delegator",
                  component: () =>
                    import("@/views/campus/admin/product/detail/Delegator"),
                },
                {
                  path: "promotion",
                  name: "campus-admin-reservation-detail-promotion",
                  component: () =>
                    import("@/views/campus/admin/reservation/detail/Promotion"),
                },
                {
                  path: "promotion/write",
                  name: "campus-admin-reservation-detail-promotion-write",
                  component: () =>
                    import(
                      "@/views/campus/admin/reservation/detail/PromotionWrite"
                    ),
                },
                {
                  path: "promotion/:promotionId",
                  name: "campus-admin-reservation-detail-promotion-detail",
                  component: () =>
                    import(
                      "@/views/campus/admin/reservation/detail/PromotionDetail"
                    ),
                },
                {
                  path: "groupPrice/",
                  name: "campus-admin-reservation-group-price",
                  component: () =>
                    import(
                      "@/views/campus/admin/reservation/detail/GroupPrice"
                    ),
                },
                {
                  path: "groupPrice/:optId",
                  name: "campus-admin-reservation-group-price-detail",
                  component: () =>
                    import(
                      "@/views/campus/admin/reservation/detail/GroupPriceDetail"
                    ),
                },
                {
                  path: "extra",
                  name: "campus-admin-reservation-detail-extra",
                  component: () =>
                    import(
                      "@/views/campus/admin/product/detail/extra/Page.vue"
                    ),
                },
              ],
            },
          ],
        },
        {
          path: "promotion",
          component: () => import("@/views/campus/admin/promotion/Main.vue"),
          children: [
            {
              path: "",
              name: "campus-admin-promotion",
              component: () =>
                import("@/views/campus/admin/promotion/Home.vue"),
            },
            {
              path: "write/:productId",
              name: "campus-admin-promotion-write",
              component: () =>
                import("@/views/campus/admin/promotion/Write.vue"),
            },
            {
              path: "edit/:promotionId",
              name: "campus-admin-promotion-edit",
              component: () =>
                import("@/views/campus/admin/promotion/Edit.vue"),
            },
            {
              path: "write/reservation/:productId",
              name: "campus-admin-promotion-reservation-write",
              component: () =>
                import("@/views/campus/admin/promotion/reservation/Write.vue"),
            },
            {
              path: "edit/reservation/:promotionId",
              name: "campus-admin-promotion-reservation-edit",
              component: () =>
                import("@/views/campus/admin/promotion/reservation/Edit.vue"),
            },
            {
              path: "write/goods/:productId",
              name: "campus-admin-promotion-goods-write",
              component: () =>
                import("@/views/campus/admin/promotion/goods/write/Page.vue"),
            },
            {
              path: "edit/goods/:promotionId",
              name: "campus-admin-promotion-goods-edit",
              component: () =>
                import("@/views/campus/admin/promotion/goods/edit/Page.vue"),
            },
          ],
        },
        {
          path: "discount/group",
          name: "campus-admin-discount-group",
          component: () => import("@/views/campus/admin/DiscountGroup.vue"),
        },
        {
          path: "application",
          component: () => import("@/views/campus/admin/application/Main.vue"),
          children: [
            {
              path: "",
              name: "campus-admin-application",
              component: () =>
                import("@/views/campus/admin/application/Home.vue"),
            },
            {
              path: ":productId",
              component: () =>
                import("@/views/campus/admin/application/detail"),
              meta: { customLayout: true },
              children: [
                {
                  path: "",
                  redirect: {
                    name: "campus-admin-application-detail-home",
                  },
                },
                {
                  path: "dashboard",
                  name: "campus-admin-application-detail-home",
                  component: () =>
                    import("@/views/campus/admin/application/detail/Home"),
                },
                {
                  path: "settings",
                  name: "campus-admin-application-detail-settings",
                  component: () =>
                    import("@/views/campus/admin/application/detail/Settings"),
                },
                {
                  path: "results",
                  name: "campus-admin-application-detail-results",
                  component: () =>
                    import("@/views/campus/admin/application/detail/Results"),
                },
                {
                  path: "delegator",
                  name: "campus-admin-application-detail-delegator",
                  component: () =>
                    import("@/views/campus/admin/product/detail/Delegator"),
                },
              ],
            },
            {
              path: ":productId/application/:memberId",
              name: "campus-admin-application-detail-application",
              meta: { customLayout: true },
              component: () =>
                import(
                  "@/views/campus/admin/application/detail/Application.vue"
                ),
            },
          ],
        },
        {
          path: "category",
          name: "campus-admin-category",
          component: () => import("@/views/campus/admin/Category.vue"),
        },
        {
          path: "customer",
          name: "campus-admin-customer-info",
          component: () => import("@/views/campus/admin/CustomerInfo.vue"),
        },
        {
          path: "deliveries",
          component: () => import("@/views/campus/admin/deliveries"),
          children: [
            {
              path: "",
              name: "campus-admin-deliveries",
              component: () =>
                import("@/views/campus/admin/deliveries/Home.vue"),
            },
            {
              path: "create",
              name: "campus-admin-deliveries-create",
              component: () =>
                import("@/views/campus/admin/deliveries/return/Page.vue"),
            },
            {
              path: "edit/:locationId",
              name: "campus-admin-deliveries-edit",
              component: () =>
                import("@/views/campus/admin/deliveries/return/Page.vue"),
            },
            {
              path: "template/create",
              name: "campus-admin-deliveries-template-create",
              component: () =>
                import("@/views/campus/admin/deliveries/template/Page.vue"),
            },
            {
              path: "template/edit/:templateId",
              name: "campus-admin-deliveries-template-edit",
              component: () =>
                import("@/views/campus/admin/deliveries/template/Page.vue"),
            },
          ],
        },
        {
          path: "content",
          component: () => import("@/views/campus/admin/content"),
          children: [
            {
              path: "",
              name: "campus-admin-content",
              component: () => import("@/views/campus/admin/content/Home.vue"),
            },
            {
              path: "create",
              name: "campus-admin-content-create",
              meta: {
                customLayout: true,
                memberAuth: {
                  authority: [
                    Authority.CAMPUS_SUPER_MANAGE,
                    Authority.PRODUCT_SUPER_MANAGE,
                    Authority.PRODUCT_CREATE,
                  ],
                },
              },
              component: () =>
                import("@/views/campus/admin/content/Create.vue"),
            },
            {
              path: "edit/:contentId",
              name: "campus-admin-content-edit",
              meta: { customLayout: true },
              component: () => import("@/views/campus/admin/content/Edit.vue"),
            },
          ],
        },
        {
          path: "payment",
          component: () =>
            import("@/views/campus/admin/product/payment/Main.vue"),
          children: [
            {
              path: "",
              name: "campus-admin-payment",
              component: () =>
                import("@/views/campus/admin/product/payment/Page.vue"),
            },
            {
              path: "method",
              name: "campus-admin-payment-management",
              component: () =>
                import("@/views/campus/admin/product/payment/Management.vue"),
            },
            {
              path: ":id",
              name: "campus-admin-payment-detail",
              component: () =>
                import("@/views/campus/admin/product/payment/Detail.vue"),
            },
          ],
        },
        {
          path: "reply",
          component: () => import("@/views/campus/admin/reply/Main.vue"),
          children: [
            {
              path: "",
              name: "campus-admin-reply",
              component: () => import("@/views/campus/admin/reply/Home.vue"),
            },
            {
              path: ":productId/:contentId",
              name: "campus-admin-reply-view",
              component: () => import("@/views/campus/admin/reply/View.vue"),
              beforeEnter(to, from, next) {
                if (!to.params.productId || !to.params.contentId) {
                  vm.$eventBus.$emit("alert", {
                    open: true,
                    msg: "콘텐츠 정보를 찾을 수 없습니다.",
                  });
                  return next({
                    name: "campus-admin-reply",
                    params: from.params,
                  });
                }
                next();
              },
            },
          ],
        },
        {
          path: "review",
          component: () => import("@/views/campus/admin/review/Main.vue"),
          children: [
            {
              path: "",
              name: "campus-admin-review",
              component: () => import("@/views/campus/admin/review/Home.vue"),
            },
            // {
            //   path: ":productId/:contentId",
            //   name: "campus-admin-review-view",
            //   component: () => import("@/views/campus/admin/review/View.vue"),
            //   beforeEnter(to, from, next) {
            //     if (!to.params.productId || !to.params.contentId) {
            //       vm.$eventBus.$emit("alert", {
            //         open: true,
            //         msg: "콘텐츠 정보를 찾을 수 없습니다.",
            //       });
            //       return next({
            //         name: "campus-admin-review",
            //         params: from.params,
            //       });
            //     }
            //     next();
            //   },
            // },
          ],
        },
        {
          path: "community",
          component: () => import("@/views/campus/admin/community/Main.vue"),
          children: [
            {
              path: "",
              name: "campus-admin-community",
              component: () =>
                import("@/views/campus/admin/community/Home.vue"),
            },
            {
              path: ":communityId",
              meta: {
                customLayout: true,
                memberAuth: {
                  authority: [
                    Authority.CAMPUS_SUPER_MANAGE,
                    Authority.COMMUNITY_SUPER_MANAGE,
                  ],
                },
              },
              component: () =>
                import("@/views/campus/admin/community/dashboard"),
              children: [
                {
                  path: "",
                  redirect: {
                    name: "campus-admin-community-dashboard",
                  },
                },
                {
                  path: "dashboard",
                  name: "campus-admin-community-dashboard",
                  component: () =>
                    import("@/views/campus/admin/community/dashboard/Home"),
                },
                {
                  path: "edit",
                  name: "campus-admin-community-dashboard-settings",
                  component: () =>
                    import("@/views/campus/admin/community/dashboard/Settings"),
                },
                {
                  path: "notice",
                  name: "campus-admin-community-dashboard-notice",
                  component: () =>
                    import("@/views/campus/admin/community/dashboard/Notice"),
                },
                {
                  path: "notice/write",
                  name: "campus-admin-community-dashboard-notice-write",
                  component: () =>
                    import(
                      "@/views/campus/admin/community/dashboard/NoticeWrite"
                    ),
                },
                {
                  path: "notice/:noticeId",
                  name: "campus-admin-community-dashboard-notice-view",
                  component: () =>
                    import(
                      "@/views/campus/admin/community/dashboard/NoticeDetail"
                    ),
                },
                {
                  path: "notice/edit/:noticeId",
                  name: "campus-admin-community-dashboard-notice-edit",
                  component: () =>
                    import(
                      "@/views/campus/admin/community/dashboard/NoticeEdit"
                    ),
                },
                {
                  path: "post",
                  name: "campus-admin-community-dashboard-post",
                  component: () =>
                    import("@/views/campus/admin/community/dashboard/Post"),
                },
                {
                  path: "post/write",
                  name: "campus-admin-community-dashboard-post-write",
                  component: () =>
                    import(
                      "@/views/campus/admin/community/dashboard/PostWrite"
                    ),
                },
                {
                  path: "post/:postId",
                  name: "campus-admin-community-dashboard-post-view",
                  component: () =>
                    import(
                      "@/views/campus/admin/community/dashboard/PostDetail"
                    ),
                },
                {
                  path: "post/edit/:postId",
                  name: "campus-admin-community-dashboard-post-edit",
                  component: () =>
                    import("@/views/campus/admin/community/dashboard/PostEdit"),
                },
                {
                  path: "member",
                  name: "campus-admin-community-dashboard-member",
                  component: () =>
                    import("@/views/campus/admin/community/dashboard/Member"),
                },
                {
                  path: "product",
                  name: "campus-admin-community-dashboard-product",
                  component: () =>
                    import("@/views/campus/admin/community/dashboard/Product"),
                },
              ],
            },
          ],
        },
        {
          path: "authority",
          name: "campus-admin-authority",
          component: () => import("@/views/campus/admin/Authority.vue"),
        },
        {
          path: "notice",
          component: () => import("@/views/campus/admin/notice/Main.vue"),
          children: [
            {
              path: "",
              name: "campus-admin-notice",
              component: () => import("@/views/campus/admin/notice/Home.vue"),
            },
            {
              path: "category",
              name: "campus-admin-notice-category",
              component: () =>
                import("@/views/campus/admin/notice/Category.vue"),
            },
            {
              path: "detail/:id",
              name: "campus-admin-notice-detail",
              meta: {
                memberAuth: {
                  authority: [
                    Authority.CAMPUS_SUPER_MANAGE,
                    Authority.CAMPUS_NOTICE_SUPER_MANAGE,
                  ],
                },
              },
              component: () => import("@/views/campus/admin/notice/Detail.vue"),
            },
            {
              path: "write",
              name: "campus-admin-notice-write",
              meta: {
                memberAuth: {
                  authority: [
                    Authority.CAMPUS_SUPER_MANAGE,
                    Authority.CAMPUS_NOTICE_SUPER_MANAGE,
                  ],
                },
              },
              component: () => import("@/views/campus/admin/notice/Edit.vue"),
            },
            {
              path: ":id",
              name: "campus-admin-notice-edit",
              meta: {
                memberAuth: {
                  authority: [
                    Authority.CAMPUS_SUPER_MANAGE,
                    Authority.CAMPUS_NOTICE_SUPER_MANAGE,
                  ],
                },
              },
              component: () => import("@/views/campus/admin/notice/Edit.vue"),
            },
          ],
        },
        {
          path: "users",
          component: () => import("@/views/campus/admin/users"),
          children: [
            {
              path: "",
              component: () => import("@/views/campus/admin/users"),
              children: [
                {
                  path: "",
                  name: "campus-admin-users",
                  component: () =>
                    import("@/views/campus/admin/users/Home.vue"),
                },
                {
                  path: ":userId",
                  component: () =>
                    import("@/views/campus/admin/users/detail/Main"),
                  children: [
                    {
                      path: "",
                      name: "campus-admin-user-detail",
                      component: () =>
                        import("@/views/campus/admin/users/detail/Home"),
                    },
                    {
                      path: "posts/:postId",
                      name: "campus-admin-user-detail-post-detail",
                      component: () =>
                        import("@/views/campus/admin/users/detail/PostDetail"),
                    },
                    {
                      path: "payment/:id/:paymentId",
                      name: "campus-admin-user-detail-payment-detail",
                      component: () =>
                        import(
                          "@/views/campus/admin/users/detail/PaymentDetail"
                        ),
                    },
                  ],
                },
              ],
            },
            {
              path: "groups",
              component: () => import("@/views/campus/admin/users/group"),
              children: [
                {
                  path: ":groupSk",
                  name: "campus-admin-user-group-detail",
                  component: () =>
                    import("@/views/campus/admin/users/group/Home"),
                },
                {
                  path: ":groupSk/users/:userId",
                  name: "campus-admin-group-user-detail",
                  component: () =>
                    import("@/views/campus/admin/users/detail/Home"),
                },
                {
                  path: ":groupSk/users/:userId/posts/:postId",
                  name: "campus-admin-group-user-detail-post-detail",
                  component: () =>
                    import("@/views/campus/admin/users/detail/PostDetail"),
                },
                {
                  path: ":groupSk/users/:userId/payment/:id/:paymentId",
                  name: "campus-admin-group-user-detail-payment-detail",
                  component: () =>
                    import("@/views/campus/admin/users/detail/PaymentDetail"),
                },
              ],
            },
          ],
        },
        {
          path: "addr",
          component: () => import("@/views/campus/admin/addr/Main.vue"),
          children: [
            {
              path: "",
              name: "campus-admin-addr",
              component: () => import("@/views/campus/admin/addr/Home.vue"),
            },
            {
              path: "search",
              name: "campus-admin-addr-search",
              component: () => import("@/views/campus/admin/addr/Search.vue"),
            },
            {
              path: ":addrSk",
              name: "campus-admin-addr-detail",
              component: () => import("@/views/campus/admin/addr/Detail.vue"),
            },
          ],
        },
        {
          path: "staff",
          component: () => import("@/views/campus/admin/staffs"),
          children: [
            {
              path: "",
              name: "campus-admin-staff",
              component: () => import("@/views/campus/admin/staffs/Home.vue"),
            },
            {
              path: ":userId",
              component: () =>
                import("@/views/campus/admin/staffs/detail/Main.vue"),
              children: [
                {
                  path: "",
                  name: "campus-admin-staff-detail",
                  component: () =>
                    import("@/views/campus/admin/staffs/detail/Home"),
                },
                {
                  path: "posts/:postId",
                  name: "campus-admin-staff-detail-post-detail",
                  component: () =>
                    import("@/views/campus/admin/staffs/detail/PostDetail"),
                },
              ],
            },
          ],
        },
        {
          path: "admission",
          name: "campus-admin-admission",
          component: () => import("@/views/campus/admin/Admission.vue"),
        },
        {
          path: "plan",
          component: () => import("@/views/campus/admin/plan/Main.vue"),
          children: [
            {
              path: "",
              name: "campus-admin-plan",
              component: () => import("@/views/campus/admin/plan/Home.vue"),
            },
            {
              path: "checkout",
              name: "campus-admin-plan-checkout",
              meta: {
                memberAuth: {
                  role: Role.A,
                },
              },
              component: () => import("@/views/campus/admin/plan/Checkout.vue"),
              beforeEnter(_, __, next) {
                if (
                  globalStore.getters["campuses/getPlan"]?.terminateYn ||
                  globalStore.getters["campuses/getPlan"]?.refundYn
                ) {
                  vm.$eventBus.$emit("alert", {
                    open: true,
                    title: "요금 플랜 변경",
                    msg: "요금 플랜 해지 예약 또는 환불 요청 시, 변경 불가합니다. 요금 플랜 해지 예약 또는 환불 요청을 취소한 후 진행해주세요.",
                  });
                  next(getCampusHomeRoute("campus-admin-plan"));
                  return;
                }
                if (
                  (!globalStore.getters["campuses/getCampusInfo"]?.batchYn &&
                    !globalStore.getters["campuses/isFreePlan"]) ||
                  globalStore.getters["campuses/isEnterprisePlan"] ||
                  globalStore.getters["campuses/isTrialPlan"]
                ) {
                  vm.$eventBus.$emit("alert", {
                    open: true,
                    title: "요금 플랜 변경",
                    msg: "요금 플랜 변경을 원하시면 고객센터에 문의해주세요.",
                    csLink: true,
                  });
                  next(getCampusHomeRoute("campus-admin-plan"));
                  return;
                }
                next();
              },
            },
            {
              path: "checkout-complete/:slug",
              meta: {
                memberAuth: {
                  role: Role.A,
                },
              },
              name: "campus-admin-plan-checkout-complete",
              component: () =>
                import("@/views/campus/admin/plan/CheckoutComplete.vue"),
            },
          ],
        },
        {
          path: "addon",
          component: () => import("@/views/campus/admin/addon/Main.vue"),
          children: [
            {
              path: "",
              name: "campus-admin-addon",
              component: () => import("@/views/campus/admin/addon/Home.vue"),
            },
            {
              path: "checkout/:slug",
              name: "campus-admin-addon-checkout",
              meta: {
                memberAuth: {
                  role: Role.A,
                },
              },
              component: () =>
                import("@/views/campus/admin/addon/Checkout.vue"),
              beforeEnter(_, __, next) {
                if (globalStore.getters["campuses/getPlan"]?.terminateYn) {
                  vm.$eventBus.$emit("alert", {
                    open: true,
                    title: "부가 서비스 변경",
                    msg: "요금 플랜 해지 예약 시, 부가 서비스는 변경 불가합니다. 부가 서비스 변경을 원하시면 요금 플랜 해지 예약을 취소한 후 진행해주세요.",
                  });
                  next(getCampusHomeRoute("campus-admin-addon"));
                  return;
                }
                if (
                  (!globalStore.getters["campuses/getCampusInfo"]?.batchYn &&
                    !globalStore.getters["campuses/isFreePlan"]) ||
                  globalStore.getters["campuses/isEnterprisePlan"] ||
                  globalStore.getters["campuses/isTrialPlan"]
                ) {
                  vm.$eventBus.$emit("alert", {
                    open: true,
                    title: "부가 서비스 변경",
                    msg: "부가 서비스 변경을 원하시면 고객센터에 문의해주세요.",
                    csLink: true,
                  });
                  next(getCampusHomeRoute("campus-admin-addon"));
                  return;
                }
                next();
              },
            },
          ],
        },
        {
          path: "plan/historeis",
          name: "campus-admin-plan-histories",
          component: () => import("@/views/campus/admin/PlanHistories.vue"),
        },
        {
          path: "plan/credit",
          name: "campus-admin-credit-histories",
          component: () => import("@/views/campus/admin/CreditHistories.vue"),
        },
        {
          path: "header",
          name: "campus-admin-header",
          component: () => import("@/views/campus/admin/Header.vue"),
        },
        {
          path: "email",
          name: "campus-admin-email",
          component: () => import("@/views/campus/admin/Email.vue"),
        },
        {
          path: "mail",
          component: () => import("@/views/campus/admin/mail/Main.vue"),
          children: [
            {
              path: "",
              name: "campus-admin-mail",
              component: () => import("@/views/campus/admin/mail/Home.vue"),
            },
            {
              path: "create/:id",
              name: "campus-admin-mail-edit",
              meta: {
                customLayout: true,
              },
              component: () => import("@/views/campus/admin/mail/Create.vue"),
            },
            {
              path: "create/:id/design",
              name: "campus-admin-mail-edit-design",
              meta: {
                customLayout: true,
              },
              component: () => import("@/views/campus/admin/Blocks.vue"),
            },
            {
              path: ":id",
              component: () => import("@/views/campus/admin/mail/detail"),
              meta: { customLayout: true },
              children: [
                {
                  path: "",
                  redirect: {
                    name: "campus-admin-mail-detail-home",
                  },
                },
                {
                  path: "report",
                  name: "campus-admin-mail-detail-home",
                  component: () =>
                    import("@/views/campus/admin/mail/detail/Home"),
                },
                // 캠페인 - 이메일 2차 업데이트 개발 예정
                {
                  path: "results",
                  name: "campus-admin-mail-detail-results",
                  component: () =>
                    import("@/views/campus/admin/mail/detail/Results"),
                },
                {
                  path: "results/:sk",
                  name: "campus-admin-mail-detail-results-activities",
                  component: () =>
                    import(
                      "@/views/campus/admin/mail/detail/ResultsActivities"
                    ),
                },
                {
                  path: "campaign",
                  name: "campus-admin-mail-detail-campaign",
                  component: () =>
                    import("@/views/campus/admin/mail/detail/Campaign"),
                },
              ],
            },
          ],
        },
        {
          path: "ask",
          component: () => import("@/views/campus/admin/ask/Main.vue"),
          children: [
            {
              path: "",
              name: "campus-admin-ask",
              component: () => import("@/views/campus/admin/ask/Home.vue"),
            },
            {
              path: "view/:slug",
              name: "campus-admin-ask-view",
              component: () => import("@/views/campus/admin/ask/View.vue"),
            },
          ],
        },
        {
          path: "faq",
          component: () => import("@/views/campus/admin/faq/Main.vue"),
          children: [
            {
              path: "",
              name: "campus-admin-faq",
              component: () => import("@/views/campus/admin/faq/Home.vue"),
            },
            {
              path: "categories",
              name: "campus-admin-faq-categories",
              component: () =>
                import("@/views/campus/admin/faq/Categories.vue"),
            },
            {
              path: "write",
              name: "campus-admin-faq-write",
              component: () => import("@/views/campus/admin/faq/Edit.vue"),
            },
            {
              path: "edit/:slug",
              name: "campus-admin-faq-edit",
              component: () => import("@/views/campus/admin/faq/Edit.vue"),
            },
          ],
        },
        {
          path: "class",
          name: "campus-admin-class-setting",
          component: () => import("@/views/campus/admin/ClassSetting.vue"),
          children: [],
        },
        {
          path: "my",
          meta: { authRequired: true },
          component: () => import("@/views/my"),
          children: [
            {
              path: "",
              name: "campus-admin-my",
              redirect: { name: "campus-admin-account" },
            },
            {
              path: "notification",
              name: "campus-admin-notification",
              meta: { authRequired: true },
              component: () => import("@/views/my/Notification.vue"),
            },
            {
              path: "account",
              name: "campus-admin-account",
              meta: { authRequired: true },
              component: () => import("@/views/my/account/Page.vue"),
            },
          ],
        },
      ],
    },
    {
      path: "partners",
      meta: {
        showGlobalMenu: false,
        authRequired: true,
        isPartners: true,
        memberAuth: {
          role: Role.D,
        },
      },
      component: () => import("@/views/campus/partners/Main.vue"),
      beforeEnter(to, from, next) {
        const member = globalStore.getters["members/getMemberItem"];
        if (!member?.partnerStatus) {
          return next({
            name: "campus-partners-landing",
            params: to.params,
            query: to.query,
          });
        }
        return next();
      },
      children: [
        {
          path: "",
          name: "campus-partners",
          redirect: { name: "campus-partners-dashboard" },
        },
        {
          path: "await",
          name: "campus-partners-await",
          component: () => import("@/views/campus/partners/await/Page.vue"),
          beforeEnter(to, from, next) {
            const member = globalStore.getters["members/getMemberItem"];
            if (
              [PartnerStatus.JOIN, PartnerStatus.DISABLED].includes(
                member?.partnerStatus
              )
            ) {
              return next({
                name: "campus-partners-dashboard",
                params: to.params,
                query: to.query,
              });
            }
            return next();
          },
        },
        {
          path: "dashboard",
          name: "campus-partners-dashboard",
          component: () => import("@/views/campus/partners/dashboard/Page.vue"),
          beforeEnter(to, from, next) {
            const member = globalStore.getters["members/getMemberItem"];
            if (
              ![PartnerStatus.JOIN, PartnerStatus.DISABLED].includes(
                member?.partnerStatus
              )
            ) {
              return next({
                name: "campus-partners-await",
                params: to.params,
                query: to.query,
              });
            }
            return next();
          },
        },
        {
          path: "product",
          name: "campus-partners-product",
          component: () => import("@/views/campus/partners/product/Page.vue"),
        },
        {
          path: "product/:id",
          name: "campus-partners-product-detail",
          component: () =>
            import("@/views/campus/partners/product/[id]/Page.vue"),
        },
        {
          path: "link",
          name: "campus-partners-link",
          component: () => import("@/views/campus/partners/link/Page.vue"),
        },
        {
          path: "recommender",
          name: "campus-partners-recommender",
          component: () =>
            import("@/views/campus/partners/recommender/Page.vue"),
        },
        {
          path: "recommender/products",
          name: "campus-partners-recommender-products",
          component: () =>
            import("@/views/campus/partners/recommender/products/Page.vue"),
        },
        {
          path: "report/sales",
          name: "campus-partners-report-sales",
          component: () =>
            import("@/views/campus/partners/report/sales/Page.vue"),
        },
        {
          path: "report/user",
          name: "campus-partners-report-user",
          component: () =>
            import("@/views/campus/partners/report/users/Page.vue"),
        },
        {
          path: "settlement",
          name: "campus-partners-settlement",
          component: () =>
            import("@/views/campus/partners/settlement/Page.vue"),
        },
        {
          path: "channel",
          name: "campus-partners-channel",
          component: () => import("@/views/campus/partners/channel/Page.vue"),
        },
        {
          path: "help/notice",
          name: "campus-partners-help-notice",
          component: () =>
            import("@/views/campus/partners/help/notice/Page.vue"),
        },
        {
          path: "help/notice/:id",
          name: "campus-partners-help-notice-detail",
          component: () =>
            import("@/views/campus/partners/help/notice/[id]/Page.vue"),
        },
        {
          path: "help/guide",
          name: "campus-partners-help-guide",
          component: () =>
            import("@/views/campus/partners/help/guide/Page.vue"),
        },
        {
          path: "help/guide/view",
          name: "campus-partners-help-guide-view",
          component: () =>
            import("@/views/campus/partners/help/guide/view/Page.vue"),
        },
        {
          path: "help/ask",
          name: "campus-partners-help-ask",
          component: () => import("@/views/campus/partners/help/ask/Page.vue"),
        },
        {
          path: "help/ask/write",
          name: "campus-partners-help-ask-write",
          component: () =>
            import("@/views/campus/partners/help/ask/write/Page.vue"),
        },
        {
          path: "help/ask/:id",
          name: "campus-partners-help-ask-detail",
          component: () =>
            import("@/views/campus/partners/help/ask/[id]/Page.vue"),
        },
        {
          path: "help/terms",
          name: "campus-partners-help-terms",
          component: () =>
            import("@/views/campus/partners/help/terms/Page.vue"),
        },
        {
          path: "type",
          name: "campus-partners-type",
          component: () =>
            import("@/views/campus/partners/partners-type/Page.vue"),
        },
        {
          path: "bank-account",
          name: "campus-partners-bank-account",
          component: () =>
            import("@/views/campus/partners/bank-account/Page.vue"),
        },
        {
          path: "my",
          component: () => import("@/views/my"),
          children: [
            {
              path: "",
              name: "campus-partners-my",
              redirect: { name: "campus-partners-account" },
            },
            {
              path: "notification",
              name: "campus-partners-notification",
              component: () => import("@/views/my/Notification.vue"),
            },
            {
              path: "account",
              name: "campus-partners-account",
              component: () => import("@/views/my/account/Page.vue"),
            },
          ],
        },
        {
          path: "*",
          name: "campus-partners-not-found",
          component: () => import("@/views/campus/partners/NotFound.vue"),
        },
      ],
    },
    // 이지웰
    {
      path: "ezwel",
    },
  ],
};

const back = (to, from, next) => {
  const notAuthRoutes = ![
    "campus-join-select",
    "campus-staff-join",
    "campus-member-join",
    "campus-join-terms-agree",
    "campus-join-form",
    "campus-join-code",
    "campus-login-select",
    "campus-reset-password",
  ].includes(from?.name);
  if (from?.name && notAuthRoutes) {
    return next(from);
  }
  return next(getCampusHomeRoute());
};
