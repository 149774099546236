<script setup>
import { ref, toRefs } from "vue";
import { useMouseInElement } from "@vueuse/core";
import colors from "@/utils/colors";
import LinkItem from "./LinkItem.vue";

const target = ref(null);
const target2 = ref(null);
const { isOutside } = useMouseInElement(target);
const { isOutside: isOutsidee } = useMouseInElement(target2);

const props = defineProps({
  text: {
    type: String,
    default: "",
  },
  items: {
    type: Array,
    default: () => [],
  },
});

const { text } = toRefs(props);
</script>

<template>
  <li
    class="nav-item"
    ref="target"
    :class="{ 'is-hover': !isOutside || !isOutsidee }"
  >
    <div class="menu">
      <span class="text">
        {{ text }}
      </span>
      <v-icon
        size="24"
        class="sm-icon"
        :color="
          isOutside && isOutsidee ? colors.gray.darken3 : colors.primary.base
        "
        >$down-sm</v-icon
      >
    </div>
    <div class="menus" ref="target2">
      <ul class="list">
        <LinkItem v-for="item in items" :key="item.name" :item="item" />
      </ul>
    </div>
  </li>
</template>

<style lang="scss" scoped>
.nav-item {
  position: relative;
  display: flex;
  justify-content: center;
  .menu {
    display: flex;
    align-items: center;
    height: 28px;
    gap: 4px;
    cursor: pointer;
    position: relative;
    color: $black;
    font-size: 14px;
    &::before {
      content: "";
      position: absolute;
      background-color: $primary;
      width: calc(100% - 4px);
      height: 2px;
      left: 0px;
      bottom: -2px;
      visibility: hidden;
      transform: scaleX(0);
      transition: all 0.3s ease-in-out;
    }
  }
  .menus {
    display: none;
    position: absolute;
    top: 28px;
    padding-top: 8px;
    opacity: 0;
    animation: 0.3s ease-in-out 0s 1 normal forwards running fadein;
  }
  &:hover {
    .sm-icon {
      transform: rotate(-180deg);
    }
    .menu {
      color: $primary;
      transition: all 0.3s ease-in-out;
      &::before {
        visibility: visible;
        transform: scaleX(1);
      }
    }
    .menus {
      display: flex;
      flex-direction: column;
      opacity: 1;
    }
  }
}
.list {
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  border-radius: 4px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  width: 480px;
  padding: 20px 0;
  background-color: $white;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
