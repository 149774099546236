<template>
  <v-overlay
    :value="containerActive"
    :opacity="0"
    color="black"
    scroll-strategy="block"
  >
    <v-dialog
      :fullscreen="isMobile"
      :height="isMobile ? '100dvh' : null"
      :value="containerActive"
      content-class="dialog-container hide-scrollbar"
      @click:outside="closeNotification"
      @keydown.esc="closeNotification"
    >
      <div
        :class="container_class"
        class="notification-container sticky-bar hide-scrollbar"
      >
        <div class="w-full d-flex align-center justify-space-between">
          <h5 class="text-h5-medium">알림</h5>
          <p-icon-btn @click="closeNotification" x-small />
        </div>
        <p-link
          @click="clickDeleteNotification"
          color="gray"
          class="ml-auto mt-3"
          >전체 삭제</p-link
        >
        <notification-list class="notification-list" />
      </div>
    </v-dialog>
  </v-overlay>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NotificationList from "@/components/layout/notification/Notification-List.vue";

export default {
  name: "Notification",
  components: {
    NotificationList,
  },
  computed: {
    ...mapGetters({
      containerActive: "notifications/getContainerActive",
      isMobile: "common/isMobile",
    }),
    container_class() {
      return {
        active: this.containerActive,
      };
    },
  },
  data() {
    return {
      pendingDelete: false,
    };
  },
  methods: {
    ...mapActions({
      reqDeleteAllNotifications: "notifications/reqDeleteAllNotifications",
      resetNotifications: "notifications/resetNotifications",
      toggleContainerActive: "notifications/toggleContainerActive",
    }),
    clickDeleteNotification() {
      if (!this.pendingDelete) {
        this.pendingDelete = true;
        this.deleteAllNotifications();
      }
    },
    async deleteAllNotifications() {
      await this.reqDeleteAllNotifications();
      this.pendingDelete = false;
    },
    closeNotification() {
      this.toggleContainerActive();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .dialog-container {
  width: auto;
  @media (max-width: $mdBreakPoint) {
    width: 100%;
  }
}

.notification-container {
  display: flex;
  flex-direction: column;
  width: 440px;
  height: 680px;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid $gray3;
  background-color: $white;
  @media (max-width: $mdBreakPoint) {
    width: 100%;
    height: 100%;
  }
  .notification__wrapper {
    scrollbar-width: unset;
    scrollbar-width: none;
    margin-top: 12px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
</style>
