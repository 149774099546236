import { endpoint } from "@/config";

export async function getSupports(supportType, userUuid, site) {
  const params = {
    userUuid,
    supportType,
  };
  if (site) {
    params.site = site;
  } else {
    params.site = "POINCAMPUS";
  }
  return this.getMethod("get", false, {
    url: `${endpoint.supports}/supports`,
    params,
  });
}

export async function postSupport({
  supportType,
  subject,
  askType,
  userUuid,
  regUser,
  site,
}) {
  const data = {
    supportType,
    subject,
    askType,
    userUuid,
    regUser,
  };
  if (site) {
    data.site = site;
  } else {
    data.site = "POINCAMPUS";
  }
  return this.getMethod("post", true, {
    url: `${endpoint.supports}/supports`,
    data,
  });
}
