import { endpointV2 as endpoint } from "@/config";

export async function getDesign({ campusId, memberId }) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.histories}/design`,
    params,
  });
}

export async function setDesign({ campusId, memberId, pubStatus, menus }) {
  if (!menus || menus.length < 1) {
    return {
      success: false,
    };
  }
  const data = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(pubStatus && { pubStatus }),
    ...(menus && { menus }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.histories}/design`,
    data,
  });
}

export async function getDesignHistories({ campusId, memberId }) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.histories}/design/history`,
    params,
  });
}

export async function restoreDesign({ campusId, memberId, versionId, menus }) {
  const data = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    versionId,
    menus,
  };
  return this.getMethod("post", true, {
    url: `${endpoint.histories}/design/restore`,
    data,
  });
}

export async function getDesignTemplates({ campusId, memberId, versionId }) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(versionId && { versionId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.histories}/design/template`,
    params,
  });
}

export async function getPartnersConsent({ campusId, memberId }) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.histories}/partners/consent`,
    params,
  });
}

/**
 * Sends a POST request to create a partners link.
 *
 * @param {Object} options - The options for creating the partners link.
 * @param {string} options.campusId - The ID of the campus.
 * @param {string} options.referrer - The referrer.
 * @param {string} options.linkType - The type of the link. Must be either "PRODUCT" or "PAGE".
 * @param {string} options.linkTargetName - The name of the link target.
 * @param {string} options.linkTargetId - The ID of the link target.
 * @param {string} options.url - The URL.
 * @return {Promise} A promise that resolves to the response of the POST request.
 */
export async function postPartnersLink({
  campusId,
  referrer,
  linkType,
  linkTargetName,
  linkTargetId,
  url,
}) {
  const data = {
    ...(campusId && { campusId }),
    ...(referrer && { referrer }),
    ...(linkType && { linkType }),
    ...(linkTargetName && { linkTargetName }),
    ...(linkTargetId && { linkTargetId }),
    ...(url && { url }),
  };
  return this.getMethod("post", false, {
    url: `${endpoint.histories}/partners/link`,
    data,
  });
}

export async function getPartnerLinkLogs({
  campusId,
  memberId,
  partnerId,
  startDate,
  endDate,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(startDate && { startDate }),
    ...(endDate && { endDate }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.histories}/partners/link/${partnerId}`,
    params,
  });
}
