import { endpointV2 as endpoint } from "@/config";

// http://swagger.poincampus.com/?urls.primaryName=14.%EB%B9%84%EB%94%94%EC%98%A4%20%EA%B4%80%EB%A6%AC#/

/*
==============================
            비디오 관리
==============================
*/

export async function getOriginalVideoUrl({ memberId, videoId, campusId }) {
  const params = {
    campusId,
    memberId,
    videoId,
  };
  return this.getMethod("get", true, {
    url: `${endpoint.transcodes}/url/${videoId}`,
    params,
  });
}
