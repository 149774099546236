import { endpoint } from "@/config";

// get configure
export async function getConfigure(configType) {
  const params = {
    configType,
  };
  return this.getMethod("get", false, {
    url: `${endpoint.configure}/configure`,
    params,
  });
}

// get events
export async function getEvents({ configType, viewYn }) {
  const params = {
    configType,
  };
  if (viewYn !== null) {
    params.viewYn = viewYn;
  }
  return this.getMethod("get", false, {
    url: `${endpoint.configure}/configure`,
    params,
  });
}
