import globalStore from "@/store";
import { useAlert } from "@/stores/alert";

export default (apiInstance) => ({
  async reqGetGroups(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      name,
      first,
      keyword,
    } = payload;
    const result = await apiInstance.groups.getGroups({
      campusId,
      memberId,
      ...(name && { name }),
      ...(keyword && { keyword }),
    });
    if (result.success) {
      store.commit("setUserGroups", result.data);
    }
    return result;
  },
  async postGroup(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      name,
      memberId,
      desc,
    } = payload;
    if (!name || !campusId) {
      return;
    }
    const result = await apiInstance.groups.postGroup({
      ...(campusId && { campusId }),
      ...(memberId && { memberId }),
      ...(name && { name }),
      ...(desc && { desc }),
    });
    return result;
  },
  reqDeleteGroup: async (store, payload) => {
    const { sk } = payload;
    const data = {
      sk,
    };
    const result = await apiInstance.groups.deleteGroup(data);
    if (result.success) {
      store.commit("deleteUserGroup", data.sk);
    } else {
      if (result.name === "ACCESS_DENIED") {
        globalStore.dispatch("common/setAlert", {
          open: true,
          msg: "접근이 제한된 페이지입니다.",
        });
      }
    }
    return result;
  },
  async reqGetGroup(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      name,
      sk,
    } = payload;
    // store.commit("clearUserGroups");
    // const lastKey = store.getters["getCampusMembersLastKey"];
    const result = await apiInstance.groups.getGroup({
      campusId,
      memberId,
      ...(name && { name }),
      ...(sk && { sk }),
    });
    if (result.success) {
      store.commit("setGroup", result.data);
      // store.commit("setCampusMembersLastKey", lastKey);
    }
    return result;
  },
  async putGroup(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      sk,
      name,
      desc,
    } = payload;
    const result = await apiInstance.groups.putGroup({
      ...(sk && { sk }),
      ...(campusId && { campusId }),
      ...(name && { name }),
      ...(memberId && { memberId }),
      desc,
    });
    if (result.success) {
      return result;
    }
    return result;
  },
  async reqPostGroupMembers(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      toSk,
      changeType,
      fromSk,
      memberIds,
      name,
    } = payload;
    const result = await apiInstance.groups.postGroupMembers({
      ...(toSk && { toSk }),
      ...(changeType && { changeType }),
      ...(campusId && { campusId }),
      ...(fromSk && { fromSk }),
      ...(name && { name }),
      ...(memberIds && { memberIds }),
      ...(memberId && { memberId }),
    });
    if (result.success) {
      return result;
    }
    return result;
  },

  //주소록
  async reqGetAddrBooks(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      keyword,
    } = payload;
    const result = await apiInstance.groups.getAddrBooks({
      campusId,
      memberId,
      ...(keyword && { keyword }),
    });
    if (result.success) {
      store.commit("setAddrBooks", result.data);
    }
    return result;
  },
  async postAddrBook(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      name,
      memberId,
      desc,
    } = payload;
    if (!name || !campusId) {
      return;
    }
    const result = await apiInstance.groups.postAddrBook({
      ...(campusId && { campusId }),
      ...(memberId && { memberId }),
      ...(name && { name }),
      ...(desc && { desc }),
    });
    return result;
  },
  reqDeleteAddrBook: async (store, payload) => {
    const { sk } = payload;
    const data = {
      sk,
    };
    const result = await apiInstance.groups.deleteAddrBook(data);
    if (result.success) {
      store.commit("deleteAddrBook", data.sk);
    } else {
      if (result.name === "ACCESS_DENIED") {
        globalStore.dispatch("common/setAlert", {
          open: true,
          msg: "접근이 제한된 페이지입니다.",
        });
      }
    }
    return result;
  },
  async reqGetAddrBook(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      sk,
    } = payload;
    // store.commit("clearUserGroups");
    // const lastKey = store.getters["getCampusMembersLastKey"];
    const result = await apiInstance.groups.getAddrBook({
      campusId,
      memberId,
      ...(sk && { sk }),
    });
    if (result.success) {
      store.commit("setAddrBook", result.data);
      // store.commit("setCampusMembersLastKey", lastKey);
    }
    return result;
  },
  async putAddrBook(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      sk,
      name,
      desc,
    } = payload;
    const result = await apiInstance.groups.putAddrBook({
      ...(sk && { sk }),
      ...(campusId && { campusId }),
      ...(name && { name }),
      ...(memberId && { memberId }),
      desc,
    });
    if (result.success) {
      return result;
    }
    return result;
  },
  async reqGetAddrs(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      keyword,
      limit = 10,
      first,
      sort,
      order,
      mktYn,
      memberYn,
      subscrStatus,
      addrBookSk,
    } = payload;
    if (first) {
      store.commit("clearAddrBookAddrs");
      store.commit("clearAddrBookAddrsLastKey");
      store.commit("setAddrBookAddrsCnt", null);
    }
    const lastKey = store.getters["getAddrBookAddrsLastKey"];
    const result = await apiInstance.groups.getAddrs({
      campusId,
      memberId,
      ...(sort && { sort }),
      ...(order && { order }),
      ...(lastKey && { lastKey }),
      ...(keyword && { keyword }),
      ...(limit && { limit }),
      ...(mktYn && { mktYn }),
      ...(memberYn && { memberYn }),
      ...(subscrStatus && { subscrStatus }),
      ...(addrBookSk && { addrBookSk }),
    });
    if (result.success) {
      const { results, lastKey, joinCnt, totalCnt } = result.data;
      store.commit("setAddrBookAddrs", results);
      store.commit("setAddrBookAddrsLastKey", lastKey);
      if (first) {
        store.commit("setAddrBookAddrsCnt", {
          joinCnt,
          totalCnt,
        });
      }
    } else {
      // cognito error
      const alert = useAlert();
      alert.open({
        open: true,
        title: "조회 불가",
        msg: "시스템 오류입니다.",
        csLink: true,
      });
    }
    return result;
  },
  async reqPostAddrBookAddrs(store, payload = {}) {
    const {
      changeType,
      addrs,
      campusId = store.rootGetters["campuses/getCampusUuid"],
      addrBookSk,
      fileKey,
      memberIds,
      memberId = store.rootGetters["members/getMemberId"],
    } = payload;
    const result = await apiInstance.groups.postAddrBookAddrs({
      ...(changeType && { changeType }),
      ...(addrs && { addrs }),
      ...(campusId && { campusId }),
      ...(addrBookSk && { addrBookSk }),
      ...(fileKey && { fileKey }),
      ...(memberIds && { memberIds }),
      ...(memberId && { memberId }),
    });
    if (result.success) {
      return result;
    }
    return result;
  },
  async reqPostAddrPublicSubscribe(store, payload = {}) {
    const {
      subscrReason,
      campaignSk,
      campusId = store.rootGetters["campuses/getCampusUuid"],
      addrBookSk,
      email,
      subscrStatus,
    } = payload;
    const result = await apiInstance.groups.postAddrPublicSubscribe({
      ...(subscrReason && { subscrReason }),
      ...(campaignSk && { campaignSk }),
      ...(campusId && { campusId }),
      ...(addrBookSk && { addrBookSk }),
      ...(email && { email }),
      ...(subscrStatus && { subscrStatus }),
    });
    if (result.success) {
      return result;
    }
    return result;
  },
});
