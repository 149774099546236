<script setup>
import { toRefs } from "vue";
import { useRoute } from "vue-router/composables";
const route = useRoute();
const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});
const { item } = toRefs(props);
const isActive = (item) => {
  if (route.name === "campus-page") {
    return (
      route.name === item.link.name &&
      route.params.path === item.link.params.path
    );
  }
  return route.name === item.link.name;
};
</script>

<template>
  <li class="link-item">
    <a
      v-if="item.link.href"
      :href="item.link.href"
      :target="item.isExternal ? '_blank' : '_self'"
    >
      <div class="w-full d-flex align-center justify-space-between">
        <p class="text-body-1-medium black--text">{{ item.title }}</p>
        <p-icon
          icon="ExternalLink/Gray8"
          size="20"
          v-if="item.isExternal"
        /></div
    ></a>
    <router-link
      v-else
      :to="item.link"
      :target="item.isExternal ? '_blank' : '_self'"
      :class="{ 'is-active': isActive(item) }"
    >
      <div class="w-full d-flex align-center justify-space-between">
        <p class="text-body-1-medium black--text">{{ item.title }}</p>
        <p-icon icon="ExternalLink/Gray8" size="20" v-if="item.isExternal" />
      </div>
      <p
        v-if="item.description"
        class="text-body-2-regular gray--text text--darken-3"
      >
        {{ item.description }}
      </p>
    </router-link>
  </li>
</template>

<style lang="scss" scoped>
.link-item {
  width: 100%;
  a {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px 24px;
    cursor: pointer;
    @include hover-transition(background);
    &:hover {
      background-color: $gray1;
    }
    &.is-active {
      background-color: $primary100;
    }
  }
}
</style>
