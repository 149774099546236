export default (apiInstance) => ({
  async postSubmission(_, payload) {
    const { code, language, multiple, stdin } = payload;

    const result = await apiInstance.compiles.postSubmission({
      code,
      language,
      stdin,
      multiple,
    });
    return result;
  },
  async getSubmission(_, { token, extra }) {
    const result = await apiInstance.compiles.getSubmission({
      token,
      ...(extra && { extra }),
    });
    return result;
  },
});
