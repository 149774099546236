import _ from "lodash";

export default {
  getApplication(state) {
    return state.application;
  },
  getApplicationsStaff(state) {
    return state.applicationsStaff.items;
  },
  getApplicationsStaffLastKey(state) {
    return state.applicationsStaff.lastKey;
  },
};
