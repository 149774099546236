export default {
  getCredit(state) {
    return state.credit;
  },
  getCreditHistories(state) {
    return state.creditHistories.items;
  },
  getCreditHistoriesLastKey(state) {
    return state.creditHistories.lastKey;
  },
  getExpireCredits(state) {
    return state.expireHistories.items;
  },
  getExpireCreditsLastKey(state) {
    return state.expireHistories.lastKey;
  },
  getCreditCnt(state) {
    return state.credit?.credit || 0;
  },
  getExpCreditCnt(state) {
    return state.credit?.expCreditCnt || 0;
  },
};
