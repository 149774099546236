export default {
  communities: {
    lastKey: "",
    items: [],
  },
  posts: {
    lastKey: "",
    items: [],
  },
  myPosts: {
    lastKey: "",
    items: [],
  },
  post: {
    privateYn: false,
    subject: "",
    comIds: [],
    notiYn: false,
    attachImages: [],
    attaches: [],
    conts: "",
    modAll: false,
    replyCnt: 0,
  },
  community: {
    name: "",
    joinType: "AUTO",
    openYn: true,
    products: [],
    images: {
      conts: "",
      textColor: "black",
      horizontal: "CENTER",
      nameYn: false,
      items: [
        {
          path: "https://file.poincampus.com/assets/sample/community.png",
          key: "DEFAULT",
        },
      ],
    },
    addMembers: [],
    thumbnailImage: {
      path: "https://file.poincampus.com/assets/sample/community-thumbnail.png",
      key: "DEFAULT",
    },
    delegators: [],
  },
  communityDashboard: {},
};
