export default {
  productReviews: {
    lastKey: "",
    items: [],
    stats: {
      rating1: 0,
      rating2: 0,
      rating3: 0,
      rating4: 0,
      rating5: 0,
      ratingAllCnt: 0,
    },
    loading: false,
  },
  productNotices: {
    lastKey: "",
    items: [],
    loading: false,
  },
  productNotice: {},
  replies: {
    lastKey: "",
    items: [],
  },
  adminReplies: {
    lastKey: "",
    items: [],
  },
  campusNotices: {
    lastKey: "",
    items: [],
  },
  campusNotice: {},
  partnerNotices: {
    lastKey: "",
    items: [],
  },
  partnerNotice: {},
  campusNoticeCategories: [],
  feedBackList: {
    lastKey: "",
    items: [],
  },
  feedBack: null,
  feedbackViewMode: "post",
  feedbackCode: "",
  availableReviews: [],
};
