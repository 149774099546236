<script setup>
import { computed, toRefs } from "vue";
import { useStore } from "@/store";
import { useCampusStore } from "@/stores/campus";
import { useEditorStore } from "@/stores/editor";
import { useRoute } from "vue-router/composables";
import { storeToRefs } from "pinia";

const props = defineProps({
  edit: {
    type: Boolean,
    default: false,
  },
});
const { edit } = toRefs(props);

const route = useRoute();
const editorStore = useEditorStore();
const { globalStyles } = storeToRefs(editorStore);

const campusStore = useCampusStore();
const { campus } = storeToRefs(campusStore);
const campusName = computed(() => campus.value?.name || "");

const store = useStore();
const display = computed(() => store.getters["campuses/getDisplayCampusInfo"]);

const userView = computed(() => !route.matched.some((r) => r.meta.isAdmin));
const styles = computed(() => {
  if (userView.value) {
    return campusStore.styles;
  }
  return globalStyles.value;
});

const logo = computed(() => {
  if (!edit.value) {
    return display.value?.logo || { key: "", path: "" };
  }
  return globalStyles.value?.headerStyles?.logo || { key: "", path: "" };
});

const color = computed(() => styles.value?.headerStyles?.color || "#000000");
</script>

<template>
  <img v-if="logo.key" class="logo-img" :src="logo.path" :alt="logo.key" />
  <h1 v-else class="logo-text">{{ campusName }}</h1>
</template>

<style lang="scss" scoped>
.logo-img {
  height: 32px;
  width: auto;
  max-width: 360px;
  object-fit: contain;
  cursor: pointer;
  @media (max-width: $smBreakPoint) {
    max-width: 240px;
  }
}
.logo-text {
  cursor: pointer;
  font-size: 22px;
  padding: 4px 0;
  line-height: 24px;
  text-align: center;
  color: v-bind(color);
  @include single-line;
  max-width: 360px;
  @media (max-width: $smBreakPoint) {
    max-width: 240px;
  }
}
</style>
