// 역할
export const Role = {
  A: "A", // 개설자
  B: "B", // 운영자
  C: "C", // 강의자
  D: "D", // 회원
  ALL: "ALL", // 모든 멤버
};

/**
 * 권한
 */
export const Authority = {
  // campus
  CAMPUS_SUPER_MANAGE: "CAMPUS_SUPER_MANAGE",
  // product
  PRODUCT_SUPER_MANAGE: "PRODUCT_SUPER_MANAGE",
  PRODUCT_CREATE: "PRODUCT_CREATE",
  // class
  CLASS_SUPER_MANAGE: "CLASS_SUPER_MANAGE",
  CLASS_CREATE: "CLASS_CREATE",
  // course
  COURSE_CREATE: "COURSE_CREATE",
  COURSE_SUPER_MANAGE: "COURSE_SUPER_MANAGE",
  // post
  POST_SUPER_MANAGE: "POST_SUPER_MANAGE",
  POST_CREATE: "POST_CREATE",
  POST_NOTICE: "POST_NOTICE",
  //community
  COMMUNITY_SUPER_MANAGE: "COMMUNITY_SUPER_MANAGE",
  // 사이트 소식
  CAMPUS_NOTICE_SUPER_MANAGE: "CAMPUS_NOTICE_SUPER_MANAGE",
};

/**
 * 상태
 */
// Campus Members Status
export const MemberStatus = {
  REQUEST: "REQUEST",
  CANCEL: "CANCEL", // 입학신청 취소
  REJECT: "REJECT",
  RETRY_REQUEST: "RETRY_REQUEST",
  INVITE: "INVITE",
  JOIN: "JOIN",
  DISABLED: "DISABLED", // 커뮤니티 사용제한
  DEL: "DEL", // 내보내기
  END: "END", // 기간만료
  PAY_REQUEST: "PAY_REQUEST", // 결제 요청 상태 (가상계좌, 기타결제)
  PAY_REJECT: "PAY_REJECT",
};

// Campus Members ChangeType
export const MemberChangeType = {
  INVITE_STAFF: "INVITE_STAFF",
  JOIN_STAFF: "JOIN_STAFF",
  MODIFY_STAFF: "MODIFY_STAFF",
  DEL_STAFF: "DEL_STAFF",
  REQUEST: "REQUEST",
  CANCEL: "CANCEL",
  APPROVAL: "APPROVAL",
  REJECT: "REJECT",
  RETRY_REQUEST: "RETRY_REQUEST",
  MODIFY: "MODIFY",
  DEL: "DEL",
};

// Product Members ChangeType
export const ProductMemberChangeType = {
  BASIC_JOIN_REQUEST: "BASIC_JOIN_REQUEST", // 콘텐츠 상품 (운영) 누구나 참여 or 신청(프로덕트 설정에 따름)
  BASIC_CANCEL: "BASIC_CANCEL", // 콘텐츠 상품 (운영) 참여 신청 취소
  BASIC_APPROVAL: "BASIC_APPROVAL", // 콘텐츠 상품 (운영) 참여 승인
  BASIC_REJECT: "BASIC_REJECT", // 콘텐츠 상품 (운영) 참여 거절
  BASIC_ADD: "BASIC_ADD", // 콘텐츠 상품 (운영) 회원 추가
  BASIC_REMOVE: "BASIC_REMOVE", // 콘텐츠 상품 (운영) 회원 제외
  MEMBER_MODIFY: "MEMBER_MODIFY", // 프로덕트 멤버의 수강종료일, 수료상태 변경
  APPLICATION_JOIN: "APPLICATION_JOIN",
};

//Community Members ChangeType
export const CommunityMemberChangeType = {
  BASIC_JOIN_REQUEST: "BASIC_JOIN_REQUEST", // 참여하기 or 참여신청하기
  BASIC_CANCEL: "BASIC_CANCEL", // 참여 신청 취소
  BASIC_APPROVAL: "BASIC_APPROVAL", // 참여 신청 승인
  BASIC_REJECT: "BASIC_REJECT", // 참여 신청 거절
  BASIC_ADD: "BASIC_ADD", // 회원 추가
  BASIC_REMOVE: "BASIC_REMOVE", // 회원 내보내기
};

export const ApplicationMemberChangeType = {
  APPLICATION_JOIN: "APPLICATION_JOIN", // 신청하기 시 멤버로 참여
};

export const ProgressStatus = {
  NONE: "NONE",
  NONE_FORCE: "NONE_FORCE",
  COMPLETE: "COMPLETE",
  COMPLETE_FORCE: "COMPLETE_FORCE",
  PROGRESS: "PROGRESS",
  HOLD: "HOLD",
  CLOSE: "CLOSE",
  CONDITION: "CONDITION",
  EXCEED: "EXCEED",
};

export const SubsStatus = {
  ACTIVE: "ACTIVE",
  PENDING_CANCEL: "PENDING_CANCEL",
  CANCEL: "CANCEL",
  FAIL: "FAIL",
};

export const UseStatus = {
  JOIN: "JOIN",
  STANDBY: "STANDBY",
  UNAVAILABLE: "UNAVAILABLE",
};

export const ProductMemberExcelType = {
  PRODUCT_MEMBER: "PRODUCT_MEMBER",
  COMPLETE_PRODUCT_MEMBER: "COMPLETE_PRODUCT_MEMBER", // 전체 수료 정보 조회
};

export const PartnersChangeType = {
  REQUEST_SAVE: "REQUEST_SAVE",
  REQUEST: "REQUEST",
  APPROVAL: "APPROVAL",
  REJECT: "REJECT",
  CANCEL: "CANCEL",
  DISABLED: "DISABLED",
  DISABLED_CANCEL: "DISABLED_CANCEL",
  RATE_CHANGE: "RATE_CHANGE",
  BIZ_CHANGE_REQUEST: "BIZ_CHANGE_REQUEST",
  BIZ_CHANGE_PROCESS: "BIZ_CHANGE_PROCESS",
  DONE: "DONE",
};

export const PartnerStatus = {
  REQUEST: "REQUEST",
  JOIN: "JOIN",
  REJECT: "REJECT",
  CANCEL: "CANCEL",
  DISABLED: "DISABLED",
};
