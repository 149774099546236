<script setup>
import { toRefs } from "vue";

const props = defineProps({
  show: {
    type: Boolean,
    default: true,
  },
  position: {
    type: [String, Number],
    required: true,
  },
});
const { show, position } = toRefs(props);

const scrollTop = () => {
  document
    .getElementsByClassName("campus-admin__container")[0]
    ?.scrollTo({ top: 0, behavior: "smooth" });
  window.scrollTo({ top: 0, behavior: "smooth" });
};
</script>

<template>
  <div class="top" :style="`bottom: ${position}`">
    <v-fab-transition>
      <v-btn
        style="opacity: 0.5"
        color="black"
        class="top-btn"
        @click="scrollTop"
        v-if="show"
        fab
        small
        absolute
      >
        <p-icon icon="UpSm/White" size="28" class="ma-0" />
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<style lang="scss" scoped>
.top {
  position: fixed;
  bottom: 100px;
  right: 28px;
  z-index: 9;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  @media (max-width: $mdBreakPoint) {
    right: 16px;
  }
}
</style>
