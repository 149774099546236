import { endpoint } from "@/config";

// https://aws-amplify.github.io/docs/js/api
export async function login({
  email,
  password,
  snsType,
  token,
  refreshToken,
  code,
  state,
  // TODO: MKT
  mktYn,
}) {
  const data = {
    snsType,
  };
  if (email) {
    data.email = email.trim();
  }
  if (password) {
    data.pass = password;
  }
  if (token) {
    data.token = token;
  }
  if (refreshToken) {
    data.refreshToken = refreshToken;
  }
  if (code) {
    data.code = code;
  }
  if (state) {
    data.state = state;
  }
  // TODO: MKT
  if (mktYn !== undefined) {
    data.mktYn = mktYn;
  }
  return this.getMethod("post", false, {
    url: `${endpoint.auth}/auth/login`,
    data,
  });
}

export async function signUp({
  name,
  email,
  password,
  redirectUrl,
  nickname = "",
}) {
  const data = {
    name,
    email: email.trim(),
    pass: password,
    redirectUrl,
    ...(nickname && { nickname }),
    // ...(agreeYn && agreeDttm && { agreeYn, agreeDttm }),
  };
  const result = this.getMethod("post", false, {
    url: `${endpoint.auth}/auth/signup`,
    data,
  });

  return result;
}

export async function naverOauthReqToken({
  grantType,
  code,
  state,
  refreshToken,
  accessToken,
}) {
  // grantType (필수) : authorization_code, refresh_token, delete
  // refreshToken : 갱신시(refresh_token)일때 필수
  // accessToken : 삭제시(delete) 필수
  const params = {
    grantType,
  };
  // client_id: clienId,
  // client_secret: clientSecret,
  if (grantType === "authorization_code") {
    params.code = code;
    params.state = state;
  } else if (grantType === "refresh_token") {
    params.refreshToken = refreshToken;
  } else if (grantType === "delete") {
    params.accessToken = accessToken;
  } else if (grantType === "profile") {
    params.accessToken = accessToken;
  }

  return this.getMethod("get", false, {
    url: `${endpoint.auth}/auth/naverToken`,
    params,
  });
}

export async function forgotPassword({ email }) {
  const data = {
    email: email.trim(),
  };
  return this.getMethod("post", false, {
    url: `${endpoint.auth}/auth/forgotPassword`,
    data,
  });
}

export async function refreshToken({
  accessKeyId,
  cognitoId,
  idToken,
  userUuid,
}) {
  const data = {
    accessKeyId,
    cognitoId,
    userUuid,
  };
  if (idToken) {
    data.idToken = idToken;
  }
  return this.getMethod("post", true, {
    url: `${endpoint.auth}/auth/tokenRefresh`,
    data,
  });
}

// export async function refreshToken ({ email, refreshToken }) {
//   const data = {
//     email,
//     refreshToken
//   }
//   return this.getMethod('post', true, {
//     url: `${endpoint.auth}/auth/refresh`,
//     data
//   })
// }

export async function resetPassword({ code, password, email }) {
  const data = {
    email: email.trim(),
    pass: password,
    code: code.trim(),
  };
  return this.getMethod("put", false, {
    url: `${endpoint.auth}/auth/resetPassword`,
    data,
  });
}

export async function changePassword({ newPassword, oldPassword, email }) {
  const data = {
    email: email.trim(),
    pass: newPassword,
    oldPass: oldPassword,
  };
  return this.getMethod("put", true, {
    url: `${endpoint.auth}/auth/changePassword`,
    data,
  });
}

/**
 * 자체가입회원 사용자 이메일 인증
 */
export async function emailVerification({ email }) {
  const data = {
    email: email.trim(),
  };
  return this.getMethod("put", false, {
    url: `${endpoint.auth}/auth/verification`,
    data,
  });
}
export async function authVerification({ code, email }) {
  const data = {
    code: code.trim(),
    email: email.trim(),
  };
  return this.getMethod("post", false, {
    url: `${endpoint.auth}/auth/verification`,
    data,
  });
}
