<script setup>
import { computed, ref, watch } from "vue";
import { useAuth } from "@/hooks/useAuth";
import { useStore } from "@/store";
import PoinHeaderStartMenu from "./PoinHeaderStartMenu.vue";

const store = useStore();
const open = ref(false);
const { signIn } = useAuth();
const campusOnly = computed(() => store.getters["common/campusOnly"]);

const handleSignIn = () => {
  signIn();
  open.value = false;
};

const onClick = () => {
  if (campusOnly.value) {
    handleSignIn();
  } else {
    open.value = true;
  }
};
</script>

<template>
  <div class="d-flex">
    <button
      class="d-flex"
      @click="onClick"
      :class="{ 'bottom-nav-item': campusOnly }"
    >
      <template v-if="campusOnly">
        <p-icon icon="CircleUser/Black" />
        <span class="text-detail-2-medium black--text">로그인</span>
      </template>
      <v-avatar :size="32" v-else>
        <v-img src="https://file.poincampus.com/assets/ProfileImg@2x.png" />
      </v-avatar>
    </button>
    <v-dialog
      :value="open"
      max-width="360"
      retain-focus
      persistent
      no-click-animation
      @click:outside="open = false"
      @keydown.esc="open = false"
      content-class="white hide-scrollbar"
    >
      <div class="p-modal--title">
        <h5 class="text-h5-medium black--text text-left">시작하기</h5>
        <p-icon-btn icon="Close" @click="open = false" x-small />
      </div>
      <div class="d-flex w-full py-5">
        <PoinHeaderStartMenu @click.native="open = false" />
      </div>
      <button class="login-btn" @click="handleSignIn">로그인</button>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>
.p-modal--title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 20px;
  border-bottom: 1px solid $gray3;
  h5 {
    line-height: 26px;
    height: 26px;
  }
}
.login-btn {
  width: 100%;
  border-top: 1px solid $gray3;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  position: relative;
  @include hover-before;
  font-size: 14px;
  color: $gray8;
}
.bottom-nav-item {
  width: 52px;
  height: 52px;
  padding: 4px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  margin: 0 auto;
  cursor: pointer;
  border-radius: 4px;
  position: relative;
}
</style>
