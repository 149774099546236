import { endpointV2 as endpoint } from "@/config";

export async function getGroups({ campusId, memberId, name, keyword }) {
  if (!campusId) {
    return;
  }
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(name && { name }),
    ...(keyword && { keyword }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.groups}/`,
    params,
  });
}
export async function postGroup({ campusId, name, memberId, desc }) {
  if (!campusId) {
    return;
  }
  const data = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(name && { name }),
    desc,
  };
  return this.getMethod("post", true, {
    url: `${endpoint.groups}/`,
    data,
  });
}

// 멤버 관리
export async function postGroupMembers({
  toSk,
  changeType,
  campusId,
  name,
  fromSk,
  memberId,
  memberIds,
}) {
  if (!toSk || !changeType || !campusId) {
    return;
  }
  const data = {
    memberIds,
    changeType,
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(toSk && { toSk }),
    ...(name && { name }),
    ...(fromSk && { fromSk }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.groups}/members`,
    data,
  });
}
export async function getGroup({ campusId, memberId, sk, name }) {
  if (!campusId || !memberId) {
    return;
  }
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(sk && { sk }),
    ...(name && { name }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.groups}/${sk}`,
    params,
  });
}
export async function putGroup({ campusId, name, memberId, desc, sk }) {
  const data = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    desc,
    ...(name && { name }),
  };
  return this.getMethod("put", true, {
    url: `${endpoint.groups}/${sk}`,
    data,
  });
}
export async function deleteGroup({ sk }) {
  return this.getMethod("delete", true, {
    url: `${endpoint.groups}/${sk}`,
  });
}

//주소록 목록 조회
export async function getAddrBooks({ campusId, memberId, keyword }) {
  if (!campusId) {
    return;
  }
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(keyword && { keyword }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.groups}/addrs`,
    params,
  });
}
//주소록 생성
export async function postAddrBook({ campusId, name, memberId, desc }) {
  if (!campusId) {
    return;
  }
  const data = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(name && { name }),
    desc,
  };
  return this.getMethod("post", true, {
    url: `${endpoint.groups}/addrs`,
    data,
  });
}
//주소록 단건 조회
export async function getAddrBook({ campusId, memberId, sk }) {
  if (!campusId || !memberId) {
    return;
  }
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(sk && { sk }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.groups}/addrs/${sk}`,
    params,
  });
}
//주소록 삭제
export async function putAddrBook({ campusId, name, memberId, desc, sk }) {
  const data = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    desc,
    ...(name && { name }),
  };
  return this.getMethod("put", true, {
    url: `${endpoint.groups}/addrs/${sk}`,
    data,
  });
}
export async function deleteAddrBook({ sk }) {
  return this.getMethod("delete", true, {
    url: `${endpoint.groups}/addrs/${sk}`,
  });
}
//주소 목록 조회
export async function getAddrs({
  keyword,
  memberId,
  mktYn,
  limit = 10,
  sort,
  campusId,
  order,
  memberYn,
  addrBookSk,
  lastKey,
  subscrStatus,
}) {
  const params = {
    ...(keyword && { keyword }),
    memberId,
    ...(mktYn && { mktYn }),
    ...(limit && { limit }),
    ...(sort && { sort }),
    campusId,
    ...(order && { order }),
    ...(memberYn && { memberYn }),
    ...(addrBookSk && { addrBookSk }),
    ...(lastKey && { lastKey }),
    ...(subscrStatus && { subscrStatus }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.groups}/addrs/addr`,
    params,
  });
}
export async function postAddrBookAddrs({
  changeType,
  addrs,
  campusId,
  addrBookSk,
  fileKey,
  memberIds,
  memberId,
}) {
  if (!memberId || !changeType || !campusId) {
    return;
  }
  const data = {
    changeType,
    ...(addrs && { addrs }),
    ...(campusId && { campusId }),
    ...(addrBookSk && { addrBookSk }),
    ...(fileKey && { fileKey }),
    ...(memberIds && { memberIds }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.groups}/addrs/addr`,
    data,
  });
}
export async function postAddrPublicSubscribe({
  subscrReason,
  campaignSk,
  campusId,
  addrBookSk,
  email,
  subscrStatus,
}) {
  if (!campusId || !email || !subscrStatus) {
    return;
  }
  const data = {
    ...(subscrReason && { subscrReason }),
    ...(campaignSk && { campaignSk }),
    ...(campusId && { campusId }),
    ...(addrBookSk && { addrBookSk }),
    ...(email && { email }),
    ...(subscrStatus && { subscrStatus }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.groups}/addrs/addr/public/subscribe`,
    data,
  });
}
