export default {
  iframeWhitelist: [
    "*.youtube.com",
    "*.naver.com",
    "*.zoom.us,forms.gle",
    "*.google.com",
    "*.instagram.com",
    "*.facebook.com",
    "youtu.be",
    "*.notion.site",
    "*.google.co.kr",
    "*.poincampus.com",
    "vscode.dev",
    "whattime.co.kr"
  ],
  notificationBar: null,
  interviewList: null,
  interviewItem: null,
};
