import { endpointV2 as endpoint } from "@/config";

//사용자 알림 정보 조회
export async function getNotifications({ userId, limit, countOnly, lastKey }) {
  const params = {
    ...(limit && { limit }),
    ...(lastKey && { lastKey }),
    ...(countOnly !== undefined && { countOnly }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.notifications}/${userId}`,
    params,
  });
}
//사용자 알림 정보 단건 조회(조회 시 읽음 처리)
export async function getNotification({ userId, pk, rk }) {
  const params = {
    ...(pk && { pk }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.notifications}/${userId}/${rk}`,
    params,
  });
}
//알림 삭제 처리
export async function deleteNotifications({ userId, data }) {
  return this.getMethod("delete", true, {
    url: `${endpoint.notifications}/${userId}`,
    data,
  });
}
//알림 읽음 처리
export async function putNotification({ userId, data }) {
  return this.getMethod("put", true, {
    url: `${endpoint.notifications}/${userId}`,
    data,
  });
}
