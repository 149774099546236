export const ProductType = {
  SALES: "SALES",
  BASIC: "BASIC",
  APPLICATION: "APPLICATION",
  RESERVATION: "RESERVATION",
  GOODS: "GOODS",
};

export const ContentType = {
  SECTION: "SECT",
  CURRICULUM: "CC",
  VIDEO: "VIDEO",
  MULTIVIDEO: "MULTIVIDEO",
  LIVE: "LIVE",
  PAGE: "PAGE",
  EPUB: "EPUB",
  MULTIEPUB: "MULTIEPUB",
  FILE: "FILE",
  AUDIO: "AUDIO",
  MULTIAUDIO: "MULTIAUDIO",
  QUIZ: "QUIZ",
  QUESTION: "QUESTION",
  CODING: "CODING",
  COACHING: "COACHING",
  APPLICATION: "APPLICATION",
};

export const QuizStatus = {
  NONE: "NONE",
  PROGRESS: "PROGRESS",
  SUBMIT: "SUBMIT",
  PASS: "PASS",
  FAIL: "FAIL",
};

export const QuestionType = {
  OBJECT: "OBJT",
  SUBJECT: "SBJT",
  DESC: "DESC",
  FILE: "FILE",
};

export const ApplicationStatus = {
  NONE: "NONE",
  PROGRESS: "PROGRESS",
  SUBMIT: "SUBMIT",
  PICK: "PICK",
  DROP: "DROP",
  HOLD: "HOLD",
  PASS: "PASS",
  FAIL: "FAIL",
  COMPLETE: "COMPLETE",
};

export const DeliveryCostType = {
  PAID: "PAID",
  FREE: "FREE",
  CONDITION: "CONDITION",
  QTY_REPEAT: "QTY_REPEAT",
  QTY_SEGMENTS: "QTY_SEGMENTS",
};

export const DeliveryWay = {
  LOGIS: "LOGIS",
  SELLER: "SELLER",
  SELF: "SELF",
};

export const CombineCalcType = {
  MIN: "MIN",
  MAX: "MAX",
};

export const LogisCd = {
  KP: "우체국택배",
  CJ: "CJ대한통운",
  LOGEN: "로젠택배",
  LOTTE: "롯데택배",
  HANJIN: "한진택배",
  QX: "큐익스프레스",
  IY: "일양로지스",
  GY: "건영택배",
  DS: "대신택배",
  KD: "경동택배",
  GS: "GS Postbox 택배",
  CU: "CU편의점택배",
  SLX: "SLX택배",
  HAPDONG: "합동택배",
  WR: "우리택배",
  EMS: "EMS",
  FEDEX: "FedEx",
  UPS: "UPS",
  DHL: "DHL",
  TNT: "TNT Express",
  USPS: "USPS",
  I_P: "i-parcel",
  LX: "LX판토스",
  GSI: "GSI Express",
  ECMS: "ECMS Express",
  AUS: "AusPost",
  ETC: "기타",
};

export const DeliveryPayType = {
  PRE: "PRE",
  COD: "COD",
};
