import { vimeo } from "@/config";

// resumable upload
export async function startUploadVideo({
  fileSize,
  videoName,
  description,
  approach = "tus",
  link,
}) {
  const token = vimeo.accessToken;
  const headers = {
    Accept: "application/vnd.vimeo.*+json;version=3.4",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const name = `${videoName || "anoymous"}`;
  const data = {
    upload: {
      approach,
      size: fileSize,
    },
    name,
    description,
  };
  if (link) {
    data.upload.link = link;
  }

  const result = await this.post(
    "/me/videos?fields=upload,uri",
    JSON.stringify(data),
    {
      headers,
    }
  );
  return result;
}

// resumable upload
export async function uploadVideo(
  { uploadLink, file, uploadOffset = 0, onProgress },
  source
) {
  const headers = {
    "Tus-Resumable": "1.0.0",
    "Content-Type": "application/offset+octet-stream",
    Accept: "application/vnd.vimeo.*+json;version=3.4",
    "Upload-Offset": uploadOffset,
  };

  const data = file;
  const result = await this.patch(uploadLink, data, {
    headers,
    cancelToken: source.token,
    onUploadProgress: function (progressEvent) {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      onProgress(percentCompleted);
    },
  });
  return result;
}

// resumable upload
export async function verifyUploadVideoStatus({ uploadLink }) {
  const headers = {
    "Tus-Resumable": "1.0.0",
    // 'Content-Type': 'application/offset+octet-stream',
    Accept: "application/vnd.vimeo.*+json;version=3.4",
  };
  const result = await this.head(uploadLink, { headers });
  const { success } = result;

  if (success) {
    const fileSize = result?.headers?.["upload-length"];
    const currentUploadFileSize = result?.headers?.["upload-offset"];
    const progress = Math.floor((currentUploadFileSize / fileSize) * 100);
    const finish = progress === 100 || false;
    return {
      finish,
      size: fileSize,
      offset: currentUploadFileSize,
      progress: progress,
    };
  }

  return {
    success: false,
  };
}

// vimeo core api
export async function getVideoUser({ userUri, videoUri }) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${vimeo.accessToken}`,
  };

  const result = await this.get(`${userUri}${videoUri}`, {
    headers,
  });
  return result;
}

export async function getVideo({ videoUri }) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${vimeo.accessToken}`,
  };

  const result = await this.get(
    `${videoUri}?fields=name,uri,status,duration,transcode,upload,width,height`,
    {
      headers,
    }
  );
  return result;
}

export async function getVideoObj({ videoUri }) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${vimeo.accessToken}`,
  };

  const result = await this.get(
    `videos/${videoUri}?fields=name,uri,status,duration,transcode,upload,width,height`,
    {
      headers,
    }
  );
  return result;
}

export async function getVideoPictures({ picturesUri }) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${vimeo.accessToken}`,
  };

  const result = await this.get(`${picturesUri}`, {
    headers,
  });
  return result;
}

// delete video
export async function deleteVideo({ videoUri }) {
  const token = vimeo.deleteAccessToken;
  const headers = {
    Accept: "application/vnd.vimeo.*+json;version=3.4",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const result = await this.delete(`${videoUri}`, {
    headers,
  });
  if (result.success && result.status === "204") {
    return result;
  }
  return result;
}

// get oEmbed
export async function getOEmbed({ link }) {
  const headers = {
    "Content-Type": "application/json",
  };

  // const encodeUrl = encodeURIComponent(link)
  // const url = `https://www.hotelscombined.com/AutoUniversal.ashx?search=${passParams.search}&limit=${passParams.limit}&languageCode=${passParams.languageCode}`
  const params = {
    url: link,
  };
  // https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/286898202&width=480&height=360
  const url = "https://vimeo.com/api/oembed.json";
  const result = await this.get(
    url,
    {
      params,
    },
    {
      headers,
    }
  );

  return result;
}
