import { useRoute, useRouter } from "vue-router/composables";

export function useRouteQuery() {
  const route = useRoute();
  const router = useRouter();
  function setQuery(name, value) {
    const newQuery = { ...route.query };
    if (value?.trim()) {
      newQuery[name] = value.trim();
    } else {
      delete newQuery[name];
    }
    if (JSON.stringify(route.query) !== JSON.stringify(newQuery)) {
      router.replace({ ...route, query: newQuery });
    }
  }
  function setQueries(queries) {
    const newQuery = { ...route.query };
    Object.keys(queries).forEach((key) => {
      if (queries[key]) {
        newQuery[key] = queries[key];
      } else {
        delete newQuery[key];
      }
    });
    if (JSON.stringify(route.query) !== JSON.stringify(newQuery)) {
      router.replace({ ...route, query: newQuery });
    }
  }
  function loadFilteredData(params, callback) {
    Object.keys(params).forEach((key) => {
      params[key] = route.query[key] || "";
    });
    callback();
  }
  return {
    setQuery,
    setQueries,
    loadFilteredData,
  };
}
