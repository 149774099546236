import { endpointV2 as endpoint } from "@/config";

export async function getSubmission({ token, extra }) {
  const params = {
    ...(extra && { extra }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.compiles}/submissions/${token}`,
    params,
  });
}

export async function postSubmission({ code, language, multiple, stdin }) {
  const data = {
    code,
    language,
    ...(stdin && { stdin }),
    ...(multiple && { multiple }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.compiles}/submissions`,
    data,
  });
}
