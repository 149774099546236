import { ref, reactive, computed } from "vue";
import { defineStore } from "pinia";

export const useAlert = defineStore("alert", () => {
  const state = reactive({
    open: false,
    lottie: "",
    icon: "alert",
    title: "",
    msg: "",
    callback: () => {},
    btnText: "확인",
    csLink: false,
  });

  const isOpen = computed(() => state.open);

  function open(params = {}) {
    state.open = true;
    state.icon = params.icon || "alert";
    state.lottie = params.lottie || "";
    state.title = params.title || "";
    state.msg = params.msg || "";
    state.callback = params.callback || (() => {});
    state.btnText = params.btnText || "확인";
    state.csLink = params.csLink || false;
  }

  function close() {
    state.open = false;
    state.icon = "alert";
    state.lottie = "";
    state.title = "";
    state.msg = "";
    state.callback = () => {};
    state.btnText = "확인";
    state.csLink = false;
  }
  return { state, isOpen, open, close };
});
