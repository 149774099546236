<script setup>
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useAuth } from "@/hooks/useAuth";
import { useStore } from "@/store";
import PoinHeaderStartMenu from "./header/PoinHeaderStartMenu.vue";

const store = useStore();
const open = ref(false);
const { signIn } = useAuth();

const user = computed(() => store.getters["users/getUser"]);
const isMobile = computed(() => store.getters["common/isMobile"]);
const campusOnly = computed(() => store.getters["common/campusOnly"]);

const showMainStartBtn = computed(() => {
  return !campusOnly.value && !user.value?.campusYn;
});

function onStart() {
  open.value = true;
}
function onClose() {
  open.value = false;
}
const handleSignIn = () => {
  signIn();
  open.value = false;
};
function handleScroll() {
  const client = document?.querySelector(".start-btn-target");

  if (!client) {
    return;
  }
  const clientHeight = client.clientHeight;

  if (window.scrollY > clientHeight) {
    document.querySelector(".poincampus-start-button").style.visibility =
      "visible";
  } else {
    document.querySelector(".poincampus-start-button").style.visibility =
      "hidden";
  }
}
onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>

<template>
  <div class="poincampus-start-button" v-if="showMainStartBtn">
    <p-btn class="w-full" large @click="onStart">시작하기</p-btn>
    <v-dialog
      :value="open"
      max-width="360"
      retain-focus
      persistent
      no-click-animation
      @click:outside="onClose"
      @keydown.esc="onClose"
      content-class="white hide-scrollbar"
    >
      <div class="p-modal--title">
        <h5 class="text-h5-medium black--text text-left">시작하기</h5>
        <p-icon-btn icon="Close" @click="onClose" x-small />
      </div>
      <div class="d-flex w-full py-5">
        <PoinHeaderStartMenu @click.native="onClose" />
      </div>
      <button class="login-btn" @click="handleSignIn">로그인</button>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>
.poincampus-start-button {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 8;
  display: grid;
  grid-template-columns: 1fr;
  visibility: hidden;

  .p-btn {
    border-radius: 0;
  }
}
.p-modal--title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 20px;
  border-bottom: 1px solid $gray3;
  h5 {
    line-height: 26px;
    height: 26px;
  }
}
.login-btn {
  width: 100%;
  border-top: 1px solid $gray3;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  position: relative;
  @include hover-before;
  font-size: 14px;
  color: $gray8;
}
</style>
