// import globalStore from '@/store'

export default (apiInstance, localStorage, sessionStorage) => ({
  setNotice: async (store, payload) => {
    const result = await apiInstance.supports.getSupports("NOTICE");
    if (result.success) {
      const { results } = result.data;
      store.commit("setNotice", results);
    }
  },
  setFaq: async (store, payload) => {
    const result = await apiInstance.supports.getSupports("FAQ");
    if (result.success) {
      const { results } = result.data;
      store.commit("setFaq", results);
    }
  },
  setAsk: async (store, payload) => {
    const uuid = store.rootGetters["users/getUserUuid"];
    const result = await apiInstance.supports.getSupports("ASK", uuid);
    if (result.success) {
      store.commit("setAskItems", result.data.results);
    }
  },
  reqCreateAsk: async (store, payload) => {
    const uuid = store.rootGetters["users/getUserUuid"];
    const { subject, askType, site } = payload;
    const data = {
      supportType: "ASK",
      subject,
      askType,
      userUuid: uuid,
      site,
    };
    await apiInstance.supports.postSupport(data);
  },
});
