import axios from "axios";
import globalStore from "@/store";
import { getAwsSigV4Headers, storeUtils } from "@/utils";
import { isValidJSON } from "@/utils/validation";

export default async function createErrorResponse(error) {
  let result = {
    ...error.response,
    success: false,
  };

  if (axios.isCancel(error)) {
    return {
      ...result,
      msg: error.message,
    };
  }
  if (error.response) {
    try {
      const { data: d, status } = error.response;
      const data = isValidJSON(d) ? JSON.parse(d) : d;
      const errorObject = data?.errors?.[0];
      // 492 -> invalid signature
      // 493 -> expire token
      if (status === 493) {
        const refreshSuccess = await this.tokenRefresh();
        if (refreshSuccess) {
          const failedRequestConfig = error.config;
          const auth = globalStore.getters["auth/getAuth"];
          const { method, params, baseURL, url, data: d } = failedRequestConfig;
          const data = isValidJSON(d) ? JSON.parse(d) : d;
          const signedHeaders = getAwsSigV4Headers({
            auth,
            method,
            params,
            url: `${baseURL}/${url}`,
            data,
          });
          const retryConfig = Object.assign(failedRequestConfig, {
            headers: signedHeaders,
          });
          return this.request(retryConfig);
        }
        // } else if (status === 493 || status === 492) {
        //   const tryTokenCount = this.getTryRefreshTokenCount()
        //   if (tryTokenCount > 3) {
        //     globalStore.dispatch('auth/signOut')
        //   }
      } else if (status === 491 || status === 492) {
        const signatureErrorMsg =
          typeof data === "object" ? data.message : data.toString();
        if (
          (signatureErrorMsg.includes("Signature expired") &&
            signatureErrorMsg.includes("is now earlier than")) ||
          (signatureErrorMsg.includes("Signature not yet current") &&
            signatureErrorMsg.includes("is still later than"))
        ) {
          await signOutWithAlert(
            "시간 설정 오류 안내",
            "PC의 시간 설정이 현재 시간과 맞지 않습니다.<br>현재 시간으로 설정 후 다시 시도해주세요."
          );
          return {
            success: false,
          };
        }
        globalStore.dispatch("auth/signOut");
      } else if (status === 500) {
        const errorObject = data.errors[0];
        const { name } = errorObject;
        // SNS Token Expired
        if (name === "NotAuthorizedException") {
          globalStore.dispatch("auth/signOut");
          window.location = "/login";
        }
      } else if (status === 403) {
        // 중복 로그인 처리
        if (errorObject.name === "DUPLICATED_LOGIN") {
          await signOutWithAlert(
            "중복로그인 안내",
            "다른 기기에서 로그인되어 이용이 종료됩니다."
          );
          return;
        }
      }

      if (data && data.errors) {
        return {
          ...result,
          ...errorObject,
          status,
          errData: data,
        };
      } else {
        return {
          ...result,
          ...data,
          status,
          errData: data,
        };
      }
    } catch (e) {
      return {
        ...result,
        msg: "unknown error",
      };
    }
  } else if (error.request) {
    return {
      ...result,
      request: error.request,
      needRefresh: true, // TODO
      status: null,
      data: null,
    };
  } else {
    // Something happened in setting up the request that triggered an Error
    return {
      ...result,
      request: null,
      status: null,
      data: null,
    };
  }
}

async function signOutWithAlert(title, msg) {
  let signoutDone;
  // 중복로그인 안내 팝업
  globalStore._vm?.$eventBus?.$emit("alert", {
    open: true,
    title,
    msg,
    csLink: true,
    // 알림창 보여준 후, 확인 클릭하면 로그아웃시킨다.
    callback: () => {
      if (!signoutDone) {
        globalStore.dispatch("auth/signOut");
        storeUtils.toHome();
        signoutDone = true;
      }
    },
  });
  await new Promise((resolve) => {
    // 만약 확인을 클릭하지 않거나 우회하여 alert 닫을 경우 대비해 3초 후 자동 로그아웃
    setTimeout(() => {
      if (!signoutDone) {
        globalStore.dispatch("auth/signOut");
        storeUtils.toHome();
        signoutDone = true;
      }
      resolve();
    }, 3000);
  });
}
