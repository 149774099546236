export default {
  // 멤버 식별자는 개설자인 경우 id, 멤버인 경우 uuid.
  // 만약 개설자라면 classes 속성 없음.
  memberItem: null,
  roles: [],
  campusMember: null,
  campusMembers: [],
  campusMembersLastKey: "",
  campusMembersCnt: null,
  staff: {
    value: [],
    inviteCnt: null,
    lastKey: null,
  },
  productMemberProgress: null,
  productMember: null,
  productsMember: null,
  productsMembers: {
    lastKey: "",
    items: [],
  },
  communityMembers: {
    lastKey: "",
    members: [],
  },
  joinProducts: [],
  communityMember: {},
  joinCommunities: [],
  authorities: {
    results: [],
    tables: [],
  },
  certificateKey: "",
  groupMembers: [],
  groupMembersLastKey: "",
  groupMembersCnt: null,
  mktForbiddenRoute: [
    "campus-public-unsubscribe",
    "campus-member-join",
    "campus-home",
    "campus-product-checkout",
  ],
};
