<script setup>
import { ref, computed, watch } from "vue";
import { useRoute } from "vue-router/composables";
import { useStore } from "@/store";
const route = useRoute();
const store = useStore();

const timeoutId = ref(0);

const count = computed(() => store.getters["notifications/getNewCount"]);
const countNormalized = computed(() => {
  if (count.value > 99) {
    return "99+";
  }
  return count.value;
});

function fetch() {
  store.dispatch("notifications/reqGetNotificationCnt");
}

function clickNotification() {
  store.dispatch("notifications/toggleContainerActive");
}

watch(
  route,
  ({ name }) => {
    clearTimeout(timeoutId.value);
    if (!["auth-bridge"].includes(name)) {
      timeoutId.value = setTimeout(fetch, 500);
    }
  },
  {
    immediate: true,
    deep: true,
  }
);
</script>

<template>
  <div class="notification-icon" @click.stop="clickNotification">
    <p-icon icon="Noti/Black" size="20" />
    <span v-show="count > 0" class="count-badge">{{ countNormalized }}</span>
  </div>
</template>

<style lang="scss" scoped>
.notification-icon {
  border-radius: 50%;
  background-color: $white;
  border: 1px solid $gray4;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  position: relative;
  cursor: pointer;
  img {
    width: 24px;
    height: 24px;
  }
  .count-badge {
    box-sizing: border-box;
    position: absolute;
    min-width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    line-height: 16px;
    padding: 0 4px;
    font-size: 10px;
    font-weight: bold;
    background: $coral;
    color: $white;
    text-align: center;
    border-radius: 24px;
    top: -6px;
    left: 22px;
  }
  &:hover {
    background: $gray1;
    transition: all 0.3s ease-in-out;
  }
}
</style>
