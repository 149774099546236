export default {
  creditHistories: {
    lastKey: "",
    items: [],
  },
  expireHistories: {
    lastKey: "",
    items: [],
  },
  credit: null,
};
