export default {
  getIframeWhitelist: (state) => {
    return state.iframeWhitelist || [];
  },
  getNotificationBar: (state) => {
    return state.notificationBar;
  },
  getInterviewList: (state) => {
    return state.interviewList || 0;
  },
  getInterviewItem: (state) => {
    return state.interviewItem || null;
  },
};
