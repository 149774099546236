import { endpointV2 as endpoint } from "@/config";

// http://swagger.poincampus.com/?urls.primaryName=08.%ED%94%84%EB%A1%9C%EB%8D%95%ED%8A%B8%EA%B4%80%EB%A6%AC#/%ED%94%84%EB%A1%9C%EB%8D%95%ED%8A%B8%EA%B4%80%EB%A6%AC/post_categories

// 캠퍼스관리 > 프로덕트 > 프로덕트 목록 조회
export async function getProducts({
  campusId,
  memberId,
  keyword,
  promotionYn,
  productType,
  limit,
  sort,
  order,
  salesStatus,
  lastKey,
  price,
  excludeCommId,
  includeCommId,
  allYn,
  categoryId,
  productTypes,
  groupPriceYn,
  freeYn,
  delegatorId,
  purchaseType,
  partnerLinkYn,
  partnerYn,
  publishYn,
  includeExtraId,
  excludeExtraId,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(keyword && { keyword }),
    ...(promotionYn !== undefined && { promotionYn }),
    ...(productType && { productType }),
    ...(limit && { limit }),
    ...(sort && { sort }),
    ...(order && { order }),
    ...(salesStatus && { salesStatus }),
    ...(lastKey && { lastKey }),
    ...(price && { price }),
    ...(excludeCommId && { excludeCommId }),
    ...(includeCommId && { includeCommId }),
    ...(allYn && { allYn }),
    ...(categoryId && { categoryId }),
    ...(productTypes?.length > 0 && { productTypes }),
    ...(groupPriceYn !== undefined && { groupPriceYn }),
    ...(freeYn !== undefined && { freeYn }),
    ...(delegatorId && { delegatorId }),
    ...(purchaseType && { purchaseType }),
    ...(partnerLinkYn !== undefined && { partnerLinkYn }),
    ...(partnerYn !== undefined && { partnerYn }),
    ...(publishYn !== undefined && { publishYn }),
    ...(includeExtraId && { includeExtraId }),
    ...(excludeExtraId && { excludeExtraId }),
  };

  return this.getMethod("get", true, {
    url: `${endpoint.products}/`,
    params,
  });
}

// 캠퍼스관리 > 프로덕트 > 카테고리, 고객 추가 정보 조회
export async function getCategories({ campusId, manageYn, productObjType }) {
  const params = {
    ...(campusId && { campusId }),
    ...(manageYn && { manageYn }),
    ...(productObjType && { productObjType }),
  };

  return this.getMethod("get", false, {
    url: `${endpoint.products}/categories`,
    params,
  });
}

// 캠퍼스관리 > 프로덕트 > 프로덕트 단건 조회
export async function getProduct({ campusId, memberId, id }) {
  if (!id) {
    return;
  }
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.products}/${id}`,
    params,
  });
}

// 캠퍼스관리 > 프로덕트 > 프로덕트 만들기
export async function postProduct(data) {
  return this.getMethod("post", true, {
    url: `${endpoint.products}/`,
    data,
  });
}

// 캠퍼스관리 > 프로덕트 > 프로덕트 수정하기
export async function putProduct(data) {
  return this.getMethod("put", true, {
    url: `${endpoint.products}/`,
    data,
  });
}

// 캠퍼스관리 > 프로덕트 > 프로덕트 삭제하기
export async function deleteProduct({ campusId, memberId, id }) {
  if (!id) {
    return;
  }
  const data = {
    id,
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("delete", true, {
    url: `${endpoint.products}/`,
    data,
  });
}

// 캠퍼스관리 > 프로덕트 > 카테고리 관리, 고객 추가 정보 관리
export async function postCategories({
  productObjType,
  categories,
  custAdditional,
}) {
  const data = {
    productObjType,
    ...(categories && { categories }),
    ...(custAdditional && { custAdditional }),
  };

  return this.getMethod("post", true, {
    url: `${endpoint.products}/categories`,
    data,
  });
}

// 캠퍼스관리 > 프로덕트 > 프로덕트 상테변경
export async function putStatus({
  publishYn,
  startDttm,
  campusId,
  endDttm,
  id,
  memberId,
}) {
  if (!id) {
    return;
  }
  const data = {
    publishYn,
    id,
    ...(startDttm && { startDttm }),
    ...(endDttm && { endDttm }),
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("put", true, {
    url: `${endpoint.products}/status`,
    data,
  });
}

// 메인 > 프로덕트 > 상세 조회 (멤버조회)
export async function getDetail({
  campusId,
  memberId,
  userId,
  id,
  calcUserCoupons,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(userId && { userId }),
    ...(calcUserCoupons && { calcUserCoupons }),
    id,
  };
  return this.getMethod("get", true, {
    url: `${endpoint.products}/detail`,
    params,
  });
}
// 메인 > 프로덕트 > 상세 조회 (비멤버조회)
export async function getDetailPublic({ campusId, id }) {
  const params = {
    ...(campusId && { campusId }),
    id,
  };
  return this.getMethod("get", false, {
    url: `${endpoint.products}/detail/public`,
    params,
  });
}
// 메인 > 프로덕트 > 조회 (멤버조회)
export async function getSearch({
  campusId,
  memberId,
  discountYn,
  salesYn,
  promotionYn,
  couponYn,
  categoryId,
  categoryIds,
  keyword,
  productType,
  limit,
  sort,
  order,
  salesStatus,
  lastKey,
  menu,
  partnerYn,
  partnerLinkYn,
  includeExtraIds,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(categoryIds && { categoryIds }),
    ...(keyword && { keyword }),
    ...(lastKey && { lastKey }),
    ...(limit && { limit }),
    ...(memberId && { memberId }),
    ...(order && { order }),
    ...(productType && { productType }),
    ...(promotionYn && { promotionYn }),
    ...(discountYn && { discountYn }),
    ...(salesYn && { salesYn }),
    ...(couponYn && { couponYn }),
    ...(categoryId && { categoryId }),
    ...(salesStatus && { salesStatus }),
    ...(sort && { sort }),
    ...(menu && { menu }),
    ...(includeExtraIds && { includeExtraIds }),
    ...(partnerYn !== undefined && { partnerYn }),
    ...(partnerLinkYn !== undefined && { partnerLinkYn }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.products}/search`,
    params,
  });
}
// 메인 > 프로덕트 > 조회 (비멤버조회)
export async function getSearchPublic({
  campusId,
  keyword,
  discountYn,
  promotionYn,
  couponYn,
  categoryId,
  categoryIds,
  productType,
  limit,
  sort,
  order,
  salesStatus,
  lastKey,
  menu,
  includeExtraIds,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(keyword && { keyword }),
    ...(promotionYn && { promotionYn }),
    ...(productType && { productType }),
    ...(discountYn && { discountYn }),
    ...(couponYn && { couponYn }),
    ...(limit && { limit }),
    ...(sort && { sort }),
    ...(order && { order }),
    ...(salesStatus && { salesStatus }),
    ...(lastKey && { lastKey }),
    ...(categoryIds && { categoryIds }),
    ...(categoryId && { categoryId }),
    ...(menu && { menu }),
    ...(includeExtraIds && { includeExtraIds }),
  };
  return this.getMethod("get", false, {
    url: `${endpoint.products}/search/public`,
    params,
  });
}
//캠퍼스 관리 > 예약프로덕트 > 인원확인
export async function getLimitCheck({ campusId, memberId, id }) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(id && { id }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.products}/promotions/${id}/salesCnts`,
    params,
  });
}
//캠퍼스 관리 > 프로덕트 > 가격확인
export async function postPricesCheck({
  campusId,
  memberId,
  id,
  optionId,
  promotionId,
  promoName,
  promoRatio,
  onceApplyYn,
  promoSubsRatio,
  subsApplyCnt,
  subsApplyYn,
  groupPrice,
  promoOpts,
  ignoreGroupPrice,
}) {
  const data = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(id && { id }),
    ...(optionId && { optionId }),
    ...(promotionId && { promotionId }),
    ...(promoName && { promoName }),
    ...(promoRatio && { promoRatio }),
    ...(promoSubsRatio && { promoSubsRatio }),
    onceApplyYn,
    subsApplyYn,
    ...(subsApplyCnt && { subsApplyCnt }),
    ...(groupPrice && { groupPrice }),
    ...(ignoreGroupPrice && { ignoreGroupPrice }),
    ...(promoOpts && { promoOpts }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.products}/prices`,
    data,
  });
}
//캠퍼스 관리 > 프로덕트 > 프로모션 만들기
export async function postPromotion({
  campusId,
  memberId,
  productId,
  id,
  name,
  promoLimit,
  promoPreYn,
  promoRatio,
  startDttm,
  endDttm,
  closeType,
  color,
  promoOptId,
  promoOpts,
  promoSubsRatio,
  promoSubsPrice,
  onceApplyYn,
  subsApplyYn,
  subsApplyCnt,
}) {
  const data = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(productId && { productId }),
    ...(id && { id }),
    ...(name && { name }),
    ...(promoPreYn && { promoPreYn }),
    ...(promoRatio && { promoRatio }),
    ...(promoLimit && { promoLimit }),
    ...(startDttm && { startDttm }),
    ...(endDttm && { endDttm }),
    ...(closeType && { closeType }),
    ...(color && { color }),
    ...(promoOptId && { promoOptId }),
    ...(promoOpts?.length > 0 && { promoOpts }),
    ...(promoRatio && { promoRatio }),
    ...(promoSubsRatio && { promoSubsRatio }),
    ...(promoSubsPrice && { promoSubsPrice: promoSubsPrice || 0 }),
    ...(onceApplyYn && { onceApplyYn }),
    ...(subsApplyYn && { subsApplyYn }),
    ...(subsApplyCnt && { subsApplyCnt }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.products}/promotions`,
    data,
  });
}
// 캠퍼스 관리 > 프로모션 > 프로모션 조회
export async function getPromotions({
  campusId,
  memberId,
  productId,
  promotionStatus,
  promoPreYn,
  keyword,
  limit,
  sort,
  order,
  lastKey,
  productType,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(productId && { productId }),
    ...(promotionStatus && { promotionStatus }),
    ...(promoPreYn && { promoPreYn }),
    ...(keyword && { keyword }),
    ...(limit && { limit }),
    ...(sort && { sort }),
    ...(order && { order }),
    ...(lastKey && { lastKey }),
    ...(productType && { productType }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.products}/promotions`,
    params,
  });
}
// 캠퍼스 관리 > 프로모션 > 프로모션 단건 조회
export async function getPromotion({ campusId, memberId, id }) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(id && { id }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.products}/promotions/${id}`,
    params,
  });
}
//캠퍼스 관리 > 프로모션 관리 > 프로모션 삭제
export async function deletePromotion({ campusId, memberId, id, productId }) {
  if (!id) {
    return;
  }
  const data = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(id && { id }),
    ...(productId && { productId }),
  };
  return this.getMethod("delete", true, {
    url: `${endpoint.products}/promotions`,
    data,
  });
}
//캠퍼스 관리 > 프로모션 관리 >  프로모션 수정
export async function putPromotion({
  campusId,
  memberId,
  productId,
  id,
  name,
  promoLimit,
  promoPreYn,
  promoRatio,
  startDttm,
  endDttm,
  closeType,
  color,
  promoOptId,
  promoOpts,
  promoSubsRatio,
  onceApplyYn,
  subsApplyYn,
  subsApplyCnt,
}) {
  const data = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(productId && { productId }),
    ...(id && { id }),
    ...(name && { name }),
    ...(promoPreYn && { promoPreYn }),
    ...(promoRatio && { promoRatio }),
    ...(promoLimit && { promoLimit }),
    ...(startDttm && { startDttm }),
    ...(endDttm && { endDttm }),
    ...(closeType && { closeType }),
    ...(color && { color }),
    promoLimit: promoLimit || 0,
    ...(promoOptId && { promoOptId }),
    ...(promoOpts?.length > 0 && { promoOpts }),
    ...(promoSubsRatio && { promoSubsRatio }),
    onceApplyYn,
    subsApplyYn,
    ...(subsApplyCnt && { subsApplyCnt }),
  };
  return this.getMethod("put", true, {
    url: `${endpoint.products}/promotions`,
    data,
  });
}
//캠퍼스 관리 > 프로모션 관리 >  프로모션 상태 수정
export async function putPromotionStatus({
  campusId,
  memberId,
  productId,
  promoPreYn,
  promoLimit,
  promoRatio,
  color,
  startDttm,
  endDttm,
  promotionStatus,
  name,
  id,
}) {
  const data = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(productId && { productId }),
    ...(promoPreYn && { promoPreYn }),
    promoLimit: promoLimit || 0,
    ...(promoRatio && { promoRatio }),
    ...(color && { color }),
    ...(startDttm && { startDttm }),
    ...(endDttm && { endDttm }),
    ...(promotionStatus && { promotionStatus }),
    ...(name && { name }),
    ...(id && { id }),
  };
  return this.getMethod("put", true, {
    url: `${endpoint.products}/promotions/status`,
    data,
  });
}
// 프로덕트 운영 > 연결 커뮤니티 관리
export async function putCommunities({
  productId,
  changeType, // CREATE, DELETE
  campusId,
  targets,
  memberId,
}) {
  if (!changeType || !targets || !productId) {
    return;
  }
  const data = {
    productId,
    targets,
    changeType,
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("put", true, {
    url: `${endpoint.products}/communities`,
    data,
  });
}

// 프로덕트 > 라이브 운영 > 라이브 미팅룸 만들기
export async function postLive({
  redirectUri,
  startUrl,
  productId,
  startDttm,
  service,
  campusId,
  contentId,
  endDttm,
  joinUrl,
  userId,
  memberId,
}) {
  const data = {
    ...(redirectUri && { redirectUri }),
    ...(startUrl && { startUrl }),
    ...(productId && { productId }),
    ...(startDttm && { startDttm: new Date(startDttm).toJSON() }),
    ...(service && { service }),
    ...(campusId && { campusId }),
    ...(contentId && { contentId }),
    ...(endDttm && { endDttm: new Date(endDttm).toJSON() }),
    ...(joinUrl && { joinUrl }),
    ...(userId && { userId }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.products}/lives`,
    data,
  });
}
// 프로덕트 > 라이브 운영 > 라이브 미팅룸 수정
export async function putLive({
  redirectUri,
  startUrl,
  productId,
  startDttm,
  service,
  campusId,
  contentId,
  endDttm,
  joinUrl,
  userId,
  memberId,
  meetingId,
}) {
  const data = {
    ...(redirectUri && { redirectUri }),
    ...(startUrl && { startUrl }),
    ...(productId && { productId }),
    ...(startDttm && { startDttm: new Date(startDttm).toJSON() }),
    ...(service && { service }),
    ...(campusId && { campusId }),
    ...(contentId && { contentId }),
    ...(endDttm && { endDttm: new Date(endDttm).toJSON() }),
    ...(joinUrl && { joinUrl }),
    ...(userId && { userId }),
    ...(memberId && { memberId }),
    ...(meetingId && { meetingId }),
  };
  return this.getMethod("put", true, {
    url: `${endpoint.products}/lives`,
    data,
  });
}
// 프로덕트 > 라이브 운영 > 라이브 미팅룸 삭제
export async function deleteLive({
  redirectUri,
  productId,
  service,
  campusId,
  contentId,
  userId,
  memberId,
  meetingId,
}) {
  const data = {
    ...(redirectUri && { redirectUri }),
    ...(productId && { productId }),
    ...(service && { service }), // 사용하지는 않지만 LARS 요청으로 보냄
    ...(campusId && { campusId }),
    ...(contentId && { contentId }),
    ...(userId && { userId }),
    ...(memberId && { memberId }),
    ...(meetingId && { meetingId }),
  };
  return this.getMethod("delete", true, {
    url: `${endpoint.products}/lives`,
    data,
  });
}

export async function getOgTags({ url }) {
  return this.getMethod("get", false, {
    url: `${endpoint.products}/ogtags`,
    params: { url },
  });
}

//커뮤니티 담당자 관리  조회
export async function getProductDelegators({ memberId, campusId, id }) {
  const params = {
    ...(memberId && { memberId }),
    ...(campusId && { campusId }),
    ...(id && { id }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.products}/delegators`,
    params,
  });
}
//커뮤니티 담당자 관리
export async function postProductDelegators({
  changeType,
  campusId,
  id,
  targets,
  memberId,
}) {
  const data = {
    ...(changeType && { changeType }),
    ...(campusId && { campusId }),
    ...(id && { id }),
    ...(targets && { targets }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("put", true, {
    url: `${endpoint.products}/delegators`,
    data,
  });
}

// 캠퍼스관리 > 프로덕트 > 프로덕트 상테변경
export async function postProductClone({ id, campusId, memberId }) {
  if (!id) {
    return;
  }
  const data = {
    id,
    campusId,
    memberId,
  };
  return this.getMethod("post", true, {
    url: `${endpoint.products}/clone`,
    data,
  });
}
// 캠퍼스관리 > 예약 관리 > 예약자 목록
export async function getReservationUsers({
  campusId,
  memberId,
  keyword,
  limit,
  sort,
  order,
  fetchType,
  id,
  startDttm,
  endDttm,
  optionId,
  priceId,
  excelType,
  fileName,
  productType,
  lastKey,
}) {
  const params = {
    campusId,
    memberId,
    fetchType,
    id,
    ...(keyword && { keyword }),
    ...(limit && { limit }),
    ...(startDttm && { startDttm }),
    ...(endDttm && { endDttm }),
    ...(optionId && { optionId }),
    ...(priceId && { priceId }),
    ...(excelType && { excelType }),
    ...(fileName && { fileName }),
    ...(productType && { productType }),
    ...(sort && { sort }),
    ...(order && { order }),
    ...(lastKey && { lastKey }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.products}/reservations`,
    params,
  });
}

// 제휴마케팅 > 프로덕트 수익 분배 설정
export async function putPartners({
  campusId,
  memberId,
  partnerYn,
  partnerLinkYn,
  partnerLinkRate,
  targets,
  productId,
  changeType,
}) {
  const data = {
    campusId,
    memberId,
    changeType,
    ...(partnerYn !== undefined && { partnerYn }),
    ...(partnerLinkYn !== undefined && { partnerLinkYn }),
    ...(partnerLinkRate > 0 && { partnerLinkRate }),
    ...(targets && { targets }),
    ...(productId && { productId }),
  };
  return this.getMethod("put", true, {
    url: `${endpoint.products}/partners`,
    data,
  });
}

export async function getSearchPartners({
  keyword,
  memberId,
  discountYn,
  partnerYn,
  campusId,
  categoryId,
  partnerLinkYn,
}) {
  const params = {
    ...(keyword && { keyword }),
    ...(memberId && { memberId }),
    ...(discountYn && { discountYn }),
    ...(partnerYn && { partnerYn }),
    ...(campusId && { campusId }),
    ...(categoryId && { categoryId }),
    ...(partnerLinkYn && { partnerLinkYn }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.products}/search/partners`,
    params,
  });
}
// 배송 정보 관리
export async function getDeliveries({
  keyword,
  memberId,
  limit,
  campusId,
  order,
  deliveryType,
  lastKey,
  id,
}) {
  const params = {
    ...(keyword && { keyword }),
    ...(memberId && { memberId }),
    ...(limit && { limit }),
    ...(order && { order }),
    ...(campusId && { campusId }),
    ...(deliveryType && { deliveryType }),
    ...(lastKey && { lastKey }),
    ...(id && { id }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.products}/deliveries`,
    params,
  });
}
export async function postDeliveries({
  campusId,
  memberId,
  template,
  changeType,
  deliveryType,
  location,
  account,
}) {
  const data = {
    campusId,
    memberId,
    template,
    changeType,
    deliveryType,
    location,
    account,
  };
  return this.getMethod("post", true, {
    url: `${endpoint.products}/deliveries`,
    data,
  });
}
export async function putExtra({
  campusId,
  memberId,
  productId,
  changeType,
  targets,
}) {
  const data = {
    campusId,
    memberId,
    productId,
    changeType,
    targets,
  };
  return this.getMethod("put", true, {
    url: `${endpoint.products}/extra`,
    data,
  });
}
