import { ref, reactive, computed } from "vue";
import { defineStore } from "pinia";

export const useToast = defineStore("toast", () => {
  const state = reactive({
    open: false,
    text: "",
  });

  const isOpen = computed(() => state.open);

  function open(text) {
    state.open = true;
    state.text = text || "";
  }
  function close() {
    state.open = false;
    state.text = "";
  }
  return { state, isOpen, open, close };
});
