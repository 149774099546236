export const naverWcs = (val) => {
  if (document.getElementById("naverWcsDo")) {
    document.getElementById("naverWcsDo").remove();
  }
  const scriptEl = document.createElement("script");
  scriptEl.type = "text/javascript";
  scriptEl.id = "naverWcsDo";
  if (val) {
    scriptEl.textContent = `
        var _nasa={};
        if(window.wcs) _nasa["cnv"] = wcs.cnv("${val}","1");
        if (!wcs_add) var wcs_add = {};
        wcs_add["wa"] = "s_dcaace22f60";
        if (!_nasa) var _nasa = {};
        if (window.wcs) {
          wcs.inflow("poincampus.com");
          wcs_do(_nasa);
        }
      `;
  } else {
    scriptEl.textContent = `
        if (!wcs_add) var wcs_add = {};
        wcs_add["wa"] = "s_dcaace22f60";
        if (!_nasa) var _nasa = {};
        if (window.wcs) {
          wcs.inflow("poincampus.com");
          wcs_do(_nasa);
        }
      `;
  }
  document.body.appendChild(scriptEl);
};
