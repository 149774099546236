export default {
  setAuth: (state, payload) => {
    const { snsType, auth } = payload;
    state.auth = {
      ...auth,
      snsType,
    };
  },
  setTokenRefreshing: (state, tokenRefreshing) => {
    state.tokenRefreshing = tokenRefreshing;
  },
  setAuthenticated: (state, authenticated) => {
    state.authenticated = authenticated;
  },
  setJoinUser: (state, user) => {
    state.joinUser = user;
  },
  deleteJoinUser: (state) => {
    state.joinUser = null;
  },
};
