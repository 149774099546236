import isEmpty from "lodash.isempty";

export function checkNumber(data) {
  return typeof data === "number" && data >= 0;
}

export function checkSelectSalesType(data) {
  const isSalesTypeNotEmpty = checkNotEmpty(data);
  if (isSalesTypeNotEmpty) {
    return data !== "NONE";
  }
  return isSalesTypeNotEmpty;
}

export function checkNotUndefined(data) {
  return data !== null && data !== undefined;
}

export function checkNotEmpty(data) {
  return !isEmpty(data);
}

export function checkNotEmptyString(data) {
  return data !== "";
}

// validate email
export function checkEmail(data) {
  if (!isEmpty(data)) {
    if (typeof data !== "string") {
      throw new Error("please pass string");
    }

    if (!data.trim()) return false;

    // get from https://emailregex.com/
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(data);
  }

  return false;
}

// validate password
// https://www.thepolyglotdeveloper.com/2015/05/use-regex-to-test-password-strength-in-javascript/
// https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
export function checkPassword(data) {
  if (!isEmpty(data)) {
    if (typeof data !== "string") {
      throw new Error("please pass string");
    }

    // ^(?=.[a-z])(?=.[A-Z])(?=.\d)(?=.[{}[]/?.,;:|)*~!^\-_+<>@\#$%&\\\=\(\'\"])[A-Za-z\d\{\}\[\]\/?.,;:|\)*~!^-_+<>@#$%&\=('"]{8,}
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[?<>_\-~:;.,#$^+=!*()@%&]).{8,12}$/;
    return passwordRegex.test(data);
  }
  return false;
}

// validate check name
export function checkName(data, letterLength = 20) {
  if (!isEmpty(data)) {
    if (typeof data !== "string") {
      throw new Error("please pass string");
    }
    // const stringByteLength = data.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, '$&$1$2').length
    if (!data.trim()) return false;
    if (data.length <= letterLength) {
      return true;
    }
  }

  return false;
}

export function checkMobilePhone(data) {
  if (!isEmpty(data)) {
    if (typeof data !== "string" && typeof data !== "number") {
      throw new Error("please pass string or number");
    }

    const mobileRegex = /^01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$/;
    const phoneRegex = /^\d{2,3}-\d{3,4}-\d{4}$/;
    const phone4NumberRegex = /^\d{4}-\d{4}$/;

    if (
      !mobileRegex.test(data) &&
      !phoneRegex.test(data) &&
      !phone4NumberRegex.test(data)
    ) {
      return false;
    }
    return true;
  }

  return false;
}

export function checkBirthday(data) {
  if (!isEmpty(data)) {
    if (typeof data !== "string" && typeof data !== "number") {
      throw new Error("please pass string or number");
    }
    const birthdayRegex = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
    return birthdayRegex.test(data);
  }

  return false;
}

export function checkBizNumber(value) {
  if (!value) {
    return false;
  }
  var valueMap = value
    .replace(/-/gi, "")
    .split("")
    .map(function (item) {
      return parseInt(item, 10);
    });

  if (valueMap.length === 10) {
    var multiply = [1, 3, 7, 1, 3, 7, 1, 3, 5];
    var checkSum = 0;

    for (var i = 0; i < multiply.length; ++i) {
      checkSum += multiply[i] * valueMap[i];
    }

    checkSum += parseInt((multiply[8] * valueMap[8]) / 10, 10);
    return Math.floor(valueMap[9]) === (10 - (checkSum % 10)) % 10;
  }
  return false;
}

export function checkUrl(val) {
  if (!val) return false;
  const urlRegex =
    /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
  return urlRegex.test(val);
}

export function passwordRules(value) {
  if (value.length < 8) {
    return "8글자 이상 입력해주세요.";
  } else if (value.length > 12) {
    return "12글자 이하로 입력해주세요.";
  } else if (!value.match(/[a-z]/)) {
    return "영어 소문자를 1글자 이상 포함해서 입력해주세요.";
  } else if (!value.match(/[A-Z]/)) {
    return "영어 대문자를 1글자 이상 포함해서 입력해주세요.";
  } else if (!value.match(/[?<>_\-~:;.,#$^+=!*()@%&]/)) {
    return "특수문자를 1글자 이상 포함해서 입력해주세요.";
  } else if (!value.match(/\d/)) {
    return "숫자를 1글자 이상 포함해서 입력해주세요.";
  } else {
    return "영어(대문자 + 소문자), 숫자, 특수문자 조합 8~12자를 입력해주세요.";
  }
}

export function isValidJSON(str) {
  // JSON 문자열에 공백을 제거
  str = str.trim();

  // JSON.parse를 이용하여 실제로 파싱 가능한지 확인
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

/**
 * 제주, 도서산간 여부 확인
 * @param zipCode
 * @param {'JEJU'|'REMOTE'|'ALL'} mode 제주, 도서산간, 전체
 * @return {boolean}
 */
export function isDeliveryAddedCostArea(zipCode, mode) {
  const jejuRanges = [
    { name: "제주도", range: ["63002", "63644"] },
    { name: "제주도 섬지역", range: ["63000", "63001"] },
  ];
  const remoteRanges = [
    { name: "경기 안산시 풍도 섬지역", range: ["15654", "15654"] },
    { name: "인천 강화 섬지역", range: ["23008", "23010"] },
    { name: "인천 웅진 섬지역1", range: ["23100", "23116"] },
    { name: "인천 웅진 섬지역2", range: ["23124", "23136"] },
    { name: "충남 태안 섬지역", range: ["32133", "32133"] },
    { name: "충남 보령 섬지역", range: ["33411", "33411"] },
    { name: "경북 울릉도 전지역", range: ["40200", "40240"] },
    { name: "경남 사천 섬지역", range: ["52570", "52571"] },
    { name: "경남 통영 섬지역1", range: ["53031", "53033"] },
    { name: "경남 통영 섬지역2", range: ["53088", "53104"] },
    { name: "전북 군산시 옥도면 섬지역", range: ["54000", "54000"] },
    { name: "전북 부안 섬지역", range: ["56347", "56349"] },
    { name: "전남 영광 섬지역", range: ["57068", "57069"] },
    { name: "전남 목포 섬지역", range: ["58760", "58761"] },
    { name: "전남 신안 섬지역1", range: ["58800", "58804"] },
    { name: "전남 신안 섬지역2", range: ["58809", "58810"] },
    { name: "전남 신안 섬지역3", range: ["58816", "58818"] },
    { name: "전남 신안 섬지역4", range: ["58826", "58826"] },
    { name: "전남 신안 섬지역5", range: ["58832", "58832"] },
    { name: "전남 신안 섬지역6", range: ["58839", "58841"] },
    { name: "전남 신안 섬지역7", range: ["58843", "58866"] },
    { name: "전남 진도 섬지역", range: ["58953", "58958"] },
    { name: "전남 완도 섬지역1", range: ["59102", "59103"] },
    { name: "전남 완도 섬지역2", range: ["59127", "59127"] },
    { name: "전남 완도 섬지역3", range: ["59137", "59145"] },
    { name: "전남 완도 섬지역4", range: ["59149", "59170"] },
    { name: "전남 보성군 벌교읍 섬지역", range: ["59421", "59421"] },
    { name: "전남 고흥군 섬지역1", range: ["59531", "59531"] },
    { name: "전남 고흥군 섬지역2", range: ["59551", "59551"] },
    { name: "전남 고흥군 섬지역3", range: ["59563", "59563"] },
    { name: "전남 고흥군 섬지역4", range: ["59568", "59568"] },
    { name: "전남 여수시 섬지역1", range: ["59650", "59650"] },
    { name: "전남 여수시 섬지역2", range: ["59766", "59766"] },
    { name: "전남 여수시 섬지역3", range: ["59781", "59790"] },
  ];

  const ranges =
    mode === "JEJU"
      ? jejuRanges
      : mode === "REMOTE"
        ? remoteRanges
        : [...jejuRanges, ...remoteRanges];
  const strCode = zipCode.toString();
  return ranges.some((region) => {
    return strCode >= region.range[0] && strCode <= region.range[1];
  });
}

export function validateSegments(data) {
  if (!Array.isArray(data) || data.length === 0) {
    return false;
  }

  const quantities = data.map((item) => item.quantity);
  const uniqueQuantities = new Set(quantities);

  // 수량이 오름차순인지 체크
  const isAscending = quantities.every((quantity, index) => {
    return index === 0 || quantity > quantities[index - 1];
  });

  // Check all conditions
  const isValid = data.every((item) => {
    return (
      // 수량이 2개 이상인지 큰지 체크
      item.quantity >= 2 &&
      // 배송비가 ₩ 100 이상인지 큰지 체크
      // item.cost >= 100 &&
      // 수량 중복 여부 체크
      uniqueQuantities.size === data.length
    );
  });

  return isAscending && isValid;
}
