export default {
  getCommunities(state) {
    return state.communities.items;
  },
  getCommunitiesLastKey(state) {
    return state.communities.lastKey;
  },
  getCommunity(state) {
    return state.community;
  },
  getCommunityDashboard(state) {
    return state.communityDashboard;
  },
  getPosts(state) {
    return state.posts.items;
  },
  getPostsLastKey(state) {
    return state.posts.lastKey;
  },
  getPost(state) {
    return state.post;
  },
  getMyPostsLastKey(state) {
    return state.myPosts.lastKey;
  },
  getMyPosts(state) {
    return state.myPosts.items;
  },
  getCommunityDelegators(state) {
    return state.community.delegators;
  },
};
