import { dateFormat } from "@/utils";
import isEmpty from "lodash.isempty";
import { MemberStatus, Authority } from "@/enums/membersEnum";
import { PaymentStatus } from "@/enums/paymentsEnum";

export default {
  getStaffList: (state) => {
    return isEmpty(state.staff.value) ? [] : state.staff.value;
  },
  getStaffLastKey: (state) => {
    return state.staff.lastKey || "";
  },
  getInviteStaff: (state) => {
    return state.staff.inviteCnt || 0;
  },
  getCertificateKey: (state) => {
    return state.certificateKey || "";
  },
  getMemberItem: (state) => {
    return isEmpty(state.memberItem) ? null : state.memberItem;
  },
  getMemberId: (state) => {
    return state.memberItem?.id || state.memberItem?.uuid || "";
  },
  getMemberUserId: (state) => {
    return state.memberItem?.userId || state.memberItem?.userId || "";
  },
  getMemberCommunityId(state) {
    return state.memberItem?.communityIds || [];
  },
  getMemberRoleCd: (state) => {
    if (!state.memberItem) return null;
    return state.memberItem.role?.code || state.memberItem.code;
  },
  getMemberAuthorityCodes: (state) => {
    if (!state.memberItem || !state.memberItem.role) return null;

    return state.memberItem.role?.authorities;
  },
  isCampusMember: (state) => {
    return !!state.memberItem;
  },
  isCampusJoinMember: (state) => {
    return (
      state.memberItem && state.memberItem.memberStatus === MemberStatus.JOIN
    );
  },
  isCampusRequestMember: (_, getters) => {
    return [MemberStatus.REQUEST, MemberStatus.RETRY_REQUEST].includes(
      getters.getMemberStatus
    );
  },
  getMemberStatus: (state) => {
    return state.memberItem?.memberStatus || "";
  },
  getLastAccCont: (state, getters) => {
    if (!getters.isCampusJoinMember) {
      return undefined;
    }
    const ids = state.memberItem?.lastAccCont?.split("#");
    const productId = ids?.[0] || "";
    const contentId = ids?.[1] || "";
    if (!productId || !contentId) {
      return undefined;
    }
    return {
      productId,
      contentId,
    };
  },
  getDeliveryAddress: (state) => {
    return state.memberItem?.recipients || [];
  },
  /* 판매 클래스 */
  getSalesClasses: (state) => {
    const { memberItem } = state;
    if (memberItem) {
      return memberItem.salesClasses;
    }
    return null;
  },
  getMemberClasses: (state) => {
    const { memberItem } = state;
    if (memberItem) {
      return memberItem.classes;
    }
    return null;
  },
  getPaymentDttm: (_, getters) => {
    const { getSalesMemberItem: salesMemberItem } = getters;
    if (salesMemberItem && salesMemberItem.paymentDttm) {
      return dateFormat.getDateFormat(
        salesMemberItem.paymentDttm,
        "yyyy-MM-dd HH:mm:ss"
      );
    }
    return "";
  },
  getEndDttm: (_, getters) => {
    const { getSalesMemberItem: salesMemberItem } = getters;
    if (salesMemberItem && salesMemberItem.endDttm) {
      return dateFormat.getDateFormat(
        salesMemberItem.endDttm,
        "yyyy-MM-dd HH:mm:ss"
      );
    }
    return "";
  },
  // roles
  getRoles: (state) => {
    return state.roles;
  },
  // 권한
  getAuthorites: (state) => {
    return state.authorities;
  },
  /**
   * 멤버의 역할 or 권한 소유 여부
   * 개설자는 항상 true
   * 역할이나 권한 중 하나만 가지고 있어도 true
   * regUserId 넘길 경우, 사용자 id와 일치하는지 여부 체크 (코스, 포스트 개설자여부)
   * @param roleCd
   * @param authority
   * @param ignoreIsJoinMember
   * @param regUserId
   * @param userId
   * @return boolean
   */
  hasRoleOrAuth: (state, getters, ___, rootGetters) => (params) => {
    if (rootGetters["campuses/isCampusOwner"]) return true;
    if (!params) return false;

    const {
      roleCd,
      authority,
      ignoreIsJoinMember = false,
      regUserId,
      staffOnly = false,
      userId = rootGetters["users/getUserUuid"],
    } = params;
    if (staffOnly && !authority) {
      return getters.isStaff;
    }
    if (!roleCd && !authority) return false;
    // 참여 멤버 여부 체크 - ignore인 경우 참여 여부 체크하지 않음.
    if (!ignoreIsJoinMember && !getters.isCampusJoinMember) return false;

    if (roleCd === getters.getMemberRoleCd) return true;

    return authority?.some((a) => {
      // super 권한이거나, 개설자여부 체크하지 않는 경우.
      if (a?.includes("SUPER") || !regUserId) {
        return getters.getMemberAuthorityCodes?.includes(a);
      }
      // super 권한 아니고, 개설자 여부 체크하는 경우.
      return (
        regUserId === userId && getters.getMemberAuthorityCodes?.includes(a)
      );
    });
  },
  isStaff: (state, __, ___, rootGetters) => {
    if (rootGetters["campuses/isCampusOwner"]) return true;
    return (
      (state.memberItem?.role?.staffYn &&
        state.memberItem?.memberStatus === "JOIN") ||
      false
    );
  },
  isCampusSuper: (_, getters) => {
    return getters.hasRoleOrAuth({
      authority: [Authority.CAMPUS_SUPER_MANAGE],
    });
  },
  isProductSuper: (_, getters) => {
    return getters.hasRoleOrAuth({
      authority: [
        Authority.CAMPUS_SUPER_MANAGE,
        Authority.PRODUCT_SUPER_MANAGE,
      ],
    });
  },
  getCampusMembers: (state) => {
    return state.campusMembers || [];
  },
  getCampusMembersLastKey: (state) => {
    return state.campusMembersLastKey;
  },
  getCampusMembersCnt: (state) => {
    return state.campusMembersCnt;
  },
  getCampusMember: (state) => {
    return state.campusMember;
  },
  getProductsMembers: (state) => {
    return state.productsMembers.items || [];
  },
  getProductsMembersLastKey: (state) => {
    return state.productsMembers.lastKey;
  },
  getProductsMember: (state) => {
    return state.productsMember;
  },
  getProductMember: (state) => {
    return state.productMember;
  },
  getProductMemberProgress: (state) => {
    return state.productMemberProgress;
  },
  getCommunityMembersLastKey(state) {
    return state.communityMembers.lastKey;
  },
  getCommunityMembers(state) {
    return state.communityMembers.members;
  },
  getJoinProducts: (state) => {
    return state.joinProducts;
  },
  getCommunityMember(state) {
    return state.communityMember;
  },
  getMyProductMembers(state) {
    return (
      state.memberItem?.productMembers?.filter(
        (m) =>
          ![
            PaymentStatus.CANCEL,
            PaymentStatus.CANCEL_PART,
            PaymentStatus.PAY_REJECT,
            PaymentStatus.PAY_REVERT,
          ].includes(m.paymentStatus) &&
          ![MemberStatus.CANCEL, MemberStatus.DEL].includes(m.memberStatus)
      ) || []
    );
  },
  isProductMember(state) {
    if (state.productMember?.isOnTime) {
      return true;
    }
    const isJoin = state.productMember?.memberStatus === "JOIN";
    const startDttm = state.productMember?.startDttm || 99999999999999;
    const endDttm = state.productMember?.endDttm || 99999999999999;
    const isStart = new Date(new Date().getTime() + 5000) > new Date(startDttm); // 서버와 클라이언트 간 시간 오차 5초
    const isEnd = new Date() < new Date(endDttm);
    return state.productMember?.id && isJoin && isStart && isEnd;
  },
  getJoinCommunities(state) {
    return state.joinCommunities || [];
  },
  getMyCommunities(state) {
    return state.memberItem?.communities || [];
  },
  getMyCommunityMembers(state) {
    return state.memberItem?.communityMembers || [];
  },
  getAuthorityTables: (state) => state.authorities.tables.auth,
  getAuthorityItems: (state) => state.authorities.results,
  getGroupMembers: (state) => {
    return state.groupMembers || [];
  },
  getGroupMembersLastKey: (state) => {
    return state.groupMembersLastKey;
  },
  getGroupMembersCnt: (state) => {
    return state.groupMembersCnt;
  },
  getMktYn: (state) => {
    return state.memberItem?.mktYn || false;
  },
  getMktForbiddenRoute: (state) => {
    return state.mktForbiddenRoute;
  },
  isPartner (state) {
    return !!state.memberItem?.partnerCode;
  }
};
