import _ from "lodash";

export default {
  updateApplicationsStaff(state, result) {
    state.applicationsStaff.lastKey = result.lastKey;
    if (result.results) {
      state.applicationsStaff.items = [
        ...state.applicationsStaff.items,
        ...result.results,
      ];
    }
  },
  updateApplicationList(state, result) {
    state.applicationsStaff.lastKey = result?.lastKey;
    state.applicationsStaff.items = result.items;
  },
  clearApplicationsStaff(state) {
    state.applicationsStaff.lastKey = "";
    state.applicationsStaff.items = [];
  },
  updateApplicationStatus(state, { id, publishYn }) {
    state.applicationsStaff.items.find(
      (application) => application.id === id
    ).publishYn = publishYn;
  },
  /*   
  캠퍼스 관리 > 프로덕트 관리 > 프로덕트 목록 테이블에서 프로덕트 삭제 시, 
  store의 프로덕트 목록 중 해당 프로덕트 삭제 처리
   */
  removeApplication(state, id) {
    state.applicationsStaff.items = state.applicationsStaff.items.map(
      (application) => {
        if (application.id === id) {
          return {
            ...application,
            name: "삭제된 신청서입니다.",
            delYn: true,
          };
        }
        return application;
      }
    );
  },
  /*   
  캠퍼스 관리 > 프로덕트 관리 > 프로덕트 목록 테이블에서 노출 정보 수정 시, 
  store의 프로덕트 목록 중 해당 프로덕트 정보 업데이트
   */
  updateApplications(state, newApplication) {
    state.applicationsStaff.items = state.applicationsStaff.items.map(
      (application) => {
        if (application.id === newApplication?.id) {
          return {
            ...newApplication,
          };
        }
        return application;
      }
    );
  },
  // store에 저장된 프로덕트 단건 업데이트
  setApplication(state, application = {}) {
    state.application = { ...application };
  },
  updateApplication(state, application = {}) {
    state.application = {
      ...state.application,
      ...application,
    };
  },
  clearApplication(state) {
    state.application = {};
  },
};
