export default {
  cognitoId: "",
  user: {},
  instructor: {},
  subscriptions: {
    user: [],
    instructor: [],
  },
  userUuid: "",
};
