export default {
  getContent(state) {
    return state.content;
  },
  getContents(state) {
    return state.contents.items;
  },
  getContentsLastKey(state) {
    return state.contents.lastKey;
  },
  getDeletableVimeoIds(state) {
    return state.deletableVimeoIds;
  },
  getContentDelegators(state) {
    return state.delegators;
  },
  getContentDelegatorIds(state) {
    return state.delegatorIds;
  },
  getFileReady(state) {
    return state.fileReady;
  },
};
